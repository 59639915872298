import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  sectionContainer: {
    left: 75.75,
    width: 458.25
  },
  sectionTitle: {
    fontSize: 12,
    fontFamily: 'Roboto',
    color: '#0079A3',
    marginBottom: 10,
    marginTop: 14
  },
  date: {
    fontSize: 9,
    fontFamily: 'Helvetica Neue',
    color: '#0079A3'
  },
  career: {
    fontFamily: 'Helvetica Neue Medium',
    fontWeight: 'medium',
    fontSize: 9
  },
  school: {
    fontSize: 9,
    fontFamily: 'Helvetica Neue',
    fontWeight: 'medium'
  },
  descriptionContainer: {
    marginTop: 7,
    fontSize: 9,
    fontFamily: 'Helvetica Neue',
    fontWeight: 'medium',
    marginBottom: 12
  }
});
