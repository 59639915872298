import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import './progressSideBar.scss';
import SharedOption from '../sharedResumeForm/SharedOption';
import SharedResumeForm from '../sharedResumeForm/SharedResumeForm';
import CustomTooltip, { PopUpPosition } from '../../../CustomTooltip/CustomTooltip';
import DownloadPopup from '../../../DownloadPopup/DownloadPopup';
import Spinner from '../../../Spinner/Spinner';
import { ArrowLeft, ArrowRight, CircleProgressBar, DownloadIcon } from '../../../../Assets/CustomIcons';
import { TemplatePDF, progressAnimationSpeed } from '../../../../constants/globalConstants';
import { ResumeSections, SectionId } from '../../../../constants/sectionIDs';
import { ISection, SectionStatus } from '../../../../Models/FormResumeSection';
import { IResumeForm } from '../../../../Models/IResumeForm';
import { ISharedFormOption } from '../../../../Models/ISharedFormOption';
import { addSharedResumes } from '../../../../redux/actions/resumeActions';
import { RootStore } from '../../../../redux/store';
import AquaTemplate from '../../../../templates/Aqua';
import ClassicTemplate from '../../../../templates/Classic/ResumenDocument';
import CorporateTemplate from '../../../../templates/Corporate/ResumeDocument';
import FormalTemplate from '../../../../templates/Formal';
import ModernTemplate from '../../../../templates/Modern/Main';
import PlainTemplate from '../../../../templates/Plain';

interface IResumeStatesProp {
  personalInformationStatus: SectionStatus;
  skillStatus: SectionStatus;
  workExperienceStatus: SectionStatus;
  educationStatus: SectionStatus;
  languageStatus: SectionStatus;
}
interface IProgressSideBarProps {
  resumeId: string;
  resumeFormData: IResumeForm;
  currentSection: ISection;
  progressStatus: number;
  resumeStates: IResumeStatesProp;
  saving: boolean;
}

interface IProgressSideBarDispatchProps {
  setCurrentSection(sectionId: SectionId): void;
  addSharedResumes(data: string[], updatedDate: Date): void;
  save: () => void;
}

type IProgressBarProps = IProgressSideBarDispatchProps & IProgressSideBarProps;

interface IResumeState {
  title: ResumeSections;
  stateValue: SectionStatus;
  sectionId: SectionId;
}
interface IProgressSideBarState {
  resumeStates: IResumeState[];
  percentageSaving: number;
  animating: boolean;
  open: boolean;
  loading: boolean;
}

class ProgressSideBar extends React.Component<IProgressBarProps, IProgressSideBarState> {
  constructor(props: IProgressBarProps) {
    super(props);
    this.state = {
      resumeStates: [
        {
          title: ResumeSections.PERSONAL_INFORMATION,
          stateValue: this.props.resumeStates.personalInformationStatus,
          sectionId: SectionId.PERSONAL_INFORMATION_SECTION
        },
        {
          title: ResumeSections.SKILL,
          stateValue: this.props.resumeStates.skillStatus,
          sectionId: SectionId.SKILL_SECTION
        },
        {
          title: ResumeSections.WORK_EXPERIENCE,
          stateValue: this.props.resumeStates.workExperienceStatus,
          sectionId: SectionId.WORK_EXPERIENCE_SECTION
        },
        {
          title: ResumeSections.EDUCATION,
          stateValue: this.props.resumeStates.educationStatus,
          sectionId: SectionId.EDUCATION_SECTION
        },
        {
          title: ResumeSections.LANGUAGE,
          stateValue: this.props.resumeStates.languageStatus,
          sectionId: SectionId.LANGUAGE_SECTION
        }
      ],
      percentageSaving: 0,
      animating: false,
      open: false,
      loading: false
    };
  }

  initAnimation() {
    this.setState({ animating: true });
    const animationInterval = setInterval(() => {
      if (this.state.percentageSaving <= 90) {
        this.setState(({ percentageSaving }) => ({
          percentageSaving: percentageSaving + 10
        }));
      } else {
        clearInterval(animationInterval);
        this.setState({
          animating: false,
          percentageSaving: 0
        });
      }
    }, progressAnimationSpeed);
  }

  watchSaving(prevSaving: boolean) {
    const { saving } = this.props;
    if (prevSaving !== saving && saving === false) {
      this.initAnimation();
    }
  }

  componentDidUpdate(prevProps: IProgressSideBarProps) {
    const { resumeStates } = this.props;
    const { saving } = prevProps;
    if (prevProps.resumeStates !== resumeStates) {
      const newStates = [
        {
          title: ResumeSections.PERSONAL_INFORMATION,
          stateValue: resumeStates.personalInformationStatus,
          sectionId: SectionId.PERSONAL_INFORMATION_SECTION
        },
        {
          title: ResumeSections.SKILL,
          stateValue: resumeStates.skillStatus,
          sectionId: SectionId.SKILL_SECTION
        },
        {
          title: ResumeSections.WORK_EXPERIENCE,
          stateValue: resumeStates.workExperienceStatus,
          sectionId: SectionId.WORK_EXPERIENCE_SECTION
        },
        {
          title: ResumeSections.EDUCATION,
          stateValue: resumeStates.educationStatus,
          sectionId: SectionId.EDUCATION_SECTION
        },
        {
          title: ResumeSections.LANGUAGE,
          stateValue: resumeStates.languageStatus,
          sectionId: SectionId.LANGUAGE_SECTION
        }
      ];
      this.setState({
        resumeStates: newStates
      });
    }

    this.watchSaving(saving);
  }

  onClose = () => {
    this.setState({ open: false });
  };

  onOpen = () => {
    this.setState({ open: true });
    this.props.save();
  };

  resumesExported = () => {
    return [
      {
        name: capitalize(TemplatePDF.CLASSIC),
        component: () => <ClassicTemplate resume={this.props.resumeFormData} />
      },
      {
        name: capitalize(TemplatePDF.CORPORATE),
        component: () => <CorporateTemplate resume={this.props.resumeFormData} />
      },
      {
        name: capitalize(TemplatePDF.MODERN),
        component: () => <ModernTemplate resume={this.props.resumeFormData} />
      },
      {
        name: capitalize(TemplatePDF.AQUA),
        component: () => <AquaTemplate resume={this.props.resumeFormData} />
      },
      {
        name: capitalize(TemplatePDF.FORMAL),
        component: () => <FormalTemplate resume={this.props.resumeFormData} />
      },
      {
        name: capitalize(TemplatePDF.PLAIN),
        component: () => <PlainTemplate resume={this.props.resumeFormData} />
      }
    ];
  };

  setLoading = (state: boolean) => {
    this.setState({ loading: state });
  };

  userOptions = (data: DropdownItemProps[]): ISharedFormOption[] => {
    return data.map((user: DropdownItemProps) => ({
      key: user.email as string,
      value: user.email as string,
      text: user.name as string,
      content: <SharedOption name={user.name} email={user.email} picture={user.picture} />
    }));
  };

  render() {
    const {
      currentSection,
      progressStatus,
      saving,
      setCurrentSection,
      resumeFormData,
      resumeId
    } = this.props;
    const { percentageSaving, animating, loading } = this.state;
    const { email } = resumeFormData.personalInformation;
    const enabledShare = resumeId !== '' && email !== '';
    return (
      <div className='card-container progress-side-bar-table' id='progress-bar'>
        <CustomTooltip
          items={this.state.resumeStates}
          handleClick={setCurrentSection}
          updatedDate={resumeFormData.updatedDate}
          resumeId={this.props.resumeId}
          position={PopUpPosition.LEFT_CENTER}
        >
          {!saving && !animating ? (
            <div>
              <CircleProgressBar className={'circle-' + progressStatus.toString()}>
                {`${progressStatus.toString()}%`}
              </CircleProgressBar>
            </div>
          ) : (
            <div>
              <CircleProgressBar className={'circle-' + percentageSaving}>
                <div>
                  <Icon color='green' name='save' size='large' />
                </div>
              </CircleProgressBar>
            </div>
          )}
        </CustomTooltip>
        <div>
          <Link
            activeClass='active'
            to={currentSection.previousSectionId}
            spy={true}
            smooth={true}
            duration={500}
            containerId='form-sections'
          >
            <ArrowLeft className='side-bar-icon' onClick={this.handlePreviousSectionClicked} />
          </Link>
        </div>
        <div>
          <Link
            activeClass='active'
            to={currentSection.nextSectionId}
            spy={true}
            smooth={true}
            duration={500}
            containerId='form-sections'
          >
            <ArrowRight className='side-bar-icon' onClick={this.handleNextSectionClicked} />
          </Link>
        </div>
        <div className='shared-container'>
          <SharedResumeForm />
        </div>
        <div className='download-container'>
          <DownloadPopup
            items={this.resumesExported()}
            fileName={`${this.generatePdfDownloadName()}.pdf`}
            resume={resumeFormData}
            open={this.state.open}
            onClose={this.onClose}
            onOpen={this.onOpen}
            setLoading={this.setLoading}
          >
            {loading ? <Spinner /> : <DownloadIcon className='side-bar-download' />}
          </DownloadPopup>
        </div>
      </div>
    );
  }

  handleNextSectionClicked = () => {
    const {
      currentSection: { nextSectionId }
    } = this.props;
    this.props.setCurrentSection(nextSectionId);
  };

  handlePreviousSectionClicked = () => {
    const {
      currentSection: { previousSectionId }
    } = this.props;
    this.props.setCurrentSection(previousSectionId);
  };

  generatePdfDownloadName = () => {
    const { personalInformation } = this.props.resumeFormData;
    const name = personalInformation.fullName.replace(/\s/g, ',');
    const currentDate = new Date();
    const formatedDate = moment(currentDate).format('DD-MM-YYYY').replace(/-/g, '');
    return `RESUME_${name}-${formatedDate}`;
  };
}

const mapStateToProps = (state: RootStore): IProgressSideBarProps => {
  return {
    resumeId: state.resume.resumeId,
    resumeFormData: state.resume.resumeFormData,
    currentSection: state.resume.currentSection,
    progressStatus: state.resume.resumeFormData?.progressStatus || 0,
    saving: state.resume.saving,
    resumeStates: {
      personalInformationStatus: state.resume.personalInformationStatus,
      skillStatus: state.resume.skillStatus,
      workExperienceStatus: state.resume.workExperienceStatus,
      educationStatus: state.resume.educationStatus,
      languageStatus: state.resume.languageStatus
    }
  };
};

export default connect(mapStateToProps, {
  addSharedResumes: addSharedResumes
})(ProgressSideBar);
