import React from 'react';
import ReactQuill from 'react-quill';
import { Delta, Sources } from 'quill';
import 'quill-paste-smart';

import { RTFConfig } from './richTextEditor.config';
import 'react-quill/dist/quill.snow.css';
import styles from './richTextEditor.module.scss';

// let icons = (ReactQuill as any).Quill.import('ui/icons');
// icons['bold'] = `<i onClick="this.toggleRichTextButtonsColor()" aria-hidden="true" class="bold icon quill-editor-icons"></i>`;
// icons['italic'] = `<i onClick="this.toggleRichTextButtonsColor()" aria-hidden="true" class="italic icon quill-editor-icons"></i>`;
// icons['underline'] = `<i onClick="this.toggleRichTextButtonsColor()" aria-hidden="true" class="underline icon quill-editor-icons"></i>`;

interface IRichTextEditorProps {
  onQuillError: boolean;
  placeHolder: string;
  value?: string;
  defaultValue?: string;
  onQuillEditorChange(content: string): void;
  onQuillEditorBlur?: () => void;
}

export default class RichTextEditor extends React.Component<IRichTextEditorProps> {
  static defaultProps = {
    defaultValue: ''
  };

  componentDidMount() {
    const buttons = document.querySelectorAll('.ql-toolbar button');
    buttons.forEach((button) => {
      button.setAttribute('tabindex', '-1');
    });
  }

  onBlur = () => {
    this.props.onQuillEditorBlur && this.props.onQuillEditorBlur();
  };

  onChange = (content: string, delta: Delta, source: Sources) => {
    source === 'user' && this.props.onQuillEditorChange(content);
  };

  render() {
    const { onQuillError } = this.props;
    return (
      <ReactQuill
        className={`${styles.reactQuillColor} ${onQuillError ? styles.reactQuillError : ''}`}
        theme='snow'
        modules={RTFConfig}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholder={this.props.placeHolder}
        value={this.props.value || this.props.defaultValue}
        preserveWhitespace
      />
    );
  }
}
