import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';

import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';
import { verifyStringLength } from '../utils/stringUtils';
import { IWorkExperience } from '../../Models/IWorkExperience';

interface IExperiencesProps {
  data: IWorkExperience[];
  highLightColor: string;
}

interface IExperienceProps {
  data: IWorkExperience;
  isFirst: boolean;
  highLightColor: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    title: {
      fontSize: 14,
      marginBottom: 10,
      marginTop: 14,
      color: textColor
    },
    experienceContainer: {
      fontSize: 10
    },
    date: {
      color: textColor
    },
    job: {
      fontFamily: 'Helvetica Bold'
    },
    client: {
      marginBottom: 6
    },
    skillContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    skillBox: {
      padding: 4,
      borderRadius: 4,
      marginTop: 5,
      marginRight: 5,
      backgroundColor: '#F5F5F5'
    },
    descriptionContainer: {
      marginTop: 7,
      marginBottom: 12
    },
    ellipsis: {
      maxLines: 2,
      maxWidth: '47%',
      textOverflow: 'ellipsis'
    }
  });

const skillMaxLength = 55;
const ellipsisMaxLength = 75;

Font.register({
  family: 'Helvetica Bold',
  src: `/fonts/Helvetica/HelveticaNeueBold.ttf`
});

const getIndexToFirstLine = (skills: string[]): number => {
  const SIZE_PER_LETTER = 6;
  const SIZE_PER_SKILL = 14;
  const WIDTH_LINE = 500;

  const skillLength = skills.length - 1;
  let firstLineSkills = 0;
  let indexLine = skillLength;
  skills.forEach((item, index) => {
    const value = item.length * SIZE_PER_LETTER + SIZE_PER_SKILL;
    if (firstLineSkills + value > WIDTH_LINE && indexLine === skillLength) {
      indexLine = index;
    }
    firstLineSkills += value;
  });

  return indexLine;
};

const Experience: React.FC<IExperienceProps> = (props) => {
  const { client, description, endDate, startDate, jobTitle, profile, skills } = props.data;
  const skillsCopy = [...skills];

  const getExperienceDate = (startDate: string, endDate: string) => {
    startDate = startDate ? startDate.replace('-', ' ') : '';
    endDate = endDate ? endDate.replace('-', ' ') : 'Present';
    return startDate.concat(' - ').concat(endDate);
  };

  const quantity = getIndexToFirstLine(skills);
  const firstSkillsLine = skillsCopy.splice(0, quantity + 1);
  const jobTitleText = jobTitle.replace(/\s+/g, ' ').trim();
  const profileText = profile.replace(/\s+/g, ' ').trim();

  return (
    <React.Fragment>
      <View style={styles().experienceContainer}>
        <View wrap={false}>
          {props.isFirst && <Text style={styles(props.highLightColor).title}> Experience </Text>}
          <Text style={styles(props.highLightColor).date}>
            {' '}
            {getExperienceDate(startDate, endDate)}{' '}
          </Text>
          <View style={[styles().job, { flexDirection: 'row' }]}>
            <Text style={styles().ellipsis}>{jobTitleText}</Text>
            <Text style={{ maxWidth: '5%', paddingHorizontal: 5 }}>{profile && ' | '}</Text>
            <Text style={styles().ellipsis}>{profileText}</Text>
          </View>
          <Text style={styles().client}> {verifyStringLength(client, ellipsisMaxLength)}</Text>
          <View style={styles().skillContent}>
            {firstSkillsLine.map((name, index) => (
              <View key={index} style={styles().skillBox}>
                <Text>{verifyStringLength(name, skillMaxLength)}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles().skillContent}>
          {skillsCopy.map((name, index) => (
            <View key={index} style={styles().skillBox} wrap={false}>
              <Text>{verifyStringLength(name, skillMaxLength)}</Text>
            </View>
          ))}
        </View>
        <View style={styles().descriptionContainer}>
          <HTMLContent data={description} />
        </View>
      </View>
    </React.Fragment>
  );
};

const Experiences: React.FC<IExperiencesProps> = ({ data, highLightColor }) => {
  return (
    <React.Fragment>
      {data &&
        data.reduce((result: Array<JSX.Element>, experience, index) => {
          experience.enabled &&
            result.push(
              <Experience
                key={index}
                data={experience}
                isFirst={result.length === 0}
                highLightColor={highLightColor}
              />
            );
          return result;
        }, [])}
    </React.Fragment>
  );
};

export default Experiences;
