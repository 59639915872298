import { Font, Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './ModernUtils';
import { templateColors } from '../utils/constants';
import { verifyStringLength, wordBreak } from '../utils/stringUtils';
import { ISkill } from '../../Models/ISkills';

interface ISkillsProps {
  skills: ISkill[];
  highLightColor: string;
}

interface ISkillObj {
  area: string;
  skills: ISkill[];
}

interface IAreaProps {
  title: string;
  skills: ISkill[];
  isFirst: boolean;
  highLightColor: string;
}

interface ISkillProps {
  skill: string;
  level: string;
  highLightColor: string;
}

const styles = (textColor = templateColors.blue) => {
  const mainTitleColor = getColors(textColor).mainTitle;
  return StyleSheet.create({
    skillsContainer: {
      marginBottom: 1
    },
    container: {
      marginBottom: 1
    },
    mainTitle: {
      fontSize: 14,
      marginBottom: 10,
      marginTop: 14,
      color: mainTitleColor
    },
    areaTitle: {
      fontSize: 10,
      fontFamily: 'Helvetica Neue Medium',
      marginBottom: 4
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginRight: 5,
      marginBottom: 3,
      paddingRight: 6
    },
    skillName: {
      fontSize: 10,
      fontFamily: 'Helvetica Neue Light',
      paddingRight: 3,
      paddingTop: 4,
      paddingBottom: 2,
      width: '85%',
      maxLines: 4
    },
    contentLevel: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    level: {
      width: 3,
      height: 9,
      marginLeft: 0.75,
      marginRight: 0.75,
      top: 6
    },
    complement: {
      width: 3,
      height: 9,
      marginLeft: 0.75,
      marginRight: 0.75,
      backgroundColor: '#E0E0E0',
      top: 6
    }
  });
};

Font.register({
  family: 'Helvetica Neue Light',
  src: `/fonts/Helvetica/HelveticaNeueLight.ttf`
});

Font.register({
  family: 'Helvetica Neue Medium',
  src: `/fonts/Helvetica/HelveticaNeueMedium.ttf`
});

const MAX_STRING_LENGTH = 55;
const MAX_SKILL_WORD_LENGTH = 15;

const generateRatingBars = (level: number, highLightColor: string) => {
  const ratings = [];
  let blocksNumber = 5;
  while (blocksNumber > 0) {
    const checkLevel = level > 0;
    const smallBlock = (
      <View
        key={blocksNumber}
        style={checkLevel ? { ...styles().level, backgroundColor: highLightColor } : styles().complement}
      />
    );
    level--;
    blocksNumber--;
    ratings.push(smallBlock);
  }
  return ratings;
};

const Skill: React.FC<ISkillProps> = (props) => {
  const { level, skill, highLightColor } = props;

  const getLevel = (level: string) => {
    switch (level) {
      case 'fundamental awareness':
        return generateRatingBars(1, highLightColor);
      case 'novice':
        return generateRatingBars(2, highLightColor);
      case 'intermediate':
        return generateRatingBars(3, highLightColor);
      case 'advanced':
        return generateRatingBars(4, highLightColor);
      case 'expert':
        return generateRatingBars(5, highLightColor);
      default:
        return generateRatingBars(0, highLightColor);
    }
  };

  const skillName = verifyStringLength(skill, MAX_STRING_LENGTH)
    .split(/(\s+)/)
    .map((word) => {
      return wordBreak(word, MAX_SKILL_WORD_LENGTH);
    });

  return (
    <View style={styles().content} wrap={false}>
      <Text style={styles().skillName}>{skillName}</Text>
      {getLevel(level)}
    </View>
  );
};

const Area: React.FC<IAreaProps> = ({ title, skills, isFirst, highLightColor }) => {
  const skillsCmpt = skills.map((skill, i) => (
    <Skill key={i} level={skill.level} skill={skill.skill} highLightColor={highLightColor} />
  ));

  const firstSkill = skillsCmpt.splice(0, 1);

  return (
    <View style={styles().container}>
      <View wrap={false}>
        {isFirst && <Text style={styles(highLightColor).mainTitle}>SKILLS</Text>}
        <Text style={styles().areaTitle}>{verifyStringLength(title, MAX_STRING_LENGTH)}</Text>
        <View style={styles().box}>{firstSkill}</View>
      </View>
      <View style={styles().box}>{skillsCmpt}</View>
    </View>
  );
};

const Skills: React.FC<ISkillsProps> = ({ skills, highLightColor }) => {
  const getSkills = (skills: ISkill[]) => {
    const skillsMap: Map<string, ISkill[]> = new Map<string, ISkill[]>();
    const skillsArray: ISkillObj[] = [];
    skills.forEach((skill) => {
      let skillByAreaArray = skillsMap.get(skill.area);

      if (skillByAreaArray) {
        skillByAreaArray.push(skill);
      } else {
        skillByAreaArray = [skill];
      }
      skillsMap.set(skill.area, skillByAreaArray);
    });
    skillsMap.forEach((value, key) => {
      const obj = {
        area: key,
        skills: value
      };
      skillsArray.push(obj);
    });
    return skillsArray;
  };

  const skillsMap = getSkills(skills);

  return (
    <React.Fragment>
      {skillsMap && (
        <View style={styles().skillsContainer}>
          {skillsMap.map((item, index) => (
            <Area
              key={index}
              title={item.area}
              skills={item.skills}
              isFirst={index === 0}
              highLightColor={highLightColor}
            />
          ))}
        </View>
      )}
    </React.Fragment>
  );
};

export default Skills;
