import { ISection } from '../Models/FormResumeSection';
import { SectionId } from './sectionIDs';

const personalInformationSection: ISection = {
  sectionId: SectionId.PERSONAL_INFORMATION_SECTION,
  nextSectionId: SectionId.SKILL_SECTION,
  previousSectionId: SectionId.PERSONAL_INFORMATION_SECTION
};

const skillsSection: ISection = {
  sectionId: SectionId.SKILL_SECTION,
  nextSectionId: SectionId.WORK_EXPERIENCE_SECTION,
  previousSectionId: SectionId.PERSONAL_INFORMATION_SECTION
};

const workExperienceSection: ISection = {
  sectionId: SectionId.WORK_EXPERIENCE_SECTION,
  nextSectionId: SectionId.EDUCATION_SECTION,
  previousSectionId: SectionId.SKILL_SECTION
};

const educationSection: ISection = {
  sectionId: SectionId.EDUCATION_SECTION,
  nextSectionId: SectionId.LANGUAGE_SECTION,
  previousSectionId: SectionId.WORK_EXPERIENCE_SECTION
};

const languageSection: ISection = {
  sectionId: SectionId.LANGUAGE_SECTION,
  nextSectionId: SectionId.PERSONAL_INFORMATION_SECTION,
  previousSectionId: SectionId.EDUCATION_SECTION
};

export const resumeSectionsDistribution = new Map<SectionId, ISection>();

resumeSectionsDistribution.set(SectionId.PERSONAL_INFORMATION_SECTION, personalInformationSection);
resumeSectionsDistribution.set(SectionId.SKILL_SECTION, skillsSection);
resumeSectionsDistribution.set(SectionId.WORK_EXPERIENCE_SECTION, workExperienceSection);
resumeSectionsDistribution.set(SectionId.EDUCATION_SECTION, educationSection);
resumeSectionsDistribution.set(SectionId.LANGUAGE_SECTION, languageSection);
