import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './AquaUtils';
import { styles } from './SkillSection.styles';
import { SkillLevels, skillLevels } from '../utils/constants';
import { getIndexToFirstLine } from '../utils/indexFirstLine';
import { SquareLevel } from '../utils/SquareLevel/SquareLevel';
import { verifyStringLength } from '../utils/stringUtils';
import { ISkill } from '../../Models/ISkills';

interface ISkillsProps {
  data: ISkill[];
  highLightColor?: string;
}

interface IAreaProps {
  title: string;
  skills: ISkill[];
  highLightColor?: string;
  indexArea: number;
}

interface ISkillProps {
  skill: string;
  level: string;
  highLightColor?: string;
}

const MAX_STRING_LENGTH = 55;
const LEVELS_NUMBER = 5;
const SIZE_PER_LETTER = 6;
const SIZE_PER_SKILL = 14;
const WIDTH_LINE = 500;

const groupSkillByArea = (data: ISkill[]) => {
  const groupedSkills = [];
  for (const skill of data) {
    const index = groupedSkills.findIndex(({ area }) => area === skill.area);
    if (index > -1) {
      groupedSkills[index].skills.push(skill);
    } else {
      groupedSkills.push({ area: skill.area, skills: [skill] });
    }
  }
  return groupedSkills;
};

const Skill: React.FC<ISkillProps> = ({ level, skill, highLightColor }) => {
  const levelValue: SkillLevels = SkillLevels[level as keyof typeof SkillLevels];

  return (
    <View style={styles().content} wrap={false}>
      <Text style={styles().skillName}>{verifyStringLength(skill, MAX_STRING_LENGTH)}</Text>
      <View style={styles(9).squarelevelMargin}>
        <SquareLevel
          level={levelValue}
          levelsNumber={LEVELS_NUMBER}
          primaryColor={getColors(highLightColor).squareLevel}
        />
      </View>
    </View>
  );
};

const Area: React.FC<IAreaProps> = ({ title, skills, highLightColor, indexArea }) => {
  const skillsData = [...skills];
  const skillsValue = skillsData.map((item) => item.skill);
  const lastIdexFirstLine = getIndexToFirstLine(
    skillsValue,
    SIZE_PER_LETTER,
    SIZE_PER_SKILL,
    WIDTH_LINE
  );
  const firstSkillsLine = () => {
    return skillsData
      .splice(0, lastIdexFirstLine + 1)
      .map((skill, index) => (
        <Skill
          key={`skillItemFirst-${index}`}
          level={skill.level}
          skill={skill.skill}
          highLightColor={highLightColor}
        />
      ));
  };
  return (
    <View wrap={!(indexArea === 0)} style={styles().skillsContainer}>
      {indexArea === 0 && (
        <Text style={{ ...styles().sectionTitle, color: getColors(highLightColor).sectionTitle }}>
          Skills
        </Text>
      )}
      <View wrap={false} style={{ ...styles().areaContainer, marginBottom: 0 }}>
        <Text style={{ ...styles().titleArea, color: getColors(highLightColor).titleArea }}>
          {verifyStringLength(title, MAX_STRING_LENGTH)}
        </Text>
        <View style={styles().box}>{firstSkillsLine()}</View>
      </View>
      <View style={styles().areaContainer}>
        <View style={styles().box}>
          {skillsData.map((skill, index) => (
            <Skill
              key={`skillItem-${index}`}
              level={skill.level}
              skill={skill.skill}
              highLightColor={highLightColor}
            />
          ))}
        </View>
      </View>
    </View>
  );
};

const LevelsDetail: React.FC<{ highLightColor?: string }> = ({ highLightColor }) => {
  return (
    <View style={[styles().box, styles().detailContainer]} wrap={false}>
      {skillLevels.map((item, index) => {
        const lastItem = item.level === SkillLevels.expert ? { marginRight: 0 } : null;
        const stylesContentLevel = {
          ...styles().contentLevel,
          ...lastItem
        };
        return (
          <View key={`indicatorSkill-${index}`} style={stylesContentLevel}>
            <View style={styles(3.7275).squarelevelMargin}>
              <SquareLevel
                level={item.level}
                levelsNumber={LEVELS_NUMBER}
                primaryColor={getColors(highLightColor).squareLevel}
              />
            </View>
            <View>
              <Text style={styles().detailName}> {item.levelName}</Text>
              <Text style={styles().levelDetails}> {item.levelDetails}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const SkillSection: React.FC<ISkillsProps> = ({ data, highLightColor }) => {
  const skillsByArea = groupSkillByArea(data);
  const existsData = skillsByArea.length > 0 || null;

  return (
    existsData && (
      <View style={styles().sectionContainer}>
        {skillsByArea.map((item, index) => (
          <Area
            indexArea={index}
            key={`AreaItem-${index}`}
            title={item.area}
            skills={item.skills}
            highLightColor={highLightColor}
          />
        ))}
        <LevelsDetail highLightColor={getColors(highLightColor).squareLevel} />
      </View>
    )
  );
};

export default SkillSection;
