import { View, Text, Image } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './Header.styles';
import { pictureToShow } from '../utils/helper';
import { verifyStringLength } from '../utils/stringUtils';
import { IPersonalInformation } from '../../Models/IPersonalInformation';

interface IHeaderProps {
  personalInformation: IPersonalInformation;
  highLightColor: string;
}

const Header: React.FC<IHeaderProps> = ({ personalInformation, highLightColor }) => {
  const { fullName, email, mainJob, phoneNumber, pictures } = personalInformation;
  const validatedPicture = pictureToShow(pictures);
  const arrayFullName = fullName.toUpperCase().split(' ');
  const getFullNameSection = arrayFullName.map((name, index) => (
    <Text key={index} style={styles().fullNameItem}>
      {`${name} `}
    </Text>
  ));
  return (
    <View style={styles().headerContainer}>
      <View style={styles().avatarContainer}>
        {validatedPicture.displayPicture && (
          <Image style={styles().userPhoto} src={validatedPicture.pictureToShow} />
        )}
      </View>
      <View style={styles().infoContainer}>
        <Text style={styles().fullName}>{getFullNameSection}</Text>
        {!!mainJob && <Text style={styles(highLightColor).job}>{mainJob}</Text>}
        {!!email && <Text style={styles().additionalInformation}>{email}</Text>}
        {!!phoneNumber && (
          <Text style={styles().additionalInformation}>{verifyStringLength(phoneNumber, 20)}</Text>
        )}
      </View>
    </View>
  );
};

export default Header;
