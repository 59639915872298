import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { ILanguage } from '../../../Models/ILanguages';

const CHINESE = '/images/chinese.png';
const FRENCH = '/images/french.png';
const GERMAN = '/images/german.png';
const PORTUGUESE = '/images/portuguese.png';
const SPAIN = '/images/spain.png';
const US = '/images/us.png';
const WORLD = '/images/world.png';

interface ILanguagesProps {
  languages: ILanguage[];
  color?: string;
  title: string;
  highLightColor: string;
}

interface ILanguageProps {
  language: ILanguage;
  color?: string;
  title: string | false;
  highLightColor: string;
}

interface ILevelProps {
  level: string;
  color?: string;
  highLightColor: string;
}

const styles = StyleSheet.create({
  languagesContainer: {
    paddingTop: 15
  },
  languageContainer: {
    marginBottom: 10
  },
  title: {
    fontSize: 15,
    fontFamily: 'Helvetica Neue Light',
    marginBottom: 9
  },
  titleBlue: {
    fontSize: 14,
    color: '#1867AC',
    marginBottom: 9
  },
  language: {
    fontSize: 10,
    marginRight: 2,
    paddingRight: 10,
    paddingBottom: 2
  },
  languageIcon: {
    height: 10,
    width: 10,
    marginRight: 3
  },
  levelText: {
    width: 38,
    fontSize: 9,
    textAlign: 'right'
  },
  levelContainer: {
    flexDirection: 'row',
    marginTop: 5
  },
  lineLevelContainer: {
    width: 74,
    marginLeft: 5,
    display: 'flex',
    justifyContent: 'center'
  },
  lineOver: {
    width: 74,
    height: 2,
    borderRadius: 1,
    backgroundColor: '#C7C7C7'
  },
  lineLevel: {
    marginTop: -2,
    height: 2,
    borderRadius: 1
  },
  lineLevelBlue: {
    marginTop: -2,
    height: 2,
    borderRadius: 1,
    backgroundColor: '#1867AC'
  },
  subTitleContainer: {
    flexDirection: 'row'
  },
  subTitle: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  native: {
    fontSize: 9
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    maxLines: 3
  }
});

const Level: React.FC<ILevelProps> = (props) => {
  const { level, color, highLightColor } = props;
  const PER_LEVEL = 25;
  let width = 0;

  switch (level) {
    case 'beginner':
      width = PER_LEVEL;
      break;
    case 'intermediate':
      width = PER_LEVEL * 2;
      break;
    case 'advanced':
      width = PER_LEVEL * 3;
      break;
    default:
      break;
  }

  return (
    <View style={styles.lineLevelContainer}>
      {level === 'native' ? (
        <Text style={styles.native}>(Native)</Text>
      ) : (
        <React.Fragment>
          <View style={styles.lineOver} />
          <View
            style={
              color
                ? [styles.lineLevelBlue, { width: width }]
                : [{ ...styles.lineLevel, backgroundColor: highLightColor }, { width: width }]
            }
          />
        </React.Fragment>
      )}
    </View>
  );
};

const getIconLanguage = (language: string): string => {
  switch (language) {
    case 'English':
      return US;
    case 'Spanish':
      return SPAIN;
    case 'French':
      return FRENCH;
    case 'Portuguese':
      return PORTUGUESE;
    case 'German':
      return GERMAN;
    case 'Chinese':
      return CHINESE;
    default:
      return WORLD;
  }
};

const Language: React.FC<ILanguageProps> = (props) => {
  const { language, listening, reading, speaking, writing } = props.language;
  const { color, title, highLightColor } = props;

  const isNative = [listening, reading, speaking, writing].every((item) => item === 'native');
  const languageName = language.replace(/\s+/g, ' ').trim();

  return (
    <View wrap={false}>
      {title && (
        <Text style={color ? styles.titleBlue : { ...styles.title, color: highLightColor }}>
          {title}
        </Text>
      )}
      <View style={styles.languageContainer}>
        <View style={styles.subTitleContainer}>
          <Image style={styles.languageIcon} src={getIconLanguage(language)} />
          <View style={styles.subTitle}>
            <Text style={[styles.language, styles.ellipsis]}>{languageName}</Text>
            {isNative && <Text style={styles.native}>(Native)</Text>}
          </View>
        </View>
        {!isNative && (
          <React.Fragment>
            <View style={styles.levelContainer}>
              <Text style={styles.levelText}>Reading</Text>
              <Level level={reading} color={color} highLightColor={highLightColor} />
            </View>
            <View style={styles.levelContainer}>
              <Text style={styles.levelText}>Listening</Text>
              <Level level={listening} color={color} highLightColor={highLightColor} />
            </View>
            <View style={styles.levelContainer}>
              <Text style={styles.levelText}>Writing</Text>
              <Level level={writing} color={color} highLightColor={highLightColor} />
            </View>
            <View style={styles.levelContainer}>
              <Text style={styles.levelText}>Speaking</Text>
              <Level level={speaking} color={color} highLightColor={highLightColor} />
            </View>
          </React.Fragment>
        )}
      </View>
    </View>
  );
};

const Languages: React.FC<ILanguagesProps> = (props) => {
  const { languages, color, title, highLightColor } = props;

  const languagesCmpts = languages.reduce((result: Array<JSX.Element>, language, index) => {
    result.push(
      <Language
        key={index}
        language={language}
        color={color}
        title={result.length === 0 && title}
        highLightColor={highLightColor}
      />
    );
    return result;
  }, []);

  return <View style={styles.languagesContainer}>{languagesCmpts}</View>;
};

export default Languages;
