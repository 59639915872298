import { IToolTipInfo } from '../Interfaces/IToolTipInfo';

export const SkillToolTipInfo: IToolTipInfo[] = [
  {
    key: 'fundamental awareness',
    header: 'Fundamental Awareness (basic knowledge)',
    content: `You have a common knowledge or an understanding of basic techniques and concepts.`
  },
  {
    key: 'novice',
    header: 'Novice (limited experience)',
    content: `You have the level of experience gained in a classroom and/or experimental 
            scenarios or as a trainee on-the-job. You are expected to need help when performing 
            this skill.`
  },
  {
    key: 'intermediate',
    header: 'Intermediate (practical application)',
    content: `You are able to successfully complete tasks in this competency as requested. 
            Help from an expert may be required from time to time, but you can usually perform 
            the skill independently.`
  },
  {
    key: 'advanced',
    header: 'Advanced (applied theory)',
    content: `You can perform the actions associated with this skill without assistance. 
            You are certainly recognized within your immediate organization as "a person to ask" 
            when difficult questions arise regarding this skill.`
  },
  {
    key: 'expert',
    header: 'Expert (recognized authority)',
    content: `You are known as an expert in this area. You can provide guidance, troubleshoot 
            and answer questions related to this area of expertise and the field where the 
            skill is used.`
  }
];
