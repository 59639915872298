export const SORT_OPTIONS = [
  {
    label: 'Oldest',
    sort: 'oldest',
    type: 'circular'
  },
  {
    label: 'Newest',
    sort: 'newest',
    type: 'circular'
  },
  {
    label: 'Alphabet',
    sort: 'alphabet',
    type: 'circular'
  }
];

export const FILTER_OPTIONS = [
  {
    label: 'Name',
    filter: 'name',
    type: 'square'
  },
  {
    label: 'Area',
    filter: 'area',
    type: 'square'
  },
  {
    label: 'Skill',
    filter: 'skills',
    type: 'square',
    options: [
      {
        label: 'On',
        refinement: [
          {
            field: 'Last Experience',
            options: ['All Skills', 'Last Experience'],
            default: 'All Skills',
            large: true
          }
        ]
      },
      {
        label: 'At least',
        refinement: [
          {
            field: 'at least',
            options: ['Fundamental', 'Novice', 'Intermediate', 'Advanced', 'Expert'],
            default: 'Intermediate',
            large: true
          }
        ]
      }
    ]
  },
  {
    label: 'Main job',
    filter: 'main job',
    type: 'square'
  },
  {
    label: 'Main internal profile',
    filter: 'main internal profile',
    type: 'square'
  }
];

export const REFINEMENT_OPTIONS = [
  {
    label: 'Work Experience',
    filter: 'work experience',
    type: 'square',
    options: [
      {
        label: 'At least',
        finalText: 'years',
        refinement: [
          {
            field: 'years',
            options: ['1', '3', '5', '7', '10'],
            default: '3',
            large: false
          }
        ]
      }
    ]
  },
  {
    label: 'Language',
    filter: 'language',
    type: 'square',
    options: [
      {
        label: 'Must have',
        refinement: [
          {
            field: 'language',
            options: ['English', 'Spanish', 'French', 'Portuguese', 'German', 'Chinese'],
            default: 'English',
            large: true
          }
        ]
      },
      {
        label: 'At least',
        refinement: [
          {
            field: 'level',
            options: ['Basic', 'Intermediate', 'Advanced', 'Native'],
            default: 'Intermediate',
            large: true
          }
        ]
      }
    ]
  }
];

export const VISIBILITY_OPTIONS = [
  {
    label: 'Include Incomplete CVs',
    visibility: 'incomplete',
    type: 'square'
  },
  {
    label: 'Include Hidden CVs',
    visibility: 'isHidden',
    type: 'square'
  }
];
