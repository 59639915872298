import React from 'react';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';

import './SharedOption.scss';
import LettersAvatar from '../../../User/LettersAvatar';

interface ISharedOptionProps {
  picture?: string;
  name?: string;
  email?: string;
}

const SharedOption: React.FC<ISharedOptionProps> = (props) => {
  const { picture, name, email } = props;
  const nameAvatar = name || email || '';

  return (
    <div className='option'>
      <div className='avatar-option'>
        {picture ? (
          <Image avatar floated='left' size='tiny' src={picture} />
        ) : (
          <LettersAvatar userName={nameAvatar} size='tiny' />
        )}
      </div>
      <span className='avatar-name'>{name || email}</span>
    </div>
  );
};

export default SharedOption;
