import { StyleSheet } from '@react-pdf/renderer';

import { getColors } from './PlainUtils';
import { templateColors } from '../utils/constants';

export const styles = (textColor = templateColors.gray) => {
  const areaTitle = getColors(textColor).textColor;

  return StyleSheet.create({
    educationContainer: {
      width: 370,
      display: 'flex',
      flexDirection: 'column',
      marginTop: 4
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'row'
    },
    contentSection: {
      width: '320',
      paddingTop: 6
    },
    descriptionContainer: {
      marginTop: 7,
      fontSize: 9,
      fontFamily: 'Georama',
      fontWeight: 'medium',
      marginBottom: 12
    },
    career: {
      fontFamily: 'Georama',
      fontWeight: 'medium',
      fontSize: 11,
      top: -10,
      color: areaTitle
    },
    school: {
      fontSize: 11,
      fontFamily: 'Georama',
      fontWeight: 'medium',
      top: -8
    },
    date: {
      fontSize: 11,
      fontFamily: 'Georama',
      color: '#707070',
      top: -6
    }
  });
};
