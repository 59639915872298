import { Image, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './ModernUtils';
import { templateColors } from '../utils/constants';
import { pictureToShow } from '../utils/helper';
import { verifyStringLength } from '../utils/stringUtils';
import { IPersonalInformation } from '../../Models/IPersonalInformation';

interface IHeaderProps {
  personalInfo: IPersonalInformation;
  highLightColor: string;
}

const styles = (textColor = templateColors.blue) => {
  const mainContainerColor = getColors(textColor).mainContainer;
  return StyleSheet.create({
    mainContainer: {
      height: 170,
      marginTop: -60,
      backgroundColor: mainContainerColor
    },
    avatarContainer: {
      width: 170,
      height: 170
    },
    userPhoto: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    styleEmptyPicture: {
      left: 45
    },
    personalInfoContainer: {
      position: 'absolute',
      bottom: 7,
      top: 5,
      right: 45,
      left: 200
    },
    personalData: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      height: '100%'
    },
    name: {
      fontSize: 30,
      color: 'white',
      maxHeight: 110
    },
    title: {
      fontSize: 13,
      height: 16,
      marginBottom: 5
    },
    containerEmail: {
      position: 'absolute',
      bottom: 0,
      left: 0
    },
    email: {
      color: 'white',
      fontSize: 10,
      fontFamily: 'Helvetica Neue Light',
      maxHeight: 12
    }
  });
};

Font.register({
  family: 'Helvetica Neue Light',
  src: `/fonts/Helvetica/HelveticaNeueLight.ttf`
});

const Header: React.FC<IHeaderProps> = ({ personalInfo, highLightColor }) => {
  const { pictures, fullName, mainJob, email, phoneNumber } = personalInfo;
  const validatedPicture = pictureToShow(pictures);

  const userInformation = validatedPicture.displayPicture
    ? [styles().personalInfoContainer]
    : [styles().personalInfoContainer, styles().styleEmptyPicture];

  return (
    <View style={styles(highLightColor).mainContainer}>
      {validatedPicture.displayPicture && (
        <View style={styles().avatarContainer}>
          <Image style={styles().userPhoto} src={validatedPicture.pictureToShow} />
        </View>
      )}
      <View style={userInformation}>
        <View style={styles().personalData}>
          <Text style={styles().name}>{fullName && fullName.toUpperCase()}</Text>
          <Text style={styles().title}>{mainJob && mainJob.toUpperCase()}</Text>
          <View style={styles().containerEmail}>
            <Text style={styles().email}>
              {validatedPicture.displayPicture
                ? verifyStringLength(email, 38)
                : verifyStringLength(email, 55)}
              {phoneNumber && ' | ' + verifyStringLength(phoneNumber, 20)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Header;
