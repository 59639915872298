import axios, { AxiosResponse } from 'axios';

import { baseAPIURL } from '../../constants/settings';
import { IResumeSectionToUpdate } from '../../Models/FormResumeSection';
import { IResumeForm, ISharedResumeForm } from '../../Models/IResumeForm';
import { GetRequestHeader } from '../../utils/requestHeader';

const axiosInstance = axios.create({ baseURL: baseAPIURL });

export class ResumeService {
  private static instance: ResumeService;
  private resource = '/resumes';

  public static getInstance(): ResumeService {
    if (!ResumeService.instance) {
      ResumeService.instance = new ResumeService();
    }
    return ResumeService.instance;
  }

  public getResumesPage(
    page: number,
    quantity: number,
    criteria: string,
    fieldToFilter: string = '',
    fieldToSort: string = '',
    visibility: string = '',
    fieldToRefinement: string = ''
  ): Promise<AxiosResponse> {
    return axiosInstance.get(`${this.resource}`, {
      params: {
        page: page,
        quantity: quantity,
        searchCriteria: criteria,
        fieldToFilter,
        fieldToSort,
        visibility,
        fieldToRefinement
      },
      headers: {
        Authorization: GetRequestHeader().headers.Authorization,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        expires: 0
      }
    });
  }

  public getResumeById(resumeId: string, requestQuery?: string) {
    const queryParam = requestQuery ? `?mode=${requestQuery}` : '';
    return axiosInstance.get(`${this.resource}/${resumeId + queryParam}`, GetRequestHeader());
  }

  public getResumeByEmail(resumeEmail: string) {
    return axiosInstance.get(`${this.resource}/resume/${resumeEmail}`, GetRequestHeader());
  }

  public createResume(resume: IResumeForm): Promise<AxiosResponse> {
    return axiosInstance.post(`${this.resource}`, resume, GetRequestHeader());
  }

  public updateResumeById(resume: IResumeForm, resumeId: string) {
    return axiosInstance.patch(`${this.resource}/${resumeId}`, resume, GetRequestHeader());
  }

  public updateResumeBySection(
    sectionData: IResumeSectionToUpdate,
    resumeId: string,
    section: string
  ): Promise<AxiosResponse> {
    return axiosInstance.patch(
      `${this.resource}/${resumeId}/section/${section}`,
      sectionData,
      GetRequestHeader()
    );
  }

  public updateSharedResume(resume: ISharedResumeForm, resumeId: string): Promise<AxiosResponse> {
    return axiosInstance.patch(`${this.resource}/${resumeId}/sharedResumes`, resume, GetRequestHeader());
  }

  public removeResume(resumeId: string): Promise<AxiosResponse> {
    return axiosInstance.delete(`${this.resource}/${resumeId}`, GetRequestHeader());
  }

  public getResumesToMerge(params: {
    page?: number;
    quantity?: number;
    searchCriteria?: string;
  }): Promise<AxiosResponse> {
    return axiosInstance.get(`${this.resource}/merge`, {
      params: { ...params },
      headers: GetRequestHeader().headers
    });
  }

  public mergeResume(sourceId: string, targetId: string): Promise<AxiosResponse> {
    return axiosInstance.patch(`${this.resource}/${sourceId}/merge/${targetId}`, {}, GetRequestHeader());
  }

  public hideResume(resumeId: string, hidden: boolean): Promise<AxiosResponse> {
    return axiosInstance.post(`${this.resource}/toogle/${resumeId}/${hidden}`, {}, GetRequestHeader());
  }

  public uploadPicture(resumeId: string, base64Picture: string): Promise<AxiosResponse> {
    return axiosInstance.patch(
      `${this.resource}/uploadPicture/${resumeId}`,
      { base64Picture },
      GetRequestHeader()
    );
  }
}
