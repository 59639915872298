import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import { ActiveOptionStyle, ResumeOptionName } from '../../constants/globalConstants';
import { SearchFilters } from '../../Models/FormSearchFilters';
import { ILanguageOptions } from '../../Models/ILanguages';
/* eslint-disable camelcase */
import { IResumeFormBasic } from '../../Models/IResumeFormBasic';
import { IResumeOptions } from '../../Models/IResumeOptions';
import { ISkillOption, IAreaOptions } from '../../Models/ISkillOptions';
import { IWorkExperienceOptions } from '../../Models/IWorkExperience';

export const DATA_LOADING = 'DATA_LOADING';
export const DATA_FAIL = 'DATA_FAIL';
export const RESUMES_SUCCESS = 'RESUMES_SUCCESS';
export const FILTERED_SUCCESS = 'FILTERED_SUCCESS';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const RESUMES_OPTIONS_SUCCESS = 'RESUMES_OPTIONS_SUCCESS';
export const ADD_JOB_OPTION = 'ADD_JOB_OPTION';
export const ADD_PROFILE_OPTION = 'ADD_PROFILE_OPTION';
export const ADD_AREA_OPTION = 'ADD_AREA_OPTION';
export const ADD_SKILL_OPTION = 'ADD_SKILL_OPTION';
export const ADD_LANGUAGE_OPTION = 'ADD_LANGUAGE_OPTION';
export const ADD_INTERNAL_PROFILE_OPTION = 'ADD_INTERNAL_PROFILE_OPTION';
export const SET_DASHBOARD_PAGE = 'SET_DASHBOARD_PAGE';
export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const UPDATE_IS_READY = 'UPDATE_IS_READY';
export const SET_TOP_BAR_ACTIVE_MENU = 'SET_TOP_BAR_ACTIVE_MENU';
export const SET_LOGGED_USER_RESUME_ID = 'SET_LOGGED_USER_RESUME_ID';
export const SET_TOP_BAR_RESUME_OPTION_NAME = 'SET_TOP_BAR_RESUME_OPTION_NAME';
export const HIDE_RESUME = 'HIDE_RESUME';

export type ResumesType = IResumeFormBasic[];

export interface IAuthDefaultState {
  access_token: string;
  expires_at: number;
  id_token: string;
  userName: string;
  userRole: string;
  userId: string;
  profile: {
    acr: string;
    auth_time: number;
    azp: string;
    birthdate: string;
    email: string;
    email_verified: boolean;
    family_name: string;
    gender: string;
    given_name: string;
    jti: string;
    name: string;
    picture: string;
    preferred_username: string;
    session_state: string;
    sub: string;
    typ: string;
  };
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

export interface IhiddenResumeInfo {
  id: string;
  isHidden: boolean;
}

export type AuthType = IAuthDefaultState;

export interface AuthSuccess {
  type: typeof AUTH_SUCCESS;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  payload: any;
}

export interface ResumesLoading {
  type: typeof DATA_LOADING;
}

export interface ResumesFail {
  type: typeof DATA_FAIL;
}

export interface ResumesSuccess {
  type: typeof RESUMES_SUCCESS;
  payload: {
    resumes: IResumeFormBasic[];
    totalPages: number;
  };
}

export interface ResumeOptionsSuccess {
  type: typeof RESUMES_OPTIONS_SUCCESS;
  payload: IResumeOptions;
}

export interface FilteredSuccess {
  type: typeof FILTERED_SUCCESS;
  payload: IResumeFormBasic[];
}

export interface AddJobOption {
  type: typeof ADD_JOB_OPTION;
  payload: IWorkExperienceOptions;
}

export interface AddProfileOption {
  type: typeof ADD_PROFILE_OPTION;
  payload: { profile: DropdownItemProps; job: IWorkExperienceOptions | number };
}

export interface AddAreaOption {
  type: typeof ADD_AREA_OPTION;
  payload: IAreaOptions;
}

export interface AddSkillOption {
  type: typeof ADD_SKILL_OPTION;
  payload: { skill: ISkillOption; area: IAreaOptions | number };
}

export interface AddLanguageOption {
  type: typeof ADD_LANGUAGE_OPTION;
  payload: ILanguageOptions;
}

export interface AddInternalProfileOption {
  type: typeof ADD_INTERNAL_PROFILE_OPTION;
  payload: DropdownItemProps;
}

export interface SetDashboardPage {
  type: typeof SET_DASHBOARD_PAGE;
  payload: number;
}

export interface SetSearchCriteria {
  type: typeof SET_SEARCH_CRITERIA;
  payload: SearchFilters;
}

export interface UpdateIsReady {
  type: typeof UPDATE_IS_READY;
  payload: boolean;
}

export interface setTopBarActiveOptionStyle {
  type: typeof SET_TOP_BAR_ACTIVE_MENU;
  payload: ActiveOptionStyle;
}

export interface setLoggedUserResumeId {
  type: typeof SET_LOGGED_USER_RESUME_ID;
  payload: string;
}

export interface setTopBarResumeOptionName {
  type: typeof SET_TOP_BAR_RESUME_OPTION_NAME;
  payload: ResumeOptionName;
}

export interface hideResume {
  type: typeof HIDE_RESUME;
  payload: IhiddenResumeInfo;
}

export type ResumesDispatchTypes =
  | AuthSuccess
  | ResumesLoading
  | ResumesFail
  | ResumesSuccess
  | ResumeOptionsSuccess
  | FilteredSuccess
  | AddJobOption
  | AddProfileOption
  | AddAreaOption
  | AddSkillOption
  | AddLanguageOption
  | AddInternalProfileOption
  | SetDashboardPage
  | SetSearchCriteria
  | UpdateIsReady
  | setTopBarActiveOptionStyle
  | setLoggedUserResumeId
  | setTopBarResumeOptionName
  | hideResume;
