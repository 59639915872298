import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Toast.scss';
import { AlertCircle, InfoCircle, CheckCircle, ErrorCircle } from '../../Assets/CustomIcons';
import { HttpErrors } from '../../constants/globalConstants';

const INFO_STROKE_COLOR = '#00529b';
const WARN_STROKE_COLOR = '#9f6000';
const ERROR_STROKE_COLOR = '#d8000c';
const SUCCESS_STROKE_COLOR = '#4f8a10';

const toastDefaultConf: ToastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  progress: undefined
};

export enum ToastEnum {
  INFO,
  WARN,
  ERROR,
  SUCCESS
}

interface IMessageContentProps {
  message: string;
  type: ToastEnum;
}

const getToastIcon = (toastType: ToastEnum) => {
  const toastTypes = {
    [ToastEnum.INFO]: <InfoCircle className='message-icon' stroke={INFO_STROKE_COLOR} />,
    [ToastEnum.WARN]: <AlertCircle className='message-icon' stroke={WARN_STROKE_COLOR} />,
    [ToastEnum.ERROR]: <ErrorCircle className='message-icon' stroke={ERROR_STROKE_COLOR} />,
    [ToastEnum.SUCCESS]: <CheckCircle className='message-icon' stroke={SUCCESS_STROKE_COLOR} />
  };
  return toastTypes[toastType];
};

export const MessageContent = ({ message, type }: IMessageContentProps) => {
  return (
    <div className='message-content'>
      <span>{getToastIcon(type)}</span>
      <p>{message}</p>
    </div>
  );
};

export const showToastMessage = (message: string, type: ToastEnum) => {
  switch (type) {
    case ToastEnum.INFO: {
      toast.info(<MessageContent message={message} type={ToastEnum.INFO} />, {
        ...toastDefaultConf
      });
      break;
    }
    case ToastEnum.WARN: {
      toast.warn(<MessageContent message={message} type={ToastEnum.WARN} />, {
        ...toastDefaultConf
      });
      break;
    }
    case ToastEnum.ERROR: {
      toast.error(<MessageContent message={message} type={ToastEnum.ERROR} />, {
        ...toastDefaultConf
      });
      break;
    }
    case ToastEnum.SUCCESS: {
      toast.success(<MessageContent message={message} type={ToastEnum.SUCCESS} />, {
        ...toastDefaultConf
      });
      break;
    }
  }
};

export const showToastMessageByErrorCode = (errorCode: number): boolean => {
  let isHttpError = true;
  switch (errorCode) {
    case HttpErrors.UNAUTHORIZED: {
      showToastMessage('Access to the CV was removed', ToastEnum.ERROR);
      break;
    }
    case HttpErrors.PAYLOAD_EXCEED: {
      showToastMessage('Data is too large, Please contact the administrator', ToastEnum.ERROR);
      break;
    }
    case HttpErrors.DUPLICATED: {
      showToastMessage('Email is already registered, Please try using another email', ToastEnum.ERROR);
      break;
    }
    default:
      isHttpError = false;
      break;
  }

  return isHttpError;
};
