import React, { Dispatch, SetStateAction, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import { getCroppedReactangleImage, reduceImageSize } from './cropImage';
import styles from './UploadImage.module.scss';
import { showToastMessage, ToastEnum } from '../../../Toast/Toast';
import { UploadIcon } from '../../../../Assets/CustomIcons';
import { ActivePictureEnum } from '../../../../Models/IPersonalInformation';
import { uploadPhoto } from '../../../../redux/actions/resumeActions';
import { RootStore } from '../../../../redux/store';

interface IImageEditorProps {
  closeModal: () => void;
  setDropDownValue: Dispatch<SetStateAction<ActivePictureEnum>>;
}

interface ICroppedArea {
  width: number;
  height: number;
  x: number;
  y: number;
}

const DEFAULT_ZOOM_VALUE = 1;
/* INFO: the following line means 25MB in bytes */
const MAX_PICTURE_SIZE_BYTES = 26214400;
const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

const validatePictureToUpload = (size: number, type: string) => {
  const isValid = true;
  if (size > MAX_PICTURE_SIZE_BYTES) {
    showToastMessage(`Picture size shouldn't exceed 25MB`, ToastEnum.WARN);
    return false;
  }
  if (!IMAGE_TYPES.includes(type)) {
    showToastMessage(
      'Invalid image type, please use one of the following extensions: ".jpg, .png, .jpeg"',
      ToastEnum.WARN
    );
    return false;
  }
  return isValid;
};

const ImageEditor: React.FC<IImageEditorProps> = ({ closeModal, setDropDownValue }) => {
  const { resumeFormData, resumeId } = useSelector((state: RootStore) => state.resume);
  const { personalInformation } = resumeFormData;
  const { pictures } = personalInformation;

  const [picture, updatePicture] = useState(pictures.customPicture || pictures.systemPicture);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(DEFAULT_ZOOM_VALUE);
  const [round, setRound] = useState(true);
  const [croppedImage, setCroppedImage] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);

  const fileReader = new FileReader();
  const dispatch = useDispatch();

  const onChangePicture = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files?.length) {
      const file = event.target.files[0];
      if (!validatePictureToUpload(file.size, file.type)) return;
      fileReader.readAsDataURL(file);
      fileReader.onload = async () => {
        if (fileReader.result) {
          const reducedSize = await reduceImageSize(fileReader.result as string);
          updatePicture(reducedSize as string);
          setZoom(DEFAULT_ZOOM_VALUE);
        }
      };
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeRange = (event: any) => {
    const rangeValue = event.target.value;
    setZoom(rangeValue);
  };

  const hanleChangeRound = () => {
    setRound(!round);
  };

  const onCropComplete = async (croppedArea: ICroppedArea, cropperAreaPixels: ICroppedArea) => {
    if (picture) {
      const response = await getCroppedReactangleImage(picture, cropperAreaPixels);
      setCroppedImage(response);
    }
  };

  const isUploadEnable = (): boolean => {
    const hasData = Boolean(croppedImage);
    const isNewPicture = picture !== pictures.customPicture && picture !== pictures.systemPicture;
    const result = hasData && isNewPicture;

    return !result;
  };

  const uploadPicture = async () => {
    try {
      if (croppedImage) {
        setLoading(true);
        await dispatch(uploadPhoto(resumeId, croppedImage));
        setDropDownValue(ActivePictureEnum.CUSTOM_PICTURE);
        setLoading(false);
        closeModal();
        showToastMessage('Image was uploaded successfully', ToastEnum.SUCCESS);
      }
    } catch (error) {
      setLoading(false);
      showToastMessage('Something went wrong, please try again', ToastEnum.ERROR);
    }
  };

  return (
    <div className={styles.avatarEditorContainer}>
      {loading && (
        <Dimmer active inverted page>
          <Loader size='medium' className={styles.loading} />
        </Dimmer>
      )}
      <div className={`${styles.cropperContainer} ${!picture ? styles.showArea : ''}`}>
        <div className={styles.switchContainer}>
          <label className={styles.switch}>
            <input
              type='checkbox'
              checked={round}
              onChange={hanleChangeRound}
              data-testid='round-check'
            />
            <span className={`${styles.slider} ${styles.round}`} />
          </label>
        </div>
        <Cropper
          image={picture}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape={round ? 'round' : 'rect'}
          showGrid={false}
          zoomWithScroll={false}
        />
        {!loading && (
          <div className={styles.uploadContainer}>
            <label htmlFor='file-input' className={!picture ? styles.labelWithBorder : ''}>
              <UploadIcon />
            </label>
            <input
              type='file'
              className={styles.fileInput}
              id='file-input'
              accept='image/png, image/jpeg'
              onChange={onChangePicture}
              data-testid='file-uploader'
            />
          </div>
        )}
      </div>
      <div className={isUploadEnable() ? styles.rangeContainerHide : styles.rangeContainer}>
        <input
          type='range'
          value={zoom}
          min='1'
          max='6'
          step='0.1'
          className={styles.slider}
          onChange={handleChangeRange}
          data-testid='range-input'
        />
      </div>
      <Button className={styles.updateButton} disabled={isUploadEnable()} onClick={uploadPicture}>
        <label>UPLOAD</label>
      </Button>
    </div>
  );
};

export default ImageEditor;
