import React, { useEffect, useState } from 'react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';

import CheckboxItem from './CheckboxItem/CheckboxItem';
import Refinement from './Refinement/Refinement';
import styles from './SortAndSearch.module.scss';
import { Roles } from '../../../constants/globalConstants';

interface IRefinementOption {
  field: string;
  options: Array<string>;
  default: string;
  large: boolean;
}

export interface IRefinement {
  label: string;
  finalText?: string;
  refinement: Array<IRefinementOption>;
}

interface ISortAndSearchProps {
  onChangeFilter: Function;
  onChangeSort: Function;
  onChangeVisibility: Function;
  onChangeRefinement: Function;
  criteriaSortMarked: string;
  criteriaFilterMarked: string;
  criteriaVisibilityMarked: string;
  sortOptions: Array<{ type: string; label: string; sort: string }>;
  visibilityOptions: Array<{ type: string; label: string; visibility: string }>;
  filterOptions: Array<{
    type: string;
    label: string;
    filter: string;
    options?: Array<IRefinement>;
  }>;
  refinementOptions: Array<{
    type: string;
    label: string;
    filter: string;
    options: Array<IRefinement>;
  }>;
  refinementChoosed: string | null;
  privileges: string;
  lastRefinement: Function;
}

interface IFieldRefinement {
  [key: string]: string;
}

interface IRefinementObject {
  [key: string]: IFieldRefinement;
}

const SortAndSearch: React.FC<ISortAndSearchProps> = ({
  onChangeFilter,
  onChangeSort,
  onChangeVisibility,
  onChangeRefinement,
  criteriaSortMarked = '',
  criteriaFilterMarked,
  criteriaVisibilityMarked,
  sortOptions,
  filterOptions,
  visibilityOptions,
  refinementOptions,
  refinementChoosed,
  privileges,
  lastRefinement
}) => {
  const SEARCH_TYPES = {
    sort: 'sort-item',
    filter: 'filter-item',
    visibility: 'visibility-item',
    refinement: 'refinement-item'
  };

  const markedFilters = !criteriaFilterMarked ? [] : criteriaFilterMarked.split(',');
  const markedVisible = !criteriaVisibilityMarked ? [] : criteriaVisibilityMarked.split(',');
  const [filters, setFilters] = useState(markedFilters);
  const [sorts, setSorts] = useState(criteriaSortMarked);
  const [visibles, setVisibles] = useState(markedVisible);
  const isAdmin = privileges === Roles.ADMIN;

  const getDefaultRefinements = () => {
    const newOption = {} as IRefinementObject;
    [...refinementOptions, ...filterOptions].forEach((filterOption) => {
      if (filterOption.options) {
        newOption[filterOption.filter] = {};
        if (filterOption.options && filterOption.options.length) {
          filterOption.options.forEach((fieldOption) => {
            fieldOption.refinement.forEach((element) => {
              const newField = {} as IFieldRefinement;
              newField[element.field] = element.default;
              newOption[filterOption.filter] = { ...newOption[filterOption.filter], ...newField };
            });
          });
        }
      }
    });
    return newOption;
  };

  const getFilterRefinement = () => {
    const newRefinementsItem =
      refinementChoosed && !!JSON.parse(refinementChoosed)
        ? JSON.parse(refinementChoosed)
        : getDefaultRefinements();
    return newRefinementsItem;
  };

  const [filterRefinement, setFilterRefinement] = useState(getFilterRefinement());
  const handleFilters = (newFilter: string) => {
    const indexFilter = filters.findIndex((filter: string) => filter === newFilter);
    const newArrayFilters = [...filters];
    if (indexFilter === -1) {
      newArrayFilters.push(newFilter);
    } else {
      newArrayFilters.splice(indexFilter, 1);
    }

    setFilters(newArrayFilters);
    onChangeFilter(newArrayFilters.join());
  };

  const handleVisibility = (newFilter: string) => {
    const indexFilter = visibles.findIndex((filter: string) => filter === newFilter);
    const newArrayFilters = [...visibles];
    if (indexFilter === -1) {
      newArrayFilters.push(newFilter);
    } else {
      newArrayFilters.splice(indexFilter, 1);
    }

    setVisibles(newArrayFilters);
    onChangeVisibility(newArrayFilters.join());
  };

  const isChecked = (kind: string, field: string) => {
    if (kind === SEARCH_TYPES.sort) {
      return field === sorts;
    } else if (kind === SEARCH_TYPES.visibility) {
      return visibles.some((filter) => filter === field);
    } else return filters.some((filter) => filter === field);
  };

  const handleCheck = (styleClass: string, filter: string) => {
    if (styleClass === SEARCH_TYPES.sort) {
      if (filter === sorts) {
        filter = '';
      }
      setSorts(filter);
      onChangeSort(filter);
    } else {
      styleClass === SEARCH_TYPES.visibility ? handleVisibility(filter) : handleFilters(filter);
    }
  };

  const handleRefinements = (refinementFilter: string, field: string, refinementOption: string) => {
    const newRefinement = { ...filterRefinement };
    const newField = {} as IFieldRefinement;
    newField[field] = refinementOption;
    if (refinementFilter in newRefinement) {
      newRefinement[refinementFilter] = { ...newRefinement[refinementFilter], ...newField };
    } else {
      newRefinement[refinementFilter] = { ...newField };
    }
    setFilterRefinement(newRefinement);
    lastRefinement(refinementFilter);
  };

  useEffect(() => {
    if (isAdmin) {
      onChangeRefinement(JSON.stringify(filterRefinement));
    }
  }, [filterRefinement]);

  return (
    <section className={styles.sortAndSearchContainer}>
      <Grid>
        <Grid.Row className={styles.rowTitle}>
          <h3 className={styles.sortAndSearchTitle}>Sort by</h3>
        </Grid.Row>
        <section className={styles.sortSection} id='sortSection'>
          {sortOptions.map((option, key) => (
            <CheckboxItem
              key={`${SEARCH_TYPES.sort}-${key}`}
              type={option.type}
              label={option.label}
              styleClass={SEARCH_TYPES.sort}
              custom={true}
              onChange={() => handleCheck(SEARCH_TYPES.sort, option.sort)}
              checked={isChecked(SEARCH_TYPES.sort, option.sort)}
            />
          ))}
        </section>
      </Grid>
      <Grid>
        <Grid.Row className={styles.rowTitle}>
          <h3 className={styles.sortAndSearchTitle}>Search only</h3>
        </Grid.Row>
        <section className={styles.filterSection} id='filterSection'>
          {filterOptions.map((option, key) => (
            <React.Fragment key={`${SEARCH_TYPES.filter}-${key}`}>
              <CheckboxItem
                type={option.type}
                label={option.label}
                styleClass={SEARCH_TYPES.filter}
                custom={true}
                onChange={() => handleCheck(SEARCH_TYPES.filter, option.filter)}
                checked={isChecked(SEARCH_TYPES.filter, option.filter)}
              />
              {isAdmin &&
                !!option.options &&
                option.options.length &&
                option.options.map((element, index) => (
                  <Refinement
                    key={index}
                    title={element.label}
                    refinement={element.refinement}
                    finalText={element.finalText}
                    filter={option.filter}
                    onChange={handleRefinements}
                    selected={refinementChoosed}
                    disabled={!isChecked(SEARCH_TYPES.filter, option.filter)}
                  />
                ))}
            </React.Fragment>
          ))}
        </section>
      </Grid>
      {isAdmin && (
        <Grid>
          <Grid.Row className={styles.rowTitle}>
            <h3 className={styles.sortAndSearchTitle}>Refinements</h3>
          </Grid.Row>
          <section className={styles.filterSection} id='refinementSection'>
            {refinementOptions.map((refinementOption, key) => (
              <React.Fragment key={`${SEARCH_TYPES.refinement}-${key}`}>
                <CheckboxItem
                  type={refinementOption.type}
                  label={refinementOption.label}
                  styleClass={SEARCH_TYPES.refinement}
                  custom={true}
                  onChange={() => handleCheck(SEARCH_TYPES.refinement, refinementOption.filter)}
                  checked={isChecked(SEARCH_TYPES.refinement, refinementOption.filter)}
                />
                {refinementOption.options.length &&
                  refinementOption.options.map((element, index) => (
                    <Refinement
                      key={index}
                      parentLabel={refinementOption.label}
                      title={element.label}
                      refinement={element.refinement}
                      finalText={element.finalText}
                      filter={refinementOption.filter}
                      onChange={handleRefinements}
                      selected={refinementChoosed}
                      disabled={!isChecked(SEARCH_TYPES.refinement, refinementOption.filter)}
                    />
                  ))}
              </React.Fragment>
            ))}
          </section>
        </Grid>
      )}
      {isAdmin && (
        <Grid>
          <Grid.Row className={styles.rowTitle}>
            <h3 className={styles.sortAndSearchTitle}>Visibility</h3>
          </Grid.Row>
          <section className={styles.visibilitySection} id='visibility'>
            {visibilityOptions.map((option, key) => (
              <CheckboxItem
                key={`${SEARCH_TYPES.visibility}-${key}`}
                type={option.type}
                label={option.label}
                styleClass={SEARCH_TYPES.visibility}
                custom={true}
                onChange={() => handleCheck(SEARCH_TYPES.visibility, option.visibility)}
                checked={isChecked(SEARCH_TYPES.visibility, option.visibility)}
              />
            ))}
          </section>
        </Grid>
      )}
    </section>
  );
};

export default SortAndSearch;
