import { PageSize } from '@react-pdf/types';
import { Font, Document, Page, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

import EducationSection from './EducationSection';
import ExperienceSection from './ExperienceSection';
import Header from './Header';
import HeaderFigure from './HeaderFigure';
import LanguageSection from './LanguageSection';
import LeftFigure from './LeftFigure';
import RightFigure from './RightFigure';
import SkillSection from './SkillSection';
import Summary from './Summary';
import { templateColors } from '../utils/constants';
import { appVersion } from '../../constants/globalConstants';
import { IResumeForm } from '../../Models/IResumeForm';

interface IResumeProps {
  size: PageSize;
  resume: IResumeForm;
  highLightColor?: string;
}

Font.register({
  family: 'Helvetica Neue',
  src: '/fonts/Helvetica/HelveticaNeueLight.ttf'
});

Font.register({
  family: 'Helvetica Neue Bold',
  src: '/fonts/Helvetica/HelveticaNeueBold.ttf'
});

Font.register({
  family: 'Helvetica Neue Medium',
  src: '/fonts/Helvetica/HelveticaNeueMedium.ttf'
});

Font.register({
  family: 'Roboto',
  src: '/fonts/Roboto/Roboto-Bold.ttf'
});
const styles = StyleSheet.create({
  pageContainer: {
    paddingBottom: 93,
    paddingTop: 93
  },
  content: {
    flexDirection: 'row'
  },
  right: {
    marginBottom: 221.595
  },
  left: {
    paddingLeft: 18.75,
    paddingRight: 18.75,
    position: 'absolute',
    left: 465.75,
    width: 129.75
  }
});

const Resume: React.FC<IResumeProps> = ({ size, resume, highLightColor = templateColors.red }) => {
  const { personalInformation, workExperiences, educations, languages, skills } = resume;
  const skillData = skills.filter((skill) => skill.area !== '' && skill.skill !== '' && skill.enabled);
  const languageData = languages.filter((language) => language.language !== '' && language.enabled);
  const educationData = educations.filter((education) => education.enabled);
  const workExperiencesData = workExperiences.filter((education) => education.enabled);

  return (
    <Page size={size} style={styles.pageContainer}>
      <HeaderFigure fixed />
      <LeftFigure fixed />
      <RightFigure fixed />
      <View style={styles.content}>
        <View style={{}}>
          <Header personalInfo={personalInformation} highLightColor={highLightColor} />
          <Summary data={personalInformation.profesionalSummary} highLightColor={highLightColor} />
          <ExperienceSection data={workExperiencesData} highLightColor={highLightColor} />
          <EducationSection data={educationData} highLightColor={highLightColor} />
        </View>
        <View style={styles.left}>
          <SkillSection data={skillData} highLightColor={highLightColor} />
          <LanguageSection data={languageData} highLightColor={highLightColor} />
        </View>
      </View>
    </Page>
  );
};

const FormalTemplate: React.FC<{ resume: IResumeForm; highLightColor?: string }> = (props) => {
  return (
    <Document
      author='Mon CV'
      keywords='awesome, resume, mon cv'
      subject='The resume of MonCV users'
      title='Resume'
      creator={`Mon CV v${appVersion}`}
      producer={`${appVersion}`}
    >
      <Resume size='A4' {...props} />
    </Document>
  );
};

export default FormalTemplate;
