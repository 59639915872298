import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  circleSeparatorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '30'
  },
  circle: {
    width: '6',
    height: '6',
    borderRadius: '3',
    border: '1',
    borderColor: '#C7C7C7'
  },
  line: {
    backgroundColor: '#C7C7C7',
    width: '1',
    flexGrow: 1
  }
});
