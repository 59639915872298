import axios, { AxiosResponse } from 'axios';

import { baseAPIURL } from '../../constants/settings';
import { IUserSetting } from '../../Models/IUserSetting';
import { GetRequestHeader } from '../../utils/requestHeader';

const axiosInstance = axios.create({ baseURL: baseAPIURL });

export class UserService {
  private static instance: UserService;
  private resource = '/users';

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public userSetting(userId: string, userSetting: IUserSetting): Promise<AxiosResponse> {
    return axiosInstance.patch(`${this.resource}/${userId}`, { userSetting }, GetRequestHeader());
  }
}
