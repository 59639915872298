import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';

export const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    sectionContainer: {
      position: 'relative',
      width: 92.25
    },
    sectionTitle: {
      fontSize: 12,
      color: textColor,
      fontFamily: 'Helvetica Neue',
      marginBottom: 9
    },
    languagesContainer: {
      position: 'relative',
      width: 92.25
    },
    language: {
      marginBottom: 21
    },
    languageTitle: {
      fontSize: 9,
      fontWeight: 'medium',
      fontFamily: 'Helvetica Neue',
      marginRight: 9,
      maxLines: 3,
      textOverflow: 'ellipsis'
    },
    box: {
      marginTop: 3,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 3
    },
    languageType: {
      fontSize: 7.5,
      fontFamily: 'Helvetica Neue'
    },
    levels: {
      marginRight: 9
    },
    languageIcon: {
      height: 13.5,
      width: 13.5,
      marginRight: 7.5
    },
    native: {
      marginTop: 1,
      fontSize: 7.5,
      fontWeight: 'medium',
      fontFamily: 'Helvetica Neue'
    },
    languageText: {
      width: 71.25,
      flexWrap: 'wrap',
      flexDirection: 'row'
    }
  });
