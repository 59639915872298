import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message';
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition';

import './SharedPDF.scss';
import { showToastMessage, ToastEnum } from '../../../Toast/Toast';
import { ChevronDown, LinkIcon } from '../../../../Assets/CustomIcons';
import { IPDFData } from '../../../../Models/IPDFData';

export interface IOptionTemplate {
  label: string;
  resume: IPDFData;
}

interface ISharedPDFProps {
  options: IOptionTemplate[];
}

const SharedPDF: React.FC<ISharedPDFProps> = ({ options }) => {
  const [message, setMessage] = useState(false);
  const refText = useRef<HTMLTextAreaElement>(null);
  let timer: NodeJS.Timeout;

  const getUrl = (pdfData: IPDFData): string => {
    const { id, template } = pdfData;
    return `${window.location.origin}/shared/${id}?theme=${template}`;
  };

  const onClickOption = (data: IPDFData) => {
    try {
      if (refText.current) {
        refText.current.value = getUrl(data);
        refText.current.focus();
        refText.current.select();
        document.execCommand('copy');
        refText.current.blur();
      }

      setMessage(true);
      timer = setTimeout(() => {
        setMessage(false);
      }, 2000);
    } catch (error) {
      showToastMessage('Something wrong, please try again', ToastEnum.ERROR);
    }
  };

  useEffect(() => {
    return () => {
      timer && clearTimeout(timer);
    };
  }, []);

  return (
    <section className='share-pdf-cmpt'>
      <Dropdown
        trigger={
          <>
            <LinkIcon className='link-icon' />
            <label>Share PDF</label>
          </>
        }
        defaultValue={false}
        icon={<ChevronDown />}
        className='options'
      >
        <Dropdown.Menu>
          {options.map((pdf) => (
            <Dropdown.Item key={pdf.label} text={pdf.label} onClick={() => onClickOption(pdf.resume)} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <textarea className='text-Area' ref={refText} />
      <Transition visible={message}>
        <Message success size='mini' className='copy-message'>
          <Icon name='copy outline' />
          Copied to clipboard!
        </Message>
      </Transition>
    </section>
  );
};

export default SharedPDF;
