import moment from 'moment';

import { emailRegex, numberRegex } from './regexpPatterns';
import {
  formatDate,
  textEditorHtmlTags,
  isSafariBrowser,
  descriptionFormat
} from '../../constants/globalConstants';

const isRequired: Function = (value: string) => value !== '';
const isTextEditorFilled: Function = (value: string) => {
  if (value === '<p><br></p>') return false;

  const reducer = (acc: string, currentValue: string): string => {
    const regex = new RegExp(`${currentValue}`, 'g');
    return acc.replace(regex, '');
  };

  const cleanValue = textEditorHtmlTags.reduce(reducer, value);

  return cleanValue.trim() !== '';
};
const isEmail: Function = (value: string) => emailRegex.test(value);
const isNumber: Function = (value: string) => numberRegex.test(value);
const isDate: Function = (date: string) => {
  if (!date || date === undefined) {
    return false;
  }
  return moment(date, formatDate, true).isValid();
};

const getFormatedDateMonth = (dateString: string) => dateString.substr(0, 3);

const getFormatedDateYear = (dateString: string) => dateString.substr(dateString.length - 4);

const endDateIsBeforeStartDate: Function = (startDate: string, endDate?: string) => {
  if (!startDate) {
    return false;
  }

  if (endDate) {
    if (isSafariBrowser) {
      const standardStartDate = new Date(
        `${getFormatedDateMonth(startDate)}-01-${getFormatedDateYear(startDate)}`
      );
      const standardEndDate = new Date(
        `${getFormatedDateMonth(endDate)}-01-${getFormatedDateYear(endDate)}`
      );
      return standardEndDate < standardStartDate;
    }
    const standardStartDate = new Date(
      `${getFormatedDateMonth(startDate)}/01/${getFormatedDateYear(startDate)}`
    );
    const standardEndDate = new Date(
      `${getFormatedDateMonth(endDate)}/01/${getFormatedDateYear(endDate)}`
    );
    return standardEndDate < standardStartDate;
  }
  return false;
};

const verifyDefaultValue: Function = (
  value: string,
  defaultValue: string = descriptionFormat
): Boolean => {
  const valueWithoutSpaces = value.replace(/(?!=>|;)\s*(?=<)/g, '');
  const resp = valueWithoutSpaces !== defaultValue;
  return resp;
};

const maxDate: Function = (dateToValidate: string, maxDateToBe?: string) => {
  if (!maxDateToBe || !dateToValidate) {
    return true;
  }
  if (isSafariBrowser) {
    const standardDateToValidate = new Date(
      `${getFormatedDateMonth(dateToValidate)}-01-${getFormatedDateYear(dateToValidate)}`
    );
    const standardMaxDateToBe = new Date(
      `${getFormatedDateMonth(maxDateToBe)}-01-${getFormatedDateYear(maxDateToBe)}`
    );
    return standardDateToValidate < standardMaxDateToBe;
  }
  const standardDateToValidate = new Date(
    `${getFormatedDateMonth(dateToValidate)}/01/${getFormatedDateYear(dateToValidate)}`
  );
  const standardMaxDateToBe = new Date(
    `${getFormatedDateMonth(maxDateToBe)}/01/${getFormatedDateYear(maxDateToBe)}`
  );
  return standardDateToValidate <= standardMaxDateToBe;
};

const when: Function = (dependentField: string, validation: Function) => {
  const when = () => [dependentField, validation];
  return when;
};

export {
  isDate,
  isEmail,
  isNumber,
  isRequired,
  isTextEditorFilled,
  endDateIsBeforeStartDate,
  maxDate,
  verifyDefaultValue,
  when
};
