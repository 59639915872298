import React, { useState } from 'react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';
import Card from 'semantic-ui-react/dist/commonjs/views/Card/Card';

import styles from './PreviewDownload.module.scss';
import { OptionTheme, CloseIcon } from '../../Assets/CustomIcons';
import { TemplatePDF } from '../../constants/globalConstants';

interface ITemplatesViewerProps {
  onChangeTemplate: (template: TemplatePDF) => void;
}

const TemplatesViewer: React.FC<ITemplatesViewerProps> = ({ onChangeTemplate }) => {
  const [openTemplatePicker, setOpenTemplatePicker] = useState(false);

  const TEMPLATE_IMAGES = [
    { src: '/images/mon-cv-classic.jpg', name: 'Classic' },
    { src: '/images/mon-cv-corporate.jpg', name: 'Corporate' },
    { src: '/images/mon-cv-modern.jpg', name: 'Modern' },
    { src: '/images/mon-cv-aqua.jpg', name: 'Aqua' },
    { src: '/images/mon-cv-formal.jpg', name: 'Formal' },
    { src: '/images/mon-cv-simple.jpg', name: 'Simple' }
  ];

  const templates = TEMPLATE_IMAGES.map((template) => (
    <Card
      key={template.name}
      className={styles.cardTemplate}
      onClick={async () => {
        await onChangeTemplate(template.name.toLowerCase() as TemplatePDF);
        setOpenTemplatePicker(false);
      }}
    >
      <img src={template.src} className={styles.logo} />
      <span>{template.name}</span>
    </Card>
  ));

  return (
    <>
      <Modal
        trigger={
          <Button className={styles.button} secondary>
            <OptionTheme className={styles.icon} strokeWidth='2' />
            Change theme
          </Button>
        }
        open={openTemplatePicker}
        onClose={() => setOpenTemplatePicker(false)}
        onOpen={() => setOpenTemplatePicker(true)}
        dimmer='blurring'
        className={styles.templatesModal}
        id='modalTemplate'
        closeIcon={<CloseIcon className={styles.closeIcon} />}
      >
        <Container textAlign='center' className={styles.templatesContainer}>
          <Card.Group>{templates}</Card.Group>
        </Container>
      </Modal>
    </>
  );
};

export default TemplatesViewer;
