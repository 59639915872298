import React from 'react';

import './Pagination.scss';
import { ChevronLeft, ChevronRight } from '../../Assets/CustomIcons';

interface IPaginationProps {
  activePage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  neighbours?: number;
}

const DOTS = '···';

const getRange = (from: number, to: number, step = 1) => {
  let index = from;
  const range = [];

  while (index <= to) {
    range.push(index);
    index += step;
  }

  return range;
};

const Pagination: React.FC<IPaginationProps> = (props) => {
  const { activePage, totalPages, onPageChange, neighbours = 1 } = props;

  const getShowPages = () => {
    const currentPage = activePage;
    const pageNeighbours = neighbours;

    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = getRange(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      if (leftSpill && !rightSpill) {
        const extraPages = getRange(startPage - singleSpillOffset, startPage - 1);
        pages = [DOTS, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = getRange(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, DOTS];
      } else if (leftSpill && rightSpill) {
        pages = [DOTS, ...pages, DOTS];
      }

      return [1, ...pages, totalPages];
    }

    return getRange(1, totalPages);
  };

  const handleLeftPag = () => {
    if (activePage !== 1) {
      onPageChange(activePage - 1);
    }
  };

  const handleRigthPag = () => {
    if (activePage !== totalPages) {
      onPageChange(activePage + 1);
    }
  };

  const handleMoveLeftDashes = () => {
    onPageChange(activePage - neighbours * 2 - 1);
  };

  const handleMoveRightDashes = () => {
    onPageChange(activePage + neighbours * 2 + 1);
  };

  const handleClickDashes = (index: number) => {
    index === 1 ? handleMoveLeftDashes() : handleMoveRightDashes();
  };

  return (
    <div className='pagination-container' id='pagination'>
      <ChevronLeft
        className={activePage === 1 ? 'chevron disable' : 'chevron'}
        onClick={handleLeftPag}
      />
      {getShowPages().map((value, index) => {
        return (
          <div
            key={value.toString() + index}
            className={value === activePage ? 'page active' : 'page'}
            onClick={() => (value === DOTS ? handleClickDashes(index) : onPageChange(value as number))}
          >
            {value}
          </div>
        );
      })}
      <ChevronRight
        className={activePage === totalPages ? 'chevron disable' : 'chevron'}
        onClick={handleRigthPag}
      />
    </div>
  );
};

export default Pagination;
