import React from 'react';
import Card from 'semantic-ui-react/dist/commonjs/views/Card/Card';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';

import './WithFormContainer.scss';
import { CheckCircle, AlertCircle } from '../../Assets/CustomIcons';
import { SectionId } from '../../constants/sectionIDs';
import {
  ResumeSection,
  ResumeKeySection,
  SectionStatus,
  ISection
} from '../../Models/FormResumeSection';
import { ResumeOption } from '../../Models/IResumeOptions';

export interface IFormContainerProps {
  resumeId: string;
  resumeSectionData: ResumeSection | ResumeSection[];
  extraSectionData?: ResumeSection | ResumeSection[];
  resumeSectionOptions?: ResumeOption;
  sectionStatus: SectionStatus;
  sectionId: SectionId;
  currentSection: ISection;
  propertyKey: ResumeKeySection;
  resumeLanguageOptions?: DropdownItemProps[];
  setCurrentSection(sectionId: SectionId): void;
  setSectionStatus(sectionId: SectionId, sectionStatus: SectionStatus): void;
  saveFormSection(
    data: ResumeSection | ResumeSection[],
    propertyKey: ResumeKeySection,
    index?: number,
    updateEnabled?: boolean
  ): void;
  deleteEntryFromFormSection(
    index: number,
    propertyKey: ResumeKeySection,
    checkStatus?: SectionStatus,
    sectionId?: SectionId
  ): void;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export function withFormContainer(WrappedComponent: any, formTitle: string, formDescription = '') {
  // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
  return class extends React.Component<IFormContainerProps, any> {
    private firstRender = true;

    shouldComponentUpdate(nextProps: IFormContainerProps) {
      const { sectionId, sectionStatus } = this.props;
      const { sectionStatus: newSectionStatus } = nextProps;
      const currentSectionId = this.props.currentSection.sectionId;
      const nextCurrenSectionId = nextProps.currentSection.sectionId;
      const update = this.firstRender;
      this.firstRender = false;
      const updateSection =
        sectionId === currentSectionId ||
        sectionId === nextCurrenSectionId ||
        update ||
        sectionStatus !== newSectionStatus;
      switch (sectionId) {
        case SectionId.WORK_EXPERIENCE_SECTION:
          return updateSection || currentSectionId === SectionId.SKILL_SECTION;
        default:
          return updateSection;
      }
    }

    render() {
      const {
        sectionStatus,
        sectionId,
        currentSection: { sectionId: currentSectionId }
      } = this.props;

      return (
        <div onClick={() => this.handleFormSectionClicked()}>
          <Card
            fluid
            className={sectionId === currentSectionId ? 'card-container-active' : 'card-container'}
          >
            <div className='card-header'>
              <Header className='header-title'> {formTitle} </Header>
              <div className='header-icon'>{this.renderFormContainerStatusIcon(sectionStatus)}</div>
            </div>
            <div className='card-description'>{formDescription}</div>
            <WrappedComponent withFormProps={this.props} />
          </Card>
        </div>
      );
    }

    handleFormSectionClicked = () => {
      const {
        sectionId: mySectionId,
        currentSection: { sectionId: currentSectionId }
      } = this.props;
      if (mySectionId !== currentSectionId) {
        this.props.setCurrentSection(mySectionId);
      }
    };

    renderFormContainerStatusIcon = (status: SectionStatus) => {
      switch (status) {
        case SectionStatus.SUCCESS:
          return <CheckCircle className='progress-icon success-icon' />;
        case SectionStatus.ERROR:
          return <AlertCircle className='progress-icon warning-icon' />;
        case SectionStatus.UNSET:
        default:
          return null;
      }
    };
  };
}
