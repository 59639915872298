import axios, { AxiosResponse } from 'axios';

import { baseAPIURL } from '../../constants/settings';
import {
  INewArea,
  INewInternalProfile,
  INewJob,
  INewLanguage,
  INewProfile,
  INewSkill
} from '../../Models/INewOptions';
import { GetRequestHeader } from '../../utils/requestHeader';

const axiosInstance = axios.create({ baseURL: baseAPIURL });

export class ResumeOptionsService {
  private static Instance: ResumeOptionsService;
  private sourceArea = '/areas';
  private sourceSkill = '/skills';
  private sourceJob = '/jobs';
  private sourceProfile = '/profiles';
  private sourceLanguage = '/languages';
  private sourceInternalProfile = '/internal-profiles';
  private sourceMonCvOptions = '/moncv/options';
  private sourceMonCvSharedUsers = '/moncv/users';

  public static getInstance(): ResumeOptionsService {
    if (!ResumeOptionsService.Instance) {
      ResumeOptionsService.Instance = new ResumeOptionsService();
    }
    return ResumeOptionsService.Instance;
  }

  public getMonCvOptions(): Promise<AxiosResponse> {
    return axiosInstance.get(this.sourceMonCvOptions, GetRequestHeader());
  }

  public getAreas(): Promise<AxiosResponse> {
    return axiosInstance.get(`${this.sourceArea}`, GetRequestHeader());
  }

  public addArea(newArea: INewArea): Promise<AxiosResponse> {
    return axiosInstance.post(`${this.sourceArea}`, newArea, GetRequestHeader());
  }

  public addSkill(newSkill: INewSkill): Promise<AxiosResponse> {
    return axiosInstance.patch(`${this.sourceArea + this.sourceSkill}`, newSkill, GetRequestHeader());
  }

  public getLanguages(): Promise<AxiosResponse> {
    return axiosInstance.get(`${this.sourceLanguage}`, GetRequestHeader());
  }

  public addLanguage(newLanguage: INewLanguage): Promise<AxiosResponse> {
    return axiosInstance.post(`${this.sourceLanguage}`, newLanguage, GetRequestHeader());
  }

  public getJobs(): Promise<AxiosResponse> {
    return axiosInstance.get(`${this.sourceJob}`, GetRequestHeader());
  }

  public addJob(newJob: INewJob): Promise<AxiosResponse> {
    return axiosInstance.post(`${this.sourceJob}`, newJob, GetRequestHeader());
  }

  public addProfile(newProfile: INewProfile): Promise<AxiosResponse> {
    return axiosInstance.patch(`${this.sourceJob + this.sourceProfile}`, newProfile, GetRequestHeader());
  }

  public getInternalProfile(): Promise<AxiosResponse> {
    return axiosInstance.get(`${this.sourceInternalProfile}`, GetRequestHeader());
  }

  public addInternalProfile(newJob: INewInternalProfile): Promise<AxiosResponse> {
    return axiosInstance.post(`${this.sourceInternalProfile}`, newJob, GetRequestHeader());
  }

  public getMonCvUsersToShare(): Promise<AxiosResponse> {
    return axiosInstance.get(`${this.sourceMonCvSharedUsers}`, GetRequestHeader());
  }
}
