import { Text, Document, Page, StyleSheet } from '@react-pdf/renderer';
import { PageSize } from '@react-pdf/types';
import React from 'react';

import Education from './Education';
import Experience from './Experience';
import Header from './Header';
import Language from './Language';
import Skills from './Skills';
import Summary from './Summary';
import { templateColors } from '../utils/constants';
import { appVersion } from '../../constants/globalConstants';
import { IResumeForm } from '../../Models/IResumeForm';

interface IResumeProps {
  size: PageSize;
  resume: IResumeForm;
  highLightColor?: string;
}

export interface IResumeDocumentProps {
  resume: IResumeForm;
  highLightColor?: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    page: {
      paddingHorizontal: 42,
      paddingVertical: 40
    },
    title: {
      fontSize: 14,
      color: textColor,
      marginBottom: 10,
      marginTop: 14
    }
  });

const Resume: React.FC<IResumeProps> = ({ size, resume, highLightColor = templateColors.red }) => {
  const skills = resume.skills.filter(
    (skill) => skill.area !== '' && skill.skill !== '' && skill.enabled
  );
  const languages = resume.languages.filter((language) => language.language !== '' && language.enabled);

  return (
    <Page size={size} style={styles().page}>
      <Header data={resume.personalInformation} highLightColor={highLightColor} />
      <Text style={styles(highLightColor).title}> Summary </Text>
      <Summary data={resume.personalInformation.profesionalSummary} />
      <Skills data={skills} highLightColor={highLightColor} />
      <Experience data={resume.workExperiences} highLightColor={highLightColor} />
      <Education data={resume.educations} highLightColor={highLightColor} />
      <Language data={languages} highLightColor={highLightColor} />
    </Page>
  );
};

const ResumeDocument: React.FC<IResumeDocumentProps> = (props) => {
  return (
    <Document
      author='Mon CV'
      keywords='awesome, resume, mon cv'
      subject='The resume of MonCV users'
      title='Resume'
      creator={`Mon CV v${appVersion}`}
      producer={`${appVersion}`}
    >
      <Resume size='A4' {...props} />
    </Document>
  );
};

ResumeDocument.defaultProps = {
  highLightColor: templateColors.red
};

export default ResumeDocument;
