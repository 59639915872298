import React from 'react';

import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';

interface ISummaryProps {
  data: string;
}

const Summary: React.FC<ISummaryProps> = ({ data }) => {
  return <HTMLContent data={data} />;
};

export default Summary;
