import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './ModernUtils';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';
import { verifyStringLength } from '../utils/stringUtils';
import { IWorkExperience } from '../../Models/IWorkExperience';

interface IExperiencesProps {
  workExperiences: IWorkExperience[];
  highLightColor: string;
}
interface IExperienceProps {
  experience: IWorkExperience;
  isFirst: boolean;
  highLightColor: string;
}

const styles = (textColor = templateColors.blue) => {
  const mainTitleColor = getColors(textColor).mainTitle;
  const jobColor = getColors(textColor).job;
  return StyleSheet.create({
    mainTitle: {
      fontSize: 14,
      marginBottom: 10,
      marginTop: 14,
      color: mainTitleColor
    },
    experienceContainer: {
      fontSize: 10
    },
    job: {
      fontFamily: 'Roboto Bold',
      fontSize: 12,
      color: jobColor
    },
    client: {
      marginBottom: 6,
      fontSize: 12,
      fontFamily: 'Roboto Bold',
      maxLines: 2
    },
    skillContent: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    skillBox: {
      borderColor: '#F5F5F5',
      borderWidth: 1,
      marginTop: 5,
      marginRight: 5,
      padding: 3,
      borderRadius: 4,
      height: 20
    },
    descriptionContainer: {
      marginTop: 7,
      marginBottom: 12
    },
    ellipsis: {
      maxLines: 2,
      maxWidth: 162,
      textOverflow: 'ellipsis'
    }
  });
};

Font.register({
  family: 'Roboto Bold',
  src: `/fonts/Roboto/Roboto-Bold.ttf`
});

const clientMaxLength = 75;
const ellipsisMaxLength = 55;

const Experience: React.FC<IExperienceProps> = (props) => {
  const { startDate, endDate, profile, jobTitle, client, skills, description } = props.experience;
  const jobTitleText = jobTitle.replace(/\s+/g, ' ').trim();
  const profileText = profile.replace(/\s+/g, ' ').trim();
  return (
    <React.Fragment>
      <View style={styles().experienceContainer} wrap={false}>
        {props.isFirst && <Text style={styles(props.highLightColor).mainTitle}>EXPERIENCE</Text>}
        <Text>
          {startDate && startDate.substr(startDate.length - 4)} -{' '}
          {endDate ? endDate.substr(endDate.length - 4) : 'Present'}
        </Text>
        <View style={[{ ...styles(props.highLightColor).job }, { flexDirection: 'row' }]}>
          <Text style={styles().ellipsis}>{jobTitleText}</Text>
          <Text style={{ maxWidth: '5%', paddingHorizontal: 5 }}>{profile && ' | '}</Text>
          <Text style={styles().ellipsis}>{profileText}</Text>
        </View>
        <Text style={styles().client}>{verifyStringLength(client, clientMaxLength)}</Text>
      </View>
      <View style={styles().experienceContainer}>
        <View style={styles().skillContent}>
          {skills.map((name: string, i: number) => (
            <View key={i} style={styles().skillBox} wrap={false}>
              <Text>{verifyStringLength(name, ellipsisMaxLength)}</Text>
            </View>
          ))}
        </View>
        <View style={styles().descriptionContainer}>
          <HTMLContent data={description} />
        </View>
      </View>
    </React.Fragment>
  );
};

const Experiences: React.FC<IExperiencesProps> = ({ workExperiences, highLightColor }) => {
  return (
    <React.Fragment>
      {workExperiences &&
        workExperiences.reduce((components: Array<JSX.Element>, experience, index) => {
          experience.enabled &&
            components.push(
              <Experience
                key={index}
                experience={experience}
                isFirst={components.length === 0}
                highLightColor={highLightColor}
              />
            );
          return components;
        }, [])}
    </React.Fragment>
  );
};

export default Experiences;
