import { Defs, LinearGradient, Rect, Stop, Svg } from '@react-pdf/renderer';
import React from 'react';

interface ICustomRectangleProps {
  width: string;
  height: string;
  primaryColor: string;
  secondColor?: string;
  styles?: object;
}

export const CustomRectangle: React.FC<ICustomRectangleProps> = ({
  width,
  height,
  primaryColor,
  secondColor,
  styles
}) => {
  return (
    <Svg width={width} height={height} style={{ ...styles }}>
      <Defs>
        <LinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' y1='0'>
          <Stop offset='0' stopColor={primaryColor} />
          <Stop offset='1' stopColor={secondColor || primaryColor} />
        </LinearGradient>
      </Defs>
      <Rect width={width} height={height} fill='url(#linear-gradient)' y='0' x='0' />
    </Svg>
  );
};
