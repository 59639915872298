import React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import Accordion, { AccordionProps } from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { v4 as uuidv4 } from 'uuid';

import { IAccordionTitle, ISetupAccordion, ISingleAccordion } from './ISingleAccordion';
import './WithMultipleEntries.scss';
import { IWrappedByFormContainerHOCProps } from '../WithFormContainer/IWrappedByFormContainerHOCProps';
import { DragDropIcon } from '../../Assets/CustomIcons';
import { SectionId } from '../../constants/sectionIDs';
import { ResumeKeySection, ResumeSection, SectionStatus } from '../../Models/FormResumeSection';
import { IEducation } from '../../Models/IEducation';
import { ILanguage } from '../../Models/ILanguages';
import { ISkill } from '../../Models/ISkills';
import { IWorkExperience } from '../../Models/IWorkExperience';
import { ValidationNotifierService } from '../../services/validationNotifier.service';

const validationNotifier = ValidationNotifierService.getInstance();

interface IMultiEntryState {
  accordionEntries: ISingleAccordion[];
  activeAccordion: number;
  firstTimeValidation: boolean;
}

export function withMultipleEntries(
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  WrappedComponent: any,
  buttonLabel: string,
  resumeKeySection: ResumeKeySection,
  setupItem: ISetupAccordion
) {
  return class extends React.Component<IWrappedByFormContainerHOCProps, IMultiEntryState> {
    private activeAccordionElement: React.RefObject<HTMLInputElement>;
    constructor(props: IWrappedByFormContainerHOCProps) {
      super(props);
      this.state = {
        accordionEntries: this.generateEntryProps(
          props.withFormProps.resumeSectionData as ResumeSection[]
        ),
        activeAccordion: 0,
        firstTimeValidation: true
      };
      this.activeAccordionElement = React.createRef();
    }

    render() {
      return (
        <div className='accordion-container'>
          {this.state.accordionEntries.length > 0 ? (
            <Accordion fluid>
              <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.state.accordionEntries.map((accordion, i) =>
                        this.renderSingleAccordionEntry(WrappedComponent, i, accordion)
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Accordion>
          ) : null}
          <Button className='new-entry-button' onClick={this.handleNewAccordion}>
            <Icon name='plus' size='small' />
            {buttonLabel}
          </Button>
        </div>
      );
    }

    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    renderSingleAccordionEntry = (WrappedComponent: any, index: number, accordion: ISingleAccordion) => {
      const resumeSectionData = this.props.withFormProps.resumeSectionData as ResumeSection[];
      const { activeAccordion } = this.state;
      return (
        <Draggable key={accordion.id} index={index} draggableId={accordion.id}>
          {(provided, snaphot) => (
            <div
              className='draggable-item-container'
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{ ...provided.draggableProps.style }}
            >
              <div className='draggable-icon-container' {...provided.dragHandleProps}>
                <DragDropIcon className='drag-drop-icon' />
              </div>
              <div
                key={index}
                className={`individual-accordion-container ${
                  accordion.accordionHasError && 'individual-accordion-container-error'
                }`}
              >
                <Accordion.Title
                  active={activeAccordion === index}
                  index={index}
                  onClick={this.handleAccordionClicked}
                >
                  <div
                    className='titles-container'
                    ref={activeAccordion === index ? this.activeAccordionElement : null}
                  >
                    <div className='section-left'>
                      <div>
                        <Icon className='dropdown-icon' name='dropdown' size='big' />
                      </div>
                      <div className='accordion-main-title'>
                        <div className='accordion-title'>
                          {accordion.accordionTitle || `(${buttonLabel.toLowerCase()})`}
                        </div>
                        <div className='accordion-secondary-title'>
                          {accordion.accordionSecondaryTitle}
                        </div>
                        <div className='accordion-third-title'>{accordion.accordionThirdTitle}</div>
                      </div>
                    </div>
                    <div className='helpers-icon-container'>
                      {resumeSectionData[index].enabled ? (
                        <Icon
                          onClick={(event: React.SyntheticEvent<HTMLElement, Event>) =>
                            this.handleHideAccordionClicked(event, index)
                          }
                          className='eye-icon'
                          name='eye'
                        />
                      ) : (
                        <Icon
                          onClick={(event: React.SyntheticEvent<HTMLElement, Event>) =>
                            this.handleHideAccordionClicked(event, index)
                          }
                          className='eye-icon'
                          name='eye slash'
                        />
                      )}
                      <Icon
                        onClick={(event: React.SyntheticEvent<HTMLElement, Event>) =>
                          this.handleDeleteAccordion(event, index)
                        }
                        className='trash-icon'
                        name='trash alternate outline'
                      />
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeAccordion === index}>
                  <WrappedComponent
                    extraSectionData={this.props.withFormProps.extraSectionData}
                    firstTimeValidation={this.state.firstTimeValidation}
                    resumeId={this.props.withFormProps.resumeId}
                    accordionIndex={index}
                    activeAccordion={this.state.activeAccordion}
                    accordionIsEnabled={resumeSectionData[index]}
                    accordionFormData={resumeSectionData[index]}
                    accordionError={accordion.accordionHasError}
                    setAccordionTitles={this.setAccordionTitles}
                    setAccordionError={this.setAccordionError}
                    withMultipleEntriesProps={this.props}
                    currentSection={this.props.withFormProps.currentSection}
                    setCurrentSection={() =>
                      this.props.withFormProps.setCurrentSection(this.props.withFormProps.sectionId)
                    }
                    sectionId={this.props.withFormProps.sectionId}
                  />
                </Accordion.Content>
              </div>
            </div>
          )}
        </Draggable>
      );
    };

    getValueTitle = (element: ResumeSection, title?: IAccordionTitle): string => {
      if (!title) return '';
      return title?.fn
        ? title.fn(element, title.key, title.dependentKey)
        : element[title?.key as string];
    };

    generateEntryProps = (data: ResumeSection[]): ISingleAccordion[] => {
      return data.map((element) => {
        const { accordionSecondaryTitle, accordionThirdTitle, accordionTitle } = setupItem;
        return {
          id: uuidv4(),
          accordionHasError: false,
          accordionSecondaryTitle: this.getValueTitle(element, accordionSecondaryTitle),
          accordionThirdTitle: this.getValueTitle(element, accordionThirdTitle),
          accordionTitle: this.getValueTitle(element, accordionTitle)
        };
      });
    };

    handleFormSectionClicked = () => {
      const {
        sectionId: mySectionId,
        currentSection: { sectionId: currentSectionId },
        setCurrentSection
      } = this.props.withFormProps;
      if (mySectionId !== currentSectionId) {
        setCurrentSection(mySectionId);
      }
    };

    onDragStart = () => {
      const { currentSection, setCurrentSection, sectionId } = this.props.withFormProps;
      currentSection.sectionId !== sectionId && setCurrentSection(sectionId);
    };

    onDragEnd = (result: DropResult) => {
      const { source, destination } = result;
      const { activeAccordion } = this.state;
      if (!destination) {
        return;
      }
      let newActiveAccordion = source.index === activeAccordion ? destination.index : activeAccordion;
      if (activeAccordion < source.index && destination.index <= activeAccordion) {
        newActiveAccordion++;
      } else if (activeAccordion > source.index && activeAccordion <= destination.index) {
        newActiveAccordion--;
      }
      const items = this.reOrder<ISingleAccordion>(
        this.state.accordionEntries,
        source.index,
        destination.index
      );
      const { saveFormSection, propertyKey, resumeSectionData } = this.props.withFormProps;
      const dataEntries: ResumeSection[] = this.reOrder<ResumeSection>(
        resumeSectionData as ResumeSection[],
        source.index,
        destination.index
      );
      saveFormSection(dataEntries, propertyKey);

      this.setState({
        accordionEntries: items,
        activeAccordion: newActiveAccordion,
        firstTimeValidation: this.props.withFormProps.sectionStatus !== SectionStatus.UNSET
      });
    };

    reOrder = <T,>(data: T[], startIndex: number, endIndex: number): T[] => {
      const result = [...data];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    handleHideAccordionClicked = (event: React.SyntheticEvent<HTMLElement, Event>, index: number) => {
      this.handleFormSectionClicked();
      event.stopPropagation();
      const resumeSectionData = this.props.withFormProps.resumeSectionData as ResumeSection[];

      const ItemUpdated = { ...resumeSectionData[index] };
      ItemUpdated.enabled = !ItemUpdated.enabled;

      this.props.withFormProps.saveFormSection(
        ItemUpdated,
        this.props.withFormProps.propertyKey,
        index,
        true
      );
    };

    handleAccordionClicked = (
      event: React.SyntheticEvent<HTMLElement, Event>,
      accordionProps: AccordionProps
    ) => {
      const { index } = accordionProps;
      const { activeAccordion } = this.state;
      const newIndex = activeAccordion === index ? -1 : index;
      this.setState({ activeAccordion: newIndex }, () => {
        this.activeAccordionElement.current?.scrollIntoView({ block: 'center' });
      });
    };

    handleDeleteAccordion = (event: React.SyntheticEvent<HTMLElement, Event>, index: number) => {
      this.handleFormSectionClicked();
      event.stopPropagation();
      const { activeAccordion } = this.state;
      const newActiveAccordion = activeAccordion > index ? activeAccordion - 1 : activeAccordion;
      const newAccordionEntries: ISingleAccordion[] = [...this.state.accordionEntries];
      newAccordionEntries.splice(index, 1);
      const checkStatus = this.anyAccordionHasError(newAccordionEntries);
      this.handleObjectDelete(index, checkStatus);
      this.setState({
        accordionEntries: newAccordionEntries,
        activeAccordion: newActiveAccordion,
        firstTimeValidation: this.props.withFormProps.sectionStatus !== SectionStatus.UNSET
      });
    };

    evaluateValidation = () => {
      if (this.props.withFormProps.sectionStatus !== SectionStatus.UNSET) {
        this.anyAccordionHasError();
      }
    };

    handleNewAccordion = () => {
      let newElement: ResumeSection | undefined;
      const { accordionEntries } = this.state;
      const { saveFormSection, resumeSectionData } = this.props.withFormProps;
      const resumeSectionDatas = resumeSectionData as ResumeSection[];
      switch (this.props.withFormProps.sectionId) {
        case SectionId.SKILL_SECTION: {
          newElement = {
            area: '',
            level: '',
            skill: '',
            enabled: true
          } as ISkill;
          break;
        }
        case SectionId.WORK_EXPERIENCE_SECTION: {
          newElement = {
            id: uuidv4(),
            jobTitle: '',
            profile: '',
            internalProfile: '',
            client: '',
            startDate: '',
            endDate: '',
            description: '',
            skills: [],
            enabled: true
          } as IWorkExperience;
          break;
        }
        case SectionId.EDUCATION_SECTION: {
          newElement = {
            school: '',
            career: '',
            startDate: '',
            endDate: '',
            description: '',
            enabled: true
          } as IEducation;
          break;
        }
        default: {
          newElement = {
            language: '',
            listening: '',
            reading: '',
            speaking: '',
            writing: '',
            enabled: true
          } as ILanguage;
          break;
        }
      }
      saveFormSection(newElement, this.props.withFormProps.propertyKey, resumeSectionDatas.length, true);
      const { accordionSecondaryTitle, accordionThirdTitle, accordionTitle } = setupItem;
      const newItem: ISingleAccordion = {
        id: uuidv4(),
        accordionHasError: false,
        accordionSecondaryTitle: this.getValueTitle(newElement, accordionSecondaryTitle),
        accordionThirdTitle: this.getValueTitle(newElement, accordionThirdTitle),
        accordionTitle: this.getValueTitle(newElement, accordionTitle)
      };
      this.setState(
        {
          firstTimeValidation: false,
          activeAccordion: accordionEntries.length,
          accordionEntries: [...accordionEntries, newItem]
        },
        () => {
          this.activeAccordionElement.current?.scrollIntoView({ block: 'center' });
        }
      );
    };

    setAccordionTitles = (
      index: number,
      newTitle?: string,
      newSecondaryTitle?: string,
      newThirdTitle?: string
    ) => {
      const newAccordionEntries = [...this.state.accordionEntries];
      if (newTitle !== undefined) {
        newAccordionEntries[index].accordionTitle = newTitle;
      }

      if (newSecondaryTitle !== undefined) {
        newAccordionEntries[index].accordionSecondaryTitle = newSecondaryTitle;
      }

      if (newThirdTitle !== undefined) {
        newAccordionEntries[index].accordionThirdTitle = newThirdTitle;
      }

      this.setState({ accordionEntries: newAccordionEntries });
    };

    setAccordionError = (hasError: boolean, index: number) => {
      const newAccordionEntries = [...this.state.accordionEntries];
      if (newAccordionEntries[index] !== undefined) {
        newAccordionEntries[index].accordionHasError = hasError;
        this.anyAccordionHasError(newAccordionEntries);
        this.setState({ accordionEntries: newAccordionEntries });
      }
    };

    anyAccordionHasError = (dataEntries: ISingleAccordion[] = this.state.accordionEntries) => {
      const accordionEntries = dataEntries;
      let sectionStatus = SectionStatus.UNSET;

      if (accordionEntries.length !== 0) {
        const hasError = accordionEntries.some((accordion) => accordion.accordionHasError);
        sectionStatus = !hasError ? SectionStatus.SUCCESS : SectionStatus.ERROR;
      }

      this.props.withFormProps.setSectionStatus(this.props.withFormProps.sectionId, sectionStatus);
      return sectionStatus;
    };

    handleObjectDelete = (index: number, checkStatus: SectionStatus) => {
      this.props.withFormProps.deleteEntryFromFormSection(
        index,
        resumeKeySection,
        checkStatus,
        this.props.withFormProps.sectionId
      );
    };
  };
}
