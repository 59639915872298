const flags: { [key: string]: string } = {
  Chinese: '/images/chinese.png',
  French: '/images/french.png',
  German: '/images/german.png',
  Portuguese: '/images/portuguese.png',
  Spanish: '/images/spain.png',
  English: '/images/us.png',
  Default: '/images/world.png'
};

export const getLanguageImages = (language: string) => flags[language] || flags.Default;
