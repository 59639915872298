import { View, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

interface IHeaderFigureProps {
  fixed: boolean;
  customStyles?: Style;
}

const styles = StyleSheet.create({
  topSection: {
    position: 'absolute',
    top: 0
  }
});

const HeaderFigure: React.FC<IHeaderFigureProps> = ({ fixed = false, customStyles = {} }) => {
  return (
    <View fixed={fixed} style={[styles.topSection, customStyles]}>
      <Svg width='199.1445' height='140.469'>
        <Path d='M0,0H199.1445L0,140.469Z' fill='#f8f8f8' />
      </Svg>
      <Svg width='66.8745' height='140.469' style={styles.topSection}>
        <Path d='M0,0,66.8745,94.02825,0,140.469Z' fill='#f1f1f1' />
      </Svg>
    </View>
  );
};

export default HeaderFigure;
