import React from 'react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';

import styles from './CheckboxItem.module.scss';
import Checkbox from '../Checkbox/Checkbox';

interface ICheckboxItemProps {
  type: string;
  label: string;
  styleClass: string;
  onChange: Function;
  custom: boolean;
  checked: boolean;
}

const CheckboxItem: React.FC<ICheckboxItemProps> = ({
  type,
  label,
  styleClass,
  onChange,
  custom,
  checked
}) => {
  return (
    <Grid.Row
      className={
        styleClass === 'sort-item'
          ? styles.sortItem
          : styleClass === 'visibility-item'
          ? styles.visibilityItem
          : styles.filterItem
      }
    >
      <Checkbox
        onChange={onChange}
        size={20}
        type={type}
        checked={checked}
        customHandle={custom}
        emptyBackground={'#F2F4F5'}
      />
      <div onClick={() => onChange()} className={styles.checkboxText}>
        {label}
      </div>
    </Grid.Row>
  );
};

export default CheckboxItem;
