import { Font, StyleSheet } from '@react-pdf/renderer';
import { templateColors } from '../utils/constants';

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'stretch',
      marginBottom: 5
    },
    userDataSection: {
      top: 20,
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'row',
      width: 512
    },
    avatarColumn: {
      width: 100,
      height: 105,
      display: 'flex',
      marginRight: 25,
      justifyContent: 'center'
    },
    avatar: {
      width: 90,
      height: 90,
      objectFit: 'cover',
      marginTop: 10,
      marginRight: 16,
      borderTopLeftRadius: '180',
      borderTopRightRadius: '180',
      borderBottomRightRadius: '180',
      borderBottomLeftRadius: '180'
    },
    styleEmptyPicture: {
      width: 512,
      marginTop: 6
    },
    personalInfoContainer: {
      paddingTop: 10,
      width: 412
    },
    personalData: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      height: '100%'
    },
    name: {
      fontSize: 22,
      marginBottom: 2,
      fontFamily: 'Helvetica Neue Light',
      maxHeight: 78
    },
    title: {
      fontSize: 14,
      marginBottom: 4,
      maxHeight: 18,
      fontFamily: 'Helvetica Neue Light',
      color: textColor
    },
    email: {
      fontSize: 10,
      maxHeight: 12,
      fontFamily: 'Helvetica Neue Light'
    },
    phone: {
      marginTop: 2,
      paddingRight: 40,
      display: 'flex',
      fontFamily: 'Helvetica Neue Light',
      textWrap: 'nowrap',
      flexWrap: 'nowrap',
      textOverflow: 'ellipsis'
    },
    logoColumn: {
      width: 80,
      position: 'absolute',
      bottom: 7,
      top: 0,
      right: 0
    },
    jalaLogo: {
      width: 84,
      height: 20,
      alignSelf: 'flex-end'
    }
  });

Font.register({
  family: 'Helvetica',
  src: '/fonts/Helvetica/HelveticaNeue.ttf'
});

export default styles;
