import { Document, Font, Page, StyleSheet, View } from '@react-pdf/renderer';
import { PageSize } from '@react-pdf/types';
import React from 'react';

import Educations from './Education';
import Header from './Header';
import Skill from './Skill';
import Summary from './Summary';
import WorkExperience from './WorkExperience';
import Languages from '../utils/CommonPDFUtils/Language';
import { templateColors } from '../utils/constants';
import { appVersion } from '../../constants/globalConstants';
import { IResumeForm } from '../../Models/IResumeForm';

interface IResumeProps {
  resume: IResumeForm;
  size: PageSize;
  highLightColor?: string;
}

const styles = StyleSheet.create({
  page: {
    padding: 45
  },
  title: {
    fontSize: 16,
    marginBottom: 12,
    marginTop: 14
  },
  columColor: {
    width: 180,
    height: 850,
    position: 'absolute',
    backgroundColor: '#F8F8F8'
  },
  column1: {
    width: 125,
    marginRight: 10
  },
  column2: {
    width: 371
  },
  body: {
    flexDirection: 'row',
    marginTop: 15
  }
});

interface IResumeDocumentT2 {
  resume: IResumeForm;
  highLightColor?: string;
}

const Resume: React.FC<IResumeProps> = ({ size, resume, highLightColor = templateColors.red }) => {
  const skills = resume.skills.filter(
    (skill) => skill.area !== '' && skill.skill !== '' && skill.enabled
  );
  const languages = resume.languages.filter((language) => language.language !== '' && language.enabled);

  return (
    <Page size={size} style={styles.page}>
      <View fixed style={styles.columColor}></View>
      <Header personalInformation={resume.personalInformation} highLightColor={highLightColor} />
      <View style={styles.body}>
        <View style={styles.column1}>
          <Skill skills={skills} highLightColor={highLightColor} />
          <Languages languages={languages} title='Languages' highLightColor={highLightColor} />
        </View>
        <View style={styles.column2}>
          <Summary
            text={resume.personalInformation.profesionalSummary}
            highLightColor={highLightColor}
          />
          <WorkExperience workExperiences={resume.workExperiences} highLightColor={highLightColor} />
          <Educations educations={resume.educations} highLightColor={highLightColor} />
        </View>
      </View>
    </Page>
  );
};

const ResumeDocument: React.FC<IResumeDocumentT2> = (props) => {
  return (
    <Document
      author='Mon CV'
      keywords='awesome, resume, mon cv'
      subject='The resume of MonCV users'
      title='Resume'
      creator={`Mon CV v${appVersion}`}
      producer={`${appVersion}`}
    >
      <Resume size='A4' {...props}></Resume>
    </Document>
  );
};

export default ResumeDocument;
