import { View, Text, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';

interface ISummaryProps {
  data: string;
  highLightColor: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    summaryContainer: {
      width: 382.5,
      left: 68.25
    },
    title: {
      fontSize: 12,
      color: textColor,
      fontFamily: 'Helvetica Neue',
      marginBottom: 9
    },
    content: {
      width: 382.5,
      fontFamily: 'Helvetica Neue',
      fontSize: 9
    }
  });

const Summary: React.FC<ISummaryProps> = ({ data, highLightColor }) => {
  return (
    <View style={styles().summaryContainer}>
      <Text style={styles(highLightColor).title}>Summary</Text>
      <View style={styles().content}>
        <HTMLContent data={data} />
      </View>
    </View>
  );
};

export default Summary;
