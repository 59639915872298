import { Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import React from 'react';

import { templateColors } from '../utils/constants';
import { getColors } from './PlainUtils';

interface ITitleProps {
  urlImage: string;
  text: string;
  containerMarginLeft: string;
  highLightColor?: string;
}

const CircleSeparator: React.FC<ITitleProps> = ({
  urlImage,
  text,
  containerMarginLeft,
  highLightColor
}) => {
  const styles = (textColor = templateColors.gray) => {
    const text = getColors(textColor).areaTitle;
    return StyleSheet.create({
      titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '8',
        marginLeft: containerMarginLeft || '4',
        alignItems: 'flex-end',
        fontFamily: 'Georama'
      },
      icon: {
        width: 18,
        height: 18
      },
      text: {
        fontSize: 12,
        paddingLeft: '8',
        color: text
      }
    });
  };
  return (
    <View style={styles().titleContainer}>
      <View style={styles().icon}>
        <Image source={urlImage} style={styles().icon} />
      </View>
      <Text style={styles(highLightColor).text}>{text}</Text>
    </View>
  );
};

export default CircleSeparator;
