export enum SkillLevels {
  'fundamental awareness' = 1,
  'novice' = 2,
  'intermediate' = 3,
  'advanced' = 4,
  'expert' = 5
}

export const skillLevels = [
  {
    level: SkillLevels['fundamental awareness'],
    levelName: 'Fundamental Awareness',
    levelDetails: '(basic knowledge)'
  },
  {
    level: SkillLevels.novice,
    levelName: 'Novice',
    levelDetails: '(limitted experience)'
  },
  {
    level: SkillLevels.intermediate,
    levelName: 'Intermediate',
    levelDetails: '(practical application)'
  },
  {
    level: SkillLevels.advanced,
    levelName: 'Advanced',
    levelDetails: '(applied theory)'
  },
  {
    level: SkillLevels.expert,
    levelName: 'Expert',
    levelDetails: '(recognized authority)'
  }
];

export enum LanguageLevels {
  beginner = 1,
  intermediate = 2,
  advanced = 3,
  native = 4
}

export const languageLevels = [
  {
    level: LanguageLevels.beginner,
    levelName: 'Beginner'
  },
  {
    level: LanguageLevels.intermediate,
    levelName: 'Intermediate'
  },
  {
    level: LanguageLevels.advanced,
    levelName: 'Advanced'
  },
  {
    level: LanguageLevels.native,
    levelName: 'Native'
  }
];

export const templateColors = {
  red: '#D20000',
  blue: '#1867AC',
  aqua: '#0EC4A9',
  gray: '#949494'
};
