import { Image, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './AquaUtils';
import { CustomRectangle } from '../utils/CustomRectangle/CustomRectangle';

const FOOTER_JALASOFT = '/images/jalasoft-footer.jpg';

interface IFooterProps {
  highLightColor?: string;
}

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 0
  },
  backgroundFooter: {
    position: 'absolute'
  },
  imageFooter: {
    height: 14.25,
    width: 595.5,
    opacity: 0.1
  }
});

const Footer: React.FC<IFooterProps> = ({ highLightColor }) => {
  return (
    <View style={styles.footer}>
      <CustomRectangle
        width='595.5'
        height='14.25'
        primaryColor={getColors(highLightColor).primaryColor}
        secondColor={getColors(highLightColor).secondColor}
        styles={styles.backgroundFooter}
      />
      <Image src={FOOTER_JALASOFT} style={styles.imageFooter} />
    </View>
  );
};

export default Footer;
