import { View, StyleSheet, Svg, Path, Polygon } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

interface IFigureProps {
  fixed: boolean;
  customStyles?: Style;
}

const styles = StyleSheet.create({
  mainContainer: {
    position: 'absolute',
    backgroundColor: '#F8F8F8',
    right: 0,
    width: 129.75
  },
  bottomSection: {
    position: 'absolute',
    top: 657.2025
  }
});

const RightFigure: React.FC<IFigureProps> = ({ fixed = false, customStyles = {} }) => {
  return (
    <View fixed={fixed} style={[styles.mainContainer, customStyles]}>
      <View>
        <Svg width='129.75' height='96.1755' viewBox='0 0 173 128.234'>
          <Path d='M0,128.234,173,0H0Z' fill='#f1f1f1' />
        </Svg>
        <Svg width='129.75' height='842.25' viewBox='0 0 173 1123'>
          <Path d='M0,128.234,173,0V1123H0Z' fill='#f8f8f8' />
        </Svg>
      </View>
      <View style={styles.bottomSection}>
        <Svg width='129.75' height='185.0475'>
          <Polygon points='0,0 0,94.1325 129.75,185.0475' fill='#F1F1F1' stroke='none' strokeWidth={1} />
        </Svg>
      </View>
    </View>
  );
};

export default RightFigure;
