export enum SectionId {
  PERSONAL_INFORMATION_SECTION = 'personalInformation',
  SKILL_SECTION = 'skills',
  WORK_EXPERIENCE_SECTION = 'workExperiences',
  EDUCATION_SECTION = 'educations',
  LANGUAGE_SECTION = 'languages'
}

export enum ResumeSections {
  PERSONAL_INFORMATION = 'Personal Information',
  SKILL = 'Skills',
  WORK_EXPERIENCE = 'Work Experience',
  EDUCATION = 'Education',
  LANGUAGE = 'Language'
}
