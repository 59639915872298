import { debounce } from 'lodash';
import React from 'react';
import { Subscription } from 'rxjs';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';

import './EducationForm.scss';
import CustomDatePicker from '../../customFormControls/DatePicker/CustomDatePicker';
import RichTextEditor from '../../customFormControls/richTextEditor/richTextEditor';
import { formsDebounceTime } from '../../../../constants/globalConstants';
import { IWrappedByMultipleEntriesHOCProps } from '../../../../HOCs/WithMultipleEntries/IWrappedByMultipleEntriesHOCProps';
import { IEducation } from '../../../../Models/IEducation';
import { ValidationNotifierService } from '../../../../services/validationNotifier.service';
import { isValid } from '../../../../utils/validation/formRules';
import { SectionStatus } from '../../../../Models/FormResumeSection';
import { endDateIsBeforeStartDate } from '../../../../utils/validation/validator';

interface IEducationFormState {
  educationFormData: IEducation;
  errors: {
    school: boolean;
    career: boolean;
    startDate: boolean;
    description: boolean;
    compareDates: boolean;
  };
  status: SectionStatus;
  isFormValid: boolean;
}

export enum Fields {
  School = 'school',
  Career = 'career',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Description = 'description'
}

type validationFields = Fields.School | Fields.Career | Fields.StartDate;

const validationNotifier = ValidationNotifierService.getInstance();
const MAX_INPUT_LENGTH = '55';
const MAX_DATE_LENGTH = '8';

export default class EducationForm extends React.Component<
  IWrappedByMultipleEntriesHOCProps,
  IEducationFormState
> {
  private validationFields = [Fields.School, Fields.Career, Fields.StartDate];
  private subscription: Subscription | undefined;

  constructor(props: IWrappedByMultipleEntriesHOCProps) {
    super(props);
    this.state = {
      educationFormData: this.props.accordionFormData as IEducation,
      errors: {
        career: false,
        description: false,
        school: false,
        startDate: false,
        compareDates: false
      },
      status: SectionStatus.UNSET,
      isFormValid: true
    };
  }

  componentDidMount() {
    const { sectionId } = this.props.withMultipleEntriesProps.withFormProps;
    if (this.props.firstTimeValidation) {
      this.props.withMultipleEntriesProps.withFormProps.resumeId && this.validateFormFields();
    }
    this.subscription = validationNotifier.ValidationRequested.subscribe((notifiedSectionId) => {
      const { accordionIndex, activeAccordion } = this.props;
      if (sectionId === notifiedSectionId && accordionIndex === activeAccordion) {
        this.validateFormFields();
      }
    });
    const datepickers = document.querySelectorAll(
      `.form-education-container.education${this.props.accordionIndex} .dates-container input`
    );
    datepickers.forEach((element) => element.setAttribute('maxLength', MAX_DATE_LENGTH));
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }

  componentDidUpdate(prevProps: IWrappedByMultipleEntriesHOCProps) {
    const { activeAccordion, accordionIndex } = this.props;
    if (prevProps.activeAccordion === accordionIndex && activeAccordion !== accordionIndex) {
      this.validateFormFields();
    }
  }

  shouldComponentUpdate(nextProps: IWrappedByMultipleEntriesHOCProps, nextState: IEducationFormState) {
    let updated = false;
    if (
      this.props.activeAccordion === this.props.accordionIndex ||
      nextProps.activeAccordion === this.props.accordionIndex
    ) {
      updated =
        nextProps.accordionFormData !== this.props.accordionFormData ||
        nextState.errors.career !== this.state.errors.career ||
        nextState.errors.description !== this.state.errors.description ||
        nextState.errors.school !== this.state.errors.school ||
        nextState.errors.startDate !== this.state.errors.startDate ||
        nextState.isFormValid !== this.state.isFormValid ||
        nextState.errors.compareDates !== this.state.errors.compareDates ||
        nextProps.activeAccordion !== this.props.activeAccordion ||
        nextState.educationFormData !== this.state.educationFormData;
    }
    return updated;
  }

  render() {
    const {
      educationFormData: { school, career, startDate, endDate, description },
      errors
    } = this.state;

    return (
      <Form error={!this.state.isFormValid}>
        <div className={`form-education-container education${this.props.accordionIndex}`}>
          <Form.Group data-testid='school'>
            <Form.Input
              className={errors.school ? '' : 'input-color'}
              onChange={this.handleFormInput}
              onBlur={this.handleInputFocus}
              label='School / University'
              error={errors.school}
              id={Fields.School}
              maxLength={MAX_INPUT_LENGTH}
              value={school}
              width={10}
            />
            <Form.Input
              className={errors.career ? '' : 'input-color'}
              onBlur={this.handleInputFocus}
              onChange={this.handleFormInput}
              label='Career Degree'
              error={errors.career}
              id={Fields.Career}
              maxLength={MAX_INPUT_LENGTH}
              value={career}
              width={6}
            />
          </Form.Group>
          <Form.Group>
            <Grid className='dates-container'>
              <CustomDatePicker
                className={errors.startDate ? '' : 'input-color'}
                inputLabel='Start Date'
                id={Fields.StartDate}
                selectedDate={startDate}
                onChangeFunction={this.handleFormInput}
                error={errors.startDate}
                compareDatesError={errors.compareDates}
                mobileProp='sixteen'
                tabletProp='five'
                computerProp='five'
                isRequired
              />
              <CustomDatePicker
                inputLabel='End Date'
                id={Fields.EndDate}
                selectedDate={endDate}
                onChangeFunction={this.handleFormInput}
                mobileProp='sixteen'
                tabletProp='five'
                computerProp='five'
              />
            </Grid>
          </Form.Group>
          <Form.Field
            className='description-education-section optional-field'
            onQuillEditorChange={this.handleTextEditorChange}
            label='Description'
            control={RichTextEditor}
            onQuillError={errors.description}
            error={errors.description}
            placeHolder='Text'
            value={description}
          />
        </div>
      </Form>
    );
  }

  handleInputFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const controlId = event.currentTarget.id;
    const controlValue = event.target.value ? (event.target.value as string) : '';
    const newEducationFormData: IEducation = { ...this.state.educationFormData };
    newEducationFormData[controlId] = controlValue.replace(/\s\s+/g, ' ').trim();
    this.setState({ educationFormData: newEducationFormData }, () => {
      this.handleVerifyChange(controlId as Fields);
    });
  };

  handleVerifyChange = (field: Fields) => {
    const { educationFormData } = this.state;
    const dataProps = this.props.accordionFormData;
    educationFormData[field] !== dataProps[field] && this.handleSaveData();
  };

  handleFormInput = (event: React.SyntheticEvent<HTMLElement, Event>, data: InputOnChangeData) => {
    const controlId = data.id;
    const controlValue = data.value !== undefined ? (data.value as string) : '';
    const newEducationFormData: IEducation = { ...this.state.educationFormData };
    newEducationFormData[controlId] = controlValue;

    this.setState(
      {
        educationFormData: newEducationFormData
      },
      () => {
        const { school, career, startDate, endDate } = this.state.educationFormData;
        switch (controlId) {
          case 'school':
            this.props.setAccordionTitles(this.props.accordionIndex, school);
            this.handleSaveData();
            break;
          case 'career':
            this.props.setAccordionTitles(this.props.accordionIndex, undefined, career);
            this.handleSaveData();
            break;
          case 'startDate':
          case 'endDate':
            this.props.setAccordionTitles(
              this.props.accordionIndex,
              undefined,
              undefined,
              `(${startDate} - ${endDate || 'Present'})`
            );
            this.compareDates();
            this.handleVerifyChange(controlId as Fields);
            break;
          default:
            break;
        }
        this.validateField(controlValue, controlId);
      }
    );
  };

  handleTextEditorChange = (content: string) => {
    this.setState(
      {
        educationFormData: { ...this.state.educationFormData, description: content }
      },
      () => {
        this.handleSaveData();
      }
    );
  };

  inspectSectionValidation = () => {
    const { status } = this.state;
    const haveErrors = Object.values(this.state.errors).some((error) => error);
    if (status !== SectionStatus.UNSET) {
      const newstatus = haveErrors ? SectionStatus.ERROR : SectionStatus.SUCCESS;
      this.setState({ isFormValid: !haveErrors, status: newstatus });
    }
    this.props.setAccordionError(haveErrors, this.props.accordionIndex);
  };

  validateField = (value: string, field: validationFields) => {
    const validationStatus = isValid(value.trim(), field);
    const updated = this.state.errors[field] !== !validationStatus;
    updated &&
      this.setState(
        ({ errors }) => {
          return {
            errors: {
              ...errors,
              [field as string]: !validationStatus
            }
          };
        },
        () => {
          this.inspectSectionValidation();
        }
      );
    return validationStatus;
  };

  validateFormFields = () => {
    this.compareDates();
    const { compareDates } = this.state.errors;
    const areCommonFieldsValid = this.validationFields.reduce((status, field) => {
      const successfullyValidated = this.validateField(
        this.state.educationFormData[field] as string,
        field as validationFields
      );
      return status && successfullyValidated;
    }, true);

    const isFormValid: boolean = areCommonFieldsValid && !compareDates;
    const status = isFormValid ? SectionStatus.SUCCESS : SectionStatus.ERROR;
    this.setState({ isFormValid, status });
    this.props.setAccordionError(!isFormValid, this.props.accordionIndex);
  };

  handleSaveData = debounce(() => {
    const { educationFormData } = this.state;
    const { withMultipleEntriesProps } = this.props;
    withMultipleEntriesProps.withFormProps.saveFormSection(
      educationFormData,
      withMultipleEntriesProps.withFormProps.propertyKey,
      this.props.accordionIndex
    );
  }, formsDebounceTime);

  compareDates() {
    const { startDate, endDate } = this.state.educationFormData;
    const response = endDateIsBeforeStartDate(startDate, endDate);
    const needUpdate = this.state.errors.compareDates !== response;
    needUpdate &&
      this.setState(
        {
          ...this.state,
          errors: { ...this.state.errors, compareDates: response }
        },
        () => {
          this.inspectSectionValidation();
        }
      );
  }
}
