import React, { useEffect, useState } from 'react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import {
  DropdownProps,
  DropdownOnSearchChangeData
} from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import './RemoteDropdown.scss';

interface IDropProps {
  value: string;
  options: DropdownItemProps[];
  onChange: Function;
  onSearch: Function;
  defaultValue?: string;
  id?: string;
}

const RemoteDropdown: React.FC<IDropProps> = (props: IDropProps) => {
  const { value, options, onSearch, onChange, id } = props;
  const optiondefault = options.find((option) => option.value === value);
  const [triggerOption, setTriggerOption] = useState(optiondefault);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const optiondefault = options.find((option) => option.value === value);
    setTriggerOption(optiondefault);
  }, [value]);

  const handleOnChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { value } = data;
    const option = options.find((option) => option.value === value);
    setTriggerOption(option);
    onChange(value, id);
  };

  const handleOnSearch = async (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownOnSearchChangeData
  ) => {
    const { searchQuery } = data;
    setLoading(true);
    await onSearch(searchQuery, id);
    setLoading(false);
  };

  const handleTrigger = triggerOption && (
    <div>{options.find((option) => option.value === triggerOption.value)?.content}</div>
  );

  return (
    <div className='remote-dropdown-container'>
      <Dropdown
        onChange={handleOnChange}
        onSearchChange={handleOnSearch}
        placeholder='Search the resume'
        fluid
        search
        value={value}
        options={options}
        id={id}
        trigger={handleTrigger}
        loading={loading}
        className={`selection ${triggerOption ? 'cursor' : ''}`}
        clearable
      />
    </div>
  );
};

export default React.memo(RemoteDropdown);
