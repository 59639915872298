import React, { useCallback, useEffect } from 'react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

import styles from './CustomDatePickerHeader.module.scss';
import { ChevronLeft, ChevronRight } from '../../../../Assets/CustomIcons';
import '../../../../Styles/components/customDatePickerHeader.scss';

const generateYearsOptions = () => {
  const start = minDate.getFullYear();
  const end = maxDate.getFullYear();
  const yearsRange = [];
  for (let i = start; i <= end; i++) {
    yearsRange.push({ key: i, value: i, text: i });
  }
  return yearsRange;
};

const DATE_RANGE = 70;
const currentDate: Date = new Date();
const currentYear: number = currentDate.getFullYear();
const currentMonth: number = currentDate.getMonth();
const currenDay: number = currentDate.getDate();

export const minDate: Date = new Date(`${currentMonth + 1}/01/${currentYear - DATE_RANGE}`);
export const maxDate: Date = new Date(`${currentMonth + 1}/${currenDay}/${currentYear + DATE_RANGE}`);

interface ICustomDatePickerHeaderProps {
  date: Date;
  changeYear: Function;
  handleOnChange: (date: Date) => void;
}

export const CustomDatePickerHeader: React.FC<ICustomDatePickerHeaderProps> = (props) => {
  const { date, changeYear, handleOnChange } = props;
  const year = date.getFullYear();

  const decreaseYear = (): void => {
    changeYear(year - 1);
  };

  const increaseYear = (): void => {
    changeYear(year + 1);
  };

  const prevYearButtonDisabled = (): boolean => {
    return !(year > minDate.getFullYear());
  };

  const nextYearButtonDisabled = (): boolean => {
    return !(year < maxDate.getFullYear());
  };

  const removeSelectedMonthStyle = () => {
    const month = document
      .querySelector('.react-datepicker__month-text--keyboard-selected')
      ?.classList.remove('react-datepicker__month-text--keyboard-selected');
    return month;
  };

  const checkMinOrMaxDate = useCallback(() => {
    const selectedYear = date.getFullYear();
    const maxDateYear = maxDate.getFullYear();
    const minDateYear = minDate.getFullYear();
    const selectedMonth = date.getMonth();
    if (
      (selectedYear === maxDateYear && currentMonth <= selectedMonth) ||
      (selectedYear === minDateYear && selectedMonth < currentMonth)
    ) {
      date.setMonth(currentMonth);
      removeSelectedMonthStyle();
    }
    return date;
  }, [date]);

  const handleDropDownChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    changeYear(data.value);
  };

  useEffect(() => {
    const verifiedDate = checkMinOrMaxDate();
    handleOnChange(verifiedDate);
  }, [year, handleOnChange, checkMinOrMaxDate]);

  return (
    <div className={`custom-datepicker-header ${styles.customDatepickerHeader}`}>
      <Button
        compact
        size='mini'
        onClick={decreaseYear}
        disabled={prevYearButtonDisabled()}
        className={styles.button}
      >
        <ChevronLeft className={styles.svg} />
      </Button>
      <Dropdown
        className={styles.datepickerDropdown}
        value={year}
        onChange={handleDropDownChange}
        options={generateYearsOptions()}
        fluid
        selection
      />
      <Button
        compact
        size='mini'
        onClick={increaseYear}
        disabled={nextYearButtonDisabled()}
        className={styles.button}
      >
        <ChevronRight className={styles.svg} />
      </Button>
    </div>
  );
};
