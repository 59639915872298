export const skillPoints = (level: string): [string, string] => {
  let skillLevel = '';
  let skillLevelComplement = '•••••';
  switch (level) {
    case 'fundamental awareness':
      skillLevel = '•';
      skillLevelComplement = '••••';
      break;
    case 'novice':
      skillLevel = '••';
      skillLevelComplement = '•••';
      break;
    case 'intermediate':
      skillLevel = '•••';
      skillLevelComplement = '••';
      break;
    case 'advanced':
      skillLevel = '••••';
      skillLevelComplement = '•';
      break;
    case 'expert':
      skillLevel = '•••••';
      skillLevelComplement = '';
      break;
    default:
      break;
  }

  return [skillLevel, skillLevelComplement];
};
