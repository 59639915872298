import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';
import Search, { SearchProps } from 'semantic-ui-react/dist/commonjs/modules/Search';
import Card from 'semantic-ui-react/dist/commonjs/views/Card/Card';

import './ResumeGrid.scss';
import {
  SORT_OPTIONS,
  FILTER_OPTIONS,
  VISIBILITY_OPTIONS,
  REFINEMENT_OPTIONS
} from './SortAndFilterFields';
import ResumeCard from '../ResumeCard/ResumeCard';
import SortAndSearch from '../SortAndSearch/SortAndSearch';
import Paginations from '../../Pagination/Pagination';
import { DeleteIcon, Filter, SearchIcon, CloseIcon } from '../../../Assets/CustomIcons';
import { ActiveOptionStyle, ResumeOptionName } from '../../../constants/globalConstants';
import { resumesPerPage } from '../../../constants/settings';
import useComponentVisible from '../../../HOOKs/useComponentVisible';
import { SearchFilters } from '../../../Models/FormSearchFilters';
import { IResumeFormBasic } from '../../../Models/IResumeFormBasic';
import {
  GetResumes,
  SetDashboardPage,
  setTopBarActiveOptionStyle,
  setTopBarResumeOptionName,
  UpdateSearchCriteria
} from '../../../redux/actions/dashboardActions';
import { RootStore } from '../../../redux/store';
import { UserService } from '../../../services/user/user.service';

interface IResumeGridProps {
  dashboardShouldUpdate: boolean;
}

enum Scroll {
  TOP,
  BUTTON,
  NONE
}

const DEBOUNCE_WAIT = 500;
const MAXIMUM_SIZE_FOR_A_COLUMN = 992;
const DEFAULT_PAGE_NUMBER = 1;
let Position = Scroll.NONE;
const userService = UserService.getInstance();

const ResumeGrid: React.FC<IResumeGridProps> = ({ dashboardShouldUpdate }) => {
  const dashboardState = useSelector((state: RootStore) => state.dashboard);
  const {
    searchCriteria,
    authData: { userRole, userId }
  } = dashboardState;
  const [searchValue, setSearchValue] = useState(searchCriteria.criteria);
  const [lastSelectedRefinement, setLastSelectedRefinement] = useState<string>('');
  const dispatch = useDispatch();
  const currentPage = dashboardState.activePage;
  const page = dashboardState.totalPages && currentPage > dashboardState.totalPages ? 1 : currentPage;
  const { windowWidth } = useComponentVisible();
  const mounted = useRef<Boolean>();

  const debounceHandler = useCallback(
    debounce(
      (value: string, filters: string, sorts: string, visibility: string, refinements: string) => {
        dispatch(
          GetResumes(DEFAULT_PAGE_NUMBER, resumesPerPage, value, filters, sorts, visibility, refinements)
        );
        dispatch(SetDashboardPage(1));
      },
      DEBOUNCE_WAIT
    ),
    [
      searchCriteria.criteria,
      searchCriteria.filter,
      searchCriteria.sort,
      searchCriteria.visibility,
      searchCriteria.refinement
    ]
  );

  const onPageChange = (newPage: number) => {
    if (newPage !== dashboardState.activePage) {
      dispatch(SetDashboardPage(newPage));
      dispatch(
        GetResumes(
          newPage,
          resumesPerPage,
          searchCriteria.criteria,
          searchCriteria.filter,
          searchCriteria.sort,
          searchCriteria.visibility,
          searchCriteria.refinement
        )
      );
    }
  };

  const formatResumeDate = (resumeUpdatedDate: Date): string => {
    let formatedDate = '';
    const day = resumeUpdatedDate.getDate();
    const month = resumeUpdatedDate.toLocaleString('default', { month: 'long' });
    const year = resumeUpdatedDate.getFullYear();
    formatedDate = `${month} ${day}, ${year}`;
    return formatedDate;
  };

  const handleClickDeleteIcon = () => {
    setSearchValue('');
    updateSearch('');
  };

  const handleMouseDown = (event?: React.ChangeEvent<HTMLInputElement>) => {
    event?.preventDefault();
  };

  const updateCriteria = async (newCriteria: SearchFilters) => {
    if ('sort' in newCriteria || 'visibility' in newCriteria) {
      const userSetting = { ...searchCriteria, ...newCriteria };
      await userService.userSetting(userId, {
        defaultSortBy: userSetting.sort,
        defaultVisibility: userSetting.visibility
      });
    }
    dispatch(UpdateSearchCriteria(newCriteria));
  };

  const updateSearch = (value: string): void => {
    const newValue = value.replace(/\s\s+/g, ' ').trim();
    newValue !== searchCriteria.criteria && updateCriteria({ criteria: newValue } as SearchFilters);
  };

  const handleSearchChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, data: SearchProps) => {
    if (data.value?.toLowerCase() !== searchValue.toLowerCase()) {
      setSearchValue(data.value || '');
      updateSearch(data.value || '');
      dispatch(SetDashboardPage(1));
    }
  };

  const search = useCallback(
    (value: string, filters: string, sorts: string, visibility: string, refinements: string) => {
      debounceHandler(value, filters, sorts, visibility, refinements);
    },
    []
  );

  useEffect(() => {
    dispatch(setTopBarActiveOptionStyle(ActiveOptionStyle.HOME));
    dispatch(setTopBarResumeOptionName(ResumeOptionName.MY_RESUME));
    if (!searchCriteria.criteria && !dashboardState.activePage) {
      dispatch(SetDashboardPage(1));
    }
  }, []);

  useEffect(() => {
    Position === Scroll.TOP && window.scrollTo(0, 0);
    Position === Scroll.BUTTON && window.scrollTo(0, document.body.scrollHeight);
    Position = Scroll.NONE;
  }, [dashboardState.resumes]);

  useEffect(() => {
    Position = mounted.current ? Scroll.BUTTON : Scroll.NONE;
  }, [dashboardState.activePage]);

  const handleSearch = () => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      search(
        searchCriteria.criteria,
        searchCriteria.filter,
        searchCriteria.sort,
        searchCriteria.visibility,
        searchCriteria.refinement
      );
      Position = Scroll.TOP;
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchCriteria.filter, searchCriteria.criteria, searchCriteria.sort, searchCriteria.visibility]);

  useEffect(() => {
    if (searchCriteria && searchCriteria.filter && searchCriteria.refinement) {
      const refinementKeyValues = Object.keys(JSON.parse(searchCriteria.refinement));
      const filterValues = searchCriteria.filter.split(',');
      const updateDataWithRefinement = filterValues.some((refinement) =>
        refinementKeyValues.includes(refinement)
      );
      if (updateDataWithRefinement && filterValues.includes(lastSelectedRefinement)) {
        handleSearch();
      }
    }
  }, [searchCriteria.refinement, searchCriteria.visibility]);

  useEffect(() => {
    dispatch(
      GetResumes(
        page,
        resumesPerPage,
        searchCriteria.criteria,
        searchCriteria.filter,
        searchCriteria.sort,
        searchCriteria.visibility,
        searchCriteria.refinement
      )
    );
  }, [dashboardShouldUpdate]);

  const { searchAndFilterHide } = dashboardState;
  const styleHide = searchAndFilterHide ? 'hide' : '';

  return (
    <Container className='container-dashboard'>
      <Grid className='dashboard-grid'>
        <Grid.Column className={`sort-and-search-wrapper ${styleHide}`}>
          <SortAndSearch
            onChangeFilter={(newFilters: string) => {
              updateCriteria({ filter: newFilters } as SearchFilters);
            }}
            onChangeSort={(newSorts: string) => {
              updateCriteria({ sort: newSorts } as SearchFilters);
            }}
            onChangeVisibility={(newSorts: string) => {
              updateCriteria({ visibility: newSorts } as SearchFilters);
            }}
            onChangeRefinement={(newRefinements: string) => {
              updateCriteria({ refinement: newRefinements } as SearchFilters);
            }}
            criteriaSortMarked={searchCriteria.sort}
            criteriaFilterMarked={searchCriteria.filter}
            criteriaVisibilityMarked={searchCriteria.visibility}
            sortOptions={SORT_OPTIONS}
            filterOptions={FILTER_OPTIONS}
            visibilityOptions={VISIBILITY_OPTIONS}
            refinementOptions={REFINEMENT_OPTIONS}
            refinementChoosed={searchCriteria.refinement}
            privileges={userRole}
            lastRefinement={setLastSelectedRefinement}
          />
        </Grid.Column>
        <Grid.Column className='dashboard-wrapper'>
          <Menu.Menu className={styleHide}>
            <Menu.Item className='search-container'>
              <div className={'nav-bar-search-show'}>
                <Search
                  value={searchValue}
                  autoFocus={true}
                  input={{
                    label: { icon: <SearchIcon className='search-icon' /> },
                    labelPosition: 'left',
                    icon: false,
                    action: {
                      icon: searchValue !== '' && (
                        <DeleteIcon
                          onMouseDown={handleMouseDown}
                          onClick={handleClickDeleteIcon}
                          className='delete-icon'
                        />
                      )
                    }
                  }}
                  placeholder={'Find a CV by name, area or skill...'}
                  onSearchChange={handleSearchChange}
                  showNoResults={dashboardState.resumes?.length === 0}
                />
              </div>
              {windowWidth < MAXIMUM_SIZE_FOR_A_COLUMN && (
                <Modal
                  closeIcon={<CloseIcon className={'close-icon'} />}
                  className='search-modal'
                  trigger={<Filter className='filter-icon' />}
                  size='fullscreen'
                >
                  <div className='search-modal-title'>Filter</div>
                  <Modal.Content>
                    <SortAndSearch
                      onChangeFilter={(newFilters: string) => {
                        updateCriteria({ filter: newFilters } as SearchFilters);
                      }}
                      onChangeSort={(newSorts: string) => {
                        updateCriteria({ sort: newSorts } as SearchFilters);
                      }}
                      onChangeVisibility={(newSorts: string) => {
                        updateCriteria({ visibility: newSorts } as SearchFilters);
                      }}
                      onChangeRefinement={(newRefinements: string) => {
                        updateCriteria({ refinement: newRefinements } as SearchFilters);
                      }}
                      criteriaSortMarked={searchCriteria.sort}
                      criteriaFilterMarked={searchCriteria.filter}
                      criteriaVisibilityMarked={searchCriteria.visibility}
                      sortOptions={SORT_OPTIONS}
                      filterOptions={FILTER_OPTIONS}
                      visibilityOptions={VISIBILITY_OPTIONS}
                      refinementOptions={REFINEMENT_OPTIONS}
                      refinementChoosed={searchCriteria.refinement}
                      privileges={userRole}
                      lastRefinement={setLastSelectedRefinement}
                    />
                  </Modal.Content>
                </Modal>
              )}
            </Menu.Item>
          </Menu.Menu>
          {dashboardState.resumes && (
            <section className='resumes-container'>
              <Card.Group>
                {dashboardState.resumes.map((resume: IResumeFormBasic, index: number) => {
                  return (
                    <ResumeCard
                      resumeFormData={resume}
                      resumeUpdatedDate={formatResumeDate(new Date(resume.updatedDate))}
                      resumeId={resume._id}
                      key={resume._id || index}
                    />
                  );
                })}
              </Card.Group>
              {dashboardState.totalPages !== undefined && dashboardState.totalPages > 1 && (
                <div className='pagination-container'>
                  <Paginations
                    totalPages={dashboardState.totalPages}
                    activePage={page}
                    onPageChange={onPageChange}
                  />
                </div>
              )}
            </section>
          )}
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default ResumeGrid;
