import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';

export const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    sectionContainer: {
      left: 68.25,
      width: 382.5,
      marginTop: 24
    },
    sectionTitle: {
      fontSize: 12,
      color: textColor,
      fontFamily: 'Helvetica Neue',
      marginBottom: 9
    },
    experienceContainer: {
      fontSize: 10,
      flexDirection: 'row'
    },
    contentLeft: {
      flexDirection: 'column',
      width: 30
    },
    date: {
      paddingTop: 2,
      paddingRight: 6.375,
      marginTop: 1,
      fontSize: 8,
      fontFamily: 'Helvetica Neue',
      fontWeight: 'medium'
    },
    startDate: {
      color: textColor
    },
    lineRight: {
      borderRight: 1,
      borderRightColor: '#C7C7C7'
    },
    lineLeft: {
      borderLeft: 1,
      borderLeftColor: '#C7C7C7'
    },
    point: {
      marginTop: 1,
      fontSize: 10,
      color: textColor,
      left: -2.3
    },
    contentRight: {
      flexDirection: 'column',
      marginLeft: -4.5
    },
    jobContainer: {
      marginLeft: 10.5,
      width: 312,
      flexDirection: 'row',
      fontFamily: 'Helvetica Neue',
      fontSize: 10.5,
      color: textColor
    },
    client: {
      width: 312,
      fontFamily: 'Helvetica Neue',
      fontSize: 10.5,
      maxLines: 2,
      textOverflow: 'ellipsis',
      paddingLeft: 10.5,
      paddingBottom: 4.5
    },
    content: {
      paddingLeft: 10.5,
      marginBottom: 3,
      marginTop: -1,
      paddingTop: 5,
      paddingBottom: 5,
      width: 351,
      left: 29
    },
    skillContent: {
      width: 312,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    skillBox: {
      padding: 3,
      borderRadius: 3,
      marginTop: 5,
      marginRight: 5,
      fontFamily: 'Helvetica Neue',
      fontSize: 7.5,
      border: 1,
      borderColor: '#CCCCCC'
    },
    descriptionContainer: {
      width: 312,
      marginTop: 7,
      fontFamily: 'Helvetica Neue',
      fontSize: 9,
      marginBottom: 12
    },
    ellipsis: {
      maxLines: 2,
      maxWidth: '47%',
      textOverflow: 'ellipsis'
    },
    divider: {
      marginTop: 2,
      marginLeft: 8,
      marginRight: 5,
      borderLeftColor: textColor,
      opacity: 0.7,
      borderLeftWidth: 0.75,
      borderLeftStyle: 'solid',
      fontFamily: 'Helvetica Neue'
    }
  });
