import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';
import { getColors } from './PlainUtils';

export const styles = (textColor = templateColors.gray) => {
  const areaTitle = getColors(textColor).textColor;

  return StyleSheet.create({
    experienceContainer: {
      fontSize: 9,
      width: 350,
      display: 'flex',
      flexDirection: 'row'
    },
    content: {
      width: 320,
      paddingTop: 6,
      marginBottom: 12
    },
    sectionContainer: {
      fontFamily: 'Georama',
      marginBottom: 20
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'row'
    },
    dateEducation: {
      color: 'gray',
      fontSize: 9,
      fontWeight: 'normal',
      top: -8
    },
    jobContainer: {
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      fontSize: 11,
      top: -10
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      fontFamily: 'Georama',
      marginBottom: 4.5,
      color: areaTitle
    },
    divider: {
      maxWidth: '2%',
      paddingHorizontal: 5,
      fontFamily: 'Georama'
    },
    skillContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      top: -4
    },
    skillBox: {
      padding: 2,
      border: '1px solid #CCCCCC',
      borderRadius: 4,
      marginTop: 5,
      marginRight: 5,
      fontSize: 8
    },
    descriptionContainer: {
      marginTop: 7,
      fontSize: 9,
      fontFamily: 'Georama',
      fontWeight: 'medium'
    }
  });
};
