import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';

export const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    sectionContainer: {
      left: 68.25,
      marginTop: 24,
      width: 382.5
    },
    sectionTitle: {
      fontSize: 12,
      fontFamily: 'Helvetica Neue',
      color: textColor,
      marginBottom: 10
    },
    educationContainer: {
      fontSize: 10,
      flexDirection: 'row'
    },
    contentLeft: {
      flexDirection: 'column',
      width: 30
    },
    date: {
      marginTop: 1,
      fontSize: 8,
      fontFamily: 'Helvetica Neue',
      fontWeight: 'medium'
    },
    startDate: {
      color: textColor
    },
    point: {
      marginTop: 1,
      fontSize: 10,
      color: textColor,
      left: -2.3
    },
    contentRight: {
      flexDirection: 'column',
      marginLeft: -4.5,
      width: 312,
      marginBottom: 0.75
    },
    career: {
      marginLeft: 10.5,
      width: 312,
      fontFamily: 'Helvetica Neue',
      fontWeight: 'medium',
      color: textColor,
      fontSize: 10.5,
      maxLines: 2,
      textOverflow: 'ellipsis'
    },
    school: {
      width: 312,
      fontSize: 9,
      fontFamily: 'Helvetica Neue',
      fontWeight: 'bold',
      maxLines: 2,
      textOverflow: 'ellipsis',
      paddingLeft: 10.5,
      paddingBottom: 4.5
    },
    descriptionContainer: {
      width: 312,
      fontSize: 9,
      fontFamily: 'Helvetica Neue',
      fontWeight: 'medium',
      paddingLeft: 10.5,
      marginTop: -1,
      paddingTop: 5,
      paddingBottom: 5,
      left: 29
    },
    lineRight: {
      borderRight: 1,
      borderRightColor: '#C7C7C7'
    },
    lineLeft: {
      borderLeft: 1,
      borderLeftColor: '#C7C7C7'
    }
  });
