import React, { useEffect, useState } from 'react';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import { ISearchTagItem } from '../../../Models/IResumeFormBasic';

export interface ICardTagsProps {
  isHovering: boolean;
  filtered: ISearchTagItem[];
  resumeId: string;
  windowSize: number;
}

const CardTags: React.FC<ICardTagsProps> = ({ filtered, isHovering, resumeId, windowSize }) => {
  const [exceededFirstTagPosition, setFirstTagExceeded] = useState(false);
  const [exceededLastTagPosition, setLastTagExceeded] = useState(false);
  const [xPosition, setxPosition] = useState(0);

  const getSkillTagsStyle = () => {
    let stylesClassName = '';
    if (isHovering) {
      stylesClassName += exceededLastTagPosition
        ? 'filtered-skills-right-border-none'
        : 'filtered-skills-right-border';
      stylesClassName += exceededFirstTagPosition
        ? ' filtered-skills-left-border'
        : ' filtered-skills-left-border-none';
    }
    return stylesClassName === '' ? 'filtered-skills-border-none' : stylesClassName;
  };

  const getPopupPosition = (tagId: string): [number, number | undefined] | undefined => {
    const tagElement = document.getElementById(tagId)?.getBoundingClientRect();
    const tagPositionX = tagElement?.x;
    const cardElementPoints = document.getElementById(resumeId)?.getBoundingClientRect();
    const cardPositionX = cardElementPoints?.x;
    if (tagPositionX && cardPositionX && tagPositionX < cardPositionX) {
      const newPosition = cardPositionX - tagPositionX - 8;
      return [newPosition, 0];
    }
    return [0, 0];
  };

  const checkTagsPositions = (selectedCard: HTMLElement) => {
    checkFirstTagPosition(selectedCard);
    checkLastTagPosition(selectedCard);
  };

  const checkFirstTagPosition = (selectedCard: HTMLElement) => {
    const firtTagId = resumeId + filtered[0].item + '0';
    const firstTagPosition = document.getElementById(firtTagId)?.getBoundingClientRect().left;
    if (firstTagPosition) {
      const cardDivPosition = selectedCard.getBoundingClientRect().left - 3;
      const firtsTagExceeded = firstTagPosition < cardDivPosition;
      if (exceededFirstTagPosition !== firtsTagExceeded) {
        setFirstTagExceeded(!exceededFirstTagPosition);
      }
    }
  };

  const checkLastTagPosition = (selectedCard: HTMLElement) => {
    const filteredLength = filtered.length - 1;
    const lastTagId = resumeId + filtered[filteredLength].item + filteredLength;
    const lastTagPosition = document.getElementById(lastTagId)?.getBoundingClientRect().right;
    if (lastTagPosition) {
      const lastTagExceeded = lastTagPosition <= selectedCard.getBoundingClientRect().right + 3;
      if (exceededLastTagPosition !== lastTagExceeded) {
        setLastTagExceeded(lastTagExceeded);
      }
    }
  };

  const handleSkillTagsOnMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    const selectedCard = document.getElementById(resumeId);
    if (filtered?.length !== 0 && selectedCard) {
      selectedCard.style.cursor = 'grab';
      const cursorPosition = {
        left: selectedCard.scrollLeft,
        x: event.clientX
      };

      const mouseMoveHandler = (event: MouseEvent) => {
        const distanceX = event.clientX - cursorPosition.x;
        selectedCard.scrollLeft = cursorPosition.left - distanceX;
      };

      const mouseUpHandler = () => {
        selectedCard.style.removeProperty('user-select');
        setxPosition(cursorPosition.x);

        removeListeners();
        checkTagsPositions(selectedCard);
      };

      const mouseLeaveHandler = () => {
        selectedCard.style.cursor = 'pointer';
        selectedCard.style.removeProperty('user-select');

        removeListeners();
      };

      const removeListeners = () => {
        selectedCard.removeEventListener('mousemove', mouseMoveHandler);
        selectedCard.removeEventListener('mouseup', mouseUpHandler);
        selectedCard.removeEventListener('mouseleave', mouseLeaveHandler);
      };

      selectedCard.addEventListener('mousemove', mouseMoveHandler);
      selectedCard.addEventListener('mouseup', mouseUpHandler);
      selectedCard.addEventListener('mouseleave', mouseLeaveHandler);
    }
  };

  const onClickTagsSection = (e: React.MouseEvent | React.FocusEvent) => {
    e.preventDefault();
  };

  const handleSkillTagsTouchStart = (event: React.TouchEvent) => {
    const selectedCard = document.getElementById(resumeId);

    if (filtered?.length !== 0 && selectedCard) {
      const cursorPosition = {
        left: selectedCard.scrollLeft,
        x: event.touches[0].clientX
      };

      const handleTouchMove = (event: TouchEvent) => {
        const distanceX = event.touches[0].clientX - cursorPosition.x;
        selectedCard.scrollLeft = cursorPosition.left - distanceX;
      };

      const handleTouchEnd = () => {
        checkTagsPositions(selectedCard);

        selectedCard.removeEventListener('touchmove', handleTouchMove);
        selectedCard.removeEventListener('touchend', handleTouchEnd);
      };

      if (selectedCard) {
        selectedCard.addEventListener('touchmove', handleTouchMove);
        selectedCard.addEventListener('touchend', handleTouchEnd);
      }
    }
  };

  useEffect(() => {
    const selectedCard = document.getElementById(resumeId);
    selectedCard && checkTagsPositions(selectedCard);
  }, [checkTagsPositions, xPosition]);

  useEffect(() => {
    setFirstTagExceeded(false);
  }, [filtered]);

  return (
    <div className='tags-container'>
      {!isHovering && exceededFirstTagPosition && (
        <div className='resume-left-tag-border' onClick={onClickTagsSection} />
      )}
      <div
        className={`${getSkillTagsStyle()}`}
        onMouseDown={handleSkillTagsOnMouseDown}
        onTouchStart={handleSkillTagsTouchStart}
        onClick={onClickTagsSection}
        id={resumeId}
        data-testid='skill-tags'
      >
        {filtered.map((skillItem, index) => {
          const tagId = resumeId + skillItem.item + index;
          return (
            <Popup
              content={skillItem.level}
              hideOnScroll
              inverted
              className='tags-popup'
              size='mini'
              position='bottom left'
              pinned
              key={tagId}
              offset={getPopupPosition(tagId)}
              trigger={
                <div id={tagId} className='tag-skill'>
                  {skillItem.item}
                </div>
              }
            />
          );
        })}
      </div>
      {!isHovering && !exceededLastTagPosition && (
        <div className='resume-right-tag-border' onClick={onClickTagsSection} />
      )}
    </div>
  );
};

export default CardTags;
