import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';

export const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    mainContainer: {
      flexDirection: 'column',
      paddingTop: -93
    },
    logoContainer: {
      marginTop: -93,
      position: 'absolute',
      top: 44.25,
      width: 84.9675,
      height: 28,
      left: 366
    },
    inforContainer: {
      flexDirection: 'row',
      left: 68.25,
      maxWidth: 382.5,
      marginBottom: 23
    },
    name: {
      color: '#000000',
      fontFamily: 'Helvetica Neue',
      fontSize: 24,
      maxLines: 3,
      marginBottom: 2,
      textOverflow: 'ellipsis'
    },
    job: {
      color: textColor,
      fontSize: 12,
      fontFamily: 'Helvetica Neue',
      fontWeight: 'medium',
      maxLines: 1,
      textOverflow: 'ellipsis'
    },
    avatarContainer: {
      marginHorizontal: 25,
      height: 74.25,
      width: 74.25
    },
    userPhoto: {
      height: '100%',
      borderRadius: '50%'
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      fontFamily: 'Helvetica Neue Light'
    },
    textRow: {
      fontSize: 7.5,
      fontFamily: 'Helvetica Neue',
      fontWeight: 'normal'
    },
    icon: {
      marginTop: 2,
      marginRight: 6.945,
      marginBottom: 4.335,
      width: 6.915,
      height: 6.915
    }
  });
