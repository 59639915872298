import { debounce } from 'lodash';
import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import './PersonalInformationForm.scss';
import PhotoSelector from '../../customFormControls/PhotoDropDown/PhotoSelector';
import RichTextEditor from '../../customFormControls/richTextEditor/richTextEditor';
import { Roles, formsDebounceTime } from '../../../../constants/globalConstants';
import { IWrappedByFormContainerHOCProps } from '../../../../HOCs/WithFormContainer/IWrappedByFormContainerHOCProps';
import { SectionStatus } from '../../../../Models/FormResumeSection';
import { INewInternalProfile, OptionType } from '../../../../Models/INewOptions';
import { IPersonalInformation } from '../../../../Models/IPersonalInformation';
import { IWorkExperienceOptions } from '../../../../Models/IWorkExperience';
import { AddJob, AddInternalProfileOption } from '../../../../redux/actions/dashboardActions';
import { addNameOption } from '../../../../redux/actions/resumeActions';
import { RootStore } from '../../../../redux/store';
import { ResumeOptionsService } from '../../../../services/resumes/resumeOptions.service';
import { ValidationNotifierService } from '../../../../services/validationNotifier.service';
import { isValid } from '../../../../utils/validation/formRules';

interface IPersonalInformationFormState {
  personalInformationData: IPersonalInformation;
  enableAddJobOption: boolean;
  enableAddInternalProfileOption: boolean;
  enableAddFullNameOption: boolean;
  errors: {
    email: boolean;
    fullName: boolean;
    phoneNumber: boolean;
    profesionalSummary: boolean;
    mainJob: boolean;
  };
  isFormValid: boolean;
}

interface IReduxProps {
  userRole: string;
  profileEmail: string;
  jobOptions: IWorkExperienceOptions[];
  internalProfilesOptions: DropdownItemProps[];
  nameOptions?: DropdownItemProps[];
}

interface IDispatchProps {
  addJob: (jobTitles: IWorkExperienceOptions) => void;
  addNameOption: (newName: string) => void;
  addInternalProfile: (
    newInternalProfileOption: DropdownItemProps,
    newOption?: INewInternalProfile | undefined
  ) => void;
}

type IPersonalInformationFormProps = IReduxProps & IDispatchProps & IWrappedByFormContainerHOCProps;

export enum Fields {
  FullName = 'fullName',
  MainJob = 'mainJob',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  MainInternalProdile = 'mainInternalProfile',
  ProfessionalSummary = 'profesionalSummary',
  EnabledPicture = 'enabledPicture'
}
export enum PersonalInfoDropdowns {
  MainJob = 'enableAddJobOption',
  InternalProfile = 'enableAddInternalProfileOption',
  FullName = 'enableAddFullNameOption'
}
const validationNotifier = ValidationNotifierService.getInstance();
const description = `Describe your most relevant experience, skills and achievements. 
    The purpose of this summary is to explain your qualifications for the job in 3-5
     sentences and to convince people to read the entire resume document.`;

type fieldValidate = Fields.FullName | Fields.Email | Fields.MainJob | Fields.ProfessionalSummary;
const resumeOptionsService = ResumeOptionsService.getInstance();
const maxInputLength = 55;

class PersonalInformationForm extends React.Component<
  IPersonalInformationFormProps,
  IPersonalInformationFormState
> {
  private subscription: Subscription | undefined;
  private formFields = [Fields.Email, Fields.FullName, Fields.MainJob, Fields.ProfessionalSummary];

  constructor(props: IPersonalInformationFormProps) {
    super(props);
    this.state = {
      personalInformationData: this.props.withFormProps.resumeSectionData as IPersonalInformation,
      enableAddJobOption: false,
      enableAddInternalProfileOption: false,
      enableAddFullNameOption: false,
      errors: {
        email: false,
        fullName: false,
        phoneNumber: false,
        profesionalSummary: false,
        mainJob: false
      },
      isFormValid: true
    };
  }

  componentDidMount() {
    const { sectionId, resumeId } = this.props.withFormProps;

    this.checkNewOptionItems();
    if (resumeId) {
      this.validateFormFields();
    }
    this.subscription = validationNotifier.ValidationRequested.subscribe((notifiedSectionId) => {
      if (sectionId === notifiedSectionId) {
        this.validateFormFields();
      }
    });
    const dropDowns = document.querySelectorAll(`.main-dropDown input.search`);
    dropDowns.forEach((element) => element.setAttribute('maxLength', maxInputLength.toString()));
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }

  shouldComponentUpdate(
    nextProps: IPersonalInformationFormProps,
    nextState: IPersonalInformationFormState
  ) {
    const { resumeSectionData } = this.props.withFormProps;
    return (
      nextProps.withFormProps.resumeSectionData !== resumeSectionData ||
      nextState.personalInformationData !== this.state.personalInformationData ||
      nextState.errors.email !== this.state.errors.email ||
      nextState.errors.fullName !== this.state.errors.fullName ||
      nextState.isFormValid !== this.state.isFormValid ||
      nextState.errors.profesionalSummary !== this.state.errors.profesionalSummary ||
      nextState.enableAddJobOption !== this.state.enableAddJobOption ||
      nextState.enableAddInternalProfileOption !== this.state.enableAddInternalProfileOption ||
      nextState.enableAddFullNameOption !== this.state.enableAddFullNameOption ||
      nextProps.jobOptions !== this.props.jobOptions ||
      nextProps.internalProfilesOptions !== this.props.internalProfilesOptions
    );
  }

  componentDidUpdate(
    prevProps: IPersonalInformationFormProps,
    prevState: IPersonalInformationFormState
  ) {
    const { personalInformationData: data } = prevState;
    this.watchRequiredData(data.fullName, data.email);
    const currentProps = this.props.withFormProps.resumeSectionData as IPersonalInformation;
    const previousProps = prevProps.withFormProps.resumeSectionData as IPersonalInformation;
    if (previousProps.pictures !== currentProps.pictures) {
      this.setState({
        ...this.state,
        personalInformationData: {
          ...this.state.personalInformationData,
          pictures: currentProps.pictures
        }
      });
    }
  }

  checkNewOptionItems = () => {
    const { jobOptions, internalProfilesOptions, addInternalProfile, addJob } = this.props;
    const { mainJob, mainInternalProfile } = this.state.personalInformationData;

    const isNewJob =
      mainJob !== '' && !jobOptions.some((option) => option.value === mainJob || option.key === mainJob);
    if (isNewJob) {
      const newJobOption = this.generateNewJob(mainJob as string);
      addJob(newJobOption);
    }
    const isNewInternalProfile =
      mainInternalProfile !== '' &&
      !internalProfilesOptions.some(
        (profileOpt) =>
          profileOpt.value === mainInternalProfile || profileOpt.key === mainInternalProfile
      );
    if (isNewInternalProfile) {
      const internalProfileOption = this.generateNewInternalProfile(mainInternalProfile);
      addInternalProfile(internalProfileOption);
    }
  };

  render() {
    const { userRole } = this.props;
    const {
      errors,
      personalInformationData,
      enableAddJobOption,
      enableAddInternalProfileOption,
      enableAddFullNameOption,
      isFormValid
    } = this.state;
    const isNewResume = window.location.pathname.includes('new');

    return (
      <Form error={!isFormValid}>
        <div className='form-personal-info-container'>
          <Form.Group data-testid='fields-1'>
            {isNewResume && userRole === Roles.ADMIN ? (
              <Form.Input
                readOnly={userRole !== Roles.ADMIN}
                name={Fields.FullName}
                className={errors.fullName ? '' : 'input-color'}
                onChange={this.handleOnChange}
                onBlur={this.handleInputFocus}
                label='Full Name'
                error={errors.fullName}
                value={personalInformationData.fullName}
                maxLength='55'
                width={8}
                data-testid='fullNameInput'
              />
            ) : (
              <Form.Field width={8} className={`${errors.fullName && 'input-error'}`}>
                <div className='label-header'>
                  <label>Full Name</label>
                </div>
                <Dropdown
                  onChange={this.handleDropdownChange}
                  onBlur={() => {
                    this.handleSaveData();
                  }}
                  onAddItem={this.handleAddFullName}
                  onSearchChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
                    this.checkSearchFieldText(data, PersonalInfoDropdowns.FullName)
                  }
                  onClick={this.controlFocus}
                  options={this.props.nameOptions || []}
                  fluid
                  selection
                  search={userRole === Roles.ADMIN}
                  allowAdditions={enableAddFullNameOption}
                  value={personalInformationData.fullName}
                  id={Fields.FullName}
                  className='main-dropDown'
                />
              </Form.Field>
            )}
            <Form.Field width={8} className={`${errors.mainJob && 'input-error'}`}>
              <div className='label-header'>
                <label>Main Job Title</label>
              </div>
              <Dropdown
                onChange={this.handleDropdownChange}
                onBlur={() => {
                  this.handleSaveData();
                }}
                onClick={this.controlFocus}
                onAddItem={this.handleAddJob}
                onSearchChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
                  this.checkSearchFieldText(data, PersonalInfoDropdowns.MainJob)
                }
                options={this.props.jobOptions || []}
                fluid
                search
                selection
                clearable
                allowAdditions={enableAddJobOption}
                value={personalInformationData.mainJob}
                id={Fields.MainJob}
                className='main-dropDown'
              />
            </Form.Field>
          </Form.Group>
          <Form.Group data-testid='fields-2'>
            <Form.Input
              readOnly={userRole !== Roles.ADMIN}
              name={Fields.Email}
              className={errors.email ? '' : 'input-color'}
              onChange={this.handleOnChange}
              onBlur={this.handleInputFocus}
              label='Email'
              error={errors.email}
              value={personalInformationData.email}
              width={8}
              maxLength='55'
            />
            <Form.Input
              className='input-color optional-field'
              onChange={this.handleOnChange}
              onBlur={this.handleInputFocus}
              label='Phone Number'
              value={personalInformationData.phoneNumber}
              width={8}
              maxLength='20'
              name={Fields.PhoneNumber}
            />
          </Form.Group>
          {userRole === Roles.ADMIN && (
            <Form.Group>
              <Form.Field width={8} className='optional-field'>
                <div className='label-header'>
                  <label>Main Internal Profile</label>
                </div>
                <Dropdown
                  onChange={this.handleDropdownChange}
                  onAddItem={this.handleAddInternalProfile}
                  onClick={this.controlFocus}
                  onSearchChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
                    this.checkSearchFieldText(data, PersonalInfoDropdowns.InternalProfile)
                  }
                  options={this.props.internalProfilesOptions || []}
                  fluid
                  search
                  selection
                  clearable
                  allowAdditions={enableAddInternalProfileOption}
                  value={personalInformationData.mainInternalProfile}
                  id={Fields.MainInternalProdile}
                  className='main-dropDown'
                />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Field
            className='profesional-summary-container'
            error={errors.profesionalSummary}
            data-testid='fields-3'
          >
            <div className='profesional-summary-header'>
              <label>Professional Summary</label>
              <div className='profesional-summary-icon'>
                <Popup content={description} trigger={<Icon name='question circle' />} />
              </div>
            </div>
            <RichTextEditor
              onQuillEditorChange={(content: string) =>
                this.handleOnChange({ target: { value: content, name: 'profesionalSummary' } })
              }
              onQuillError={errors.profesionalSummary}
              placeHolder='Text'
              value={personalInformationData.profesionalSummary}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field width={6}>
              <PhotoSelector />
            </Form.Field>
          </Form.Group>
        </div>
      </Form>
    );
  }

  handleInputFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const controlId = event.currentTarget.name;
    const controlValue = event.target.value !== undefined ? (event.target.value as string) : '';
    const newPersonalInformation: IPersonalInformation = { ...this.state.personalInformationData };
    newPersonalInformation[controlId] = controlValue.replace(/\s\s+/g, ' ').trim();
    this.setState({ personalInformationData: newPersonalInformation }, () => {
      this.handleVerifyChange(controlId as Fields);
    });
  };

  watchRequiredData = (prevFullName: string, prevEmail: string) => {
    const { fullName, email } = this.state.personalInformationData;
    if (prevFullName !== fullName || prevEmail !== email) {
      if (!this.state.errors.fullName && !this.state.errors.email) {
        this.handleSaveData();
      }
    }
  };

  inspectSectionValidation = () => {
    const sectionStatus = this.props.withFormProps.sectionStatus;
    const canCheckSectionStatus =
      sectionStatus === SectionStatus.ERROR || sectionStatus === SectionStatus.SUCCESS;

    canCheckSectionStatus && this.validateFormFields();
  };

  validateField = (value: string, field: fieldValidate) => {
    const { errors } = this.state;
    const validationStatus = isValid(value.trim(), field);
    const updated = errors[field] !== !validationStatus;
    updated &&
      this.setState(
        ({ errors }) => ({
          errors: {
            ...errors,
            [field]: !validationStatus
          }
        }),
        () => {
          const { errors } = this.state;
          const { sectionId, sectionStatus, setSectionStatus } = this.props.withFormProps;
          const status = Object.values(errors).every((value) => value === false)
            ? SectionStatus.SUCCESS
            : SectionStatus.ERROR;
          sectionStatus !== status && setSectionStatus(sectionId, status);
        }
      );

    return validationStatus;
  };

  handleVerifyChange = (field: Fields) => {
    const { personalInformationData } = this.state;
    const dataProps = this.props.withFormProps.resumeSectionData as IPersonalInformation;

    personalInformationData[field] !== dataProps[field] && this.handleSaveData();
  };

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  handleOnChange = (event: any) => {
    const { value, name } = event.target;
    const newValues = { ...this.state.personalInformationData };
    newValues[name] = value;
    this.setState({
      personalInformationData: newValues
    });
    this.validateField(value, name);
    (name === 'profesionalSummary' || name === 'phoneNumber') && this.handleSaveData();
  };

  handleCheckboxChange = (event: SyntheticEvent<HTMLElement, Event>, field: string) => {
    event.stopPropagation();
    const keyEvent = event.nativeEvent as KeyboardEvent;
    if (keyEvent.key && keyEvent.key !== 'Enter') {
      return;
    }
    const newState = { ...this.state.personalInformationData };
    newState[field] = !newState.enabledPicture;
    this.setState({ personalInformationData: newState }, () => {
      this.handleSaveData();
    });
  };

  validateFormFields = () => {
    const { personalInformationData } = this.state;
    const isFormValid = this.formFields.reduce((status, field) => {
      const successfullyValidated = this.validateField(
        personalInformationData[field] as string,
        field as fieldValidate
      );
      return status && successfullyValidated;
    }, true);

    const sectionStatus = isFormValid ? SectionStatus.SUCCESS : SectionStatus.ERROR;
    this.setState({ isFormValid });
    this.props.withFormProps.setSectionStatus(this.props.withFormProps.sectionId, sectionStatus);
    this.handleSaveData();
  };

  controlFocus = () => {
    const { sectionId, currentSection, setCurrentSection } = this.props.withFormProps;
    if (sectionId !== currentSection.sectionId) {
      setCurrentSection && setCurrentSection(sectionId);
    }
  };

  handleSaveData = debounce(() => {
    const { personalInformationData } = this.state;
    const { withFormProps } = this.props;
    personalInformationData.mainJob = personalInformationData.mainJob
      ? personalInformationData.mainJob.replace(/\s\s+/g, ' ').trim()
      : '';
    withFormProps.saveFormSection(personalInformationData, 'personalInformation');
  }, formsDebounceTime);

  generateNewJob = (jobOption: string) => {
    const newJob: IWorkExperienceOptions = {
      key: jobOption,
      value: jobOption,
      text: jobOption,
      profiles: []
    };
    return newJob;
  };

  generateNewInternalProfile = (mainInternalProfile: string): DropdownItemProps => {
    const newInternalProfile = {
      key: mainInternalProfile,
      value: mainInternalProfile,
      text: mainInternalProfile
    };

    return newInternalProfile;
  };

  handleDropdownChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const selectedValue = (data.value as string).replace(/\s\s+/g, ' ').trim();
    const field = data.id;
    const newState = { ...this.state.personalInformationData };
    newState[field] = selectedValue;

    this.validateField(selectedValue, field);
    this.setState(
      {
        personalInformationData: newState
      },
      () => {
        this.handleSaveData();
      }
    );
  };

  handleAddFullName = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    this.props.addNameOption(data.value as string);
  };

  handleAddJob = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { profileEmail, jobOptions, addJob } = this.props;
    const newJobOption = (data.value as string).replace(/\s\s+/g, ' ').trim();
    if (newJobOption) {
      const isRegistered = jobOptions.some(
        (option) => option.value === newJobOption || option.key === newJobOption
      );
      if (!isRegistered) {
        const newJobOptionItem = this.generateNewJob(newJobOption);
        addJob(newJobOptionItem);
        profileEmail &&
          resumeOptionsService.addJob({
            userEmail: profileEmail,
            name: newJobOption,
            type: OptionType.candidate
          });
      }
    }
  };

  handleAddInternalProfile = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { addInternalProfile, profileEmail } = this.props;
    const newInternalProfile = (data.value as string).replace(/\s\s+/g, ' ').trim();

    const newInternalProfileOption = this.generateNewInternalProfile(newInternalProfile);

    const newInternalProfileDTO: INewInternalProfile = {
      name: newInternalProfile,
      type: OptionType.candidate,
      userEmail: profileEmail
    };

    addInternalProfile(newInternalProfileOption, newInternalProfileDTO);
  };

  checkSearchFieldText = (data: DropdownProps, prop: PersonalInfoDropdowns) => {
    const newEnableAdd = data.searchQuery?.trim() !== '';
    const newData: IPersonalInformationFormState = { ...this.state };
    newData[prop] = newEnableAdd;
    this.setState(newData);
  };
}

const mapStateToProps = (state: RootStore) => {
  return {
    userRole: state.dashboard.authData.userRole,
    profileEmail: state.dashboard.authData.profile.email,
    jobOptions: state.dashboard.resumeOptions.profiles,
    internalProfilesOptions: state.dashboard.resumeOptions.internalProfiles,
    nameOptions: state.resume.nameOptions
  };
};

export default connect(mapStateToProps, {
  addJob: AddJob,
  addInternalProfile: AddInternalProfileOption,
  addNameOption
})(PersonalInformationForm);
