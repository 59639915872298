import { useState } from 'react';

const useUserStatus = () => {
  const existUser = () => {
    let res = false;
    const storageSize = sessionStorage.length;
    for (let i = 0; i < storageSize; i++) {
      const key = sessionStorage.key(i);
      if (key) {
        const storageData = sessionStorage.getItem(key);
        const storageValue = storageData ? JSON.parse(storageData) : {};
        res =
          res || (storageValue ? Object.prototype.hasOwnProperty.call(storageValue, 'id_token') : false);
      }
    }
    return res;
  };
  const [isOnline] = useState(existUser());

  return isOnline;
};

export default useUserStatus;
