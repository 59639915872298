import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  pageContainer: {
    paddingBottom: 45,
    paddingTop: 45
  },
  body: {
    flexDirection: 'row'
  },
  column1: {
    width: 180,
    paddingLeft: '45',
    paddingRight: '29'
  },
  column2: {
    width: 415.5
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: '46',
    marginBottom: '10'
  }
});
