import React from 'react';

import styles from './LettersAvatar.module.scss';

interface ILettersAvatar {
  userName: string;
  size?: string;
  className?: string;
}

const LettersAvatar: React.FC<ILettersAvatar> = (props) => {
  const { userName, size, className } = props;

  const getInitialLetters = () => {
    return userName
      .split(/\s/)
      .slice(0, 2)
      .reduce((response, word) => (response += word.slice(0, 1)), '');
  };

  const getAvatarStyles = () => {
    const isTiny = size === 'tiny' ? styles.lettersAvatarTiny : '';
    const hasClass = className || '';
    return `${isTiny} ${hasClass}`;
  };

  return (
    <div className={`${styles.lettersAvatarProfile} ${getAvatarStyles()}`}>
      {getInitialLetters().toUpperCase()}
    </div>
  );
};

export default LettersAvatar;
