export const actions = {
  DELETE: 'delete',
  ADD: 'add',
  CHANGE: 'change'
};
export const formatDate = 'MMM-yyyy';

export const formatDateLength = 8;

export const isSafariBrowser = /apple/.test(window.navigator.vendor.toLocaleLowerCase());

export const dashboardPage = window.sessionStorage.getItem('page');

const searchCriteria = JSON.parse(window.sessionStorage.getItem('searchCriteria') as string);
export const sort = searchCriteria?.sort;
export const filter = searchCriteria?.filter;
export const refinement = searchCriteria?.refinement;
export const visibility = searchCriteria?.visibility;

export const textEditorHtmlTags = [
  '<p>',
  '</p>',
  '<strong>',
  '</strong>',
  '<em>',
  '</em>',
  '<ol>',
  '</ol>',
  '<li>',
  '</li>',
  '<u>',
  '</u>'
];

export const descriptionFormat =
  '<p><strong>Tools:</strong>&nbsp;</p>' +
  '<p><strong>Frameworks:</strong>&nbsp;</p>' +
  '<p><strong>Third-party:</strong>&nbsp;</p>' +
  '<p><strong>Version Control:</strong>&nbsp;</p>' +
  '<p><strong>Project Management:</strong>&nbsp;</p>' +
  '<p><strong>Project:</strong>&nbsp;</p>' +
  '<p><strong>Description:</strong>&nbsp;</p>';

export const appVersion = process.env.REACT_APP_VERSION;

export const Roles = {
  ADMIN: 'admin',
  USER: 'user'
};

export enum TemplatePDF {
  CORPORATE = 'corporate',
  CLASSIC = 'classic',
  MODERN = 'modern',
  AQUA = 'aqua',
  PLAIN = 'simple',
  FORMAL = 'formal'
}

export const formsDebounceTime = 410;

export enum HttpErrors {
  UNAUTHORIZED = 401,
  PAYLOAD_EXCEED = 413,
  DUPLICATED = 11000
}

export enum ResumeOptionName {
  RESUME = 'Resume',
  MY_RESUME = 'My Resume'
}

export enum ActiveOptionStyle {
  RESUME = 'resume',
  HOME = 'home'
}

export enum DeviceType {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile'
}

export const progressAnimationSpeed = 30;

export const pathSharedViewer = 'shared';
