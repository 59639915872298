import React, { SyntheticEvent } from 'react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import { Menu } from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

import styles from './Refinement.module.scss';

interface IRefinementOption {
  field: string;
  options: Array<string>;
  default: string;
  large: boolean;
}

interface IRefinement {
  title: string;
  finalText?: string;
  refinement: Array<IRefinementOption>;
  filter: string;
  onChange: Function;
  selected?: string | null;
  disabled?: boolean;
  parentLabel?: string;
}

const Refinement: React.FC<IRefinement> = ({
  title,
  refinement,
  finalText,
  filter,
  onChange,
  selected,
  disabled = false,
  parentLabel
}) => {
  const getRefinementOptions = (options: Array<string>) => {
    return options.map((option) => {
      return { key: option, text: option, value: option };
    });
  };

  const getDefaultValue = (defaultValue: string, filter: string, refinement: string) => {
    const newRefinements = selected ? JSON.parse(selected) : {};
    const value =
      newRefinements && newRefinements[filter] ? newRefinements[filter][refinement] : defaultValue;
    return value;
  };

  const getLastDropDownStyle = (field: string) => {
    return parentLabel === 'Language' && field === 'language' ? styles.lastMenu : '';
  };

  return (
    <section className={styles.refinementContainer}>
      <div className={`${styles.title} ${disabled && styles.disabledText}`}>{title}</div>
      <div className='refinement-dropdown'>
        {refinement.map((refinementOption, index) => (
          <Menu
            compact
            key={`refinement-option-${index}`}
            className={`${styles.menu} ${getLastDropDownStyle(refinementOption.field)}`}
          >
            <Dropdown
              item
              disabled={disabled}
              selection={refinementOption.large}
              defaultValue={getDefaultValue(refinementOption.default, filter, refinementOption.field)}
              options={getRefinementOptions(refinementOption.options)}
              className={`${styles.dropdown} ${refinementOption.large ? styles.largeOption : ''} ${
                disabled && styles.disabledDropdown
              }`}
              onChange={(_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
                onChange(filter, refinementOption.field, data.value);
              }}
            />
          </Menu>
        ))}
      </div>
      <div className={`${styles.finalText} ${disabled && styles.disabledText}`}>{finalText}</div>
    </section>
  );
};

export default Refinement;
