import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import TitleSection from './Title';
import { styles } from './SkillSection.styles';
import { skillPoints } from '../utils/skillPoints';
import { verifyStringLength, wordBreak } from '../utils/stringUtils';
import { ISkill } from '../../Models/ISkills';

interface ISkillsProps {
  data: ISkill[];
  highLightColor: string;
}

interface IAreaProps {
  isFirst: boolean;
  title: string;
  skills: ISkill[];
  highLightColor: string;
}

interface ISkillObj {
  area: string;
  skills: ISkill[];
}

interface ISkillProps {
  skill: string;
  level: string;
  highLightColor: string;
}

const WIDTH_LINE = 500;
const SIZE_PER_LETTER = 4;
const SIZE_PER_SKILL = 47;
const MAX_STRING_LENGTH = 55;
const MAX_SKILL_WORD_LENGTH = 15;
const LIGHT_ICON = '/images/light.png';

const getIndexToFirstLine = (skills: ISkill[]): number => {
  const skillLength = skills.length - 1;
  let firstLineSkills = 0;
  let indexLine = skillLength;
  skills.some((item, index) => {
    const value = item.skill.length * SIZE_PER_LETTER + SIZE_PER_SKILL;
    if (firstLineSkills + value > WIDTH_LINE) {
      indexLine = index - 1;
    }
    firstLineSkills += value;
    return indexLine !== skillLength;
  });

  return indexLine;
};

const Skill: React.FC<ISkillProps> = ({ level, skill, highLightColor }) => {
  const getLevel = (level: string) => {
    const [skillLevel, skillLevelComplement] = skillPoints(level);
    return (
      <React.Fragment>
        <Text style={styles(highLightColor).skillLevel}>{skillLevel}</Text>
        <Text style={styles().skillComplementLevel}>{skillLevelComplement}</Text>
      </React.Fragment>
    );
  };

  const skillName = skill.split(/(\s+)/).map((word) => {
    return wordBreak(word, MAX_SKILL_WORD_LENGTH);
  });
  return (
    <View style={styles().skill} wrap={false}>
      {getLevel(level)}
      <Text style={styles().skillName}>{skillName}</Text>
    </View>
  );
};

const Area: React.FC<IAreaProps> = ({ title, skills, isFirst, highLightColor }) => {
  const skillTitle = 'SKILLS';
  const skillsCopy = [...skills];
  const quantityFirstLine = getIndexToFirstLine(skills);
  const firstLine = skillsCopy
    .splice(0, quantityFirstLine + 1)
    .map((skill, index) => (
      <Skill key={index} level={skill.level} skill={skill.skill} highLightColor={highLightColor} />
    ));

  return (
    <View style={styles().areaContainer}>
      <View wrap={false}>
        {isFirst && (
          <View style={styles().containerElements}>
            <TitleSection
              urlImage={LIGHT_ICON}
              text={skillTitle}
              containerMarginLeft={'0'}
              highLightColor={highLightColor}
            />
          </View>
        )}
        <Text style={styles().areaTitle}>
          {verifyStringLength(title.toUpperCase(), MAX_STRING_LENGTH)}
        </Text>
        {firstLine.length > 0 && <View>{firstLine}</View>}
      </View>
    </View>
  );
};

const Skills: React.FC<ISkillsProps> = ({ data, highLightColor }) => {
  const getSkills = (skills: ISkill[]) => {
    const skillsMap: Map<string, ISkill[]> = new Map<string, ISkill[]>();
    const skillsArray: ISkillObj[] = [];
    skills.forEach((skill) => {
      let skillByAreaArray = skillsMap.get(skill.area);

      if (skillByAreaArray) {
        skillByAreaArray.push(skill);
      } else {
        skillByAreaArray = [skill];
      }
      skillsMap.set(skill.area, skillByAreaArray);
    });
    skillsMap.forEach((value, key) => {
      const obj = {
        area: key,
        skills: value
      };
      skillsArray.push(obj);
    });
    return skillsArray;
  };

  const skillsMap = getSkills(data);

  return (
    <React.Fragment>
      {skillsMap && (
        <View style={styles(highLightColor).skillsContainer}>
          {skillsMap.map((item, index) => (
            <Area
              key={index}
              title={item.area}
              skills={item.skills}
              isFirst={index === 0}
              highLightColor={highLightColor}
            />
          ))}
        </View>
      )}
    </React.Fragment>
  );
};

export default Skills;
