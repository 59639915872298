import { capitalize } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import DialogModal from '../../DialogModal/DialogModal';
import ResumeMergeForm from '../../Forms/ResumeMergeForm/ResumeMergeForm';
import { showToastMessage, ToastEnum } from '../../Toast/Toast';
import { HorizontalDotsIcon, LinkIcon } from '../../../Assets/CustomIcons';
import { Roles, TemplatePDF } from '../../../constants/globalConstants';
import { resumesPerPage } from '../../../constants/settings';
import { IPDFData } from '../../../Models/IPDFData';
import { GetResumes, hideResumeDashboard } from '../../../redux/actions/dashboardActions';
import { hideResume } from '../../../redux/actions/resumeActions';
import { RootStore } from '../../../redux/store';
import { ResumeService } from '../../../services/resumes/resume.service';

interface IMenuProps {
  resumeId: string;
  name: string;
  email: string;
  picture: string;
  isHovering: boolean;
  setHovering: () => void;
  isHidden: boolean;
  icon?: React.ReactNode;
}

const WIDTH_SHARE_PDF_OPTIONS = 92;
const ResumeRequest = ResumeService.getInstance();

const getModalContent = (name: string) => ({
  title: 'Remove Resume',
  description: `${name}'s CV will be removed.`,
  buttonName: 'Remove'
});

const resumePdfExported = (resumeId: string) => [
  {
    label: capitalize(TemplatePDF.CLASSIC),
    resume: {
      id: resumeId,
      template: TemplatePDF.CLASSIC
    }
  },
  {
    label: capitalize(TemplatePDF.CORPORATE),
    resume: {
      id: resumeId,
      template: TemplatePDF.CORPORATE
    }
  },
  {
    label: capitalize(TemplatePDF.MODERN),
    resume: {
      id: resumeId,
      template: TemplatePDF.MODERN
    }
  },
  {
    label: capitalize(TemplatePDF.AQUA),
    resume: {
      id: resumeId,
      template: TemplatePDF.AQUA
    }
  },
  {
    label: capitalize(TemplatePDF.FORMAL),
    resume: {
      id: resumeId,
      template: TemplatePDF.FORMAL
    }
  },
  {
    label: capitalize(TemplatePDF.PLAIN),
    resume: {
      id: resumeId,
      template: TemplatePDF.PLAIN
    }
  }
];

export const CardMenu: React.FC<IMenuProps> = ({
  resumeId,
  name,
  email,
  picture,
  isHovering,
  setHovering,
  isHidden,
  icon = null
}: IMenuProps) => {
  const history = useHistory();
  const [openDialogModal, setDialogModal] = useState(false);
  const [openMergeModal, setMergeModal] = useState(false);
  const [openSharePDF, setSharePDF] = useState({ open: false, right: true });
  const [openDropdown, setOpenDropdown] = useState(false);
  const refSharedOption = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const dashboardState = useSelector((state: RootStore) => state.dashboard);
  const { userRole } = dashboardState.authData;
  const handleClickMenu = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const handleClickDropdownItem = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenDropdown(true);
  };

  const handleClickSharedPdfItem = (event: React.SyntheticEvent, resume: IPDFData) => {
    event.preventDefault();
    event.stopPropagation();
    onClickOption(resume);
    setOpenDropdown(false);
  };

  const handleOpenDialogModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setDialogModal(true);
  };

  const handleCloseModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setHovering();
    setDialogModal(false);
  };

  const handleHideResume = (event: React.MouseEvent) => {
    event.preventDefault();
    if (icon) {
      dispatch(hideResume(resumeId, !isHidden));
    } else {
      dispatch(hideResumeDashboard(resumeId, !isHidden));
    }
  };

  const disableButton = () =>
    document.getElementById('confirm-button')?.setAttribute('disabled', 'true');

  const enableButton = () => document.getElementById('confirm-button')?.removeAttribute('disabled');

  const redirectToDashboard = () => {
    icon && history.push('/dashboard');
  };

  const handleClickRemoveOption = async () => {
    try {
      disableButton();
      const currentPage = sessionStorage.getItem('page');
      const page = currentPage ? +currentPage : 1;
      setHovering();
      await ResumeRequest.removeResume(resumeId);
      const { criteria, filter, sort, visibility, refinement } = dashboardState.searchCriteria;
      dispatch(GetResumes(page, resumesPerPage, criteria, filter, sort, visibility, refinement));
      setDialogModal(false);
      showToastMessage('Removed successfully', ToastEnum.SUCCESS);
      redirectToDashboard();
    } catch (error) {
      showToastMessage('Something wrong, please try again', ToastEnum.ERROR);
      enableButton();
    }
  };

  const handleMerge = (event: React.MouseEvent) => {
    event.preventDefault();
    setMergeModal(true);
  };

  const onClickOption = (data: IPDFData) => {
    const { id, template } = data;
    const url = `${window.location.origin}/shared/${id}?theme=${template}`;
    try {
      const textField = document.createElement('textarea');
      textField.innerText = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      showToastMessage('Copied to clipboard!', ToastEnum.SUCCESS);
    } catch (error) {
      showToastMessage('Something wrong, please try again', ToastEnum.ERROR);
    }
  };

  const renderAdminMenuOptions = () => (
    <>
      <Dropdown.Item id='first-option' className='card-option' onClick={handleOpenDialogModal}>
        <span className='title'>Remove</span>
        <Icon className='trash alternate outline option-icon' size='small' />
      </Dropdown.Item>
      <Dropdown.Item id='second-option' className='card-option' onClick={handleMerge}>
        <span className='title'>Migrate</span>
        <Icon className='option-icon' name='clone outline' size='small' />
      </Dropdown.Item>
      <Dropdown.Item id='third-option' className='card-option' onClick={handleHideResume}>
        <span className='title'>{isHidden ? 'Unhide' : 'Hide'}</span>
        {isHidden ? (
          <Icon className='option-icon' size='small' name='eye' />
        ) : (
          <Icon className='option-icon' size='small' name='eye slash' />
        )}
      </Dropdown.Item>
    </>
  );

  const onOpenSharePDF = () => {
    let espaceRight = true;
    const position = refSharedOption.current?.getBoundingClientRect();
    if (position) {
      const width = document.documentElement.clientWidth;
      espaceRight = position.x + position.width + WIDTH_SHARE_PDF_OPTIONS < width;
    }
    setSharePDF({
      open: true,
      right: espaceRight
    });
  };

  const iconToShow = icon !== null ? icon : <HorizontalDotsIcon className='dot-options' />;

  return (
    <>
      {isHovering && (
        <Dropdown onClick={handleClickMenu} icon={iconToShow} className='card-dropdown'>
          <Dropdown.Menu className='card-menu' open={openDropdown}>
            {userRole === Roles.ADMIN && renderAdminMenuOptions()}
            <div
              className={`card-option ${openSharePDF.open ? 'shared-pdf-hover' : ''}`}
              onMouseEnter={onOpenSharePDF}
              onMouseLeave={() => {
                setSharePDF({ ...openSharePDF, open: false });
                setOpenDropdown(false);
              }}
              ref={refSharedOption}
            >
              <Dropdown
                item
                icon={<LinkIcon className='custom-icon-option' />}
                text='Share PDF'
                onClick={handleClickDropdownItem}
                id='share-option'
                open={openSharePDF.open}
                direction={openSharePDF.right ? 'right' : 'left'}
                pointing='left'
              >
                <Dropdown.Menu className='sub-menu'>
                  {resumePdfExported(resumeId).map((resumePdf) => (
                    <Dropdown.Item
                      key={`${resumePdf.label}-option`}
                      onClick={(event: React.SyntheticEvent) =>
                        handleClickSharedPdfItem(event, resumePdf.resume)
                      }
                    >
                      <span className='title'>{resumePdf.label}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <DialogModal
        open={openDialogModal}
        size='small'
        confirmFunction={handleClickRemoveOption}
        closeFunction={handleCloseModal}
        content={getModalContent(name)}
        closeIcon
      />
      <ResumeMergeForm
        open={openMergeModal}
        click={handleClickMenu}
        sourceData={{ resumeId, picture, fullName: name, email }}
        closeFunction={() => {
          setHovering();
          setMergeModal(false);
          redirectToDashboard();
        }}
      />
    </>
  );
};
