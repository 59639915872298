import config from '../config.json';

export const baseAPIURL = config.REACT_APP_APIURL || process.env.REACT_APP_APIURL;
export const loginApiURL = config.REACT_APP_LOGIN_API_URL || process.env.REACT_APP_LOGIN_API_URL;
export const logoutApiURL = config.REACT_APP_LOGOUT_API_URL || process.env.REACT_APP_LOGOUT_API_URL;
export const resumesPerPage = 24;

export const Configuration = {
  authority: config.REACT_APP_ISSUER || process.env.REACT_APP_ISSUER,
  client_id: config.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
  client_secret: config.REACT_APP_CLIENT_SECRET || process.env.REACT_APP_CLIENT_SECRET,
  redirect_uri: config.REACT_APP_CALLBACK_URL || process.env.REACT_APP_CALLBACK_URL,
  response_type: 'code',
  scope: 'openid email profile',
  post_logout_redirect_uri:
    config.REACT_APP_POST_LOGOUT_REDIRECT_URI || process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  silent_redirect_uri: config.REACT_APP_SILENT_REDIRECT_URI || process.env.REACT_APP_SILENT_REDIRECT_URI,
  automaticSilentRenew: true,
  loadUserInfo: true
};
