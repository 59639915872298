import React from 'react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';
import { SemanticICONS } from 'semantic-ui-react/src/generic';

import styles from './DialogModal.module.scss';

type sizeProp = 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';

interface IDialogModal {
  size: sizeProp;
  open: boolean;
  ModalIcon?: React.ReactElement;
  confirmFunction: () => void;
  closeFunction: (event: React.MouseEvent) => void;
  content: {
    title: string;
    description: string;
    buttonName: string;
    iconButton?: string;
  };
  closeIcon?: boolean;
  className?: string;
}

const handleOnClickModal = (event: React.MouseEvent) => {
  event.preventDefault();
};

const DialogModal: React.FC<IDialogModal> = ({
  size,
  open,
  confirmFunction,
  closeFunction,
  ModalIcon,
  content,
  closeIcon,
  className
}: IDialogModal) => {
  return (
    <Modal
      dimmer='inverted'
      size={size}
      open={open}
      onClick={handleOnClickModal}
      onClose={closeFunction}
      className={`${styles.customModal} ${className}`}
      closeIcon={closeIcon}
      data-testid='dialog-modal'
    >
      <Modal.Header className={styles.modalTitle} icon={ModalIcon || ''}>
        {content.title}
      </Modal.Header>
      <Modal.Content>
        <p className={styles.modalDescription}>{content.description}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button className={styles.confirmButton} onClick={confirmFunction} id='confirm-button'>
          <Icon
            className={styles.buttonIcon}
            name={(content.iconButton as SemanticICONS) || 'trash alternate outline'}
          />
          {content.buttonName}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DialogModal;
