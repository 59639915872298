import { Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import { GetStyles, IContentProps } from './HTMLContent';
import { parseStringForPdf } from '../helper';

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginTop: 5
  },
  bulletPoint: {
    fontSize: 10
  },
  itemContent: {
    flex: 1,
    fontSize: 10,
    marginLeft: 4
  }
});

interface IListProps {
  indexList: string;
  type: string;
  level: number;
}

export const Item: React.FC<IListProps> = ({ indexList, type, level, children }) => {
  const indentSpace = 20;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const getBody = (data: any) => {
    if (typeof data === 'string' || data instanceof String) {
      const textContent = parseStringForPdf(data);
      return <Text style={styles.itemContent}>{textContent}</Text>;
    } else {
      if (Array.isArray(data)) {
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        data = data.filter((item: string | any[]) => item !== ' ');
        return data.map((item: IContentProps, i: number) => (
          <Text key={i} style={item.style ? GetStyles(item.style) : []}>
            {getBody(item)}
          </Text>
        ));
      } else {
        if (typeof data === 'string' || data instanceof String) {
          return <Text style={styles.itemContent}>{data}</Text>;
        } else {
          const itemStyles = data.style ? GetStyles(data.style) : [];
          itemStyles.push(styles.itemContent);
          if (typeof data.text === 'string' || data.text instanceof String) {
            return <Text style={itemStyles}>{data.text}</Text>;
          } else {
            return data.text.map((item: IContentProps, i: number) => (
              <Text key={i} style={itemStyles}>
                {getBody(item)}
              </Text>
            ));
          }
        }
      }
    }
  };

  return (
    <View style={styles.item}>
      <View
        style={{
          marginLeft: (level + 1) * indentSpace
        }}
      >
        {type === 'list' ? (
          <Text style={styles.bulletPoint}>
            {'• '}
            <View>{getBody(children)}</View>
          </Text>
        ) : (
          <Text style={styles.bulletPoint}>
            {indexList + '. '}
            <Text>{getBody(children)}</Text>
          </Text>
        )}
      </View>
    </View>
  );
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const List: React.FC<any> = ({ children }) => children;

export default List;
