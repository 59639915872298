import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Card from 'semantic-ui-react/dist/commonjs/views/Card/Card';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import { CardMenu } from './CardMenu';
import CardTags from './CardTags';
import './ResumeCard.scss';
import DownloadPopup from '../../DownloadPopup/DownloadPopup';
import PreviewDownload from '../../PreviewDownload/PreviewDownload';
import Spinner from '../../Spinner/Spinner';
import LettersAvatar from '../../User/LettersAvatar';
import avatar from '../../../Assets/avatar.jpg';
import { DownloadIcon, EyeIcon, CheckCircle, AlertCircle } from '../../../Assets/CustomIcons';
import { ResumeOptionName, TemplatePDF } from '../../../constants/globalConstants';
import { IResumeForm } from '../../../Models/IResumeForm';
import { setTopBarResumeOptionName } from '../../../redux/actions/dashboardActions';
import { RootStore } from '../../../redux/store';
import { ResumeService } from '../../../services/resumes/resume.service';
import AquaTemplate from '../../../templates/Aqua';
import ClassicTemplate from '../../../templates/Classic/ResumenDocument';
import CorporateTemplate from '../../../templates/Corporate/ResumeDocument';
import FormalTemplate from '../../../templates/Formal';
import ModernTemplate from '../../../templates/Modern/Main';
import PlainTemplate from '../../../templates/Plain';

const ResumeRequest = ResumeService.getInstance();

export const generatePdfDownloadTitle = (userName?: string) => {
  let title = 'RESUME';
  if (userName) {
    const nameRegex = / /g;
    const formatDateRegex = /-/g;
    const name = userName.replace(nameRegex, ',');
    const currentDate = new Date();
    const formatedDate = moment(currentDate).format('DD-MM-YYYY').replace(formatDateRegex, '');
    title = `${title}_${name}-${formatedDate}`;
  }
  return title;
};

interface IResumeCardProps {
  resumeFormData: IResumeForm;
  resumeUpdatedDate: string;
  resumeId: string;
}

const HIGHT_PROGRESS = 100;

const ResumeCard: React.FC<IResumeCardProps> = ({ resumeFormData, resumeUpdatedDate, resumeId }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    personalInformation: { pictures, fullName, email },
    isHidden
  } = resumeFormData;
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { loggedUserResumeId } = useSelector((state: RootStore) => state.dashboard);
  const dispatch = useDispatch();

  const handleMouseHover = () => {
    setIsHovering(true);
  };

  const setHovering = () => {
    setIsHovering(!isHovering);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    open && setOpen(false);
  };

  const handleDownload = (event: React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleProgress = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setOpenPreview(true);
  };

  const handleCloseDownloadMenu = (closeHover?: boolean) => {
    open && setOpen(false);
    closeHover && setIsHovering(false);
  };

  const handleOpenDownloadMenu = () => {
    setOpen(true);
    setIsHovering(true);
  };

  const getPdfTitle = () => {
    const {
      personalInformation: { fullName }
    } = resumeFormData;

    return generatePdfDownloadTitle(fullName);
  };

  const ResumesExported = [
    {
      name: capitalize(TemplatePDF.CLASSIC),
      component: async () => {
        const resume: IResumeForm = (await ResumeRequest.getResumeById(resumeId)).data;
        return <ClassicTemplate resume={resume} />;
      }
    },
    {
      name: capitalize(TemplatePDF.CORPORATE),
      component: async () => {
        const resume: IResumeForm = (await ResumeRequest.getResumeById(resumeId)).data;
        return <CorporateTemplate resume={resume} />;
      }
    },
    {
      name: capitalize(TemplatePDF.MODERN),
      component: async () => {
        const resume: IResumeForm = (await ResumeRequest.getResumeById(resumeId)).data;
        return <ModernTemplate resume={resume} />;
      }
    },
    {
      name: capitalize(TemplatePDF.AQUA),
      component: async () => {
        const resume: IResumeForm = (await ResumeRequest.getResumeById(resumeId)).data;
        return <AquaTemplate resume={resume} />;
      }
    },
    {
      name: capitalize(TemplatePDF.FORMAL),
      component: async () => {
        const resume: IResumeForm = (await ResumeRequest.getResumeById(resumeId)).data;
        return <FormalTemplate resume={resume} />;
      }
    },
    {
      name: capitalize(TemplatePDF.PLAIN),
      component: async () => {
        const resume: IResumeForm = (await ResumeRequest.getResumeById(resumeId)).data;
        return <PlainTemplate resume={resume} />;
      }
    }
  ];

  const showProgressStatus = () => {
    const { progressStatus } = resumeFormData;
    const status = progressStatus === HIGHT_PROGRESS;
    return (
      <Popup
        content={progressStatus ? `${progressStatus}%` : '%'}
        style={{ opacity: 0.5 }}
        size='mini'
        position='bottom center'
        inverted
        hideOnScroll
        trigger={
          <div className='progress-icon-container' onClick={handleProgress}>
            {status ? (
              <CheckCircle className='status-indicator check' data-testid='icon-progress-status' />
            ) : (
              <AlertCircle className='status-indicator warning' data-testid='icon-progress-status' />
            )}
          </div>
        }
      />
    );
  };

  const handleResize = () => {
    const currentWindowSize = window.innerWidth;
    setWindowSize(currentWindowSize);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, true);
    return () => {
      window.removeEventListener('resize', handleResize, true);
    };
  }, []);

  const handleOnClickCard = () => {
    if (loggedUserResumeId !== resumeId) {
      dispatch(setTopBarResumeOptionName(ResumeOptionName.RESUME));
    }
  };

  const hiddenStyle = isHidden ? 'hidden' : '';

  return (
    <Link to={`resume/${resumeId}`} className='resume-card-link' onClick={handleOnClickCard}>
      <Card
        className={`resume-card-container ${isHidden ? 'hidden-card' : ''}`}
        onMouseOver={handleMouseHover}
        onMouseLeave={handleMouseLeave}
        link={isHovering}
      >
        <Card.Content>
          {pictures && pictures.systemPicture ? (
            <Image
              avatar
              className={`avatar ${hiddenStyle}`}
              floated='left'
              size='big'
              src={pictures.systemPicture}
              /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
              onError={(e: any) => (e.target.src = avatar)}
            />
          ) : (
            <LettersAvatar userName={fullName} className={`letter-avatar ${hiddenStyle}`} />
          )}
          <Card.Header className='resume-card-title'>
            <span className={`full-name ${hiddenStyle}`}>
              {resumeFormData.personalInformation.fullName}
            </span>
            {showProgressStatus()}
            <CardMenu
              name={resumeFormData.personalInformation.fullName}
              resumeId={resumeId}
              email={email}
              picture={pictures ? pictures.systemPicture : ''}
              isHovering={isHovering}
              setHovering={setHovering}
              isHidden={!!isHidden}
            />
          </Card.Header>
          <Card.Meta className={`resume-card-content ${hiddenStyle}`}>{resumeUpdatedDate}</Card.Meta>
          {resumeFormData.filtered && resumeFormData.filtered.length > 0 && (
            <CardTags
              filtered={resumeFormData.filtered}
              windowSize={windowSize}
              isHovering={isHovering}
              resumeId={resumeId}
            />
          )}
          {(isHovering || loading || openPreview) && (
            <Card.Description onClick={handleDownload} className='resume-card-footer'>
              <PreviewDownload
                id={resumeId}
                setHoverCard={() => {
                  setHovering();
                  setOpenPreview(false);
                }}
              >
                <div onClick={handleProgress}>
                  <EyeIcon className='eye-icon' />
                </div>
              </PreviewDownload>
              <DownloadPopup
                items={ResumesExported}
                fileName={open ? `${getPdfTitle()}.pdf` : ''}
                resume={resumeFormData}
                open={open}
                onClose={handleCloseDownloadMenu}
                onOpen={handleOpenDownloadMenu}
                setLoading={setLoading}
              >
                {loading ? (
                  <div className='spinner-container'>
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    <DownloadIcon className='resume-card-icon' />
                  </div>
                )}
              </DownloadPopup>
            </Card.Description>
          )}
        </Card.Content>
      </Card>
    </Link>
  );
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export default withRouter(ResumeCard as React.ComponentType<any>);
