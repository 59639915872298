import { View, Text } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './EducationSection.styles';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { IEducation } from '../../Models/IEducation';

interface IEducationsProps {
  data: IEducation[];
  highLightColor: string;
}

interface IEducationProps {
  data: IEducation;
  highLightColor: string;
  indexItem: number;
}

const Education: React.FC<IEducationProps> = ({ data, highLightColor, indexItem }) => {
  const { description, endDate, startDate, career, school } = data;
  const textStartDate = startDate ? startDate.substr(4) : ' ';
  const textEndDate = endDate ? endDate.substr(4) : 'Present';

  return (
    <View>
      <View wrap={false}>
        {indexItem === 0 && <Text style={styles(highLightColor).sectionTitle}>Education</Text>}
        <View style={{ flexDirection: 'row' }}>
          <View style={styles().contentLeft}>
            <Text style={[styles().date, { ...styles(highLightColor).startDate }]}>{textStartDate}</Text>
            <Text style={[styles().date, styles().lineRight, { paddingBottom: 5 }]}>{textEndDate}</Text>
          </View>
          <Text style={styles(highLightColor).point}>•</Text>
          <View style={styles().contentRight}>
            <Text style={[styles(highLightColor).career]}>{career || ' '}</Text>
            <Text style={[styles().school, styles().lineLeft]}>{school}</Text>
          </View>
        </View>
      </View>
      <View style={[styles().descriptionContainer, styles().lineLeft]}>
        <HTMLContent data={description} />
      </View>
    </View>
  );
};

const EducationSection: React.FC<IEducationsProps> = ({ data, highLightColor }) => {
  const existsData = data.length > 0 || null;

  return (
    existsData && (
      <View style={styles().sectionContainer}>
        {data.map((item, index) => (
          <Education
            key={`educationItem-${index}`}
            data={item}
            highLightColor={highLightColor}
            indexItem={index}
          />
        ))}
      </View>
    )
  );
};

export default EducationSection;
