import { templateColors } from '../utils/constants';

export const getColors = (highLightColor?: string) => {
  switch (highLightColor) {
    case templateColors.red:
      return {
        titleColor: '#D20000',
        careerColor: '#D20000',
        mainContainer: '#D20000',
        mainTitle: '#D20000',
        job: '#D20000',
        footer: highLightColor,
        header: highLightColor
      };
    case templateColors.blue:
      return {
        titleColor: '#1867AC',
        careerColor: '#17B1EB',
        mainContainer: '#17B1EB',
        mainTitle: '#1867AC',
        job: '#17B1EB',
        footer: '#17B1EB',
        header: '#17B1EB'
      };
    case templateColors.aqua:
      return {
        titleColor: '#0FC4A8',
        careerColor: '#0FC4A8',
        mainContainer: '#0FC4A8',
        mainTitle: '#0FC4A8',
        job: '#0FC4A8',
        footer: highLightColor,
        header: highLightColor
      };
    case templateColors.gray:
      return {
        titleColor: '#525252',
        careerColor: '#949494',
        mainContainer: '#949494',
        mainTitle: '#525252',
        job: '#949494',
        footer: highLightColor,
        header: highLightColor
      };
    default:
      return {
        titleColor: '#1867AC',
        careerColor: '#17B1EB',
        mainContainer: '#17B1EB',
        mainTitle: '#1867AC',
        job: '#17B1EB',
        footer: '#17B1EB',
        header: '#17B1EB'
      };
  }
};
