import { combineReducers } from 'redux';

import dashboardReducer from './dashboardReducer';
import resumeReducer from './resumeReducer';

const RootReducer = combineReducers({
  dashboard: dashboardReducer,
  resume: resumeReducer
});

export default RootReducer;
