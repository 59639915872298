import { isEqual } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { animateScroll as scroll } from 'react-scroll';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import GridColumn from 'semantic-ui-react/dist/commonjs/collections/Grid/GridColumn';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';

import './ResumeFormContainer.scss';
import EducationForm from '../EducationForm/EducationForm';
import LanguagesForm from '../LanguagesForm/LanguagesForm';
import LeftProgressSideBar from '../LeftProgressSideBar/LeftSideBar';
import PersonalInformationForm from '../PersonalInformationForm/PersonalInformationForm';
import BottomProgressBar from '../progressSideBar/BottomProgressBar';
import ProgressSideBar from '../progressSideBar/RightProgressBar';
import SkillForm from '../SkillsForm/SkillsForm';
import WorkExperienceForm from '../WorkExperienceForm/WorkExperienceForm';
import CustomLoader from '../../CustomLoader/CustomLoader';
import DialogModal from '../../../DialogModal/DialogModal';
import { showToastMessage, ToastEnum } from '../../../Toast/Toast';
import {
  Roles,
  HttpErrors,
  ResumeOptionName,
  ActiveOptionStyle
} from '../../../../constants/globalConstants';
import { SkillLevels } from '../../../../constants/dropDownItems';
import { SectionId } from '../../../../constants/sectionIDs';
import { withFormContainer } from '../../../../HOCs/WithFormContainer/WithFormContainer';
import { ISetupAccordion } from '../../../../HOCs/WithMultipleEntries/ISingleAccordion';
import { withMultipleEntries } from '../../../../HOCs/WithMultipleEntries/WithMultipleEntries';
import {
  ResumeSection,
  ResumeKeySection,
  SectionStatus,
  ResumeSectionByStatus
} from '../../../../Models/FormResumeSection';
import { IResumeForm } from '../../../../Models/IResumeForm';
import {
  ClearResumeForm,
  GetResumesOptions,
  setTopBarActiveOptionStyle,
  setTopBarResumeOptionName
} from '../../../../redux/actions/dashboardActions';
import { RootStore } from '../../../../redux/store';
import {
  getResume,
  updateProgressStatus,
  setSectionStatus,
  setSection,
  updateResume,
  saveSectionData,
  createResume,
  finishSaving,
  finishLoading,
  cleanResume,
  addSharedResumes,
  deleteResume
} from '../../../../redux/actions/resumeActions';
import { ValidationNotifierService } from '../../../../services/validationNotifier.service';
import { OnRequestError } from '../../../../utils/errorRequest';
import { IdleTimer } from '../../../../utils/idleTime/idleTime';
import { calculateProgressStatus, validateFields } from '../../../../utils/validation/formRules';
import {
  educationSchema,
  languageSchema,
  personalInformationSchema,
  skillSchema,
  workExperienceSchema
} from '../../../../utils/validation/resume-schema';

const validationNotifier = ValidationNotifierService.getInstance();

const PersonalInformationFormContainer = withFormContainer(
  PersonalInformationForm,
  'Personal Information',
  ''
);

const setupWorkExperience: ISetupAccordion = {
  accordionTitle: { key: 'jobTitle' },
  accordionSecondaryTitle: { key: 'client' },
  accordionThirdTitle: {
    key: 'startDate',
    dependentKey: 'endDate',
    fn: (data: ResumeSection, key: string, dependentKey: string) => {
      return `(${data[key]} - ${data[dependentKey] || 'Present'})`;
    }
  }
};

const WorkExperienceFormContainer = withFormContainer(
  withMultipleEntries(WorkExperienceForm, 'NEW EXPERIENCE', 'workExperiences', setupWorkExperience),
  'Work Experience'
);

const setupEducation: ISetupAccordion = {
  accordionTitle: { key: 'school' },
  accordionSecondaryTitle: { key: 'career' },
  accordionThirdTitle: {
    key: 'startDate',
    dependentKey: 'endDate',
    fn: (data: ResumeSection, key: string, dependentKey: string) => {
      return `(${data[key]} - ${data[dependentKey] || 'Present'})`;
    }
  }
};

const EducationFormContainer = withFormContainer(
  withMultipleEntries(EducationForm, 'NEW EDUCATION', 'educations', setupEducation),
  'Education'
);

const setupLanguage: ISetupAccordion = {
  accordionTitle: { key: 'language' }
};

const LanguagesFormContainer = withFormContainer(
  withMultipleEntries(LanguagesForm, 'NEW LANGUAGE', 'languages', setupLanguage),
  'Language'
);

const setupSkill: ISetupAccordion = {
  accordionTitle: { key: 'area' },
  accordionSecondaryTitle: { key: 'skill' },
  accordionThirdTitle: {
    key: 'level',
    fn: (data: ResumeSection, key: string) => {
      return SkillLevels.find((dropdownLevel) => dropdownLevel.value === data[key])?.text;
    }
  }
};

const SkillsFormContainer = withFormContainer(
  withMultipleEntries(SkillForm, 'NEW SKILL', 'skills', setupSkill),
  'Skills'
);

interface IResumeContainerState {
  isAuthorized: boolean;
  showModal: boolean;
}

const contentModalIdleTime = {
  title: 'Wake up!',
  description: 'You have been inactive for 15 minutes, the page will be reloaded.',
  buttonName: 'Reload',
  iconButton: 'refresh'
};

const TIMEOUT_IDLE_TIME = 900000;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
class ResumeFormContainer extends React.Component<any, IResumeContainerState> {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  interval: any;
  idleTime: IdleTimer;
  id: string;

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  constructor(props: any) {
    super(props);
    this.id = this.props.match.params.id === 'new' ? '' : this.props.match.params.id;
    this.idleTime = new IdleTimer(this.onIdle, TIMEOUT_IDLE_TIME);
    this.state = {
      isAuthorized: this.props.userRole === Roles.ADMIN || this.props.match.params.id !== 'new',
      showModal: false
    };
  }

  scrollEvent = () => {
    const element = document.getElementsByClassName('progress-side-bar-container')[0] as HTMLElement;
    if (element && this.props.currentSection !== SectionId.PERSONAL_INFORMATION_SECTION) {
      window.scrollY < 45
        ? element.classList.add('first-section')
        : element.classList.remove('first-section');
    }
  };

  componentDidMount() {
    if (this.id !== '') {
      this.getResumeById(this.id);
    } else {
      this.props.finishLoading();
    }
    this.props.getResumeOptions();
    this.interval = setInterval(() => {
      const { updated } = this.props.resume;
      if (!Object.values(updated).every((item) => item === false)) {
        console.log('Will perform auto save...');
        this.saveResume();
      }
    }, 60000);
    this.setProgressStatus();
    this.idleTime.activate();
    this.props.setTopBarActiveOptionStyle(ActiveOptionStyle.RESUME);
    window.addEventListener('scroll', this.scrollEvent);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('scroll', this.scrollEvent);
    this.idleTime.deactivate();
    this.props.clearResumeForm();
    this.props.cleanResume();
    this.saveResumeSectionBeforeUnmount(true);
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  componentDidUpdate(prevProps: any, prevState: IResumeContainerState) {
    if (this.props.resume.currentSection.sectionId === SectionId.PERSONAL_INFORMATION_SECTION) {
      scroll.scrollToTop({ duration: 500, smooth: true });
    }
    if (this.props.resume.currentSection.sectionId !== prevProps.resume.currentSection.sectionId) {
      this.saveResume();
    }
    const {
      resume,
      loggedUserId,
      topBarResumeOptionName,
      match: { params }
    } = this.props;
    const prevResume = prevProps.resume;
    const statusSection =
      resume.personalInformationStatus !== prevResume.personalInformationStatus ||
      resume.skillStatus !== prevResume.skillStatus ||
      resume.workExperienceStatus !== prevResume.workExperienceStatus ||
      resume.educationStatus !== prevResume.educationStatus ||
      resume.languageStatus !== prevResume.languageStatus;
    if (statusSection) {
      this.setProgressStatus();
    }
    const deletedItem =
      resume.resumeFormData.skills.length < prevResume.resumeFormData.skills.length ||
      resume.resumeFormData.workExperiences.length < prevResume.resumeFormData.workExperiences.length ||
      resume.resumeFormData.educations.length < prevResume.resumeFormData.educations.length ||
      resume.resumeFormData.languages.length < prevResume.resumeFormData.languages.length;
    if (deletedItem) {
      this.saveResume();
    }
    if (
      loggedUserId !== '' &&
      params.id !== loggedUserId &&
      topBarResumeOptionName === ResumeOptionName.MY_RESUME
    ) {
      this.props.setTopBarResumeOptionName(ResumeOptionName.RESUME);
    }
  }

  render() {
    if (this.state.isAuthorized) {
      const {
        isReady,
        resume: { currentSection, isLoading }
      } = this.props;
      if (!isReady || isLoading) {
        return <CustomLoader />;
      }
      return (
        <>
          <Grid className='form-global-container'>
            <GridRow className='form-row'>
              <GridColumn only='computer' className='left-sidebar-grid'>
                <div className='left-sidebar-container'>
                  <LeftProgressSideBar />
                </div>
              </GridColumn>
              <GridColumn className='form-grid'>
                <GridColumn className='form-sections' id='form-sections'>
                  {
                    <>
                      {this.renderFormSections()}
                      <div
                        className={`progress-side-bar-container ${
                          currentSection.sectionId === 'personalInformation' ? 'first-section' : ''
                        }`}
                      >
                        <ProgressSideBar
                          save={this.saveResume}
                          setCurrentSection={this.setCurrentSection}
                        />
                      </div>
                    </>
                  }
                </GridColumn>
              </GridColumn>
            </GridRow>
            <DialogModal
              open={this.state.showModal}
              content={contentModalIdleTime}
              size='small'
              confirmFunction={this.handleRefreshIdleTime}
              closeFunction={this.handleCloseIdleTime}
              className='idle-time'
            />
          </Grid>
        </>
      );
    }
    this.props.history.push('/notfound');
    return null;
  }

  renderFormSections() {
    const {
      resumeFormData,
      currentSection,
      personalInformationStatus,
      skillStatus,
      workExperienceStatus,
      educationStatus,
      languageStatus,
      resumeId
    } = this.props.resume;
    return (
      <div className='form-container'>
        <div id={SectionId.PERSONAL_INFORMATION_SECTION}>
          <PersonalInformationFormContainer
            resumeId={resumeId}
            resumeSectionData={resumeFormData.personalInformation}
            sectionStatus={personalInformationStatus}
            sectionId={SectionId.PERSONAL_INFORMATION_SECTION}
            currentSection={currentSection}
            propertyKey='personalInformation'
            setCurrentSection={this.setCurrentSection}
            setSectionStatus={this.setSectionStatus}
            saveFormSection={this.saveFormSection}
            deleteEntryFromFormSection={this.deleteEntryFromFormSection}
          />
        </div>
        <div id={SectionId.SKILL_SECTION}>
          <SkillsFormContainer
            resumeId={resumeId}
            resumeSectionData={resumeFormData.skills}
            sectionStatus={skillStatus}
            sectionId={SectionId.SKILL_SECTION}
            currentSection={currentSection}
            propertyKey='skills'
            setCurrentSection={this.setCurrentSection}
            setSectionStatus={this.setSectionStatus}
            saveFormSection={this.saveFormSection}
            deleteEntryFromFormSection={this.deleteEntryFromFormSection}
          />
        </div>
        <div id={SectionId.WORK_EXPERIENCE_SECTION}>
          <WorkExperienceFormContainer
            resumeId={resumeId}
            resumeSectionData={resumeFormData.workExperiences}
            extraSectionData={[...resumeFormData.skills]}
            sectionStatus={workExperienceStatus}
            sectionId={SectionId.WORK_EXPERIENCE_SECTION}
            currentSection={currentSection}
            propertyKey='workExperiences'
            setCurrentSection={this.setCurrentSection}
            setSectionStatus={this.setSectionStatus}
            saveFormSection={this.saveFormSection}
            deleteEntryFromFormSection={this.deleteEntryFromFormSection}
          />
        </div>
        <div id={SectionId.EDUCATION_SECTION}>
          <EducationFormContainer
            resumeId={resumeId}
            resumeSectionData={resumeFormData.educations}
            sectionStatus={educationStatus}
            sectionId={SectionId.EDUCATION_SECTION}
            currentSection={currentSection}
            propertyKey='educations'
            setCurrentSection={this.setCurrentSection}
            setSectionStatus={this.setSectionStatus}
            saveFormSection={this.saveFormSection}
            deleteEntryFromFormSection={this.deleteEntryFromFormSection}
          />
        </div>
        <div id={SectionId.LANGUAGE_SECTION}>
          <LanguagesFormContainer
            resumeId={resumeId}
            resumeSectionData={resumeFormData.languages}
            sectionStatus={languageStatus}
            sectionId={SectionId.LANGUAGE_SECTION}
            currentSection={currentSection}
            propertyKey='languages'
            setCurrentSection={this.setCurrentSection}
            setSectionStatus={this.setSectionStatus}
            saveFormSection={this.saveFormSection}
            deleteEntryFromFormSection={this.deleteEntryFromFormSection}
          />
        </div>
        <BottomProgressBar setCurrentSection={this.setCurrentSection} />
      </div>
    );
  }

  onIdle = () => {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
    this.idleTime.togglePause();
  };

  handleCloseIdleTime = (event: React.MouseEvent) => {
    event.preventDefault();
    this.handleRefreshIdleTime();
  };

  handleRefreshIdleTime = () => {
    window.location.reload();
    this.idleTime.deactivate();
  };

  setSectionStatus = (sectionId: SectionId, sectionStatus: SectionStatus) => {
    this.props.setSectionStatus(sectionId, sectionStatus);
  };

  setCurrentSection = (sectionId: SectionId) => {
    const oldSection = this.props.resume.currentSection;
    this.executeSectionValidation(oldSection.sectionId);
    this.props.setSection(sectionId);
  };

  private executeSectionValidation = (sectionId: SectionId) => {
    validationNotifier.dispatchSectionValidationRequested(sectionId);
  };

  saveFormSection = (
    data: ResumeSection | ResumeSection[],
    propertyKey: ResumeKeySection,
    index?: number,
    updateEnable = true
  ) => {
    const newResumeFormData: IResumeForm = { ...this.props.resume.resumeFormData };
    newResumeFormData.updatedDate = new Date();
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const item = newResumeFormData as any;
    if (index === undefined) {
      const dataUpdated = isEqual(item[propertyKey], data as ResumeSection);
      if (dataUpdated) return;
      item[propertyKey] = data;
    } else {
      const dataUpdated = isEqual(item[propertyKey][index], data as ResumeSection);
      if (dataUpdated) return;
      const newData = updateEnable ? data : { ...data, enabled: item[propertyKey][index].enabled };
      item[propertyKey][index] = newData;
    }
    this.props.saveSectionData(newResumeFormData, { ...this.props.resume.updated, [propertyKey]: true });
  };

  addSharedResumes = (data: string[], updatedDate: Date) => {
    this.props.addSharedResumes(data, updatedDate);
  };

  deleteEntryFromFormSection = (
    index: number,
    propertyKey: ResumeKeySection,
    checkStatus?: SectionStatus,
    sectionId?: SectionId
  ) => {
    const newResumeFormData = { ...this.props.resume.resumeFormData };
    newResumeFormData[propertyKey].splice(index, 1);
    newResumeFormData.progressStatus = this.verifyProgressBySection(
      sectionId as SectionId,
      checkStatus as SectionStatus
    );

    this.props.deleteResume(newResumeFormData, propertyKey);
  };

  verifyProgressBySection = (sectionId: SectionId, status: SectionStatus) => {
    const newSectionStatus: { [key: string]: SectionStatus } = {};
    newSectionStatus[`${ResumeSectionByStatus[sectionId]}`] = status;

    const statusBySections = {
      personalInformationStatus: this.props.resume.personalInformationStatus,
      skillStatus: this.props.resume.skillStatus,
      workExperienceStatus: this.props.resume.workExperienceStatus,
      educationStatus: this.props.resume.educationStatus,
      languageStatus: this.props.resume.languageStatus,
      ...newSectionStatus
    };

    const progress = calculateProgressStatus([
      statusBySections.personalInformationStatus,
      statusBySections.skillStatus,
      statusBySections.workExperienceStatus,
      statusBySections.educationStatus,
      statusBySections.languageStatus
    ]);
    return progress;
  };

  getResumeById = async (id: string) => {
    try {
      await this.props.getResume(id);
    } catch (error) {
      console.log('Error while retrieving resume with id: ', id);
      this.props.history.push('/notfound');
    }
  };

  verifyStatusBySection = (resume: IResumeForm, currentSection: SectionId) => {
    const { educations, languages, skills, personalInformation, workExperiences } = resume;
    const status = {
      [SectionId.PERSONAL_INFORMATION_SECTION]: validateFields(
        personalInformation,
        personalInformationSchema
      ),
      [SectionId.SKILL_SECTION]: validateFields(skills, skillSchema),
      [SectionId.WORK_EXPERIENCE_SECTION]: validateFields(workExperiences, workExperienceSchema),
      [SectionId.EDUCATION_SECTION]: validateFields(educations, educationSchema),
      [SectionId.LANGUAGE_SECTION]: validateFields(languages, languageSchema)
    }[currentSection];
    return status;
  };

  saveResumeSectionBeforeUnmount = async (updateParentComponent?: boolean) => {
    const { resumeId, resumeFormData, currentSection, updated } = this.props.resume;
    const { setDashboardShouldUpdate, dashboardShouldUpdate } = this.props;
    const sectionsToSave = Object.entries<boolean>(updated).filter(([key, value]) => value);
    const newResumeFormData = { ...resumeFormData };
    const verifyStatus = this.verifyStatusBySection(resumeFormData, currentSection.sectionId);
    const verifyProgress = this.verifyProgressBySection(currentSection.sectionId, verifyStatus);
    newResumeFormData.progressStatus = verifyProgress;
    if (!sectionsToSave.length) {
      return;
    }
    if (resumeId) {
      await this._updateResume(newResumeFormData, sectionsToSave);
      updateParentComponent && setDashboardShouldUpdate(!dashboardShouldUpdate);
    } else {
      const { fullName, email } = resumeFormData.personalInformation;
      if (fullName && email) {
        await this._createResume(resumeFormData, sectionsToSave);
      } else {
        console.log('It could not be saved due to fullName and email are required!');
      }
    }
  };

  saveResume = () => {
    const { resumeId, resumeFormData, currentSection, updated } = this.props.resume;
    const newResumeFormData = { ...resumeFormData };
    const sectionsToSave = Object.entries<boolean>(updated).filter(([key, value]) => value);
    if (!sectionsToSave.length) {
      return;
    }

    const verifyStatus = this.verifyStatusBySection(newResumeFormData, currentSection.sectionId);
    const verifyProgress = this.verifyProgressBySection(currentSection.sectionId, verifyStatus);
    newResumeFormData.progressStatus = verifyProgress;

    if (resumeId !== '') {
      this._updateResume(newResumeFormData, sectionsToSave);
    } else {
      const { email, fullName } = newResumeFormData.personalInformation;
      if (!email || !fullName) {
        showToastMessage('Full name and email are required fields to create a resume', ToastEnum.ERROR);
        return;
      }
      const { profile } = this.props;
      email === profile.email && (newResumeFormData.personalInformation.picture = profile.picture);
      newResumeFormData.updatedDate = new Date();
      this._createResume(newResumeFormData, sectionsToSave);
    }
  };

  private _updateResume = async (newResumeData: IResumeForm, sectionsToSave: [string, boolean][]) => {
    try {
      await this.props.updateResume(newResumeData, sectionsToSave);
    } catch (error) {
      const onRemoveAccess = () => {
        error.request?.data?.statusCode === HttpErrors.UNAUTHORIZED &&
          this.props.history.push('/dashboard');
      };
      OnRequestError(error, 'updating the resume', onRemoveAccess);
      console.log('There was an error updating resume with id: ', newResumeData._id);
    } finally {
      validationNotifier.cleanValidationNotifierType();
      this.props.finishSaving();
    }
  };

  private _createResume = async (newResumeData: IResumeForm, sectionsToSave: [string, boolean][]) => {
    try {
      await this.props.createResume(newResumeData, sectionsToSave);
    } catch (error) {
      OnRequestError(error, 'creating the resume');
      console.log('Error while creating resume, error: ', error);
    } finally {
      validationNotifier.cleanValidationNotifierType();
      this.props.finishSaving();
    }
  };

  setProgressStatus = () => {
    const statusBySection: SectionStatus[] = [
      this.props.resume.personalInformationStatus,
      this.props.resume.skillStatus,
      this.props.resume.workExperienceStatus,
      this.props.resume.educationStatus,
      this.props.resume.languageStatus
    ];
    this.props.updateProgressStatus(statusBySection);
  };
}

const mapStateToProps = (state: RootStore) => {
  return {
    userRole: state.dashboard.authData.userRole,
    profile: state.dashboard.authData.profile,
    options: state.dashboard.resumeOptions,
    isReady: state.dashboard.isReady,
    resume: state.resume,
    loggedUserId: state.dashboard.loggedUserResumeId,
    currentSection: state.resume.currentSection,
    topBarResumeOptionName: state.dashboard.topBarResumeOptionName
  };
};

export default connect(mapStateToProps, {
  getResumeOptions: GetResumesOptions,
  clearResumeForm: ClearResumeForm,
  getResume: getResume,
  updateProgressStatus,
  setSectionStatus,
  setSection,
  updateResume,
  saveSectionData,
  createResume,
  finishSaving,
  finishLoading,
  cleanResume,
  addSharedResumes,
  deleteResume,
  setTopBarActiveOptionStyle,
  setTopBarResumeOptionName
})(withRouter(ResumeFormContainer));
