import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';
import { getColors } from './PlainUtils';

export const styles = (textColor = templateColors.gray) => {
  const areaTitle = getColors(textColor).textColor;
  const skillLevel = getColors(textColor).skillComplementLevel;
  return StyleSheet.create({
    skillsContainer: {
      marginBottom: 10
    },
    areaContainer: {
      marginBottom: 10
    },
    containerElements: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    areaTitle: {
      fontSize: 9,
      fontFamily: 'Georama',
      marginBottom: 8,
      textAlign: 'center',
      color: areaTitle
    },
    skill: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '3 5',
      width: 105.75,
      borderRadius: 4
    },
    skillLevel: {
      fontSize: 10,
      color: skillLevel
    },
    skillComplementLevel: {
      color: '#D7D7D7',
      fontSize: 10
    },
    skillName: {
      fontSize: 9,
      marginLeft: 5,
      maxLines: 4,
      textOverflow: 'ellipsis',
      fontFamily: 'Georama'
    }
  });
};
