import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import Dropdown, { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';

import styles from './PhotoSelector.module.scss';
import UploadPictureForm from '../UploadImageForm/UploadPicture';
import { showToastMessage, ToastEnum } from '../../../Toast/Toast';
import { UploadIcon, SimpleCircle } from '../../../../Assets/CustomIcons';
import { ActivePictureEnum, IPictureData } from '../../../../Models/IPersonalInformation';
import { createResume, saveSectionData, updateResume } from '../../../../redux/actions/resumeActions';
import { RootStore } from '../../../../redux/store';
import { isEmail } from '../../../../utils/validation/validator';

const getOptions = (pictureData: IPictureData) => {
  const { systemPicture, customPicture } = pictureData;
  const options: DropdownItemProps[] = [
    {
      text: 'Do not include photo',
      value: ActivePictureEnum.DISABLE_PICTURE,
      key: 'without-photo',
      icon: <SimpleCircle className={styles.withoutPhoto} />
    }
  ];
  systemPicture &&
    options.push({
      image: { avatar: true, src: pictureData.systemPicture },
      text: 'System photo',
      value: ActivePictureEnum.SYSTEM_PICTURE,
      key: 'system-photo'
    });
  customPicture &&
    options.push({
      image: { avatar: true, src: pictureData.customPicture ? pictureData.customPicture : '' },
      text: 'Custom photo',
      value: ActivePictureEnum.CUSTOM_PICTURE,
      key: 'custom-photo'
    });

  options.push({
    icon: <UploadIcon className={styles.uploadItem} />,
    text: 'Upload new photo',
    value: 'open-upload-form',
    key: 'upload-photo'
  });

  return options;
};

const PhotoSelector: React.FC = () => {
  const { resume } = useSelector((state: RootStore) => state);
  const { resumeFormData, resumeId } = resume;
  const { personalInformation } = resumeFormData;
  const dispatch = useDispatch();

  const [modalState, setModalState] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(personalInformation.pictures.activePicture);

  const dropDownOptions = getOptions(personalInformation.pictures);

  const handleOpenModal = async () => {
    const { fullName, email } = personalInformation;
    if (!fullName && !email) {
      showToastMessage('Full Name and Email are required to open the upload-photo form', ToastEnum.WARN);
      return;
    }
    if (!resumeId && fullName && email) {
      if (!isEmail(email)) {
        showToastMessage('Invalid Email', ToastEnum.WARN);
        return;
      }
      await dispatch(createResume(resumeFormData, [['personalInformation', true]]));
    }
    setModalState(true);
  };

  const handleChange = (event: React.SyntheticEvent, data: DropdownProps) => {
    if (data.value === 'open-upload-form') {
      handleOpenModal();
      return;
    }
    const activePictureValue = data.value as ActivePictureEnum;
    const newResumeData = {
      ...resumeFormData,
      personalInformation: {
        ...personalInformation,
        pictures: { ...personalInformation.pictures, activePicture: activePictureValue }
      }
    };
    setDropDownValue(activePictureValue);
    dispatch(updateResume(newResumeData, [['personalInformation', true]]));
    dispatch(saveSectionData(newResumeData, { personalInformation: true }));
  };

  const showSelectedPicture = () => {
    const { pictures } = personalInformation;
    return pictures.activePicture === ActivePictureEnum.SYSTEM_PICTURE
      ? pictures.systemPicture
      : pictures.customPicture;
  };

  return (
    <>
      {personalInformation.pictures.activePicture === ActivePictureEnum.DISABLE_PICTURE ? (
        <SimpleCircle className={styles.grayPhoto} />
      ) : (
        <Image avatar src={showSelectedPicture()} className={styles.selectedPicture} />
      )}
      <Dropdown
        inline
        options={dropDownOptions}
        onChange={handleChange}
        text='SELECT PHOTO'
        value={dropDownValue}
        className={`${styles.photoDropDown} photoSelector`}
      />
      <UploadPictureForm
        open={modalState}
        handleOpen={setModalState}
        setDropDownValue={setDropDownValue}
      />
    </>
  );
};

export default PhotoSelector;
