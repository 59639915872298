import { templateColors } from '../../templates/utils/constants';

export const getColors = (highLightColor?: string) => {
  switch (highLightColor) {
    case templateColors.blue:
      return {
        primaryColor: '#17D3EB',
        secondColor: '#0076A2',
        sectionTitle: '#0079A3',
        titleArea: '#0079A3',
        squareLevel: '#0079A3',
        date: '#0079A3',
        languageTitle: '#0079A3',
        title: '#0079A3'
      };
    case templateColors.red:
      return {
        primaryColor: '#D20000',
        secondColor: '#5F0000',
        sectionTitle: '#D20000',
        titleArea: '#D20000',
        squareLevel: '#D20000',
        date: '#D20000',
        languageTitle: '#D20000',
        title: '#D20000'
      };
    case templateColors.aqua:
      return {
        primaryColor: '#0FC4A8',
        secondColor: '#006757',
        sectionTitle: '#0EC4A9',
        titleArea: '#0EC4A9',
        squareLevel: '#0EC4A9',
        date: '#0EC4A9',
        languageTitle: '#0EC4A9',
        title: '#0EC4A9'
      };
    case templateColors.gray:
      return {
        primaryColor: '#949494',
        secondColor: '#525252',
        sectionTitle: '#949494',
        titleArea: '#949494',
        squareLevel: '#949494',
        date: '#949494',
        languageTitle: '#949494',
        title: '#949494'
      };
    default:
      return {
        primaryColor: '#17D3EB',
        secondColor: '#0076A2',
        sectionTitle: '#0079A3',
        titleArea: '#0079A3',
        squareLevel: '#0079A3',
        date: '#0079A3',
        languageTitle: '#0079A3',
        title: '#0079A3'
      };
  }
};
