import { Document, Font, Page, View } from '@react-pdf/renderer';
import { PageSize } from '@react-pdf/types';
import React from 'react';

import { styles } from './index.styles';
import { templateColors } from '../utils/constants';
import { appVersion } from '../../constants/globalConstants';
import { IResumeForm } from '../../Models/IResumeForm';
import Education from './EducationSection';
import Experience from './ExperienceSection';
import Header from './Header';
import Language from './LanguageSection';
import Skills from './SkillSection';
import Summary from './Summary';

interface IResumeProps {
  resume: IResumeForm;
  size: PageSize;
  highLightColor?: string;
}

interface IResumeDocument {
  resume: IResumeForm;
  highLightColor?: string;
}

Font.register({
  family: 'Georama',
  src: '/fonts/Georama/Georama-Regular.ttf'
});

Font.register({
  family: 'Georama semibold',
  src: '/fonts/Georama/Georama-SemiBold.ttf'
});

const Resume: React.FC<IResumeProps> = ({ size, resume, highLightColor = templateColors.gray }) => {
  const { personalInformation, workExperiences, educations, languages, skills } = resume;
  const resumeSkills = skills.filter(
    (skill) => skill.area !== '' && skill.skill !== '' && skill.enabled
  );
  const workExperiencesData = workExperiences.filter((education) => education.enabled);
  const educationData = educations.filter((education) => education.enabled);
  return (
    <Page size={size} style={styles.pageContainer}>
      <Header personalInformation={personalInformation} highLightColor={highLightColor} />
      <View style={styles.body}>
        <View style={styles.column1}>
          <Skills data={resumeSkills} highLightColor={highLightColor} />
          <Language languages={languages} title='LANGUAGES' highLightColor={highLightColor} />
        </View>
        <View style={styles.column2}>
          <Summary text={personalInformation.profesionalSummary} highLightColor={highLightColor} />
          <Experience data={workExperiencesData} highLightColor={highLightColor} />
          <Education data={educationData} highLightColor={highLightColor} />
        </View>
      </View>
    </Page>
  );
};

const PlainResume: React.FC<IResumeDocument> = (props) => {
  return (
    <Document
      author='Mon CV'
      keywords='awesome, resume, mon cv'
      subject='The resume of MonCV users'
      title='Resume'
      creator={`Mon CV v${appVersion}`}
      producer={`${appVersion}`}
    >
      <Resume size='A4' {...props}></Resume>
    </Document>
  );
};

export default PlainResume;
