import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import { UpdateSection } from '../reducers/resumeReducer';
import { ISection, SectionStatus } from '../../Models/FormResumeSection';
import { IResumeForm } from '../../Models/IResumeForm';

export const SET_RESUME = 'SET_RESUME';
export const UPDATE_RESUME = 'UPDATE_RESUME';
export const CREATE_RESUME = 'CREATE_RESUME';
export const UPDATE_PROGRESS_STATUS = 'UPDATE_PROGRESS_STATUS';
export const SET_SECTION_STATUS = 'SET_SECTION_STATUS';
export const SET_SECTION = 'SET_SECTION';
export const SAVE_SECTION_DATA = 'SAVE_SECTION_DATA';
export const SAVING = 'SAVING';
export const FINISH_SAVING = 'FINISH_SAVING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const CLEAN_RESUME = 'CLEAN_RESUME';
export const SET_UPDATED = 'SET_UPDATED';
export const ADD_SHARED_RESUMES = 'ADD_SHARED_RESUMES';
export const DELETE_RESUME = 'DELETE_RESUME';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const NAME_OPTIONS = 'NAME_OPTIONS';
export const ADD_NAME_OPTION = 'ADD_NAME_OPTION';
export const HIDE_ONE_RESUME = 'HIDE_ONE_RESUME';

export interface setResume {
  type: typeof SET_RESUME;
  payload: {
    resumeFormData: IResumeForm;
    resumeId: string;
  };
}

export interface IhiddenResumeInfo {
  id: string;
  isHidden: boolean;
}

export interface updateResume {
  type: typeof UPDATE_RESUME;
  payload: {
    updated: UpdateSection;
  };
}

export interface createResume {
  type: typeof CREATE_RESUME;
  payload: {
    resumeId: string;
    updated: UpdateSection;
  };
}

export interface updateProgressStatus {
  type: typeof UPDATE_PROGRESS_STATUS;
  payload: {
    progressStatus: number;
  };
}
export interface setSectionStatus {
  type: typeof SET_SECTION_STATUS;
  payload: {
    personalInformationStatus?: SectionStatus;
    skillStatus?: SectionStatus;
    workExperienceStatus?: SectionStatus;
    educationStatus?: SectionStatus;
    languageStatus?: SectionStatus;
  };
}

export interface setSection {
  type: typeof SET_SECTION;
  payload: {
    currentSection: ISection;
  };
}

export interface saveSectionData {
  type: typeof SAVE_SECTION_DATA;
  payload: {
    resumeFormData: IResumeForm;
    updated: UpdateSection;
  };
}

export interface saving {
  type: typeof SAVING;
  payload: {
    saving: boolean;
  };
}

export interface finishSaving {
  type: typeof FINISH_SAVING;
}

export interface finishLoading {
  type: typeof FINISH_LOADING;
}

export interface cleanResume {
  type: typeof CLEAN_RESUME;
}

export interface setUpdated {
  type: typeof SET_UPDATED;
  payload: {
    updated: UpdateSection;
  };
}

export interface addSharedResumes {
  type: typeof ADD_SHARED_RESUMES;
  payload: {
    sharedWith: string[];
    updatedDate: Date;
  };
}

export interface deleteResume {
  type: typeof DELETE_RESUME;
  payload: {
    resumeFormData: IResumeForm;
    updated: UpdateSection;
  };
}

export interface uploadPhoto {
  type: typeof UPLOAD_PHOTO;
  payload: {
    resumeData: IResumeForm;
  };
}

export interface nameOptions {
  type: typeof NAME_OPTIONS;
  payload: {
    nameOptions: DropdownItemProps[];
  };
}

export interface addNameOption {
  type: typeof ADD_NAME_OPTION;
  payload: {
    value: string;
    text: string;
  };
}

export interface hideResume {
  type: typeof HIDE_ONE_RESUME;
  payload: IhiddenResumeInfo;
}

export type ResumeDispatchTypes =
  | updateResume
  | createResume
  | setResume
  | updateProgressStatus
  | setSectionStatus
  | setSection
  | saving
  | saveSectionData
  | finishSaving
  | finishLoading
  | cleanResume
  | setUpdated
  | addSharedResumes
  | deleteResume
  | uploadPhoto
  | nameOptions
  | addNameOption
  | hideResume;
