import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import GridColumn from 'semantic-ui-react/dist/commonjs/collections/Grid/GridColumn';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image/Image';

import TopBarMenu from './TopBarMenu';
import styles from './TopNavBar.module.scss';
import Hamburger from '../HamburgerMenu/Hamburger';
import { User } from '../User/User';
import appLogo from '../../Assets/mon-cv-logo.svg';
import { ActiveOptionStyle, DeviceType, pathSharedViewer } from '../../constants/globalConstants';
import useComponentVisible from '../../HOOKs/useComponentVisible';
import { RootStore } from '../../redux/store';

const TopNavBar: React.FC = () => {
  const history = useHistory();
  const { topBarActiveOptionStyle } = useSelector((state: RootStore) => state.dashboard);
  const { pathname } = useLocation();
  const sharedViewer = pathname.includes(pathSharedViewer);
  const { windowWidth } = useComponentVisible();
  const SPLIT_MAX_WIDTH = 767;

  const handleClickLogo = () => {
    history.push('/dashboard');
  };

  const getLogoStyle = () => {
    const seatLogo = topBarActiveOptionStyle === ActiveOptionStyle.RESUME ? styles.seatLogo : '';
    return `${styles.logo} ${seatLogo}`;
  };

  const getTabsNavBar =
    windowWidth > SPLIT_MAX_WIDTH ? (
      <GridColumn computer={10} tablet={8} only='computer tablet' key='options-topnavbar'>
        <Grid>
          <GridRow centered className={styles.menuGridRow}>
            <TopBarMenu type={DeviceType.DESKTOP} active={!sharedViewer} />
          </GridRow>
        </Grid>
      </GridColumn>
    ) : (
      <TopBarMenu type={DeviceType.MOBILE} active={!sharedViewer} />
    );

  const topNavBarSections = [
    getTabsNavBar,
    <GridColumn
      computer={3}
      tablet={4}
      mobile={8}
      className={styles.userProfileContainer}
      key='user-topnavbar'
    >
      <div className={styles.userProfile}>
        <User />
      </div>
    </GridColumn>
  ];

  return (
    <div className={styles.topBarContainer}>
      <Grid className={styles.topBarGrid}>
        {topBarActiveOptionStyle === ActiveOptionStyle.RESUME && !sharedViewer && (
          <GridColumn verticalAlign='bottom' only='mobile tablet' mobile={2} tablet={1}>
            <Hamburger />
          </GridColumn>
        )}
        <GridColumn
          computer={3}
          tablet={3}
          mobile={topBarActiveOptionStyle === ActiveOptionStyle.RESUME ? 6 : 8}
          className={styles.logoGrid}
        >
          <Image src={appLogo} alt='Mon Cv Logo' onClick={handleClickLogo} className={getLogoStyle()} />
        </GridColumn>
        {windowWidth > SPLIT_MAX_WIDTH
          ? topNavBarSections.map((element) => element)
          : topNavBarSections.reverse().map((element) => element)}
      </Grid>
    </div>
  );
};

export default TopNavBar;
