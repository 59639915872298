import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

export const Levels: DropdownItemProps[] = [
  { key: 'beginner', value: 'beginner', text: 'Beginner' },
  { key: 'intermediate', value: 'intermediate', text: 'Intermediate' },
  { key: 'advanced', value: 'advanced', text: 'Advanced' },
  { key: 'native', value: 'native', text: 'Native' }
];

export const SkillLevels: DropdownItemProps[] = [
  {
    key: 'fundamental awareness',
    value: 'fundamental awareness',
    text: 'Fundamental Awareness (basic knowledge)'
  },
  { key: 'novice', value: 'novice', text: 'Novice (limited experience)' },
  { key: 'intermediate', value: 'intermediate', text: 'Intermediate (practical application)' },
  { key: 'advanced', value: 'advanced', text: 'Advanced (applied theory)' },
  { key: 'expert', value: 'expert', text: 'Expert (recognized authority)' }
];
