import React from 'react';
import { Icon } from 'semantic-ui-react';

import styles from './ResumeOptions.module.scss';
import './ResumeOptions.scss';
import { CardMenu } from '../ResumeDashboard/ResumeCard/CardMenu';

interface IResumeOptions {
  resumeId: string;
  name: string;
  email: string;
  picture: string;
  isHidden: boolean;
}

const ResumeOptions: React.FC<IResumeOptions> = ({ resumeId, name, email, picture, isHidden }) => {
  return (
    <section className={styles.ResumeOptions} id={'ResumeOptions'}>
      <div className={styles.iconSection} id={'iconSection'}>
        <CardMenu
          name={name}
          resumeId={resumeId}
          email={email}
          picture={picture}
          isHovering={true}
          setHovering={() => {}}
          isHidden={isHidden}
          icon={<Icon name='ellipsis horizontal' className={styles.dotsIcon} />}
        />
      </div>
    </section>
  );
};

export default ResumeOptions;
