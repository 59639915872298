import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useState } from 'react';

import { templateColors } from '../utils/constants';
import { skillPoints } from '../utils/skillPoints';
import { verifyStringLength, wordBreak } from '../utils/stringUtils';
import { ISkill } from '../../Models/ISkills';

interface ISkillProps {
  skills: ISkill[];
  highLightColor: string;
}

interface ISkillObj {
  area: string;
  skills: ISkill[];
}

interface ISkillEntryProps {
  title: string;
  skills: ISkill[];
  isFirst: boolean;
  highLightColor: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    skillsContainer: {
      paddingRight: 5
    },
    title: {
      fontSize: 15,
      fontFamily: 'Helvetica Neue Light',
      color: textColor
    },
    subTitle: {
      fontSize: 9,
      marginBottom: 2
    },
    skillContainer: {
      marginTop: 9
    },
    box: {},
    content: {
      flexDirection: 'row',
      marginTop: 3
    },
    name: {
      fontSize: 9,
      fontFamily: 'Helvetica',
      maxWidth: 100
    },
    levelContainer: {
      width: 23,
      marginRight: 2,
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    level: {
      fontSize: 10,
      letterSpacing: 1,
      marginRight: 1,
      color: textColor
    },
    complement: {
      fontSize: 10,
      color: '#000000',
      letterSpacing: 1,
      opacity: 0.2
    }
  });

Font.register({
  family: 'Helvetica',
  src: '/fonts/Helvetica/HelveticaNeue.ttf'
});

const MAX_STRING_LENGTH = 55;
const MAX_SKILL_WORD_LENGTH = 15;

const SkillEntry: React.FC<ISkillEntryProps> = ({ title, skills, isFirst, highLightColor }) => {
  const getLevel = (level: string) => {
    const [skillLevel, skillLevelComplement] = skillPoints(level);

    return (
      <View style={styles().levelContainer}>
        <Text style={{ ...styles(highLightColor).level }}>{skillLevel}</Text>
        <Text style={styles().complement}>{skillLevelComplement}</Text>
      </View>
    );
  };

  const skillCmpts = skills.map((skill, i) => {
    const skillName = verifyStringLength(skill.skill, MAX_STRING_LENGTH)
      .split(/(\s+)/)
      .map((word) => {
        return wordBreak(word, MAX_SKILL_WORD_LENGTH);
      });
    return (
      <View key={i} style={styles().content} wrap={false}>
        {getLevel(skill.level)}
        <Text style={styles().name}>{skillName}</Text>
      </View>
    );
  });

  const firstSkill = skillCmpts.splice(0, 1);

  return (
    <React.Fragment>
      {isFirst && <Text style={{ ...styles(highLightColor).title }}>Skills</Text>}
      <View style={styles().skillContainer}>
        <View wrap={false}>
          <Text style={styles().subTitle}>{verifyStringLength(title, MAX_STRING_LENGTH)}</Text>
          {firstSkill}
        </View>
        <View style={styles().box}>{skillCmpts}</View>
      </View>
    </React.Fragment>
  );
};

const Skill: React.FC<ISkillProps> = ({ skills, highLightColor }) => {
  const getSkills = (skills: ISkill[]) => {
    const skillsMap: Map<string, ISkill[]> = new Map<string, ISkill[]>();
    const skillsArray: ISkillObj[] = [];
    skills.forEach((skill) => {
      let skillByAreaArray = skillsMap.get(skill.area);

      if (skillByAreaArray) {
        skillByAreaArray.push(skill);
      } else {
        skillByAreaArray = [skill];
      }
      skillsMap.set(skill.area, skillByAreaArray);
    });
    skillsMap.forEach((value, key) => {
      const obj = {
        area: key,
        skills: value
      };
      skillsArray.push(obj);
    });

    return skillsArray;
  };
  const [skillsMap] = useState(getSkills(skills));

  return (
    <View style={styles().skillsContainer}>
      {skillsMap.map((item, index) => (
        <SkillEntry
          key={index}
          title={item.area}
          skills={item.skills}
          isFirst={index === 0}
          highLightColor={highLightColor}
        />
      ))}
    </View>
  );
};

export default Skill;
