import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import { resumeSectionsDistribution } from '../../constants/resumeSectionsDistribution';
import { SectionId } from '../../constants/sectionIDs';
import { ISection, SectionStatus } from '../../Models/FormResumeSection';
import { ActivePictureEnum } from '../../Models/IPersonalInformation';
import { IResumeForm } from '../../Models/IResumeForm';
import {
  ResumeDispatchTypes,
  SET_RESUME,
  UPDATE_PROGRESS_STATUS,
  SET_SECTION_STATUS,
  SET_SECTION,
  UPDATE_RESUME,
  CREATE_RESUME,
  SAVING,
  SAVE_SECTION_DATA,
  FINISH_SAVING,
  CLEAN_RESUME,
  SET_UPDATED,
  ADD_SHARED_RESUMES,
  DELETE_RESUME,
  FINISH_LOADING,
  NAME_OPTIONS,
  ADD_NAME_OPTION,
  UPLOAD_PHOTO,
  HIDE_ONE_RESUME
} from '../actions/resumeActionTypes';

export type UpdateSection = {
  [key in SectionId]?: boolean;
};

export interface IDefaultResumeState {
  resumeFormData: IResumeForm;
  resumeId: string;
  nameOptions: DropdownItemProps[];
  currentSection: ISection;
  personalInformationStatus: SectionStatus;
  skillStatus: SectionStatus;
  workExperienceStatus: SectionStatus;
  educationStatus: SectionStatus;
  languageStatus: SectionStatus;
  saving: boolean;
  isLoading: boolean;
  updated: UpdateSection;
}

const defaultResumeState: IDefaultResumeState = {
  resumeFormData: {
    _id: '',
    educations: [],
    languages: [],
    personalInformation: {
      fullName: '',
      givenName: '',
      familyName: '',
      phoneNumber: '',
      email: '',
      profesionalSummary: '',
      enabled: true,
      pictures: {
        activePicture: ActivePictureEnum.DISABLE_PICTURE,
        systemPicture: '',
        customPicture: '',
        customPictureId: ''
      },
      mainJob: '',
      mainInternalProfile: ''
    },
    skills: [],
    workExperiences: [],
    updatedDate: new Date(),
    sharedWith: [],
    progressStatus: 0
  },
  resumeId: '',
  nameOptions: [],
  currentSection: resumeSectionsDistribution.get(SectionId.PERSONAL_INFORMATION_SECTION) as ISection,
  personalInformationStatus: SectionStatus.UNSET,
  skillStatus: SectionStatus.UNSET,
  workExperienceStatus: SectionStatus.UNSET,
  educationStatus: SectionStatus.UNSET,
  languageStatus: SectionStatus.UNSET,
  saving: false,
  isLoading: true,
  updated: {
    personalInformation: false,
    skills: false,
    workExperiences: false,
    educations: false,
    languages: false
  }
};

const ResumeReducer = (
  state: IDefaultResumeState = defaultResumeState,
  action: ResumeDispatchTypes
): IDefaultResumeState => {
  switch (action.type) {
    case SET_RESUME:
      return {
        ...state,
        resumeFormData: action.payload.resumeFormData,
        resumeId: action.payload.resumeId,
        isLoading: false
      };
    case CREATE_RESUME:
      return {
        ...state,
        resumeId: action.payload.resumeId,
        updated: { ...state.updated, ...action.payload.updated },
        saving: false
      };
    case UPDATE_RESUME:
      return {
        ...state,
        updated: { ...state.updated, ...action.payload.updated },
        saving: false
      };
    case SET_SECTION_STATUS:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_PROGRESS_STATUS: {
      return {
        ...state,
        resumeFormData: {
          ...(state.resumeFormData as IResumeForm),
          progressStatus: action.payload.progressStatus
        }
      };
    }
    case SET_SECTION:
      return {
        ...state,
        currentSection: action.payload.currentSection
      };
    case SAVE_SECTION_DATA:
      return {
        ...state,
        resumeFormData: action.payload.resumeFormData,
        updated: { ...state.updated, ...action.payload.updated }
      };
    case SAVING:
      return {
        ...state,
        saving: action.payload.saving
      };
    case FINISH_SAVING:
      return {
        ...state,
        saving: false
      };
    case FINISH_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case CLEAN_RESUME: {
      return {
        ...defaultResumeState
      };
    }
    case SET_UPDATED: {
      return {
        ...state,
        updated: {
          ...state.updated,
          ...action.payload.updated
        }
      };
    }
    case ADD_SHARED_RESUMES: {
      return {
        ...state,
        resumeFormData: {
          ...(state.resumeFormData as IResumeForm),
          sharedWith: action.payload.sharedWith,
          updatedDate: action.payload.updatedDate
        }
      };
    }
    case DELETE_RESUME: {
      return {
        ...state,
        resumeFormData: action.payload.resumeFormData,
        updated: {
          ...state.updated,
          ...action.payload.updated
        }
      };
    }

    case HIDE_ONE_RESUME: {
      return {
        ...state,
        resumeFormData: {
          ...(state.resumeFormData as IResumeForm),
          isHidden: action.payload.isHidden
        }
      };
    }

    case UPLOAD_PHOTO: {
      return {
        ...state,
        resumeFormData: action.payload.resumeData
      };
    }

    case NAME_OPTIONS: {
      return {
        ...state,
        nameOptions: action.payload.nameOptions
      };
    }

    case ADD_NAME_OPTION: {
      const foundName = state.nameOptions.find((name) => name.value === action.payload.value);
      if (foundName) return { ...state };
      return {
        ...state,
        nameOptions: [...state.nameOptions, action.payload]
      };
    }

    default:
      return state;
  }
};

export default ResumeReducer;
