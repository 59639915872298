import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

import { styles } from './SquareLevel.styles';

interface ISquareLevelProps {
  width?: string;
  height?: string;
  primaryColor?: string;
  secondColor?: string;
  level: number;
  levelsNumber: number;
}

export const SquareLevel: React.FC<ISquareLevelProps> = ({
  level,
  levelsNumber,
  width,
  height,
  primaryColor
}) => {
  const baseDimensions = { widthBase: width, heightBase: height };
  const itemPercentage = `${100 / levelsNumber}%`;
  const itemLevel = (index: number, itemPercentage: string) => {
    const lastItem = index === levelsNumber - 1 ? styles({}).lastItem : undefined;
    const firstItem = index === 0 ? styles({}).firstItem : undefined;
    const checkedItem = index < level ? styles({}).check : undefined;
    const stylesItem = {
      ...styles({ itemPercentage, primaryColor }).item,
      ...checkedItem,
      ...firstItem,
      ...lastItem
    };
    return <View key={`itemLevel-${index}`} style={[stylesItem as Style]}></View>;
  };

  return (
    <View style={styles(baseDimensions).base}>
      <View style={styles({}).background}>
        {Array(levelsNumber)
          .fill(0)
          .map((_, index) => {
            return itemLevel(index, itemPercentage);
          })}
      </View>
      <View style={styles({}).brightness}></View>
    </View>
  );
};
