import { Rect, Svg, Circle } from '@react-pdf/renderer';
import React from 'react';

interface ICircleLevelProps {
  width?: string;
  height?: string;
  primaryColor?: string;
  secondColor?: string;
  level: number;
  levelsNumber?: number;
  highLightColor?: string;
}

export const CircleLevel: React.FC<ICircleLevelProps> = ({ level, levelsNumber, highLightColor }) => {
  const colorCircle = highLightColor || '#d20000';
  const filledLine = level !== undefined ? 6 + 22 * (level - 1) : 0;
  const itemLevel = (index: number) => {
    const filledCircle = index < level ? colorCircle : '#c2c2c2';
    return <Circle key={`circle-${index}`} cx={`${3 + index * 22}`} cy='3' r='3' fill={filledCircle} />;
  };

  return (
    <Svg width='54' height='4.5' viewBox='0 0 72 6'>
      <Rect x='0' y='2' width='72' height='2' rx='1' opacity='1' fill='#C2C2C2' />
      <Rect x='0' y='2' width={`${filledLine}`} height='2' rx='1' fill={colorCircle} />
      {Array(levelsNumber)
        .fill(0)
        .map((_, index) => {
          return itemLevel(index);
        })}
    </Svg>
  );
};
