import {
  isDate,
  isEmail,
  isRequired,
  isTextEditorFilled,
  maxDate,
  verifyDefaultValue,
  when
} from './validator';
import { ResumeValues, SectionStatus } from '../../Models/FormResumeSection';

type tFormValidationRules = {
  [key: string]: Function[];
};

const dependentFunctionName = 'when';

export const formValidationRules: tFormValidationRules = {
  fullName: [isRequired],
  email: [isEmail],
  mainJob: [isRequired],
  profesionalSummary: [isRequired, isTextEditorFilled],
  area: [isRequired],
  skill: [isRequired],
  level: [isRequired],
  jobTitle: [isRequired],
  client: [isRequired],
  startDate: [isDate, when('endDate', maxDate)],
  endDate: [],
  description: [isRequired, isTextEditorFilled, verifyDefaultValue],
  school: [isRequired],
  career: [isRequired],
  language: [isRequired],
  reading: [isRequired],
  listening: [isRequired],
  writing: [isRequired],
  speaking: [isRequired]
};

const isValid = (value: string, field: string, secondValue?: string): boolean => {
  const validationRules = formValidationRules[field] || [];
  const validationStatus = validationRules.some((rule) => {
    const ruleStatus = rule(value, secondValue);
    return !ruleStatus;
  });

  return !validationStatus;
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export const isValidateSchema = (data: any, schema: any) => {
  if (!data) {
    return;
  }
  const fields = Object.keys(schema);
  const schemaStatus = fields.every((field) => {
    const functionsToValidate = schema[field];
    const fieldStatus = functionsToValidate.every((rule: Function) => {
      let ruleExec = rule;
      let dependentField;
      if (rule.name === dependentFunctionName) {
        const [field, fun] = rule();
        dependentField = data[field];
        ruleExec = fun;
      }
      const ruleState = ruleExec(data[field], dependentField);
      return ruleState;
    });
    return fieldStatus;
  });
  return schemaStatus;
};

const calculateProgressStatus = (status: SectionStatus[]) => {
  const [
    personalInformationStatus,
    skillStatus,
    workExperienceStatus,
    educationStatus,
    languageStatus
  ] = status;
  const personalInformationValue =
    personalInformationStatus > SectionStatus.UNSET
      ? personalInformationStatus === SectionStatus.SUCCESS
        ? ResumeValues.COMPLETE
        : ResumeValues.INCOMPLETE
      : 0;
  const skillValue =
    skillStatus > SectionStatus.UNSET
      ? skillStatus === SectionStatus.SUCCESS
        ? ResumeValues.COMPLETE
        : ResumeValues.INCOMPLETE
      : 0;
  const workExperienceValue =
    workExperienceStatus > SectionStatus.UNSET
      ? workExperienceStatus === SectionStatus.SUCCESS
        ? ResumeValues.COMPLETE
        : ResumeValues.INCOMPLETE
      : 0;
  const educationValue =
    educationStatus > SectionStatus.UNSET
      ? educationStatus === SectionStatus.SUCCESS
        ? ResumeValues.COMPLETE
        : ResumeValues.INCOMPLETE
      : 0;
  const languageValue =
    languageStatus > SectionStatus.UNSET
      ? languageStatus === SectionStatus.SUCCESS
        ? ResumeValues.COMPLETE
        : ResumeValues.INCOMPLETE
      : 0;

  const newProgressStatus =
    personalInformationValue + skillValue + workExperienceValue + educationValue + languageValue;

  return newProgressStatus;
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const validateFields = (data: any, schema: any) => {
  let dataIsValid;
  if (Array.isArray(data)) {
    if (data.length === 0) return SectionStatus.UNSET;
    dataIsValid = data.reduce((status, item) => {
      const validation = isValidateSchema(item, schema);
      return status && validation;
    }, true);
  } else {
    dataIsValid = isValidateSchema(data, schema);
  }
  const status = dataIsValid ? SectionStatus.SUCCESS : SectionStatus.ERROR;
  return status;
};

export { isValid, calculateProgressStatus, validateFields };
