/* eslint-disable camelcase */
import {
  ResumesDispatchTypes,
  ResumesType,
  AuthType,
  DATA_FAIL,
  DATA_LOADING,
  RESUMES_SUCCESS,
  FILTERED_SUCCESS,
  AUTH_SUCCESS,
  RESUMES_OPTIONS_SUCCESS,
  ADD_JOB_OPTION,
  ADD_AREA_OPTION,
  ADD_SKILL_OPTION,
  ADD_LANGUAGE_OPTION,
  ADD_INTERNAL_PROFILE_OPTION,
  ADD_PROFILE_OPTION,
  SET_DASHBOARD_PAGE,
  SET_SEARCH_CRITERIA,
  UPDATE_IS_READY,
  SET_TOP_BAR_ACTIVE_MENU,
  SET_LOGGED_USER_RESUME_ID,
  SET_TOP_BAR_RESUME_OPTION_NAME,
  HIDE_RESUME
} from '../actions/dashboardActionTypes';
import {
  ActiveOptionStyle,
  dashboardPage,
  filter,
  refinement,
  visibility,
  ResumeOptionName,
  Roles,
  sort
} from '../../constants/globalConstants';
import { resumesPerPage } from '../../constants/settings';
import { SearchFilters } from '../../Models/FormSearchFilters';
import { IResumeOptions } from '../../Models/IResumeOptions';

export interface IDefaultState {
  loading: boolean;
  authData: AuthType;
  resumes: ResumesType;
  filteredResumes?: ResumesType;
  totalPages?: number;
  activePage: number;
  searchAndFilterHide: boolean;
  resumeOptions: IResumeOptions;
  topBarActiveOptionStyle: string;
  loggedUserResumeId: string;
  topBarResumeOptionName: string;
  searchCriteria: SearchFilters;
  isReady: boolean;
}

export interface IProfile {
  acr: string;
  auth_time: number;
  azp: string;
  birthdate: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  gender: string;
  given_name: string;
  jti: string;
  name: string;
  picture: string;
  preferred_username: string;
  session_state: string;
  sub: string;
  typ: string;
}

const defaultState: IDefaultState = {
  loading: false,
  authData: {
    access_token: '',
    expires_at: 0,
    id_token: '',
    profile: {
      acr: '',
      auth_time: 0,
      azp: '',
      birthdate: '',
      email: '',
      email_verified: false,
      family_name: '',
      gender: '',
      given_name: '',
      jti: '',
      name: '',
      picture: '',
      preferred_username: '',
      session_state: '',
      sub: '',
      typ: ''
    },
    refresh_token: '',
    scope: '',
    session_state: '',
    token_type: '',
    userRole: '',
    userName: '',
    userId: ''
  },
  resumes: [],
  resumeOptions: {
    skills: [],
    profiles: [],
    languages: [],
    internalProfiles: []
  },
  searchCriteria: { criteria: '', sort, filter, refinement, visibility } as SearchFilters,
  activePage: dashboardPage ? parseInt(dashboardPage, 10) : 1,
  searchAndFilterHide: true,
  loggedUserResumeId: '',
  topBarResumeOptionName: ResumeOptionName.MY_RESUME,
  topBarActiveOptionStyle: localStorage.getItem('menu') || ActiveOptionStyle.HOME,
  isReady: false
};

const dashboardReducer = (
  state: IDefaultState = defaultState,
  action: ResumesDispatchTypes
): IDefaultState => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        loading: false,
        authData: action.payload,
        resumes: [...state.resumes],
        resumeOptions: state.resumeOptions,
        activePage: state.activePage,
        searchCriteria: state.searchCriteria,
        searchAndFilterHide: state.searchAndFilterHide,
        isReady: state.isReady,
        loggedUserResumeId: state.loggedUserResumeId,
        topBarActiveOptionStyle: state.topBarActiveOptionStyle,
        topBarResumeOptionName: state.topBarResumeOptionName
      };
    case DATA_FAIL:
      return {
        loading: false,
        authData: state.authData,
        resumes: [...state.resumes],
        resumeOptions: state.resumeOptions,
        activePage: state.activePage,
        searchCriteria: state.searchCriteria,
        searchAndFilterHide: state.searchAndFilterHide,
        isReady: state.isReady,
        loggedUserResumeId: state.loggedUserResumeId,
        topBarActiveOptionStyle: state.topBarActiveOptionStyle,
        topBarResumeOptionName: state.topBarResumeOptionName
      };
    case DATA_LOADING:
      return {
        loading: true,
        authData: state.authData,
        resumes: [...state.resumes],
        resumeOptions: state.resumeOptions,
        activePage: state.activePage,
        searchCriteria: state.searchCriteria,
        searchAndFilterHide: state.searchAndFilterHide,
        isReady: false,
        loggedUserResumeId: state.loggedUserResumeId,
        topBarActiveOptionStyle: state.topBarActiveOptionStyle,
        topBarResumeOptionName: state.topBarResumeOptionName
      };
    case RESUMES_SUCCESS: {
      const { resumes, totalPages } = action.payload;
      const page = totalPages !== 0 && state.activePage > totalPages ? totalPages : state.activePage;
      const hideSibebar =
        state.authData.userRole !== Roles.ADMIN &&
        state.searchCriteria.criteria === '' &&
        resumes.length <= 1 &&
        !state.searchCriteria.filter;

      return {
        loading: false,
        authData: state.authData,
        resumes: resumes,
        filteredResumes: resumes,
        activePage: page,
        totalPages: Math.ceil(totalPages),
        resumeOptions: state.resumeOptions,
        searchCriteria: state.searchCriteria,
        searchAndFilterHide: hideSibebar,
        isReady: state.isReady,
        loggedUserResumeId: state.loggedUserResumeId,
        topBarActiveOptionStyle: state.topBarActiveOptionStyle,
        topBarResumeOptionName: state.topBarResumeOptionName
      };
    }
    case RESUMES_OPTIONS_SUCCESS:
      return {
        loading: false,
        authData: state.authData,
        resumes: [...state.resumes],
        resumeOptions: action.payload,
        filteredResumes: state.filteredResumes,
        totalPages: state.totalPages,
        activePage: state.activePage,
        searchCriteria: state.searchCriteria,
        searchAndFilterHide: state.searchAndFilterHide,
        isReady: true,
        loggedUserResumeId: state.loggedUserResumeId,
        topBarActiveOptionStyle: state.topBarActiveOptionStyle,
        topBarResumeOptionName: state.topBarResumeOptionName
      };
    case FILTERED_SUCCESS:
      return {
        loading: false,
        authData: state.authData,
        resumes: [...state.resumes],
        filteredResumes: action.payload,
        totalPages: Math.ceil(action.payload.length / resumesPerPage),
        resumeOptions: state.resumeOptions,
        activePage: state.activePage,
        searchCriteria: state.searchCriteria,
        searchAndFilterHide: state.searchAndFilterHide,
        isReady: state.isReady,
        loggedUserResumeId: state.loggedUserResumeId,
        topBarActiveOptionStyle: state.topBarActiveOptionStyle,
        topBarResumeOptionName: state.topBarResumeOptionName
      };
    case ADD_JOB_OPTION: {
      const foundJob = state.resumeOptions.profiles.find(
        (profile) => profile.value === action.payload.value
      );
      if (foundJob) return { ...state };
      return {
        ...state,
        resumeOptions: {
          ...state.resumeOptions,
          profiles: [...state.resumeOptions.profiles, action.payload]
        }
      };
    }
    case ADD_PROFILE_OPTION: {
      const newProfiles = [...state.resumeOptions.profiles];
      const { job, profile } = action.payload;
      if (typeof job === 'number') {
        const foundProfile = newProfiles[job].profiles.some(
          (profileOpt) => profileOpt.value === profile.value
        );
        !foundProfile && newProfiles[job].profiles.push(profile);
      } else {
        const foundJob = newProfiles.find((jobOpt) => jobOpt.value === job.value);
        const foundProfile = foundJob?.profiles.some((profileOpt) => profileOpt.value === profile.value);
        foundJob ? !foundProfile && foundJob.profiles.push(profile) : newProfiles.push(job);
      }
      return {
        ...state,
        resumeOptions: {
          ...state.resumeOptions,
          profiles: newProfiles
        }
      };
    }
    case ADD_AREA_OPTION: {
      const foundSkill = state.resumeOptions.skills.find((area) => area.value === action.payload.value);
      if (foundSkill) return { ...state };
      return {
        ...state,
        resumeOptions: {
          ...state.resumeOptions,
          skills: [...state.resumeOptions.skills, action.payload]
        }
      };
    }
    case ADD_SKILL_OPTION: {
      const newSkills = [...state.resumeOptions.skills];
      const { area, skill } = action.payload;
      if (typeof area === 'number') {
        const foundSkill = newSkills[area].skills.some((skillOpt) => skillOpt.value === skill.value);
        !foundSkill && newSkills[area].skills.push(skill);
      } else {
        const foundArea = newSkills.find((areaOpt) => areaOpt.value === area.value);
        const foundSkill = foundArea?.skills.some((skillOpt) => skillOpt.value === skill.value);
        foundArea ? !foundSkill && foundArea.skills.push(skill) : newSkills.push(area);
      }
      return {
        ...state,
        resumeOptions: {
          ...state.resumeOptions,
          skills: newSkills
        }
      };
    }
    case ADD_LANGUAGE_OPTION: {
      const { languages } = state.resumeOptions;
      const foundLanguage = languages.find((language) => language.value === action.payload.value);
      if (foundLanguage) return { ...state };
      const newLanguage = { ...action.payload, orderBy: languages.length };
      return {
        ...state,
        resumeOptions: {
          ...state.resumeOptions,
          languages: [...state.resumeOptions.languages, newLanguage]
        }
      };
    }
    case ADD_INTERNAL_PROFILE_OPTION: {
      const { internalProfiles } = state.resumeOptions;
      const foundInternalProfile = internalProfiles.find(
        (profile) => profile.value === action.payload.value
      );
      if (foundInternalProfile) return { ...state };
      return {
        ...state,
        resumeOptions: {
          ...state.resumeOptions,
          internalProfiles: [...state.resumeOptions.internalProfiles, action.payload]
        }
      };
    }
    case SET_DASHBOARD_PAGE: {
      const page = action.payload;
      return {
        ...state,
        activePage: page
      };
    }
    case SET_SEARCH_CRITERIA: {
      return {
        ...state,
        searchCriteria: { ...state.searchCriteria, ...action.payload }
      };
    }
    case UPDATE_IS_READY: {
      const value = action.payload;
      return {
        ...state,
        isReady: value
      };
    }
    case SET_TOP_BAR_ACTIVE_MENU: {
      const { payload } = action;
      return {
        ...state,
        topBarActiveOptionStyle: payload
      };
    }
    case SET_TOP_BAR_RESUME_OPTION_NAME: {
      const { payload } = action;
      return {
        ...state,
        topBarResumeOptionName: payload
      };
    }
    case SET_LOGGED_USER_RESUME_ID: {
      const { payload } = action;
      return {
        ...state,
        loggedUserResumeId: payload
      };
    }
    case HIDE_RESUME: {
      const { id, isHidden } = action.payload;
      let newArrayResumes = [...state.resumes];
      const refinementVisibility = state.searchCriteria.visibility.includes('isHidden');
      if (isHidden && !refinementVisibility) {
        return {
          ...state,
          resumes: state.resumes.filter((resume) => resume._id !== id)
        };
      }
      newArrayResumes = newArrayResumes.map((resume) => {
        if (resume._id === id) {
          resume.isHidden = isHidden;
        }
        return resume;
      });
      return {
        ...state,
        resumes: newArrayResumes
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
