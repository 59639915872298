import { View, Text } from '@react-pdf/renderer';
import React from 'react';

import CircleSeparator from './Divider';
import { styles } from './ExperienceSection.styles';
import TitleSection from './Title';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { formatTextDate } from '../utils/formatTextDate';
import { verifyStringLength } from '../utils/stringUtils';
import { IWorkExperience } from '../../Models/IWorkExperience';

interface IExperiencesProps {
  data: IWorkExperience[];
  highLightColor: string;
}

interface IExperienceProps {
  data: IWorkExperience;
  highLightColor: string;
}

const skillMaxLength = 55;
const ellipsisMaxLength = 75;
const BAG_ICON = '/images/bag.png';

const Experience: React.FC<IExperienceProps> = ({ data, highLightColor }) => {
  const { client, description: newDescription, endDate, startDate, jobTitle, profile, skills } = data;
  const jobTitleText = jobTitle.replace(/\s+/g, ' ').trim();
  const profileText = profile.replace(/\s+/g, ' ').trim();
  const strongTag = ':</strong>';
  const regexStrongTag = new RegExp(strongTag, 'g');
  let description = newDescription.replace(regexStrongTag, '</strong><br>');
  const emptySpaces = '&nbsp;';
  const regexEmptySpaces = new RegExp(emptySpaces, 'g');
  description = description.replace(regexEmptySpaces, '');
  return (
    <View style={styles().experienceContainer}>
      <CircleSeparator />
      <View style={styles().content}>
        <View wrap={false}>
          <View style={styles().jobContainer}>
            <Text style={styles(highLightColor).ellipsis}>{`${jobTitleText}${
              profile && ' - '
            }${profileText}${client && ' - '}${verifyStringLength(client, ellipsisMaxLength)}`}</Text>
          </View>
          <Text style={styles().dateEducation}> {formatTextDate(startDate, endDate)} </Text>
        </View>
        <View style={styles().skillContent}>
          {skills.map((name, index) => (
            <View key={`experience-unique-${index}`} style={styles().skillBox} wrap={false}>
              <Text>{verifyStringLength(name, skillMaxLength)}</Text>
            </View>
          ))}
        </View>
        <View style={styles().descriptionContainer}>
          <HTMLContent data={description} />
        </View>
      </View>
    </View>
  );
};

const ExperienceSection: React.FC<IExperiencesProps> = ({ data, highLightColor }) => {
  const existsData = data.length > 0 || null;
  return (
    existsData && (
      <View style={styles().sectionContainer} wrap={true}>
        <TitleSection
          urlImage={BAG_ICON}
          text={'EXPERIENCE'}
          containerMarginLeft={'2'}
          highLightColor={highLightColor}
        />
        {data.map((experience, index) => (
          <View style={styles().contentWrapper} key={`experience-item-${index}`}>
            <Experience data={experience} highLightColor={highLightColor} />
          </View>
        ))}
      </View>
    )
  );
};

export default ExperienceSection;
