import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
// @ts-ignore
import htmlToPdfmake from 'html-to-pdfmake';
import React from 'react';

import List, { Item } from './List';
import { convertToRoman, parseStringForPdf } from '../helper';
import { wordBreak } from '../stringUtils';

interface IHTMLContentProps {
  data: string;
}

export interface IContentProps {
  nodeName: string;
  style: string[];
  text?: IContentProps[];
  ol?: IContentProps[];
  ul?: IContentProps[];
}

Font.register({
  family: 'Helvetica Bold',
  src: '/fonts/Helvetica/HelveticaNeueBold.ttf'
});
Font.register({
  family: 'Helvetica Italic',
  src: `/fonts/Helvetica/HelveticaNeueItalic.ttf`
});
Font.register({
  family: 'Helvetica Bold Italic',
  src: `/fonts/Helvetica/HelveticaNeueBoldItalic.ttf`
});

Font.registerHyphenationCallback((word) => wordBreak(word));

const styles = StyleSheet.create({
  container: {
    fontSize: 10,
    lineHeight: 1.5
  },
  htmlP: {},
  htmlStrong: {
    fontFamily: 'Helvetica Bold'
  },
  htmlEm: {
    fontFamily: 'Helvetica Italic'
  },
  htmlStrongEm: {
    fontFamily: 'Helvetica Bold Italic'
  },
  htmlU: {
    textDecoration: 'underline'
  },
  paragraph: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
});

const indexOptions = {
  kindOptions: 3,
  characterNumberOption: 2,
  decimalSystem: 10,
  levelIndentPosition: 10,
  secondPosition: 1,
  firstLevel: 0
};

export const GetStyles = (contentStyles: string[]) => {
  let arrayStyles: Style[] = [];
  contentStyles.indexOf('html-strong') > -1 && arrayStyles.push(styles.htmlStrong);
  contentStyles.indexOf('html-em') > -1 && arrayStyles.push(styles.htmlEm);
  const existBold = arrayStyles.findIndex((style) => style === styles.htmlStrong);
  const existItalic = arrayStyles.findIndex((style) => style === styles.htmlEm);
  if (existBold > -1 && existItalic > -1) {
    arrayStyles = arrayStyles.splice(existBold);
    arrayStyles = arrayStyles.splice(existItalic);
    arrayStyles.push(styles.htmlStrongEm);
  }
  contentStyles.indexOf('html-u') > -1 && arrayStyles.push(styles.htmlU);
  return arrayStyles;
};

const HTMLContent: React.FC<IHTMLContentProps> = ({ data }) => {
  const content = htmlToPdfmake(data);

  const getBody = (content: IContentProps, i: number) => {
    let currentLevel = indexOptions.firstLevel;
    const indexTemplate = [1, 'a', 1];
    let indexLevel: { level: number; position: string }[] = [];

    const getIndex = (level: number) => {
      const indexTemplateposition = level % indexOptions.kindOptions;
      if (currentLevel > level) {
        indexLevel = indexLevel.filter((lvl) => lvl.level < level + 1);
      }
      currentLevel = level;
      const existIndex = indexLevel.find((index) => index.level === level);
      if (!existIndex) {
        const indexCharacter = indexTemplate[indexTemplateposition].toString();
        indexLevel.push({ level, position: indexCharacter });
        if (indexTemplateposition === indexOptions.characterNumberOption) {
          return convertToRoman(parseInt(indexCharacter, indexOptions.decimalSystem));
        }
        return indexCharacter;
      }
      const arrayPosition = indexLevel.findIndex((index) => index.level === level);
      if (indexTemplateposition === indexOptions.secondPosition) {
        indexLevel[arrayPosition].position = String.fromCharCode(
          indexLevel[arrayPosition].position.charCodeAt(0) + 1
        );
        return indexLevel[arrayPosition].position;
      }
      const newIndex = parseInt(indexLevel[arrayPosition].position, indexOptions.decimalSystem) + 1;
      indexLevel[arrayPosition].position = newIndex.toString();
      if (indexTemplateposition === indexOptions.characterNumberOption) {
        return convertToRoman(newIndex);
      }
      return indexLevel[arrayPosition].position;
    };

    const getItem = (element: IContentProps, type: string, j: number) => {
      const { style } = element;
      const indent = style.find((styleClass) => styleClass.includes('ql-indent-'));
      if (indent) {
        const indentNumber = indent.slice(indexOptions.levelIndentPosition);
        const level = parseInt(indentNumber, indexOptions.decimalSystem);
        return (
          <Item key={j} type={type} indexList={getIndex(level)} level={level}>
            {element.text}{' '}
          </Item>
        );
      }
      return (
        <Item
          key={j}
          type={type}
          indexList={getIndex(indexOptions.firstLevel)}
          level={indexOptions.firstLevel}
        >
          {element.text}{' '}
        </Item>
      );
    };

    if (typeof content.text === 'string' || content.text instanceof String) {
      const textContent = parseStringForPdf(content.text);
      return (
        <Text key={i} style={content.style ? GetStyles(content.style) : []}>
          <Text>{'  '}</Text>
          {textContent}
        </Text>
      );
    } else {
      if (content.ol) {
        return <List key={i}>{content.ol.map((element, j) => getItem(element, 'listNumber', j))}</List>;
      }
      if (content.ul) {
        return <List key={i}>{content.ul.map((element, j) => getItem(element, 'list', j))}</List>;
      } else {
        return (
          <Text key={i} style={styles.paragraph}>
            {content.text?.map((item, index) => getBody(item, index))}
          </Text>
        );
      }
    }
  };

  return (
    <View style={styles.container}>
      {content.map((item: IContentProps, index: number) => getBody(item, index))}
    </View>
  );
};

export default HTMLContent;
