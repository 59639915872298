import { View, Text, Image } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './LanguageSection.styles';
import { LanguageLevels } from '../utils/constants';
import { getLanguageImages } from '../utils/languageImages';
import { CircleLevel } from '../utils/CircleLevel/CircleLevel';
import { ILanguage } from '../../Models/ILanguages';

interface ILanguagesProps {
  data: ILanguage[];
  highLightColor: string;
}

interface ILanguageProps {
  data: ILanguage;
  highLightColor: string;
  indexItem: number;
}

const LEVELS_NUMBER = 4;

const Language: React.FC<ILanguageProps> = ({ data, highLightColor, indexItem }) => {
  const { language, listening, reading, speaking, writing } = data;
  const languageName = language.replace(/\s+/g, ' ').trim();
  const languageSkills = [
    {
      skillTitle: 'Reading',
      level: LanguageLevels[reading as keyof typeof LanguageLevels]
    },
    {
      skillTitle: 'Listening',
      level: LanguageLevels[listening as keyof typeof LanguageLevels]
    },
    {
      skillTitle: 'Writing',
      level: LanguageLevels[writing as keyof typeof LanguageLevels]
    },
    {
      skillTitle: 'Speaking',
      level: LanguageLevels[speaking as keyof typeof LanguageLevels]
    }
  ];
  const nativeSkills = languageSkills.every((item) => item.level === LanguageLevels.native);

  return (
    <View wrap={false} style={styles().language}>
      {indexItem === 0 && <Text style={styles(highLightColor).sectionTitle}>Languages</Text>}
      <View wrap={false} style={{ flexDirection: 'row' }}>
        <Image style={styles().languageIcon} src={getLanguageImages(language)} />
        <View style={styles().languageText}>
          <Text style={styles().languageTitle}>{languageName}</Text>
          {nativeSkills && <Text style={styles().native}>(Native)</Text>}
        </View>
      </View>
      {!nativeSkills && (
        <View style={styles().box}>
          {languageSkills.map((item, index) => (
            <View style={styles().content} key={`languageSkills-${index}`}>
              <View
                style={{
                  width: 31.5,
                  alignItems: 'flex-end',
                  marginRight: 6,
                  flexDirection: 'column'
                }}
              >
                <Text style={styles().languageType}>{item.skillTitle}</Text>
              </View>
              <View style={styles().levels}>
                <CircleLevel
                  level={item.level}
                  levelsNumber={LEVELS_NUMBER}
                  highLightColor={highLightColor}
                />
              </View>
            </View>
          ))}
        </View>
      )}
    </View>
  );
};

const LanguageSection: React.FC<ILanguagesProps> = ({ data, highLightColor }) => {
  const existsData = data.length > 0 || null;
  return (
    existsData && (
      <View style={styles().sectionContainer}>
        <View style={styles().languagesContainer}>
          {data.map((item, index) => (
            <Language
              indexItem={index}
              key={`languageItem-${index}`}
              data={item}
              highLightColor={highLightColor}
            />
          ))}
        </View>
      </View>
    )
  );
};

export default LanguageSection;
