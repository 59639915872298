import { pdf } from '@react-pdf/renderer';
import React, { useEffect, useState, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { useSelector, useDispatch } from 'react-redux';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import GridColumn from 'semantic-ui-react/dist/commonjs/collections/Grid/GridColumn';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';

import styles from './PreviewDownload.module.scss';
import TemplatesViewer from './TemplatesViewer';
import { FileLoader } from '../Forms/CustomLoader/CustomLoader';
import HighLightColor from '../HighLightColor/HighLightColor';
import { generatePdfDownloadTitle } from '../ResumeDashboard/ResumeCard/ResumeCard';
import ResumeOptions from '../ResumeOptions/ResumeOptions';
import { CloseIcon, DownloadIcon } from '../../Assets/CustomIcons';
import { TemplatePDF } from '../../constants/globalConstants';
import { getResume } from '../../redux/actions/resumeActions';
import { RootStore } from '../../redux/store';
import '../../Styles/components/previewDownload.scss';
import AquaTemplate from '../../templates/Aqua';
import ClassicTemplate from '../../templates/Classic/ResumenDocument';
import CorporateTemplate from '../../templates/Corporate/ResumeDocument';
import FormalTemplate from '../../templates/Formal';
import ModernTemplate from '../../templates/Modern/Main';
import PlainTemplate from '../../templates/Plain';
import { templateColors } from '../../templates/utils/constants';

interface IPreviewDownload {
  id?: string;
  setHoverCard?: () => void;
}

interface IHightLightColorObject {
  [key: string]: string;
}

const MARGIN_PAGE = 24;

const pageSize = () => {
  const width = document.documentElement.clientWidth;
  const height = document.documentElement.clientHeight;

  return width * 1.4 > height ? [undefined, height] : [width, undefined];
};

const PreviewDownload: React.FC<IPreviewDownload> = ({ id, setHoverCard, children }) => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const [totalPages, setTotalPages] = useState<number>();
  const [downloading, setDownloading] = useState(false);
  const [template, setTemplate] = useState(TemplatePDF.CLASSIC);
  const [highLightColor, setHightLightColor] = useState('');
  const [loading, setLoading] = useState(true);
  const [displayTemplateBlob, setDisplayTemplateBlob] = useState<Blob | null>(null);

  const { resumeFormData } = useSelector((state: RootStore) => state.resume);
  const {
    _id = '',
    personalInformation: { fullName, email, pictures = null },
    isHidden = false
  } = resumeFormData;

  const dispatch = useDispatch();

  const getTemplateBlob = async (template: TemplatePDF, color?: string) => {
    switch (template) {
      case TemplatePDF.CORPORATE: {
        return await pdf(
          <CorporateTemplate resume={resumeFormData} highLightColor={color || templateColors.red} />
        ).toBlob();
      }
      case TemplatePDF.CLASSIC: {
        return await pdf(
          <ClassicTemplate resume={resumeFormData} highLightColor={color || templateColors.red} />
        ).toBlob();
      }
      case TemplatePDF.MODERN: {
        return await pdf(
          <ModernTemplate resume={resumeFormData} highLightColor={color || templateColors.blue} />
        ).toBlob();
      }
      case TemplatePDF.AQUA: {
        return await pdf(
          <AquaTemplate resume={resumeFormData} highLightColor={color || templateColors.aqua} />
        ).toBlob();
      }
      case TemplatePDF.FORMAL: {
        return await pdf(
          <FormalTemplate resume={resumeFormData} highLightColor={color || templateColors.red} />
        ).toBlob();
      }
      case TemplatePDF.PLAIN: {
        return await pdf(
          <PlainTemplate resume={resumeFormData} highLightColor={color || templateColors.gray} />
        ).toBlob();
      }
      default: {
        return await pdf(
          <CorporateTemplate resume={resumeFormData} highLightColor={color || templateColors.blue} />
        ).toBlob();
      }
    }
  };

  const onDocumentLoad = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
  };

  const setHightLightColorObject: IHightLightColorObject = {
    corporate: templateColors.red,
    classic: templateColors.red,
    modern: templateColors.blue,
    aqua: templateColors.aqua,
    formal: templateColors.red,
    simple: templateColors.gray
  };

  const onChangeTemplate = async (newTemplate: TemplatePDF) => {
    setLoading(true);
    setTemplate(newTemplate);
    setLoading(false);
  };

  const downloadPDFDocument = () => {
    if (resumeFormData._id) {
      setDownloading(true);
      const {
        personalInformation: { fullName }
      } = resumeFormData;
      const fileName = generatePdfDownloadTitle(fullName);
      displayTemplateBlob && saveAs(displayTemplateBlob, fileName);
      setDownloading(false);
    }
  };

  const [pageWidth, pageHeight] = pageSize();

  const updateDisplayBlob = async (firstRender: boolean) => {
    setLoading(true);
    firstRender && setHightLightColor(setHightLightColorObject[template]);
    const newBlob = await getTemplateBlob(
      template,
      firstRender ? setHightLightColorObject[template] : highLightColor
    );
    newBlob && setDisplayTemplateBlob(newBlob);
    setLoading(false);
  };

  useEffect(() => {
    if (openTemplate) {
      updateDisplayBlob(false);
    }
  }, [resumeFormData, highLightColor, openTemplate]);

  useEffect(() => {
    if (openTemplate) {
      updateDisplayBlob(true);
    }
  }, [template]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  const memoizedAllTemplates = useMemo(() => {
    return <TemplatesViewer onChangeTemplate={onChangeTemplate} />;
  }, [resumeFormData]);

  const handleOpenModal = async () => {
    setLoading(true);
    if (id) {
      await dispatch(getResume(id));
    }
    setOpenTemplate(true);
  };

  const handleCloseModal = () => {
    setOpenTemplate(false);
    setHoverCard && setHoverCard();
  };

  return (
    <React.Fragment>
      <Modal
        trigger={children}
        closeIcon={<CloseIcon className={styles.closeIcon} />}
        open={openTemplate}
        onClose={handleCloseModal}
        onOpen={handleOpenModal}
        className={
          loading && openTemplate
            ? styles.PreviewDownloadContainerWhite
            : styles.PreviewDownloadContainer
        }
        id='modalDownload'
      >
        <section className={styles.container}>
          {loading && openTemplate ? (
            <FileLoader />
          ) : (
            <React.Fragment>
              <Document
                className={styles.document}
                file={displayTemplateBlob}
                onLoadError={(error) => console.log('PDF error:', error)}
                onLoadSuccess={onDocumentLoad}
                loading={<div className='loading-Container' />}
              >
                {totalPages &&
                  Array(totalPages)
                    .fill(null)
                    .map((_, index) => (
                      <Page
                        className={styles.page}
                        key={`page_${index + 1}`}
                        height={pageHeight && pageHeight - MARGIN_PAGE}
                        width={pageWidth && pageWidth - MARGIN_PAGE}
                        pageNumber={index + 1}
                        loading={<div className='loading-Container' />}
                      />
                    ))}
              </Document>
            </React.Fragment>
          )}
          <section className={styles.optionsContainer}>
            <Grid className={styles.previewDownloadGrid}>
              <GridColumn only='tablet mobile' className={`${styles.rowOptions} ${styles.smallDevices}`}>
                <HighLightColor
                  defaultColor={highLightColor || templateColors.red}
                  onChange={(color: string) => {
                    setHightLightColor(color);
                  }}
                />
                <ResumeOptions
                  resumeId={_id}
                  name={fullName}
                  email={email}
                  picture={pictures ? pictures.systemPicture : ''}
                  isHidden={isHidden}
                />
              </GridColumn>
            </Grid>
            <div className={styles.options}>
              {memoizedAllTemplates}
              <Grid>
                <GridColumn only='computer' className={`${styles.rowOptions} ${styles.computerDevice}`}>
                  <HighLightColor
                    defaultColor={highLightColor || templateColors.red}
                    onChange={(color: string) => {
                      setHightLightColor(color);
                    }}
                  />
                  <ResumeOptions
                    resumeId={_id}
                    name={fullName}
                    email={email}
                    picture={pictures ? pictures.systemPicture : ''}
                    isHidden={isHidden}
                  />
                </GridColumn>
              </Grid>
              <Button
                className={styles.button}
                onClick={downloadPDFDocument}
                active={!downloading}
                primary
              >
                <DownloadIcon className={styles.icon} strokeWidth='2' />
                Download
              </Button>
            </div>
          </section>
        </section>
      </Modal>
    </React.Fragment>
  );
};

export default PreviewDownload;
