import { View, Text } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './AquaUtils';
import { styles } from './EducationSection.styles';
import { formatTextDate } from '../utils/formatTextDate';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { verifyStringLength } from '../utils/stringUtils';
import { IEducation } from '../../Models/IEducation';

interface IEducationProps {
  data: IEducation[];
  highLightColor?: string;
}

interface IEducationItemProps {
  item: IEducation;
  highLightColor?: string;
  index: number;
}

const MAX_LENG_INPUT = 55;

const Education: React.FC<IEducationItemProps> = ({ item, highLightColor, index }) => {
  return (
    <View>
      <View wrap={false}>
        {index === 0 && (
          <Text style={{ ...styles.sectionTitle, color: getColors(highLightColor).sectionTitle }}>
            Education
          </Text>
        )}
        <View>
          <Text style={{ ...styles.date, color: getColors(highLightColor).date }}>
            {formatTextDate(item.startDate, item.endDate)}
          </Text>
          <Text style={styles.career}>{verifyStringLength(item.career, MAX_LENG_INPUT)}</Text>
          <Text style={styles.school}>{verifyStringLength(item.school, MAX_LENG_INPUT)}</Text>
        </View>
      </View>
      <View style={styles.descriptionContainer}>
        <HTMLContent data={item.description} />
      </View>
    </View>
  );
};

const EducationSection: React.FC<IEducationProps> = ({ data, highLightColor }) => {
  const existsData = data.length > 0 || null;
  return (
    existsData && (
      <View style={styles.sectionContainer}>
        {data.map((item, index) => (
          <Education
            item={item}
            key={`educationItem-${index}`}
            index={index}
            highLightColor={highLightColor}
          />
        ))}
      </View>
    )
  );
};

export default EducationSection;
