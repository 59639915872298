export const getIndexToFirstLine = (
  items: string[],
  sizePerLetter: number,
  sizePerItem: number,
  widthLine: number
): number => {
  const itemsLength = items.length - 1;
  let itemsfirstLine = 0;
  let indexLine = itemsLength;
  items.forEach((item, index) => {
    const value = item.length * sizePerLetter + sizePerItem;
    if (itemsfirstLine + value > widthLine && indexLine === itemsLength) {
      indexLine = index;
    }
    itemsfirstLine += value;
  });

  return indexLine;
};
