import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

import styles from './DocumentViewer.module.scss';

interface IPageViewerProps {
  blob?: Blob | null;
}

const DocumentViewer: React.FC<IPageViewerProps> = ({ blob }) => {
  const [totalPages, setTotalPages] = useState<number>();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  const onDocumentLoad = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
  };

  return (
    <Document
      file={blob}
      onLoadError={(error) => console.log('PDF error:', error)}
      onLoadSuccess={onDocumentLoad}
      loading={<div className={styles.loadingContainer} />}
      className='document-viewer'
    >
      {totalPages &&
        Array.from(new Array(totalPages), (el, index) => (
          <Page
            scale={2}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            loading={<div className={styles.loadingContainer} />}
          />
        ))}
    </Document>
  );
};

export default DocumentViewer;
