import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './LanguageSection.styles';
import TitleSection from './Title';
import { ILanguage } from '../../Models/ILanguages';

interface ILanguagesProps {
  languages: ILanguage[];
  title: string;
  highLightColor: string;
}

interface ILanguageProps {
  language: ILanguage;
  title: string | false;
  highLightColor: string;
}

interface ILevelProps {
  level: string;
  highLightColor: string;
  id: string;
}

const WEB_ICON = '/images/web.png';

const Level: React.FC<ILevelProps> = ({ level, highLightColor, id }) => {
  const getIconLevel = (level: number, id: string) => {
    const newLevelArray = [];
    for (let index = 0; index < level; index++) {
      newLevelArray.push(
        <View key={`language-${id}-${index}`} style={styles(highLightColor).lineLevel} />
      );
    }
    return newLevelArray;
  };

  const getIconListLevel = () => {
    const levels = [
      <View key={`language-level-beginner-default-${id}`} style={styles().lineLevelGray} />,
      <View key={`language-level-intermediate-default-${id}`} style={styles().lineLevelGray} />,
      <View key={`language-level-advanced-default-${id}`} style={styles().lineLevelGray} />,
      <View key={`language-level-native-default-${id}`} style={styles().lineLevelGray} />
    ];
    switch (level) {
      case 'beginner':
        [levels[0]] = getIconLevel(1, id);
        break;
      case 'intermediate':
        [levels[0], levels[1]] = getIconLevel(2, id);
        break;
      case 'advanced':
        [levels[0], levels[1], levels[2]] = getIconLevel(3, id);
        break;
      case 'native':
        [levels[0], levels[1], levels[2], levels[3]] = getIconLevel(4, id);
        break;
      default:
        break;
    }
    return levels;
  };

  return (
    <View style={styles().lineLevelContainer}>
      <React.Fragment>
        <View style={{ display: 'flex', flexDirection: 'row' }}>{getIconListLevel()}</View>
      </React.Fragment>
    </View>
  );
};

const Language: React.FC<ILanguageProps> = ({ language, title, highLightColor }) => {
  const { language: newLanguage, listening, reading, speaking, writing } = language;
  const isNative = [listening, reading, speaking, writing].every((item) => item === 'native');
  const languageName = newLanguage.replace(/\s+/g, ' ').trim();

  return (
    <View wrap={false}>
      {title && (
        <TitleSection
          urlImage={WEB_ICON}
          text={title}
          containerMarginLeft={'0'}
          highLightColor={highLightColor}
        />
      )}
      <View style={styles().languageContainer}>
        <View style={styles().subTitleContainer}>
          <View style={styles().subTitle}>
            <Text style={[styles().language, styles().ellipsis]}>{languageName}</Text>
            {isNative && <Text style={styles().native}>Native</Text>}
          </View>
        </View>
        <React.Fragment>
          <View style={styles().levelContainer}>
            <Text style={styles().levelText}>Reading</Text>
            <Level level={reading} highLightColor={highLightColor} id={`${languageName}-reading`} />
          </View>
          <View style={styles().levelContainer}>
            <Text style={styles().levelText}>Listening</Text>
            <Level level={listening} highLightColor={highLightColor} id={`${languageName}-listening`} />
          </View>
          <View style={styles().levelContainer}>
            <Text style={styles().levelText}>Writing</Text>
            <Level level={writing} highLightColor={highLightColor} id={`${languageName}-writing`} />
          </View>
          <View style={styles().levelContainer}>
            <Text style={styles().levelText}>Speaking</Text>
            <Level level={speaking} highLightColor={highLightColor} id={`${languageName}-speaking`} />
          </View>
        </React.Fragment>
      </View>
    </View>
  );
};

const Languages: React.FC<ILanguagesProps> = ({ languages, title, highLightColor }) => {
  const languagesCmpts = languages.reduce((result: Array<JSX.Element>, language, index) => {
    result.push(
      <Language
        key={index}
        language={language}
        title={result.length === 0 && title}
        highLightColor={highLightColor}
      />
    );
    return result;
  }, []);

  return <View style={styles().languagesContainer}>{languagesCmpts}</View>;
};

export default Languages;
