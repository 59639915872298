import { capitalize } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DropdownItemProps } from 'semantic-ui-react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import SharedPDF, { IOptionTemplate } from './SharedPDF';
import './SharedResumeForm.scss';
import SharedOption from './SharedOption';
import { showToastMessage, ToastEnum } from '../../../Toast/Toast';
import { GroupOutlineIcon } from '../../../../Assets/CustomIcons';
import { TemplatePDF } from '../../../../constants/globalConstants';
import { ISharedResumeForm } from '../../../../Models/IResumeForm';
import { ISharedFormOption } from '../../../../Models/ISharedFormOption';
import { addSharedResumes } from '../../../../redux/actions/resumeActions';
import { RootStore } from '../../../../redux/store';
import { ResumeService } from '../../../../services/resumes/resume.service';
import { ResumeOptionsService } from '../../../../services/resumes/resumeOptions.service';
import { isValid } from '../../../../utils/validation/formRules';
import { equalsArray } from '../../../../utils/utils';

const resumeOptionsService = ResumeOptionsService.getInstance();
const resumeService = ResumeService.getInstance();
interface ISharedFormState {
  options?: ISharedFormOption[];
  disabledSave: boolean;
  optionsApp: [];
  optionsAdded: [];
  open: boolean;
}

interface IOption {
  key: string;
  value: string;
  text: string;
  content: JSX.Element;
}

enum ActionKind {
  Select = 'SELECT',
  Add = 'ADD',
  Open = 'OPEN',
  Save = 'SAVE',
  SetOptions = 'SET_OPTIONS',
  DisableButton = 'DISABLE_BUTTON'
}

type Action = {
  type: ActionKind;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  payload: any;
};

const validationType = 'email';
const maxInputLength = '55';

const resumePdfExported = (resumeId: string) => [
  {
    label: capitalize(TemplatePDF.CLASSIC),
    resume: {
      id: resumeId,
      template: TemplatePDF.CLASSIC
    }
  },
  {
    label: capitalize(TemplatePDF.CORPORATE),
    resume: {
      id: resumeId,
      template: TemplatePDF.CORPORATE
    }
  },
  {
    label: capitalize(TemplatePDF.MODERN),
    resume: {
      id: resumeId,
      template: TemplatePDF.MODERN
    }
  },
  {
    label: capitalize(TemplatePDF.AQUA),
    resume: {
      id: resumeId,
      template: TemplatePDF.AQUA
    }
  },
  {
    label: capitalize(TemplatePDF.FORMAL),
    resume: {
      id: resumeId,
      template: TemplatePDF.FORMAL
    }
  },
  {
    label: capitalize(TemplatePDF.PLAIN),
    resume: {
      id: resumeId,
      template: TemplatePDF.PLAIN
    }
  }
];

const formatUserOptions = (data: DropdownItemProps[]): ISharedFormOption[] => {
  return data.map((user: DropdownItemProps) => ({
    key: user.email as string,
    value: user.email as string,
    text: user.name as string,
    content: <SharedOption name={user.name} email={user.email} picture={user.picture} />
  }));
};

const SharedResumeReducer = (state: ISharedFormState, action: Action): ISharedFormState => {
  const { type, payload } = action;
  const actionReduce = {
    [ActionKind.Select]: {
      ...state,
      currentValues: payload.currentValues,
      disabledSave: payload.disabledSave
    },
    [ActionKind.Add]: {
      ...state,
      options: payload.options,
      optionsAdded: payload.optionsAdded
    },
    [ActionKind.SetOptions]: {
      ...state,
      options: payload.options,
      currentValues: payload.currentValues,
      optionsAdded: payload.optionsAdded,
      optionsApp: payload.optionsApp
    },
    [ActionKind.Open]: {
      ...state,
      open: payload.open
    },
    [ActionKind.Save]: {
      ...state,
      open: payload.open
    },
    [ActionKind.DisableButton]: {
      ...state,
      disabledSave: payload.disabled
    }
  };
  return actionReduce[type];
};

const initSharedFormState: ISharedFormState = {
  options: [],
  disabledSave: true,
  optionsApp: [],
  optionsAdded: [],
  open: false
};

const SharedResumeForm: React.FC = () => {
  const [state, dispatchState] = useReducer(SharedResumeReducer, initSharedFormState);
  const [newOptions, setNewOptions] = useState<Array<IOption>>([]);
  const { disabledSave, options, open } = state;

  const { resume } = useSelector((state: RootStore) => state);
  const { resumeId } = resume;
  const {
    personalInformation: { email },
    sharedWith
  } = resume.resumeFormData;
  const reduxDispatch = useDispatch();

  const sharePDF = resumePdfExported(resumeId);
  const history = useHistory();

  const enabledShare = resumeId !== '' && email !== '';

  const getData = async () => {
    const { data } = await resumeOptionsService.getMonCvUsersToShare();
    const formatData = formatUserOptions(data.users);
    const { app, temp } = filterOptions(formatData);
    dispatchState({
      type: ActionKind.SetOptions,
      payload: { options: formatData, optionsApp: app, optionsAdded: temp, currentValues: sharedWith }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    moveMenu();
  }, [sharedWith.length]);

  const onSearchChange = () => {
    moveMenu();
  };

  const onMountModal = () => {
    const elementInput = document.querySelector<HTMLElement>('#shared-resume-items input.search');
    elementInput && elementInput.setAttribute('maxLength', maxInputLength);
  };

  const filterOptions = (options: ISharedFormOption[] = []) => {
    const usersApp: ISharedFormOption[] = [];
    const usersTemp = options.filter((user) => {
      const condition = user.text.includes('@');
      if (!condition) {
        usersApp.push(user);
      }
      return condition;
    });
    return { app: usersApp, temp: usersTemp };
  };

  const saveData = async () => {
    try {
      dispatchState({ type: ActionKind.DisableButton, payload: { disabled: true } });
      const data = sharedWith;
      const updatedDate = new Date();
      reduxDispatch(addSharedResumes(data, updatedDate));
      const resumeToUpdate: ISharedResumeForm = {
        email: email,
        sharedWith: data,
        updatedDate: updatedDate
      };
      await resumeService.updateSharedResume(resumeToUpdate, resumeId);
      dispatchState({ type: ActionKind.Save, payload: { open: false } });
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data.statusCode === 401) {
          showToastMessage('Access mode to CV has been removed', ToastEnum.ERROR);
          history.push('/Dashboard');
          return;
        }
      }
      showToastMessage('Something wrong, please try again', ToastEnum.ERROR);
      dispatchState({ type: ActionKind.DisableButton, payload: { disabled: false } });
    }
  };

  const moveMenu = () => {
    const elementInput = document.querySelector<HTMLElement>('#shared-resume-items input.search');
    const elementMenu = document.querySelector<HTMLElement>('#shared-resume-items .menu');
    if (elementMenu && elementInput) {
      const newleft = sharedWith.length === 0 ? 0 : elementInput.offsetLeft;
      elementMenu.style.left = `${newleft}px`;
    }
  };

  const handleOnchange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    propsOption: DropdownProps
  ) => {
    const controlValue = propsOption.value as string[];
    const validValue =
      isValid(controlValue[controlValue.length - 1], validationType) || controlValue.length === 0;
    if (validValue) {
      const updatedDate = new Date();
      const newChanges = equalsArray(controlValue as [], sharedWith as []);
      reduxDispatch(addSharedResumes(controlValue, updatedDate));
      dispatchState({
        type: ActionKind.Select,
        payload: { currentValues: controlValue, disabledSave: newChanges }
      });
    }
  };

  const getOptions = () => {
    if (options && options.length) {
      return [...newOptions, ...options];
    }
    if (newOptions && newOptions.length) {
      return newOptions;
    }
    return [];
  };

  const addNewItem = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const options = [...newOptions];
    const email = data.value ? data.value.toString() : '';
    options.push({
      key: data.value,
      text: data.value,
      value: data.value,
      content: <SharedOption name={email} email={email} />
    } as IOption);
    setNewOptions(options);
  };

  return (
    <Modal
      className='modal-container'
      closeIcon
      trigger={
        enabledShare ? (
          <GroupOutlineIcon className={`share-icon ${sharedWith.length > 0 ? 'active-icon' : ''}`} />
        ) : (
          <Popup
            content='Please first save your full name and email on your resume'
            inverted
            style={{ opacity: 0.5 }}
            position='left center'
            trigger={<GroupOutlineIcon className='share-icon' />}
          />
        )
      }
      dimmer={'inverted'}
      onMount={onMountModal}
      open={open}
      onClose={() => dispatchState({ type: ActionKind.Open, payload: { open: false } })}
      onOpen={() => dispatchState({ type: ActionKind.Open, payload: { open: true } })}
    >
      <Header className='header-title'>Shared with:</Header>
      <Modal.Content>
        <Form.Field className='shared-resume-control'>
          <div className='label-shared'>
            <label>User names</label>
          </div>
          <Dropdown
            id={'shared-resume-items'}
            value={sharedWith}
            onChange={handleOnchange}
            onClick={moveMenu}
            onSearchChange={onSearchChange}
            options={getOptions()}
            multiple
            search
            fluid
            inline
            selection
            floating
            allowAdditions
            onAddItem={addNewItem}
          />
        </Form.Field>
        {sharePDF.length !== 0 && <SharedPDF options={sharePDF} />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          className='shared-button'
          onClick={saveData}
          disabled={disabledSave}
          data-testid='shared-button-id'
        >
          <GroupOutlineIcon className='group-icon icon' strokeWidth='0.8' />
          <span className='text-button'>Save</span>
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default React.memo(SharedResumeForm);
