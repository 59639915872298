export interface ISkill {
  [key: string]: string | boolean;
  area: string;
  level: string;
  skill: string;
  enabled: boolean;
  highLightColor: string;
}

export enum SkillFields {
  AREA = 'AREA',
  SKILL = 'SKILL'
}
