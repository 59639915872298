import { templateColors } from '../../templates/utils/constants';

export const colorOptions = [
  {
    selected: templateColors.red,
    cssColor: '#D20000'
  },
  {
    selected: templateColors.blue,
    cssColor: '#03ABFB'
  },
  {
    selected: templateColors.aqua,
    cssColor: '#0EC4A9'
  },
  {
    selected: templateColors.gray,
    cssColor: '#949494'
  }
];
