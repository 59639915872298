import { View, Text } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './AquaUtils';
import { styles } from './LanguageSection.styles';
import { LanguageLevels, languageLevels } from '../utils/constants';
import { SquareLevel } from '../utils/SquareLevel/SquareLevel';
import { ILanguage } from '../../Models/ILanguages';

interface ILanguagesProps {
  data: ILanguage[];
  highLightColor?: string;
}

interface ILanguageProps {
  data: ILanguage;
  highLightColor?: string;
  index: number;
}

const LEVELS_NUMBER = 4;
const WIDTH_SQUARE = '26.61';
const HEIGHT_SQUARE = '5.2725';

const Language: React.FC<ILanguageProps> = ({ data, highLightColor, index }) => {
  const { language, listening, reading, speaking, writing } = data;
  const languageName = language.replace(/\s+/g, ' ').trim();
  const languageSkills = [
    {
      skillTitle: 'Reading',
      level: LanguageLevels[reading as keyof typeof LanguageLevels]
    },
    {
      skillTitle: 'Listening',
      level: LanguageLevels[listening as keyof typeof LanguageLevels]
    },
    {
      skillTitle: 'Writing',
      level: LanguageLevels[writing as keyof typeof LanguageLevels]
    },
    {
      skillTitle: 'Speaking',
      level: LanguageLevels[speaking as keyof typeof LanguageLevels]
    }
  ];
  const nativeSkills = languageSkills.every((item) => item.level === LanguageLevels.native);

  return (
    <View wrap={false} style={styles.languagesContainer}>
      {index === 0 && (
        <Text style={{ ...styles.sectionTitle, color: getColors(highLightColor).sectionTitle }}>
          Languages
        </Text>
      )}
      <View wrap={false} style={styles.language}>
        <View wrap={false} style={styles.headerLanguage}>
          <Text style={{ ...styles.languageTitle, color: getColors(highLightColor).languageTitle }}>
            {languageName}
          </Text>
          {nativeSkills && <Text style={styles.native}>(Native)</Text>}
        </View>
        {!nativeSkills && (
          <View style={styles.box}>
            {languageSkills.map((item, index) => (
              <View style={styles.content} key={`languageSkills-${index}`}>
                <Text style={styles.languageType}>{item.skillTitle}</Text>
                <View style={styles.squareLevel}>
                  <SquareLevel
                    width={WIDTH_SQUARE}
                    height={HEIGHT_SQUARE}
                    level={item.level}
                    levelsNumber={LEVELS_NUMBER}
                    primaryColor={getColors(highLightColor).squareLevel}
                  />
                </View>
              </View>
            ))}
          </View>
        )}
      </View>
    </View>
  );
};

const LevelsDetail: React.FC<{ highLightColor?: string }> = ({ highLightColor }) => {
  return (
    <View style={styles.detailContainer} wrap={false}>
      {languageLevels.map((item, index) => (
        <View key={`indicatorLevel-${index}`} style={styles.contentLevel}>
          <View style={styles.indicator}>
            <SquareLevel
              width={WIDTH_SQUARE}
              height={HEIGHT_SQUARE}
              level={item.level}
              levelsNumber={LEVELS_NUMBER}
              primaryColor={getColors(highLightColor).squareLevel}
            />
          </View>
          <View>
            <Text style={styles.levelName}>{item.levelName}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const LanguageSection: React.FC<ILanguagesProps> = ({ data, highLightColor }) => {
  const existsData = data.length > 0 || null;
  return (
    existsData && (
      <View style={styles.sectionContainer}>
        {data.map((item, index) => (
          <Language
            key={`languageItem-${index}`}
            data={item}
            highLightColor={highLightColor}
            index={index}
          />
        ))}
        <LevelsDetail highLightColor={highLightColor} />
      </View>
    )
  );
};

export default LanguageSection;
