import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-scroll';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import GridColumn from 'semantic-ui-react/dist/commonjs/collections/Grid/GridColumn';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';

import styles from './LeftSideBar.module.scss';
import { handleShowMenu } from '../../../HamburgerMenu/Hamburger';
import PreviewDownload from '../../../PreviewDownload/PreviewDownload';
import { CheckCircle, NumberCircleIcon, AlertCircle, Eye } from '../../../../Assets/CustomIcons';
import { SectionId } from '../../../../constants/sectionIDs';
import { SectionStatus, ResumeKeySection } from '../../../../Models/FormResumeSection';
import { RootStore } from '../../../../redux/store';
import { setSection } from '../../../../redux/actions/resumeActions';

export const numberIcons = {
  personalInformation: <NumberCircleIcon className={`${styles.icon} ${styles.numberIcon}`} text='1' />,
  skills: <NumberCircleIcon className={`${styles.icon} ${styles.numberIcon}`} text='2' />,
  workExperiences: <NumberCircleIcon className={`${styles.icon} ${styles.numberIcon}`} text='3' />,
  educations: <NumberCircleIcon className={`${styles.icon} ${styles.numberIcon}`} text='4' />,
  languages: <NumberCircleIcon className={`${styles.icon} ${styles.numberIcon}`} text='5' />
};

const getIcon = (sectionState: SectionStatus, sectionName: ResumeKeySection) => {
  switch (sectionState) {
    case SectionStatus.SUCCESS: {
      return <CheckCircle className={`${styles.icon} ${styles.checkIcon}`} />;
    }
    case SectionStatus.ERROR: {
      return <AlertCircle className={`${styles.icon} ${styles.warningIcon}`} />;
    }
    default: {
      return numberIcons[sectionName];
    }
  }
};

const LeftProgressSideBar: React.FC = () => {
  const {
    currentSection,
    personalInformationStatus,
    skillStatus,
    workExperienceStatus,
    educationStatus,
    languageStatus,
    resumeFormData
  } = useSelector((state: RootStore) => state.resume);
  const dispatch = useDispatch();

  const progressBarItems = [
    {
      title: 'Personal Information',
      linkTo: SectionId.PERSONAL_INFORMATION_SECTION,
      status: personalInformationStatus
    },
    {
      title: 'Skills',
      linkTo: SectionId.SKILL_SECTION,
      status: skillStatus
    },
    {
      title: 'Work Experiences',
      linkTo: SectionId.WORK_EXPERIENCE_SECTION,
      status: workExperienceStatus
    },
    {
      title: 'Educations',
      linkTo: SectionId.EDUCATION_SECTION,
      status: educationStatus
    },
    {
      title: 'Languages',
      linkTo: SectionId.LANGUAGE_SECTION,
      status: languageStatus
    }
  ];

  const getProgresItemActiveStyle = (item: SectionId) => {
    return currentSection.sectionId === item ? styles.active : '';
  };

  const memoizedProgressBarItems = useMemo(() => {
    return progressBarItems.map((item) => {
      return (
        <GridRow className={styles.menuItem} key={item.title}>
          <GridColumn mobile={2} className={item.title !== 'Languages' ? styles.menuItemIcon : ''}>
            {getIcon(item.status, item.linkTo)}
          </GridColumn>
          <GridColumn mobile={14} className={styles.menuItemTitle}>
            <Link
              activeClass='active'
              to={item.linkTo}
              smooth={true}
              duration={500}
              onClick={() => handleClick(item.linkTo)}
              className={getProgresItemActiveStyle(item.linkTo)}
              containerId='form-sections'
            >
              <span>{item.title}</span>
            </Link>
          </GridColumn>
        </GridRow>
      );
    });
  }, [progressBarItems]);

  const handleClick = (section: SectionId) => {
    dispatch(setSection(section));
    handleShowMenu(false);
  };

  return (
    <React.Fragment>
      <Grid className={styles.sidebarContainer}>{memoizedProgressBarItems}</Grid>
      <div className={styles.sidebarActions}>
        <PreviewDownload>
          <Button className={styles.button} primary>
            <Eye className={styles.icon} />
            <span>Preview and Download</span>
          </Button>
        </PreviewDownload>
      </div>
    </React.Fragment>
  );
};

export default LeftProgressSideBar;
