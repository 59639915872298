import { Dispatch } from 'redux';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { v4 as uuidv4 } from 'uuid';

import {
  SET_RESUME,
  ResumeDispatchTypes,
  UPDATE_PROGRESS_STATUS,
  SET_SECTION_STATUS,
  SET_SECTION,
  SAVING,
  UPDATE_RESUME,
  SAVE_SECTION_DATA,
  CREATE_RESUME,
  FINISH_SAVING,
  CLEAN_RESUME,
  SET_UPDATED,
  ADD_SHARED_RESUMES,
  DELETE_RESUME,
  FINISH_LOADING,
  UPLOAD_PHOTO,
  NAME_OPTIONS,
  ADD_NAME_OPTION,
  HIDE_ONE_RESUME
} from './resumeActionTypes';
import { UpdateSection } from '../reducers/resumeReducer';
import { resumeSectionsDistribution } from '../../constants/resumeSectionsDistribution';
import { SectionId } from '../../constants/sectionIDs';
import {
  IResumeSectionToUpdate,
  ISection,
  ResumeKeySection,
  SectionStatus
} from '../../Models/FormResumeSection';
import { IResumeForm } from '../../Models/IResumeForm';
import store from '../../redux/store';
import { ResumeService } from '../../services/resumes/resume.service';
import { combineName } from '../../utils/utils';
import { calculateProgressStatus } from '../../utils/validation/formRules';

const resumeService = ResumeService.getInstance();

export const getResume = (id: string) => async (dispatch: Dispatch<ResumeDispatchTypes>) => {
  const response = await resumeService.getResumeById(id);
  const { givenName, familyName, fullName } = response.data.personalInformation;
  const onlyName = givenName?.split(' ');
  const onlyLastName = familyName?.split(' ');
  const resultOptions = givenName && familyName ? [...combineName(onlyName, onlyLastName)] : [fullName];
  const result = resultOptions.includes(fullName) ? resultOptions : [...resultOptions, fullName];
  const nameOptions: DropdownItemProps[] = result.map((name) => ({
    value: name,
    text: name,
    key: uuidv4()
  }));
  dispatch({
    type: NAME_OPTIONS,
    payload: {
      nameOptions
    }
  });
  dispatch({
    type: SET_RESUME,
    payload: {
      resumeFormData: response.data,
      resumeId: response.data._id
    }
  });
};

export const updateProgressStatus = (statusBySection: SectionStatus[]) => async (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  const newProgressStatus = calculateProgressStatus(statusBySection);
  dispatch({
    type: UPDATE_PROGRESS_STATUS,
    payload: {
      progressStatus: newProgressStatus
    }
  });
};

export const setSectionStatus = (sectionId: SectionId, sectionStatus: SectionStatus) => (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  const statusUpdate = {
    [SectionId.PERSONAL_INFORMATION_SECTION]: {
      personalInformationStatus: sectionStatus
    },
    [SectionId.SKILL_SECTION]: { skillStatus: sectionStatus },
    [SectionId.WORK_EXPERIENCE_SECTION]: { workExperienceStatus: sectionStatus },
    [SectionId.EDUCATION_SECTION]: { educationStatus: sectionStatus },
    [SectionId.LANGUAGE_SECTION]: { languageStatus: sectionStatus }
  }[sectionId];
  dispatch({
    type: SET_SECTION_STATUS,
    payload: statusUpdate
  });
};

export const setSection = (sectionId: SectionId) => (dispatch: Dispatch<ResumeDispatchTypes>) => {
  const newSection = resumeSectionsDistribution.get(sectionId) as ISection;
  dispatch({
    type: SET_SECTION,
    payload: {
      currentSection: newSection
    }
  });
};

export const saveSectionData = (resumeFormData: IResumeForm, updated: UpdateSection) => (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  dispatch({
    type: SAVE_SECTION_DATA,
    payload: {
      resumeFormData,
      updated
    }
  });
};

export const updateResume = (resumeFormData: IResumeForm, sectionsToSave: [string, boolean][]) => async (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  dispatch({ type: SAVING, payload: { saving: true } });
  const newUpdated: UpdateSection = {};
  const sectionsToUpdate = sectionsToSave.map(([keySection, value]) => {
    const sectionForm: IResumeSectionToUpdate = {
      progressStatus: resumeFormData.progressStatus,
      updatedDate: new Date(),
      sectionData: {
        [keySection]: resumeFormData[keySection as SectionId]
      }
    };
    newUpdated[keySection as SectionId] = !value;
    const resumeState = store.getState().resume;
    const resumeId = resumeFormData._id ? resumeFormData._id : resumeState.resumeId;
    return resumeService.updateResumeBySection(sectionForm, resumeId as string, keySection);
  });
  const response = await Promise.all(sectionsToUpdate);
  dispatch({
    type: UPDATE_RESUME,
    payload: {
      updated: { ...newUpdated }
    }
  });
};

export const createResume = (
  { _id, ...restResumeData }: IResumeForm,
  sectionsToSave: [string, boolean][]
) => async (dispatch: Dispatch<ResumeDispatchTypes>) => {
  dispatch({ type: SAVING, payload: { saving: true } });
  const response = await resumeService.createResume(restResumeData);
  console.log('Successfully created resume: ', response.data);
  dispatch({
    type: CREATE_RESUME,
    payload: {
      resumeId: response.data._id,
      updated: {
        ...Object.fromEntries(
          sectionsToSave.map(([key, value]) => {
            value = false;
            return [key, value];
          })
        )
      }
    }
  });
};

export const finishSaving = () => async (dispatch: Dispatch<ResumeDispatchTypes>) => {
  dispatch({
    type: FINISH_SAVING
  });
};

export const finishLoading = () => async (dispatch: Dispatch<ResumeDispatchTypes>) => {
  dispatch({
    type: FINISH_LOADING
  });
};

export const cleanResume = () => async (dispatch: Dispatch<ResumeDispatchTypes>) => {
  dispatch({
    type: CLEAN_RESUME
  });
};

export const setUpdated = (updated: UpdateSection) => async (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  dispatch({
    type: SET_UPDATED,
    payload: {
      updated
    }
  });
};

export const addSharedResumes = (data: string[], updatedDate: Date) => async (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  dispatch({
    type: ADD_SHARED_RESUMES,
    payload: {
      sharedWith: data,
      updatedDate: updatedDate
    }
  });
};

export const deleteResume = (newResumeFormData: IResumeForm, sectionForm: ResumeKeySection) => async (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  dispatch({
    type: DELETE_RESUME,
    payload: {
      resumeFormData: newResumeFormData,
      updated: {
        [sectionForm]: true
      }
    }
  });
};

export const uploadPhoto = (resumeId: string, base64Picture: string) => async (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  const updatedResume = await resumeService.uploadPicture(resumeId, base64Picture);
  const resumeData = updatedResume.data;
  dispatch({
    type: UPLOAD_PHOTO,
    payload: {
      resumeData: resumeData
    }
  });
};

export const addNameOption = (newName: string) => async (dispatch: Dispatch<ResumeDispatchTypes>) => {
  dispatch({
    type: ADD_NAME_OPTION,
    payload: {
      value: newName,
      text: newName,
      key: uuidv4()
    }
  });
};

export const hideResume = (resumeId: string, isHidden: boolean) => async (
  dispatch: Dispatch<ResumeDispatchTypes>
) => {
  try {
    const response = await resumeService.hideResume(resumeId, isHidden);
    dispatch({ type: HIDE_ONE_RESUME, payload: { id: resumeId, isHidden } });
  } catch (error) {
    alert(error);
  }
};
