import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';
import { getColors } from './PlainUtils';

export const styles = (textColor = templateColors.gray) => {
  const lineLevel = getColors(textColor).areaTitle;
  return StyleSheet.create({
    languagesContainer: {
      paddingTop: 15,
      fontFamily: 'Georama'
    },
    languageContainer: {
      marginBottom: 10
    },
    title: {
      fontSize: 14,
      fontFamily: 'Georama',
      color: '#D20000',
      marginBottom: 9
    },
    titleBlue: {
      fontSize: 13,
      color: '#1867AC',
      marginBottom: 9
    },
    language: {
      fontSize: 9,
      marginRight: 2
    },
    languageIcon: {
      height: 10,
      width: 10,
      marginRight: 3
    },
    levelText: {
      width: 38,
      fontSize: 8,
      textAlign: 'right'
    },
    levelContainer: {
      flexDirection: 'row',
      marginTop: 5
    },
    lineLevelContainer: {
      width: 74,
      marginLeft: 5,
      display: 'flex',
      justifyContent: 'center'
    },
    lineLevel: {
      marginTop: -2,
      height: 2,
      borderRadius: 1,
      backgroundColor: lineLevel,
      marginRight: 2,
      width: 20
    },
    lineLevelGray: {
      marginTop: -2,
      height: 2,
      borderRadius: 1,
      backgroundColor: '#E0E0E0',
      marginRight: 2,
      width: 20
    },
    subTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end'
    },
    subTitle: {
      flexDirection: 'row',
      fontSize: 9
    },
    native: {
      fontSize: 8,
      marginLeft: 3,
      paddingTop: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end'
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      maxLines: 3
    }
  });
};
