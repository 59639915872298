import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-scroll';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import GridColumn from 'semantic-ui-react/dist/commonjs/collections/Grid/GridColumn';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import styles from './BottomProgressBar.module.scss';
import SharedResumeForm from '../sharedResumeForm/SharedResumeForm';
import CustomTooltip, { PopUpPosition } from '../../../CustomTooltip/CustomTooltip';
import PreviewDownload from '../../../PreviewDownload/PreviewDownload';
import { ArrowDown, ArrowUp, CircleProgressBar, EyeIcon } from '../../../../Assets/CustomIcons';
import { progressAnimationSpeed } from '../../../../constants/globalConstants';
import { ResumeSections, SectionId } from '../../../../constants/sectionIDs';
import { SectionStatus } from '../../../../Models/FormResumeSection';
import { RootStore } from '../../../../redux/store';

interface IResumeState {
  title: ResumeSections;
  stateValue: SectionStatus;
  sectionId: SectionId;
}

interface IBottomProgressBarProps {
  setCurrentSection: (sectionId: SectionId) => void;
}

const maxPercentage = 90;

const BottomProgressBar: React.FC<IBottomProgressBarProps> = (props) => {
  const resumeState = useSelector((state: RootStore) => state.resume);
  const { setCurrentSection } = props;

  const [animating, setAnimating] = useState<boolean>(false);
  const [percentageSaving, setPercentageSaving] = useState(0);
  const [resumeStates, setResumeStates] = useState<IResumeState[]>([
    {
      title: ResumeSections.PERSONAL_INFORMATION,
      stateValue: resumeState.personalInformationStatus,
      sectionId: SectionId.PERSONAL_INFORMATION_SECTION
    },
    {
      title: ResumeSections.SKILL,
      stateValue: resumeState.skillStatus,
      sectionId: SectionId.SKILL_SECTION
    },
    {
      title: ResumeSections.WORK_EXPERIENCE,
      stateValue: resumeState.workExperienceStatus,
      sectionId: SectionId.WORK_EXPERIENCE_SECTION
    },
    {
      title: ResumeSections.EDUCATION,
      stateValue: resumeState.educationStatus,
      sectionId: SectionId.EDUCATION_SECTION
    },
    {
      title: ResumeSections.LANGUAGE,
      stateValue: resumeState.languageStatus,
      sectionId: SectionId.LANGUAGE_SECTION
    }
  ]);

  const initAnimation = () => {
    setAnimating(true);
    let percentageCopy = 0;
    const animationInterval = setInterval(() => {
      if (percentageSaving <= maxPercentage && percentageCopy <= maxPercentage) {
        setPercentageSaving((percentageSaving) => percentageSaving + 10);
        percentageCopy = percentageCopy + 10;
      } else {
        clearInterval(animationInterval);
        setPercentageSaving(0);
        setAnimating(false);
        percentageCopy = 0;
      }
    }, progressAnimationSpeed);
  };

  const watchSaving = () => {
    const { saving } = resumeState;
    saving && initAnimation();
  };

  const handleNextSectionClicked = () => {
    const {
      currentSection: { nextSectionId }
    } = resumeState;
    setCurrentSection(nextSectionId);
  };

  const handlePreviousSectionClicked = () => {
    const {
      currentSection: { previousSectionId }
    } = resumeState;
    setCurrentSection(previousSectionId);
  };

  useEffect(() => {
    watchSaving();
  }, [resumeState.saving]);

  useEffect(() => {
    setResumeStates([
      {
        title: ResumeSections.PERSONAL_INFORMATION,
        stateValue: resumeState.personalInformationStatus,
        sectionId: SectionId.PERSONAL_INFORMATION_SECTION
      },
      {
        title: ResumeSections.SKILL,
        stateValue: resumeState.skillStatus,
        sectionId: SectionId.SKILL_SECTION
      },
      {
        title: ResumeSections.WORK_EXPERIENCE,
        stateValue: resumeState.workExperienceStatus,
        sectionId: SectionId.WORK_EXPERIENCE_SECTION
      },
      {
        title: ResumeSections.EDUCATION,
        stateValue: resumeState.educationStatus,
        sectionId: SectionId.EDUCATION_SECTION
      },
      {
        title: ResumeSections.LANGUAGE,
        stateValue: resumeState.languageStatus,
        sectionId: SectionId.LANGUAGE_SECTION
      }
    ]);
  }, [
    resumeState.personalInformationStatus,
    resumeState.skillStatus,
    resumeState.workExperienceStatus,
    resumeState.educationStatus,
    resumeState.languageStatus
  ]);

  return (
    <Grid>
      <GridRow centered className={styles.progressBottomBarGrid}>
        <GridColumn only='mobile tablet' className={styles.progressBottomBarColumn}>
          <div className={styles.progressBottomBarContainer}>
            <div className={styles.bottomBarItem}>
              <CustomTooltip
                items={resumeStates}
                handleClick={setCurrentSection}
                updatedDate={resumeState.resumeFormData.updatedDate}
                resumeId={resumeState.resumeId}
                position={PopUpPosition.TOP_CENTER}
              >
                {!resumeState.saving && !animating ? (
                  <div>
                    <CircleProgressBar
                      className={`circle-${resumeState.resumeFormData.progressStatus} ${styles.circle}`}
                    >
                      {`${resumeState.resumeFormData.progressStatus.toString()}%`}
                    </CircleProgressBar>
                  </div>
                ) : (
                  <div>
                    <CircleProgressBar className={`circle-${percentageSaving} ${styles.circle}`}>
                      <div>
                        <Icon color='green' name='save' size='large' />
                      </div>
                    </CircleProgressBar>
                  </div>
                )}
              </CustomTooltip>
            </div>
            <div className={styles.bottomBarItem}>
              <Link
                activeClass='active'
                to={resumeState.currentSection.previousSectionId}
                spy={true}
                smooth={true}
                duration={500}
                containerId='form-sections'
              >
                <ArrowUp onClick={handlePreviousSectionClicked} />
              </Link>
            </div>
            <div className={styles.bottomBarItem}>
              <Link
                activeClass='active'
                to={resumeState.currentSection.nextSectionId}
                spy={true}
                smooth={true}
                duration={500}
                containerId='form-sections'
              >
                <ArrowDown onClick={handleNextSectionClicked} />
              </Link>
            </div>
            <div className={styles.bottomBarItem}>
              <SharedResumeForm />
            </div>
            <div className={styles.bottomBarItem}>
              <PreviewDownload>
                <EyeIcon />
              </PreviewDownload>
            </div>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default BottomProgressBar;
