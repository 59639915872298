import { Text, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';

interface SummaryProps {
  text: string;
  highLightColor: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    summaryContainer: {
      marginLeft: 22,
      paddingBottom: 8
    },
    title: {
      fontSize: 15,
      marginBottom: 10,
      maxHeight: 22,
      fontFamily: 'Helvetica Neue Light',
      color: textColor
    }
  });

const Summary: React.FC<SummaryProps> = (props) => {
  const { text, highLightColor } = props;

  return (
    <View style={styles().summaryContainer}>
      <Text style={{ ...styles(highLightColor).title }}>Summary</Text>
      <HTMLContent data={text} />
    </View>
  );
};

export default Summary;
