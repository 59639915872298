import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  sectionContainer: {
    left: 75.75,
    marginTop: 21.25
  },
  sectionTitle: {
    fontSize: 12,
    color: '#0079A3',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    marginBottom: 9
  },
  experienceContainer: {
    fontSize: 10,
    width: 458.25
  },
  date: {
    color: '#0079A3',
    fontSize: 9,
    fontFamily: 'Helvetica Neue',
    fontWeight: 'normal',
    marginBottom: 3
  },
  jobContainer: {
    flexDirection: 'row',
    fontFamily: 'Helvetica Neue Medium',
    fontWeight: 'bold',
    fontSize: 9,
    marginBottom: 1
  },
  divider: {
    marginTop: 2,
    marginLeft: 8,
    marginRight: 5,
    borderLeftColor: '#00000026',
    borderLeftWidth: 0.75,
    borderLeftStyle: 'solid',
    fontFamily: 'Helvetica Neue'
  },
  client: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 'medium',
    fontSize: 9,
    marginBottom: 4.5
  },
  skillContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  skillBox: {
    padding: 4,
    borderRadius: 4,
    marginTop: 5,
    marginRight: 5,
    fontFamily: 'Helvetica Neue',
    fontSize: 7.5,
    backgroundColor: '#F5F5F5'
  },
  descriptionContainer: {
    marginTop: 7,
    fontFamily: 'Helvetica Neue',
    fontSize: 9,
    marginBottom: 12
  },
  ellipsis: {
    maxLines: 2,
    maxWidth: '47%',
    textOverflow: 'ellipsis'
  }
});
