import React from 'react';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import List from 'semantic-ui-react/dist/commonjs/elements/List';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import { Link } from 'react-scroll';

import styles from './CustomTooltip.module.scss';
import { CheckCircle, AlertCircle, NumberCircleIcon } from '../../Assets/CustomIcons';
import { SectionId } from '../../constants/sectionIDs';
import { SectionStatus } from '../../Models/FormResumeSection';

export interface IResumeSection {
  title: string;
  stateValue: SectionStatus;
  sectionId: SectionId;
}

export enum PopUpPosition {
  TOP_CENTER = 'top center',
  LEFT_CENTER = 'left center'
}

export interface ICustomTooltipProps {
  items?: IResumeSection[];
  handleClick(sectionId: SectionId): void;
  updatedDate: Date;
  resumeId: string;
  className?: string;
  position: PopUpPosition;
}

export const numberIcons = {
  personalInformation: <NumberCircleIcon text='1' />,
  skills: <NumberCircleIcon text='2' />,
  workExperiences: <NumberCircleIcon text='3' />,
  educations: <NumberCircleIcon text='4' />,
  languages: <NumberCircleIcon text='5' />
};

const CustomTooltip: React.FC<ICustomTooltipProps> = ({
  children,
  items = [],
  handleClick,
  updatedDate,
  resumeId,
  className,
  position
}) => {
  const [eventsEnabled] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const getSavedDateFormat = (): string => {
    let formatedDate = '';
    const savedDate = new Date(updatedDate);
    const hour = savedDate.getHours();
    const minute = savedDate.getMinutes();
    formatedDate = `${hour}:${minute <= 9 ? `0${minute}` : minute}`;
    return formatedDate;
  };

  const getSavedDateLabel = (): string => {
    if (resumeId) {
      return `Saved at ${getSavedDateFormat()}`;
    }
    return 'Not saved yet.';
  };

  return (
    <Popup
      basic
      trigger={children}
      offset={[60, 0]}
      position={position}
      eventsEnabled={eventsEnabled}
      on='click'
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      className={`${styles.containerTooltip} ${className}`}
    >
      <List>
        {items.map((item, index) => {
          return (
            <Link
              key={index}
              activeClass='active'
              to={item.sectionId}
              smooth={true}
              offset={5}
              duration={500}
              containerId='form-sections'
            >
              <List.Item onClick={() => handleClick(item.sectionId)} className={styles.containerMenu}>
                {item.stateValue ? (
                  <React.Fragment>
                    {item.stateValue === SectionStatus.SUCCESS ? (
                      <CheckCircle className={`${styles.progressIcon} success-icon`} />
                    ) : (
                      <AlertCircle className={`${styles.progressIcon} warning-icon`} />
                    )}
                  </React.Fragment>
                ) : (
                  <span className={styles.numberIconContainer}>{numberIcons[item.sectionId]}</span>
                )}
                <List.Content className={styles.menuTitle}> {item.title} </List.Content>
              </List.Item>
            </Link>
          );
        })}
        <Divider className={styles.tooltipDivider} />
        <span>
          <List.Item className={styles.containerMenu}>
            <List.Icon name='circle' className={styles.hide} />
            <List.Content className={styles.menuTitle}>
              <i> {getSavedDateLabel()} </i>
            </List.Content>
          </List.Item>
        </span>
      </List>
    </Popup>
  );
};

export default CustomTooltip;
