import { AxiosError } from 'axios';

import { showToastMessage, showToastMessageByErrorCode, ToastEnum } from '../Components/Toast/Toast';

export const OnRequestError = (error: AxiosError, actionMessage = '', action = () => {}) => {
  if (error.response) {
    let showHttpMessage = false;
    const { data } = error.response;
    showHttpMessage = showToastMessageByErrorCode(data.internalCode || data.statusCode);
    showHttpMessage || showToastMessage(`There was an error ${actionMessage}`, ToastEnum.ERROR);
    action();
    return;
  }
  if (error.request) {
    showToastMessage('Something went wrong, the application failed', ToastEnum.ERROR);
    return;
  }
  showToastMessage(error.message, ToastEnum.ERROR);
};
