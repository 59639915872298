import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './SkillSection.styles';
import { SkillLevels } from '../utils/constants';
import { ISkill } from '../../Models/ISkills';
import { verifyStringLength, wordBreak } from '../utils/stringUtils';
import { RectangleLevel } from '../utils/RectangleLevel/RectangleLevel';

interface ISkillsProps {
  data: ISkill[];
  highLightColor: string;
}

interface IAreaProps {
  title: string;
  skills: ISkill[];
  highLightColor: string;
}

interface ISkillProps {
  skill: string;
  level: string;
  highLightColor: string;
}

const MAX_STRING_LENGTH = 55;
const MAX_SKILL_WORD_LENGTH = 15;
const LEVELS_NUMBER = 5;

const groupSkillByArea = (data: ISkill[]) => {
  const groupedSkills = [];
  for (const skill of data) {
    const index = groupedSkills.findIndex(({ area }) => area === skill.area);
    if (index > -1) {
      groupedSkills[index].skills.push(skill);
    } else {
      groupedSkills.push({ area: skill.area, skills: [skill] });
    }
  }
  return groupedSkills;
};

const Skill: React.FC<ISkillProps> = ({ level, skill, highLightColor }) => {
  const levelValue: SkillLevels = SkillLevels[level as keyof typeof SkillLevels];
  const skillName = skill.split(/(\s+)/).map((word) => {
    return wordBreak(word, MAX_SKILL_WORD_LENGTH);
  });

  return (
    <View wrap={false} style={styles().content}>
      <View style={styles(5, highLightColor).levels}>
        <RectangleLevel
          levelsNumber={LEVELS_NUMBER}
          level={levelValue}
          highLightColor={highLightColor}
        />
      </View>
      <Text style={styles().skillName}>{skillName}</Text>
    </View>
  );
};

const Area: React.FC<IAreaProps> = ({ title, skills, highLightColor }) => {
  const firstSkill = skills.shift() as ISkill;
  return (
    <View style={styles().areaContainer}>
      <View wrap={false}>
        <Text style={styles().titleArea}>{verifyStringLength(title, MAX_STRING_LENGTH)}</Text>
        {firstSkill && (
          <Skill level={firstSkill.level} skill={firstSkill.skill} highLightColor={highLightColor} />
        )}
      </View>
      <View style={styles().box}>
        {skills.map((skill, index) => (
          <Skill
            key={`areaSkill-${index}`}
            level={skill.level}
            skill={skill.skill}
            highLightColor={highLightColor}
          />
        ))}
      </View>
    </View>
  );
};

const SkillSection: React.FC<ISkillsProps> = ({ data, highLightColor }) => {
  const skillsByArea = groupSkillByArea(data);
  const existsData = skillsByArea.length > 0 || null;

  return (
    existsData && (
      <View style={styles().sectionContainer}>
        <Text style={styles(0, highLightColor).sectionTitle}>Skills</Text>
        <View style={styles().skillsContainer}>
          {skillsByArea.map((item, index) => (
            <Area
              key={`skillItem-${index}`}
              title={item.area}
              skills={item.skills}
              highLightColor={highLightColor}
            />
          ))}
        </View>
      </View>
    )
  );
};

export default SkillSection;
