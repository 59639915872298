import { View } from '@react-pdf/renderer';
import React from 'react';

import CircleSeparator from './Divider';
import { styles } from './Summary.styles';
import TitleSection from './Title';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';

interface SummaryProps {
  text: string;
  highLightColor: string;
}

const AVATAR_ICON = '/images/avatar.png';

const Summary: React.FC<SummaryProps> = (props) => {
  const { text, highLightColor } = props;
  return (
    <View style={styles.summaryContainer}>
      <TitleSection
        urlImage={AVATAR_ICON}
        text={'SUMMARY'}
        containerMarginLeft={'1'}
        highLightColor={highLightColor}
      />
      <View style={styles.contentWrapper}>
        <CircleSeparator />
        <View style={styles.contentSection}>
          <HTMLContent data={text} />
        </View>
      </View>
    </View>
  );
};

export default Summary;
