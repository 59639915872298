import { View, Text, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './AquaUtils';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';

interface ISummaryProps {
  data: string;
  highLightColor?: string;
}

const styles = StyleSheet.create({
  summaryContainer: {
    left: 75.75,
    marginTop: 16
  },
  title: {
    fontSize: 12,
    color: '#0079A3',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    marginBottom: 9
  },
  content: {
    width: 458.25,
    fontFamily: 'Helvetica Neue',
    fontSize: 9
  }
});

const Summary: React.FC<ISummaryProps> = ({ data, highLightColor }) => {
  return (
    <View style={styles.summaryContainer}>
      <Text style={{ ...styles.title, color: getColors(highLightColor).title }}>Summary</Text>
      <View style={styles.content}>
        <HTMLContent data={data} />
      </View>
    </View>
  );
};

export default Summary;
