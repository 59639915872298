import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';

import './AppRouter.scss';
import ResumeFormContainer from '../Forms/ResumeForm/ResumeFormContainer/ResumeFormContainer';
import PageNotFound from '../PageNotFound/PageNotFound';
import ResumeGrid from '../ResumeDashboard/ResumeGrid/ResumeGrid';
import ResumePDFViewer from '../ResumePDFViewer/ResumePDFViewer';
import TopNavBar from '../TopNavBar/TopNavBar';
import { pathSharedViewer, Roles } from '../../constants/globalConstants';
import { RootStore } from '../../redux/store';

export const AppRouter: React.FC = () => {
  const [dashboardShouldUpdate, setDashboardShouldUpdate] = useState(false);
  const { userRole } = useSelector((state: RootStore) => state.dashboard).authData;

  const getNewResume = () => {
    return userRole === Roles.ADMIN ? <Redirect from='/resume' to='/resume/new' /> : null;
  };

  return (
    <React.Fragment>
      {userRole && (
        <Router>
          <Container className='main-container'>
            <TopNavBar />
            <Switch>
              <Route exact path='/dashboard'>
                <ResumeGrid dashboardShouldUpdate={dashboardShouldUpdate} />
              </Route>
              <Route exact path='/resume/:id'>
                <ResumeFormContainer
                  dashboardShouldUpdate={dashboardShouldUpdate}
                  setDashboardShouldUpdate={setDashboardShouldUpdate}
                />
              </Route>
              <Route exact path={`/${pathSharedViewer}/:id`} component={ResumePDFViewer} />
              {getNewResume()}
              <Route path='/notfound' component={PageNotFound} />
              <Redirect exact={true} from='/' to='/dashboard' />;
              <Route path='*' exact={true} component={PageNotFound} />
            </Switch>
          </Container>
        </Router>
      )}
    </React.Fragment>
  );
};
