import { Image, View, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

import { styles } from './Header.styles';
import { pictureToShow } from '../utils/helper';
import { verifyStringLength } from '../utils/stringUtils';
import { IPersonalInformation } from '../../Models/IPersonalInformation';

const LOGO_JALASOFT = '/images/jalasoftLogo.png';
const MAIL_ICON = '/images/mail.png';
const PHONE_ICON = '/images/phone.png';

interface IHeaderProps {
  personalInfo: IPersonalInformation;
  highLightColor: string;
}

const Header: React.FC<IHeaderProps> = ({ personalInfo, highLightColor }) => {
  const { pictures, fullName, mainJob, email, phoneNumber } = personalInfo;
  const validatedPicture = pictureToShow(pictures);
  const existsPhoneNumber = Boolean(phoneNumber);
  const styleWithPicture: Style = validatedPicture.displayPicture ? { width: 260.36 } : {};
  const stylesName = {
    ...styles().name,
    ...styleWithPicture
  };
  const stylesJob = {
    ...styles(highLightColor).job,
    ...styleWithPicture
  };
  const marginJob = mainJob !== '' ? { marginBottom: 9 } : { marginBottom: 2 };

  return (
    <>
      <View style={styles().logoContainer}>
        <Image src={LOGO_JALASOFT} />
      </View>
      <View style={styles().mainContainer}>
        <View style={styles().inforContainer}>
          {validatedPicture.displayPicture && (
            <View style={styles().avatarContainer}>
              <Image style={styles().userPhoto} src={validatedPicture.pictureToShow} />
            </View>
          )}
          <View>
            <Text style={stylesName}>{fullName}</Text>
            <Text style={[stylesJob, marginJob]}>{mainJob}</Text>
            <View style={styles().rowContainer}>
              <Image src={MAIL_ICON} style={styles().icon} />
              <Text style={styles().textRow}>{verifyStringLength(email, 55)}</Text>
            </View>
            {existsPhoneNumber && (
              <View style={styles().rowContainer}>
                <Image src={PHONE_ICON} style={styles().icon} />
                <Text style={styles().textRow}>{verifyStringLength(phoneNumber, 20)}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </>
  );
};

export default Header;
