import { StyleSheet } from '@react-pdf/renderer';

export const styles = (marginRightValue = 0) =>
  StyleSheet.create({
    sectionContainer: {
      left: 75.75
    },
    sectionTitle: {
      fontSize: 12,
      color: '#0079A3',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      marginBottom: 9,
      marginTop: 9
    },
    skillsContainer: {
      position: 'relative',
      width: 457.5
    },
    areaContainer: {
      backgroundColor: '#BFBFBF33',
      borderLeft: '3.75 solid #DAE6EA',
      marginBottom: 6.75,
      paddingLeft: 12.75
    },
    titleArea: {
      color: '#0079A3',
      fontSize: 9,
      fontWeight: 'bold',
      fontFamily: 'Helvetica Neue Bold',
      marginTop: 5
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 6.75,
      marginBottom: 8.25
    },
    skillName: {
      fontSize: 9,
      fontWeight: 'medium',
      fontFamily: 'Helvetica Neue',
      marginRight: 9
    },
    detailContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 5,
      marginTop: 12
    },
    contentLevel: {
      flexDirection: 'column',
      marginRight: 22
    },
    detailName: {
      fontSize: 7.5,
      fontFamily: 'Helvetica Neue',
      paddingRight: 4
    },
    levelDetails: {
      fontSize: 6.75,
      fontFamily: 'Helvetica Neue'
    },
    squarelevelMargin: {
      marginRight: marginRightValue
    }
  });
