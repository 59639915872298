import { View, Text, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './ModernUtils';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';

interface ISummaryProps {
  summary: string;
  highLightColor: string;
}

const styles = (textColor = templateColors.blue) => {
  const mainTitleColor = getColors(textColor).mainTitle;
  return StyleSheet.create({
    summaryContainer: {
      paddingLeft: 45,
      paddingRight: 45
    },
    title: {
      fontSize: 14,
      color: mainTitleColor,
      marginBottom: 10,
      marginTop: 14
    }
  });
};

const Summary: React.FC<ISummaryProps> = ({ summary, highLightColor = templateColors.blue }) => {
  return (
    <View style={styles().summaryContainer}>
      <Text style={styles(highLightColor).title}> SUMMARY </Text>
      <HTMLContent data={summary} />
    </View>
  );
};

export default Summary;
