import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';
import { IEducation } from '../../Models/IEducation';

interface IEducationsProps {
  educations: IEducation[];
  highLightColor: string;
}

interface IEducationProps {
  education: IEducation;
  isFirst: boolean;
  highLightColor: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    educationsContainer: {
      marginLeft: 22
    },
    title: {
      fontSize: 15,
      fontFamily: 'Helvetica Neue Light',
      marginBottom: 18,
      color: textColor
    },
    educationContainer: {
      flexDirection: 'row',
      marginTop: -10,
      width: 320
    },
    contentLeft: {
      flexDirection: 'column',
      width: 30
    },
    lineRight: {
      borderRight: 1,
      borderRightColor: '#C7C7C7'
    },
    startDate: {
      fontSize: 10,
      width: 37,
      color: textColor
    },
    point: {
      fontSize: 10,
      marginLeft: 10,
      color: textColor
    },
    contentRight: {
      flexDirection: 'column',
      marginLeft: 4.5,
      marginTop: -2,
      width: 312,
      marginBottom: 0.75
    },
    career: {
      marginLeft: 7,
      fontSize: 10,
      minHeight: 15,
      color: textColor,
      width: 312,
      fontFamily: 'Helvetica Neue',
      fontWeight: 'medium',
      maxLines: 2,
      textOverflow: 'ellipsis'
    },
    school: {
      fontSize: 9,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 12,
      marginLeft: -6.2,
      marginBottom: 5,
      fontFamily: 'Helvetica Bold',
      borderLeft: 1,
      borderLeftColor: '#C7C7C7'
    },
    dates: {
      width: 38,
      paddingTop: 5,
      paddingRight: 38,
      fontSize: 10
    },
    lineLeft: {
      borderLeft: 1,
      borderLeftColor: '#C7C7C7'
    },
    descriptionContainer: {
      width: 320,
      fontSize: 9,
      paddingLeft: 10.5,
      paddingTop: 5,
      paddingBottom: 5,
      left: 38,
      marginTop: -10,
      marginBottom: 10
    }
  });

Font.register({
  family: 'Helvetica Bold',
  src: `/fonts/Helvetica/HelveticaNeueBold.ttf`
});

const Education: React.FC<IEducationProps> = (props) => {
  const { career, description, endDate, school, startDate } = props.education;

  const startYear = startDate && startDate.substr(4);
  const endYear = endDate ? endDate.substr(4) : 'Present';

  return (
    <React.Fragment>
      <View wrap={false}>
        {props.isFirst && <Text style={styles(props.highLightColor).title}>Education</Text>}
        <View style={styles().educationContainer}>
          <View style={styles().contentLeft}>
            <Text style={styles(props.highLightColor).startDate}>{startYear || ' '}</Text>
            <View style={[styles().dates, styles().lineRight, { paddingBottom: 5 }]}>
              <Text>{endYear || ' '}</Text>
            </View>
          </View>
          <Text style={styles(props.highLightColor).point}>•</Text>
          <View style={styles().contentRight}>
            <Text style={[styles(props.highLightColor).career]}>{career || ' '}</Text>
            <Text style={[styles().school, styles().lineLeft]}>{school || ' '}</Text>
          </View>
        </View>
      </View>
      <View style={[styles().descriptionContainer, styles().lineLeft]}>
        <HTMLContent data={description} />
      </View>
    </React.Fragment>
  );
};

const Educations: React.FC<IEducationsProps> = (props) => {
  const { educations, highLightColor } = props;

  return (
    <View style={styles().educationsContainer}>
      {educations.reduce((result: Array<JSX.Element>, education, index) => {
        education.enabled &&
          result.push(
            <Education
              key={index}
              education={education}
              isFirst={result.length === 0}
              highLightColor={highLightColor}
            />
          );
        return result;
      }, [])}
    </View>
  );
};

export default Educations;
