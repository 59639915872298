import React from 'react';
import { useDispatch } from 'react-redux';

import { AppRouter } from '../AppRouter/AppRouter';
import { FileLoader } from '../Forms/CustomLoader/CustomLoader';
import useUserStatus from '../User/useUserStatus';
import { SetAuthData } from '../../redux/actions/dashboardActions';
import { AuthService } from '../../services/auth/authService';
import { AuthData } from '../../utils/authData';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useUserStatus();

  if (isLoggedIn) {
    if (location.pathname === '/authentication/callback') {
      location.replace(location.origin + '/dashboard');
    }
    const appValue = AuthData();
    dispatch(SetAuthData(appValue));

    return <AppRouter />;
  }
  AuthService.getInstance();
  return <FileLoader />;
};

export default App;
