export const AuthData = () => {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const res: any = {};
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const temp: any = {};
  const storageSize = sessionStorage.length;
  for (let i = 0; i < storageSize; i++) {
    const key = sessionStorage.key(i);
    if (key) {
      temp[key] = sessionStorage.getItem(key);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
      const values: any = JSON.parse(temp[key]);
      for (const valueKey in values) {
        res[valueKey] = values[valueKey];
      }
    }
  }
  return res;
};
