import { Image, View, Text } from '@react-pdf/renderer';
import React from 'react';

import styles from './HeaderStyles';
import { pictureToShow } from '../utils/helper';
import { verifyStringLength } from '../utils/stringUtils';
import { IPersonalInformation } from '../../Models/IPersonalInformation';

interface IHeaderProps {
  data: IPersonalInformation;
  highLightColor: string;
}

const Header: React.FC<IHeaderProps> = ({ data, highLightColor }) => {
  const { email, fullName, mainJob, pictures, phoneNumber } = data;
  const validatedPicture = pictureToShow(pictures);
  const userInformation = validatedPicture.displayPicture
    ? [styles().personalInfoContainer]
    : [styles().styleEmptyPicture];

  return (
    <View style={styles().container}>
      <View style={styles().userDataSection}>
        {validatedPicture.displayPicture && (
          <View style={styles().avatarColumn}>
            <Image src={validatedPicture.pictureToShow} style={styles().avatar} />
          </View>
        )}
        <View style={userInformation}>
          <View style={styles().personalData}>
            <Text style={styles().name}>{fullName.toLocaleUpperCase()}</Text>
            <Text style={styles(highLightColor).title}>{mainJob && mainJob.toLocaleUpperCase()}</Text>
            <Text style={styles().email}>{verifyStringLength(email, 55)}</Text>
            <Text style={[styles().email, styles().phone]}>
              {' ' + verifyStringLength(phoneNumber, 20) + ' '}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles().logoColumn}>
        <Image src='/images/jalasoftLogo.png' style={styles().jalaLogo} />
      </View>
    </View>
  );
};

export default Header;
