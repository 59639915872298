import { View, Text, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './ModernUtils';
import { templateColors } from '../utils/constants';
import { IPersonalInformation } from '../../Models/IPersonalInformation';

interface IHeaderProps {
  personalInfo: IPersonalInformation;
  highLightColor: string;
}

const styles = (textColor = templateColors.blue) => {
  const headerColor = getColors(textColor).header;
  return StyleSheet.create({
    container: {
      top: 0,
      height: 45,
      width: '100%',
      position: 'absolute',
      backgroundColor: headerColor
    },
    personalInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: '100%',
      alignItems: 'center'
    },
    name: {
      fontSize: 13,
      paddingLeft: 45,
      width: '70%',
      color: 'white',
      paddingRight: 7
    },
    mainJob: {
      width: '30%',
      textAlign: 'right',
      paddingRight: 50,
      fontSize: 9,
      maxHeight: 24
    }
  });
};

const AlterneHeader: React.FC<IHeaderProps> = ({ personalInfo, highLightColor }) => {
  const { fullName, mainJob } = personalInfo;

  return (
    <View fixed style={styles(highLightColor).container}>
      <View style={styles().personalInfo}>
        <Text style={styles().name}>{fullName && fullName.toUpperCase()}</Text>
        <Text style={styles().mainJob}>{mainJob && mainJob.toUpperCase()}</Text>
      </View>
    </View>
  );
};

export default AlterneHeader;
