import React from 'react';

export interface ICustomIconProps {
  className?: string;
  strokeWidth?: string;
  stroke?: string;
  text?: string;
  onClick?: () => void;
  onMouseDown?: () => void;
}

export const ArrowLeft: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='40'
      height='40'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' />
      <line x1='5' y1='12' x2='19' y2='12' />
      <line x1='5' y1='12' x2='9' y2='16' />
      <line x1='5' y1='12' x2='9' y2='8' />
    </svg>
  );
};

export const ArrowRight: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='40'
      height='40'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' />
      <line x1='5' y1='12' x2='19' y2='12' />
      <line x1='15' y1='16' x2='19' y2='12' />
      <line x1='15' y1='8' x2='19' y2='12' />
    </svg>
  );
};

export const DownloadIcon: React.FC<ICustomIconProps> = ({ className, onClick, strokeWidth }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='40'
      height='40'
      viewBox='0 0 24 24'
      strokeWidth={strokeWidth || '1.5'}
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
      <polyline points='7 11 12 16 17 11' />
      <line x1='12' y1='4' x2='12' y2='16' />
    </svg>
  );
};

export const CircleProgressBar: React.FC<ICustomIconProps> = ({ className, children }) => {
  return (
    <div className={`circle-progress-bar ${className}`}>
      <svg>
        <circle cx='18' cy='18' r='18' />
        <circle cx='18' cy='18' r='18' className={className} />
      </svg>
      <div className='percent'>{children}</div>
    </div>
  );
};

export const DragDropIcon: React.FC<ICustomIconProps> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      width='15'
      height='15'
      viewBox='0 0 26 26'
      strokeWidth='1.5'
      stroke='#607D8B'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' />
      <line x1='4' y1='6' x2='20' y2='6' />
      <line x1='4' y1='10' x2='20' y2='10' />
      <line x1='4' y1='14' x2='20' y2='14' />
      <line x1='4' y1='18' x2='20' y2='18' />
    </svg>
  );
};

export const ChevronLeft: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='52'
      height='52'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' />
      <polyline points='15 6 9 12 15 18' />
    </svg>
  );
};

export const ChevronRight: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='52'
      height='52'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#000000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' />
      <polyline points='9 6 15 12 9 18' />
    </svg>
  );
};

export const LogOutIcon: React.FC<ICustomIconProps> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#17B1EB'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2' />
      <path d='M7 12h14l-3 -3m0 6l3 -3' />
    </svg>
  );
};

export const DeleteIcon: React.FC<ICustomIconProps> = ({ className, onClick, onMouseDown }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      onMouseDown={onMouseDown}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='2.5'
      stroke='#000000'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <line x1='18' y1='6' x2='6' y2='18' />
      <line x1='6' y1='6' x2='18' y2='18' />
    </svg>
  );
};

export const SearchIcon: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='22.414'
      height='22.414'
      viewBox='0 0 22.414 22.414'
    >
      <g id='search' transform='translate(-2 -2)'>
        <circle
          cx='8.889'
          cy='8.889'
          r='8.889'
          transform='translate(3 3)'
          fill='none'
          stroke={stroke || '#009DFF'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <line
          x1='4.833'
          y1='4.833'
          transform='translate(18.167 18.167)'
          fill='none'
          stroke={stroke || '#009DFF'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};

export const GroupIcon: React.FC<ICustomIconProps> = ({ className, onClick, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      fill='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path d='M15.984 12.984c2.344 0 7.031 1.172 7.031 3.516v2.484h-6v-2.484c0-1.5-0.797-2.625-1.969-3.469 0.328-0.047 0.656-0.047 0.938-0.047zM8.016 12.984c2.344 0 6.984 1.172 6.984 3.516v2.484h-14.016v-2.484c0-2.344 4.688-3.516 7.031-3.516zM8.016 11.016c-1.641 0-3-1.359-3-3s1.359-3 3-3 2.953 1.359 2.953 3-1.313 3-2.953 3zM15.984 11.016c-1.641 0-3-1.359-3-3s1.359-3 3-3 3 1.359 3 3-1.359 3-3 3z' />
    </svg>
  );
};

export const GroupOutlineIcon: React.FC<ICustomIconProps> = ({
  className,
  onClick,
  strokeWidth,
  ...rest
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='28'
      height='30'
      viewBox='0 0 24 24'
      strokeWidth={strokeWidth || '0.1'}
      stroke='currentColor'
      fill='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <path d='M16.5 6.516c-1.078 0-2.016 0.891-2.016 1.969s0.938 2.016 2.016 2.016 2.016-0.938 2.016-2.016-0.938-1.969-2.016-1.969zM16.5 12c-1.922 0-3.516-1.594-3.516-3.516s1.594-3.469 3.516-3.469 3.516 1.547 3.516 3.469-1.594 3.516-3.516 3.516zM7.5 6.516c-1.078 0-2.016 0.891-2.016 1.969s0.938 2.016 2.016 2.016 2.016-0.938 2.016-2.016-0.938-1.969-2.016-1.969zM7.5 12c-1.922 0-3.516-1.594-3.516-3.516s1.594-3.469 3.516-3.469 3.516 1.547 3.516 3.469-1.594 3.516-3.516 3.516zM21.516 17.484v-1.219c0-0.563-2.578-1.781-5.016-1.781-1.078 0-2.109 0.281-3 0.563 0.328 0.375 0.516 0.75 0.516 1.219v1.219h7.5zM12.516 17.484v-1.219c0-0.563-2.578-1.781-5.016-1.781s-5.016 1.219-5.016 1.781v1.219h10.031zM16.5 12.984c2.156 0 6.516 1.125 6.516 3.281v2.719h-22.031v-2.719c0-2.156 4.359-3.281 6.516-3.281 1.219 0 3.047 0.375 4.5 1.031 1.453-0.656 3.281-1.031 4.5-1.031z' />
    </svg>
  );
};

export const LinkIcon: React.FC<ICustomIconProps> = ({ className, onClick, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='22'
      height='22'
      viewBox='0 0 24 24'
      strokeWidth='2.3'
      stroke='#7e7e7e'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='link-icon'
      {...rest}
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5' />
      <path d='M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5' />
    </svg>
  );
};

export const ChevronDown: React.FC<ICustomIconProps> = ({ className, onClick, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='18'
      height='18'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#7e7e7e'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='chevron-down'
      {...rest}
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <polyline points='6 9 12 15 18 9' />
    </svg>
  );
};

export const HorizontalDotsIcon: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#1AB2EB'
      fill='#1AB2EB'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='dots-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <circle cx='12' cy='12' r='1' />
      <circle cx='12' cy='19' r='1' />
      <circle cx='12' cy='5' r='1' />
    </svg>
  );
};

export const AlertCircle: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke={stroke || '#1AB2EB'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='alert-circle-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <circle cx='12' cy='12' r='9' />
      <line x1='12' y1='8' x2='12' y2='12' />
      <line x1='12' y1='16' x2='12.01' y2='16' />
    </svg>
  );
};

export const InfoCircle: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke={stroke || '#1AB2EB'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='info-circle-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <circle cx='12' cy='12' r='9' />
      <line x1='12' y1='8' x2='12.01' y2='8' />
      <polyline points='11 12 12 12 12 16 13 16' />
    </svg>
  );
};

export const CheckCircle: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke={stroke || '#1AB2EB'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='check-circle-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <circle cx='12' cy='12' r='9' />
      <path d='M9 12l2 2l4 -4' />
    </svg>
  );
};

export const ErrorCircle: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke={stroke || '#1AB2EB'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='error-circle-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <circle cx='12' cy='12' r='9' />
      <path d='M10 10l4 4m0 -4l-4 4' />
    </svg>
  );
};

export const CheckboxSquare: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='2'
      stroke={stroke || '#009DFF'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='checkbox-square-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <polyline points='9 11 12 14 20 6' />
      <path d='M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9' />
    </svg>
  );
};

export const Square: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='2'
      stroke={stroke || '#c8c8c8'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='square-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <rect x='4' y='4' width='16' height='16' rx='2' />
    </svg>
  );
};

export const Filter: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='2'
      stroke={stroke || '#009DFF'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='filter-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5' />
    </svg>
  );
};

export const CloseIcon: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='26'
      height='26'
      viewBox='0 0 24 24'
      strokeWidth='2'
      stroke={stroke || '#999999'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='close-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <line x1='18' y1='6' x2='6' y2='18' />
      <line x1='6' y1='6' x2='18' y2='18' />
    </svg>
  );
};

export const Eye: React.FC<ICustomIconProps> = ({ className, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='2'
      stroke={stroke || '#2c3e50'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='eye-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <circle cx='12' cy='12' r='2' />
      <path d='M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7' />
    </svg>
  );
};

export const OptionTheme: React.FC<ICustomIconProps> = ({ className, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke={stroke || '#2c3e50'}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='optionTheme-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <rect x='4' y='4' width='16' height='16' rx='2' />
      <line x1='4' y1='10' x2='20' y2='10' />
      <line x1='10' y1='10' x2='10' y2='20' />
    </svg>
  );
};

export const HamburgerMenuIcon: React.FC<ICustomIconProps> = ({ className, onClick, stroke }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.8'
      stroke={stroke}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <line x1='4' y1='6' x2='20' y2='6' />
      <line x1='4' y1='12' x2='20' y2='12' />
      <line x1='4' y1='18' x2='20' y2='18' />
    </svg>
  );
};

export const NumberCircleIcon: React.FC<ICustomIconProps> = ({ className, onClick, stroke, text }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke={stroke}
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g>
        <text x='9' y='15.5' fontSize='11' strokeWidth='1'>
          {text}
        </text>
        <circle cx='12' cy='12' r='9' />
      </g>
    </svg>
  );
};

export const ArrowUp: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#2c3e50'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <line x1='12' y1='5' x2='12' y2='19' />
      <line x1='18' y1='11' x2='12' y2='5' />
      <line x1='6' y1='11' x2='12' y2='5' />
    </svg>
  );
};

export const ArrowDown: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#2c3e50'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <line x1='12' y1='5' x2='12' y2='19' />
      <line x1='18' y1='13' x2='12' y2='19' />
      <line x1='6' y1='13' x2='12' y2='19' />
    </svg>
  );
};

export const EyeIcon: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#2c3e50'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <circle cx='12' cy='12' r='2' />
      <path d='M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7' />
    </svg>
  );
};

export const UserOff: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='44'
      height='44'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#2c3e50'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M14.274 10.291a4 4 0 1 0 -5.554 -5.58m-.548 3.453a4.01 4.01 0 0 0 2.62 2.65' />
      <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 1.147 .167m2.685 2.681a4 4 0 0 1 .168 1.152v2' />
      <line x1='3' y1='3' x2='21' y2='21' />
    </svg>
  );
};

export const UploadIcon: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#2c3e50'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      data-testid='upload-icon'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1' />
      <polyline points='9 15 12 12 15 15' />
      <line x1='12' y1='12' x2='12' y2='21' />
    </svg>
  );
};

export const SimpleCircle: React.FC<ICustomIconProps> = ({ className, onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      width='34'
      height='34'
      viewBox='0 0 24 24'
      fill='#EDEDED'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <circle cx='12' cy='12' r='10' />
    </svg>
  );
};
