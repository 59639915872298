import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';

import { templateColors } from '../utils/constants';
import { ILanguage } from '../../Models/ILanguages';

interface ILanguagesProps {
  data: ILanguage[];
  highLightColor: string;
}

interface ILanguageProps {
  data: ILanguage;
  isFirst: boolean;
  highLightColor: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    title: {
      fontSize: 14,
      marginBottom: 10,
      marginTop: 14,
      color: textColor
    },
    languageContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 5
    },
    name: {
      fontSize: 10,
      fontFamily: 'Helvetica Bold',
      paddingTop: 2,
      paddingRight: 3,
      marginRight: 5,
      maxLines: 1,
      textOverflow: 'ellipsis'
    },
    languageType: {
      fontSize: 9
    },
    detailsBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      backgroundColor: '#F5F5F5',
      borderRadius: 4,
      paddingLeft: 8,
      paddingBottom: 3,
      paddingTop: 3
    },
    values: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    valuesGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginRight: 12
    },
    levels: {
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: 15
    },
    levelName: {
      fontSize: 9
    },
    level: {
      fontSize: 10,
      color: textColor
    },
    complement: {
      color: '#D7D7D7',
      fontSize: 10,
      marginRight: 8
    },
    native: {
      fontSize: 9,
      marginRight: 8
    }
  });

Font.register({
  family: 'Helvetica Bold',
  src: `/fonts/Helvetica/HelveticaNeueBold.ttf`
});

const Language: React.FC<ILanguageProps> = (props) => {
  const { language, listening, reading, speaking, writing } = props.data;

  const isNative = [listening, reading, speaking, writing].every((item) => item === 'native');

  const getLevel = (level: string) => {
    let skillLevel = '';
    let skillLevelComplement = '•••';
    switch (level) {
      case 'beginner':
        skillLevel = '•';
        skillLevelComplement = '••';
        break;
      case 'intermediate':
        skillLevel = '••';
        skillLevelComplement = '•';
        break;
      case 'advanced':
        skillLevel = '•••';
        skillLevelComplement = '';
        break;
      default:
        break;
    }
    return (
      <React.Fragment>
        {level === 'native' ? (
          <Text style={styles().native}>(Native)</Text>
        ) : (
          <React.Fragment>
            <Text style={styles(props.highLightColor).level}>{skillLevel}</Text>
            <Text style={styles().complement}>{skillLevelComplement}</Text>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
  const widthLanguage = isNative ? { maxWidth: '89%' } : { maxWidth: '39%' };
  const languageName = language.replace(/\s+/g, ' ').trim();
  return (
    <View wrap={false}>
      {props.isFirst && <Text style={styles(props.highLightColor).title}> Languages </Text>}
      <View style={styles().languageContainer}>
        <Text style={[styles().name, widthLanguage]}>{languageName}</Text>
        <View style={styles().detailsBox}>
          {isNative ? (
            <Text style={styles().native}>(Native)</Text>
          ) : (
            <React.Fragment>
              <View style={styles().values}>
                <Text style={styles().languageType}> Reading </Text>
                {getLevel(reading)}
              </View>
              <View style={styles().values}>
                <Text style={styles().languageType}> Listening </Text>
                {getLevel(listening)}
              </View>
              <View style={styles().values}>
                <Text style={styles().languageType}> Writing </Text>
                {getLevel(writing)}
              </View>
              <View style={styles().values}>
                <Text style={styles().languageType}> Speaking </Text>
                {getLevel(speaking)}
              </View>
            </React.Fragment>
          )}
        </View>
      </View>
    </View>
  );
};

const Languages: React.FC<ILanguagesProps> = (props) => {
  const { data, highLightColor } = props;
  const languageDescription = [
    {
      level: '•',
      complement: '••',
      levelName: 'Beginner'
    },
    {
      level: '••',
      complement: '•',
      levelName: 'Intermediate'
    },
    {
      level: '•••',
      complement: '',
      levelName: 'Advanced'
    }
  ];

  const languagesCmpt =
    data &&
    data.reduce((result: Array<JSX.Element>, item, index) => {
      result.push(
        <Language
          key={index}
          data={item}
          isFirst={result.length === 0}
          highLightColor={highLightColor}
        />
      );
      return result;
    }, []);

  return (
    <React.Fragment>
      {languagesCmpt}
      {languagesCmpt.length > 0 && (
        <View style={styles().levels} wrap={false}>
          {languageDescription.map((item, i) => (
            <View key={i} style={styles().valuesGroup}>
              <Text style={styles(highLightColor).level}>{item.level}</Text>
              <Text style={styles().complement}>{item.complement} </Text>
              <Text style={styles().levelName}>{item.levelName}</Text>
            </View>
          ))}
        </View>
      )}
    </React.Fragment>
  );
};

export default Languages;
