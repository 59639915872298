import React, { useState } from 'react';

import styles from './Checkbox.module.scss';
import { CheckboxSquare, Square } from '../../../../Assets/CustomIcons';

interface ICheckboxProps {
  type: string;
  checked: boolean;
  size: Number;
  onChange: Function;
  customHandle: boolean;
  emptyBackground: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  type,
  size,
  checked = false,
  onChange,
  customHandle = false,
  emptyBackground
}) => {
  const [checkboxChecked, setCheckboxChecked] = useState(checked);
  const isChecked = () => {
    if (customHandle) {
      return checked;
    }
    return checkboxChecked;
  };
  const style = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: type === 'circular' ? '50%' : '4px'
  };
  const handleChecked = () => {
    setCheckboxChecked(!checkboxChecked);
    onChange(!checkboxChecked);
  };
  return (
    <>
      {type === 'circular' ? (
        <section
          className={`${styles.checkboxContainer} ${isChecked() && styles.checked}`}
          style={style}
          onClick={() => handleChecked()}
        >
          {isChecked() && (
            <div className={styles.checkboxIcon}>
              <div className={styles.emptyCorner} style={{ background: emptyBackground }}></div>
              <span className={styles.checkmark} style={style}>
                <div className={styles.checkmarkStem}></div>
                <div className={styles.checkmarkKick}></div>
              </span>
            </div>
          )}
        </section>
      ) : (
        <>
          {isChecked() ? (
            <CheckboxSquare onClick={handleChecked} className={styles.svgIcon} />
          ) : (
            <Square onClick={handleChecked} className={styles.svgIcon} />
          )}
        </>
      )}
    </>
  );
};

export default Checkbox;
