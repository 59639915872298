import React from 'react';

import styles from './Hamburger.module.scss';
import LeftProgressSideBar from '../Forms/ResumeForm/LeftProgressSideBar/LeftSideBar';
import { CloseIcon, HamburgerMenuIcon } from '../../Assets/CustomIcons';

export const handleShowMenu = (value: boolean) => {
  const hamburguerInput = document.getElementById('hamburger-checkbox');
  if (hamburguerInput) {
    const input = hamburguerInput as HTMLInputElement;
    input.checked = value;
  }
};

const Hamburger: React.FC = () => {
  return (
    <>
      <div className={styles.menuToggle}>
        <input type='checkbox' id='hamburger-checkbox' defaultChecked={false} data-testid='hamburger' />
        <HamburgerMenuIcon className={styles.hamburgerIcon} onClick={() => handleShowMenu(true)} />
        <div className={styles.sidebar}>
          <div className={styles.header}>
            <CloseIcon className={styles.closeIcon} onClick={() => handleShowMenu(false)} />
          </div>
          <div className={styles.body}>
            <LeftProgressSideBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hamburger;
