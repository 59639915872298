import vanillaOidc from '@axa-fr/vanilla-oidc';
import jwtDecode from 'jwt-decode';

import { Configuration } from '../constants/settings';

export class UserManager {
  constructor() {
    this.vanillaOidcPromise = vanillaOidc.init(Configuration);
    this.getUserInfo();
  }

  getUserInfo() {
    this.vanillaOidcPromise.then((status) => {
      let foundUser = {};
      if (status.type !== 'callback') {
        if (!status.user) {
          vanillaOidc.signinRedirect();
        } else {
          foundUser = status.user;
        }
      }
      return foundUser;
    });
  }
}
