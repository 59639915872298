// Could be changed for Redux or any reactive programming pattern in the future.
import { SectionId } from '../constants/sectionIDs';
import { Subject } from 'rxjs';

export class ValidationNotifierService {
  private static Instance: ValidationNotifierService;
  private _validationRequested: Subject<SectionId> = new Subject();
  private _validationNotifierType: string | undefined;

  public static getInstance(): ValidationNotifierService {
    if (!ValidationNotifierService.Instance) {
      ValidationNotifierService.Instance = new ValidationNotifierService();
    }

    return ValidationNotifierService.Instance;
  }

  get ValidationRequested() {
    return this._validationRequested;
  }

  public dispatchSectionValidationRequested(sectionId: SectionId) {
    this._validationRequested.next(sectionId);
  }

  get ValidationNotifierType() {
    return this._validationNotifierType;
  }

  public setValidationNotifierType(type: string) {
    this._validationNotifierType = type;
  }

  public cleanValidationNotifierType() {
    this._validationNotifierType = undefined;
  }
}
