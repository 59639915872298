import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import MenuItem from 'semantic-ui-react/dist/commonjs/collections/Menu/MenuItem';
import GridColumn from 'semantic-ui-react/dist/commonjs/collections/Grid/GridColumn';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import styles from './TopNavBar.module.scss';
import { ActiveOptionStyle, DeviceType } from '../../constants/globalConstants';
import { setTopBarActiveOptionStyle } from '../../redux/actions/dashboardActions';
import { RootStore } from '../../redux/store';

interface ITopBarMenuProps {
  type: DeviceType;
  active?: boolean;
}

const TopBarMenu: React.FC<ITopBarMenuProps> = ({ type, active }) => {
  const { topBarActiveOptionStyle, loggedUserResumeId, topBarResumeOptionName } = useSelector(
    (state: RootStore) => state.dashboard
  );
  const dispatch = useDispatch();

  const handleActiveMenuItem = (menuName: ActiveOptionStyle) => {
    dispatch(setTopBarActiveOptionStyle(menuName));
  };

  return type === DeviceType.DESKTOP ? (
    <Menu pointing secondary className={styles.menu}>
      <MenuItem
        className={topBarActiveOptionStyle === ActiveOptionStyle.HOME && active ? styles.activeMenu : ''}
      >
        <Link to='/dashboard' onClick={() => handleActiveMenuItem(ActiveOptionStyle.HOME)}>
          <Icon className='home' />
          <span>Home</span>
        </Link>
      </MenuItem>
      <MenuItem
        className={
          topBarActiveOptionStyle === ActiveOptionStyle.RESUME && active ? styles.activeMenu : ''
        }
      >
        <Link
          to={`/resume/${loggedUserResumeId}`}
          onClick={() => handleActiveMenuItem(ActiveOptionStyle.RESUME)}
        >
          <Icon className='file outline' />
          <span>{topBarResumeOptionName}</span>
        </Link>
      </MenuItem>
    </Menu>
  ) : (
    <GridRow only='mobile' textAlign='center' verticalAlign='middle' className={styles.mobileMenu}>
      <GridColumn
        mobile={8}
        className={`${styles.column} ${
          topBarActiveOptionStyle === ActiveOptionStyle.HOME && active ? styles.activeMobileMenu : ''
        }`}
      >
        <MenuItem>
          <Link to='/dashboard' onClick={() => handleActiveMenuItem(ActiveOptionStyle.HOME)}>
            <Icon className='home' />
            <span>Home</span>
          </Link>
        </MenuItem>
      </GridColumn>
      <GridColumn
        mobile={8}
        className={`${styles.column} ${
          topBarActiveOptionStyle === ActiveOptionStyle.RESUME && active ? styles.activeMobileMenu : ''
        }`}
      >
        <MenuItem>
          <Link
            to={`/resume/${loggedUserResumeId}`}
            onClick={() => handleActiveMenuItem(ActiveOptionStyle.RESUME)}
          >
            <Icon className='file outline' />
            <span>{topBarResumeOptionName}</span>
          </Link>
        </MenuItem>
      </GridColumn>
    </GridRow>
  );
};

export default TopBarMenu;
