export enum OptionType {
  system,
  candidate
}

export interface INewArea {
  name: string;
  type: OptionType;
  userEmail: string;
}

export interface INewSkill {
  area: string;
  skill: string;
  type: OptionType;
  userEmail: string;
}

export interface INewLanguage {
  name: string;
  type: OptionType;
  userEmail: string;
}

export interface INewJob {
  name: string;
  type: OptionType;
  userEmail: string;
}

export interface INewProfile {
  job: string;
  profile: string;
  type: OptionType;
  userEmail: string;
}

export interface INewInternalProfile {
  name: string;
  type: OptionType;
  userEmail: string;
}
