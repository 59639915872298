import { pdf } from '@react-pdf/renderer';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';

import styles from './ResumePDFViewer.module.scss';
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import { FileLoader } from '../Forms/CustomLoader/CustomLoader';
import { generatePdfDownloadTitle } from '../ResumeDashboard/ResumeCard/ResumeCard';
import TemplatesViewer from '../PreviewDownload/TemplatesViewer';
import Spinner from '../Spinner/Spinner';
import { DownloadIcon } from '../../Assets/CustomIcons';
import { TemplatePDF } from '../../constants/globalConstants';
import { IResumeForm } from '../../Models/IResumeForm';
import { ResumeService } from '../../services/resumes/resume.service';
import AquaTemplate from '../../templates/Aqua';
import ClassicTemplate from '../../templates/Classic/ResumenDocument';
import CorporateTemplate from '../../templates/Corporate/ResumeDocument';
import FormalTemplate from '../../templates/Formal';
import ModernTemplate from '../../templates/Modern/Main';
import PlainTemplate from '../../templates/Plain';

const resumeService = ResumeService.getInstance();
const pathError = '/notfound';
const requestQuery = 'pdf-reader';

const getTemplate = (template: TemplatePDF, data: IResumeForm): JSX.Element => {
  const templates = {
    [TemplatePDF.CORPORATE]: () => <CorporateTemplate resume={data} />,
    [TemplatePDF.CLASSIC]: () => <ClassicTemplate resume={data} />,
    [TemplatePDF.MODERN]: () => <ModernTemplate resume={data} />,
    [TemplatePDF.AQUA]: () => <AquaTemplate resume={data} />,
    [TemplatePDF.PLAIN]: () => <PlainTemplate resume={data} />,
    [TemplatePDF.FORMAL]: () => <FormalTemplate resume={data} />
  };

  return templates[template]() || null;
};

const buildBlob = async (currentTemplate: TemplatePDF, data: IResumeForm): Promise<Blob> => {
  const templateComponent = getTemplate(currentTemplate, data);
  return await pdf(templateComponent).toBlob();
};

const ResumePDFViewer: React.FC = () => {
  const [resumeData, setResumeData] = useState<IResumeForm>();
  const [downloading, setDownloading] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const paramTheme = new URLSearchParams(search).get('theme')?.toLocaleLowerCase() as TemplatePDF;
  const template = Object.values(TemplatePDF).includes(paramTheme) ? paramTheme : TemplatePDF.CORPORATE;
  const [blobGenerate, setBlob] = useState<Blob | null>(null);

  useEffect(() => {
    getResumeById(id);
  }, []);

  useEffect(() => {
    if (template && resumeData) {
      setBlob(null);
      blobup(template, resumeData as IResumeForm);
    }
  }, [template]);

  const getResumeById = async (id: string) => {
    try {
      const newResume = await resumeService.getResumeById(id, requestQuery);
      setResumeData(newResume.data);
      blobup(template, newResume.data as IResumeForm);
    } catch (error) {
      history.push(pathError);
    }
  };

  const blobup = async (currentTemaplate: TemplatePDF, data: IResumeForm) => {
    const newBlob = await buildBlob(currentTemaplate, data);
    setBlob(newBlob);
  };

  const downloadPDFDocument = () => {
    if (resumeData) {
      const {
        personalInformation: { fullName }
      } = resumeData;
      const fileName = generatePdfDownloadTitle(fullName);
      setDownloading(true);
      blobGenerate && saveAs(blobGenerate, fileName);
      setDownloading(false);
    }
  };

  const memoizedAllTemplates = useMemo(() => {
    return (
      <TemplatesViewer
        onChangeTemplate={(template) => history.push(`/shared/${id}?theme=${template}`)}
      />
    );
  }, [resumeData]);

  return (
    <section className={styles.containerViewer}>
      {blobGenerate ? <DocumentViewer blob={blobGenerate} /> : <FileLoader />}
      <section className={styles.optionsContainer}>
        <div className={styles.options}>
          {memoizedAllTemplates}
          <Button
            className={styles.downloadButton}
            onClick={downloadPDFDocument}
            active={!downloading}
            primary
          >
            {downloading ? (
              <Spinner />
            ) : (
              <>
                <DownloadIcon className={styles.downloadIcon} strokeWidth='2' />
                Download
              </>
            )}
          </Button>
        </div>
      </section>
    </section>
  );
};

export default ResumePDFViewer;
