import { View, Document, Page, StyleSheet } from '@react-pdf/renderer';
import { PageSize } from '@react-pdf/types';
import React from 'react';

import AlterneHeader from './AlterneHeader';
import Educations from './Education';
import Experiences from './Experience';
import Header from './Header';
import { getColors } from './ModernUtils';
import Skills from './Skills';
import Summary from './Summary';
import Languages from '../utils/CommonPDFUtils/Language';
import { templateColors } from '../utils/constants';
import { appVersion } from '../../constants/globalConstants';
import { IResumeForm } from '../../Models/IResumeForm';

interface IResumeProps {
  size: PageSize;
  resume: IResumeForm;
  highLightColor?: string;
}

interface IResumeDocumentProps {
  resume: IResumeForm;
  highLightColor?: string;
}

const styles = (textColor = templateColors.blue) => {
  const footerColor = getColors(textColor).footer;
  return StyleSheet.create({
    page: {
      paddingBottom: 60,
      paddingTop: 60
    },
    contentContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row'
    },
    leftSectionsContainer: {
      width: 175,
      paddingLeft: 45
    },
    rigthSectionsContainer: {
      width: 420,
      paddingRight: 45,
      paddingLeft: 22
    },
    footer: {
      bottom: 0,
      height: 25,
      width: '100%',
      position: 'absolute',
      backgroundColor: '#262626'
    }
  });
};

const templateColor = 'blue';

const Resume: React.FC<IResumeProps> = ({ size, resume, highLightColor = templateColors.blue }) => {
  const { personalInformation, workExperiences, educations } = resume;
  const skills = resume.skills.filter(
    (skill) => skill.area !== '' && skill.skill !== '' && skill.enabled
  );
  const languages = resume.languages.filter((language) => language.language !== '' && language.enabled);

  return (
    <Page size={size} style={styles().page}>
      <AlterneHeader personalInfo={personalInformation} highLightColor={highLightColor} />
      <Header personalInfo={personalInformation} highLightColor={highLightColor} />
      <Summary summary={personalInformation.profesionalSummary} highLightColor={highLightColor} />
      <View style={styles().contentContainer}>
        <View style={styles().leftSectionsContainer}>
          <Skills skills={skills} highLightColor={highLightColor} />
          <Languages
            languages={languages}
            color={highLightColor ? '' : templateColor}
            title='LANGUAGES'
            highLightColor={getColors(highLightColor).titleColor}
          />
        </View>
        <View style={styles().rigthSectionsContainer}>
          <Experiences workExperiences={workExperiences} highLightColor={highLightColor} />
          <Educations educations={educations} highLightColor={highLightColor} />
        </View>
      </View>
      <View fixed style={styles(highLightColor).footer} />
    </Page>
  );
};

const BlueResumeDocument: React.FC<IResumeDocumentProps> = (props) => {
  return (
    <Document
      author='Mon CV'
      keywords='awesome, resume, mon cv'
      subject='The resume of MonCV users'
      title='Resume'
      creator={`Mon CV v${appVersion}`}
      producer={`${appVersion}`}
    >
      <Resume size='A4' {...props} />
    </Document>
  );
};

export default BlueResumeDocument;
