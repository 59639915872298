export const equalsArray = (firstArray = [], secondArray = []) => {
  const array1 = [...firstArray].sort();
  const array2 = [...secondArray].sort();
  return array1.length === array2.length && array1.every((item, index) => item === array2[index]);
};

export const capitalizeWordsFirstLetter = (text: string) => {
  return text.replace(/\b\w/g, (letter) => letter.toUpperCase());
};

export const powerSet = (arrayData: string[]) => {
  const results = [];
  for (let i = 0; i < 1 << arrayData.length; i++) {
    const subset = [];
    for (let j = 0; j < arrayData.length; j++) {
      if (i & (1 << j)) {
        subset.push(arrayData[j]);
      }
    }
    results.push(subset);
  }
  return results;
};

export const combineName = (arrayName: string[], arrayLastName: string[]) => {
  const resultName = powerSet(arrayName).reduce((result, items): string[] => {
    items.filter((item) => item).length && result.push(items.join(' '));
    return result;
  }, []);
  const resultLastName = powerSet(arrayLastName).reduce((result, items): string[] => {
    items.filter((item) => item).length && result.push(items.join(' '));
    return result;
  }, []);
  const result = [];
  for (let i = 0; i <= resultName.length - 1; i++) {
    const name = resultName[i];
    for (let x = 0; x <= resultLastName.length - 1; x++) {
      const lastName = resultLastName[x];
      result.push(`${name} ${lastName}`);
    }
  }
  return result;
};
