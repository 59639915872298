import { Dispatch } from 'redux';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import {
  ResumesDispatchTypes,
  AUTH_SUCCESS,
  DATA_FAIL,
  DATA_LOADING,
  FILTERED_SUCCESS,
  RESUMES_SUCCESS,
  RESUMES_OPTIONS_SUCCESS,
  ADD_JOB_OPTION,
  ADD_PROFILE_OPTION,
  ADD_AREA_OPTION,
  ADD_SKILL_OPTION,
  ADD_LANGUAGE_OPTION,
  ADD_INTERNAL_PROFILE_OPTION,
  SET_DASHBOARD_PAGE,
  UPDATE_IS_READY,
  SET_SEARCH_CRITERIA,
  SET_TOP_BAR_ACTIVE_MENU,
  SET_LOGGED_USER_RESUME_ID,
  SET_TOP_BAR_RESUME_OPTION_NAME,
  HIDE_RESUME
} from './dashboardActionTypes';
import { ActiveOptionStyle, ResumeOptionName } from '../../constants/globalConstants';
import { resumesPerPage } from '../../constants/settings';
import { ILanguageOptions } from '../../Models/ILanguages';
import { INewArea, INewInternalProfile, INewLanguage, INewSkill } from '../../Models/INewOptions';
import { IResumeFormBasic } from '../../Models/IResumeFormBasic';
import { IResumeOptions } from '../../Models/IResumeOptions';
import { IAreaOptions, ISkillOption } from '../../Models/ISkillOptions';
import { SearchFilters } from '../../Models/FormSearchFilters';
import { IWorkExperienceOptions } from '../../Models/IWorkExperience';
import { AuthAPIService } from '../../services/auth/authAPIService';
import { ResumeService } from '../../services/resumes/resume.service';
import { ResumeOptionsService } from '../../services/resumes/resumeOptions.service';
import { OnRequestError } from '../../utils/errorRequest';

const resumeService = ResumeService.getInstance();
const resumeOptionsService = ResumeOptionsService.getInstance();
const authService = AuthAPIService.getInstance();

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export const SetAuthData = (currentAuth: any) => async (dispatch: Dispatch<ResumesDispatchTypes>) => {
  try {
    const userSession = await authService.getAuthUser();
    const { role, name, userSetting, id } = userSession;
    const prevData = sessionStorage.getItem('searchCriteria') as string;
    const objPrevData = prevData ? JSON.parse(prevData) : null;
    const existsUserSetting = objPrevData && 'sort' in objPrevData && 'visibility' in objPrevData;
    !existsUserSetting &&
      sessionStorage.setItem(
        'searchCriteria',
        JSON.stringify({
          ...objPrevData,
          sort: userSetting.defaultSortBy,
          visibility: userSetting.defaultVisibility
        })
      );
    const newUserSetting = existsUserSetting
      ? { sort: objPrevData.sort, visibility: objPrevData.visibility }
      : {
          sort: userSetting.defaultSortBy,
          visibility: userSetting.defaultVisibility
        };
    dispatch({
      type: SET_SEARCH_CRITERIA,
      payload: {
        ...newUserSetting
      } as SearchFilters
    });

    dispatch({
      type: AUTH_SUCCESS,
      payload: { ...currentAuth, userRole: role, userName: name, userId: id }
    });
    if (userSession) {
      const loggedUserResume = await resumeService.getResumeByEmail(userSession.email);
      dispatch({ type: SET_LOGGED_USER_RESUME_ID, payload: loggedUserResume.data._id });
    }
  } catch (error) {
    OnRequestError(error);
  }
};

export const GetResumes = (
  page: number,
  quantity = resumesPerPage,
  criteria = '',
  fieldToFilter = '',
  fieldToSort = '',
  visibility = '',
  fieldToRefinement = ''
) => async (dispatch: Dispatch<ResumesDispatchTypes>) => {
  try {
    dispatch({
      type: DATA_LOADING
    });

    let response = await resumeService.getResumesPage(
      page,
      quantity,
      criteria,
      fieldToFilter,
      fieldToSort,
      visibility,
      fieldToRefinement
    );
    if (!criteria && page > response.data.totalPages && page > 1) {
      response = await resumeService.getResumesPage(response.data.totalPages, quantity, criteria);
    }

    dispatch({
      type: RESUMES_SUCCESS,
      payload: {
        resumes: response.data.resumes,
        totalPages: response.data.totalPages
      }
    });
  } catch (e) {
    dispatch({
      type: DATA_FAIL
    });
  }
};

export const GetResumesOptions = () => async (dispatch: Dispatch<ResumesDispatchTypes>) => {
  dispatch({
    type: DATA_LOADING
  });

  try {
    const { data } = await resumeOptionsService.getMonCvOptions();

    const resumeOptions: IResumeOptions = {
      skills: data.areas,
      profiles: data.jobs,
      languages: data.languages,
      internalProfiles: data.internalProfiles
    };
    dispatch({
      type: RESUMES_OPTIONS_SUCCESS,
      payload: resumeOptions
    });
  } catch (error) {
    dispatch({
      type: DATA_FAIL
    });
  }
};

export const UpdateFilteredResumes = (newFilteredResumes: IResumeFormBasic[]) => async (
  dispatch: Dispatch<ResumesDispatchTypes>
) => {
  dispatch({
    type: FILTERED_SUCCESS,
    payload: newFilteredResumes
  });
};

export const UpdateSearchCriteria = (newSearchCriteria: SearchFilters) => async (
  dispatch: Dispatch<ResumesDispatchTypes>
) => {
  const { criteria, ...rest } = newSearchCriteria;
  const prevData = sessionStorage.getItem('searchCriteria');
  const objPrevData = prevData ? JSON.parse(prevData) : null;
  sessionStorage.setItem('searchCriteria', JSON.stringify({ ...objPrevData, ...rest }));
  dispatch({
    type: SET_SEARCH_CRITERIA,
    payload: newSearchCriteria
  });
};

export const AddJob = (newJobOptions: IWorkExperienceOptions) => async (
  dispatch: Dispatch<ResumesDispatchTypes>
) => {
  dispatch({
    type: ADD_JOB_OPTION,
    payload: newJobOptions
  });
};

export const AddProfile = (
  newJobOptions: DropdownItemProps,
  jobOption: IWorkExperienceOptions | number
) => async (dispatch: Dispatch<ResumesDispatchTypes>) => {
  dispatch({
    type: ADD_PROFILE_OPTION,
    payload: { profile: newJobOptions, job: jobOption }
  });
};

export const ClearResumeForm = () => (dispatch: Dispatch<ResumesDispatchTypes>) => {
  const resumeOptions = {
    skills: [],
    profiles: [],
    languages: [],
    internalProfiles: [],
    users: []
  };

  dispatch({
    type: RESUMES_OPTIONS_SUCCESS,
    payload: resumeOptions
  });

  dispatch({
    type: UPDATE_IS_READY,
    payload: false
  });
};

export const AddArea = (newSkillOptions: IAreaOptions, newAreaDTO?: INewArea | undefined) => (
  dispatch: Dispatch<ResumesDispatchTypes>
) => {
  newAreaDTO && resumeOptionsService.addArea(newAreaDTO);

  dispatch({
    type: ADD_AREA_OPTION,
    payload: newSkillOptions
  });
};

export const AddSkill = (
  newSkillOptions: ISkillOption,
  areaOption: IAreaOptions | number,
  newSkillDTO?: INewSkill | undefined
) => (dispatch: Dispatch<ResumesDispatchTypes>) => {
  newSkillDTO && resumeOptionsService.addSkill(newSkillDTO);
  dispatch({
    type: ADD_SKILL_OPTION,
    payload: { skill: newSkillOptions, area: areaOption }
  });
};

export const AddLanguageOption = (
  newLanguageOption: ILanguageOptions,
  newOption?: INewLanguage | undefined
) => (dispatch: Dispatch<ResumesDispatchTypes>) => {
  newOption && resumeOptionsService.addLanguage(newOption);

  dispatch({
    type: ADD_LANGUAGE_OPTION,
    payload: newLanguageOption
  });
};

export const AddInternalProfileOption = (
  newInternalProfileOption: DropdownItemProps,
  newOption?: INewInternalProfile | undefined
) => (dispatch: Dispatch<ResumesDispatchTypes>) => {
  newOption && resumeOptionsService.addInternalProfile(newOption);

  dispatch({
    type: ADD_INTERNAL_PROFILE_OPTION,
    payload: newInternalProfileOption
  });
};

export const SetDashboardPage = (newPage: number) => (dispatch: Dispatch<ResumesDispatchTypes>) => {
  sessionStorage.setItem('page', `${newPage}`);
  dispatch({
    type: SET_DASHBOARD_PAGE,
    payload: newPage
  });
};

export const setTopBarActiveOptionStyle = (optionName: ActiveOptionStyle) => (
  dispatch: Dispatch<ResumesDispatchTypes>
) => {
  localStorage.setItem('menu', optionName);
  dispatch({
    type: SET_TOP_BAR_ACTIVE_MENU,
    payload: optionName
  });
};

export const setTopBarResumeOptionName = (resumeOptionName: ResumeOptionName) => (
  dispatch: Dispatch<ResumesDispatchTypes>
) => {
  dispatch({
    type: SET_TOP_BAR_RESUME_OPTION_NAME,
    payload: resumeOptionName
  });
};

export const setLoggedUserResumeId = (resumeId: string) => async (
  dispatch: Dispatch<ResumesDispatchTypes>
) => {
  dispatch({
    type: SET_LOGGED_USER_RESUME_ID,
    payload: resumeId
  });
};

export const hideResumeDashboard = (resumeId: string, isHidden: boolean) => async (
  dispatch: Dispatch<ResumesDispatchTypes>
) => {
  try {
    const response = await resumeService.hideResume(resumeId, isHidden);
    dispatch({ type: HIDE_RESUME, payload: { id: resumeId, isHidden } });
  } catch (error) {
    alert(error);
  }
};
