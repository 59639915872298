import { debounce } from 'lodash';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import './WorkExperienceForm.scss';
import CustomDatePicker from '../../customFormControls/DatePicker/CustomDatePicker';
import RichTextEditor from '../../customFormControls/richTextEditor/richTextEditor';
import {
  descriptionFormat,
  formatDate,
  formsDebounceTime,
  Roles
} from '../../../../constants/globalConstants';
import { IWrappedByMultipleEntriesHOCProps } from '../../../../HOCs/WithMultipleEntries/IWrappedByMultipleEntriesHOCProps';
import { SectionStatus } from '../../../../Models/FormResumeSection';
import { INewInternalProfile, OptionType } from '../../../../Models/INewOptions';
import { ISkill } from '../../../../Models/ISkills';
import { IWorkExperience, IWorkExperienceOptions } from '../../../../Models/IWorkExperience';
import {
  AddInternalProfileOption,
  AddJob,
  AddProfile
} from '../../../../redux/actions/dashboardActions';
import { RootStore } from '../../../../redux/store';
import { ResumeOptionsService } from '../../../../services/resumes/resumeOptions.service';
import { ValidationNotifierService } from '../../../../services/validationNotifier.service';
import { isValid } from '../../../../utils/validation/formRules';
import { endDateIsBeforeStartDate } from '../../../../utils/validation/validator';

interface IWorkExperienceFormState {
  workExperienceFormData: IWorkExperience;
  errors: {
    jobTitle: boolean;
    client: boolean;
    startDate: boolean;
    description: boolean;
    compareDates: boolean;
  };
  status: SectionStatus;
  isFormValid: boolean;
  enable: {
    jobTitle: boolean;
    profile: boolean;
    internalProfile: boolean;
  };
}

interface IReactProps {
  userRole: string;
  jobOptions: IWorkExperienceOptions[];
  internalProfileOptions: DropdownItemProps[];
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  profile: any;
}

interface IDispatchProps {
  addJob: (jobOptions: IWorkExperienceOptions) => void;
  addProfile: (newJobOptions: DropdownItemProps, jobOption: IWorkExperienceOptions | number) => void;
  addInternalProfile: (
    newInternalProfileOption: DropdownItemProps,
    newOption?: INewInternalProfile | undefined
  ) => void;
}

export enum Fields {
  JobTitle = 'jobTitle',
  Profile = 'profile',
  InternalProfile = 'internalProfile',
  Client = 'client',
  StartDate = 'startDate',
  EndDate = 'endDate',
  SkillApplied = 'skills',
  Description = 'description'
}

type IWorkExperienceFormProps = IReactProps & IDispatchProps & IWrappedByMultipleEntriesHOCProps;
type ValidateFields = Fields.JobTitle | Fields.Client | Fields.StartDate | Fields.Description;

const validationNotifier = ValidationNotifierService.getInstance();
const resumeOptionsService = ResumeOptionsService.getInstance();

const initSkillOptions = (skillsData: ISkill[] = []): DropdownItemProps[] => {
  return skillsData.reduce((skills: DropdownItemProps[], skillData, i) => {
    skillData.skill === '' ||
      skills.find((skill) => skill.value === skillData.skill) ||
      skills.push({
        key: i,
        value: skillData.skill,
        text: skillData.skill
      });
    return skills;
  }, []);
};

const maxInputLength = '75';
const maxDropdownLength = '55';
const maxDateLength = '8';

class WorkExperienceForm extends React.Component<IWorkExperienceFormProps, IWorkExperienceFormState> {
  private subscription: Subscription | undefined;
  private descriptionDefaultValue = descriptionFormat;

  private validationFields = [Fields.JobTitle, Fields.Client, Fields.StartDate, Fields.Description];

  constructor(props: IWorkExperienceFormProps) {
    super(props);
    this.state = {
      workExperienceFormData: this.props.accordionFormData as IWorkExperience,
      errors: {
        jobTitle: false,
        client: false,
        startDate: false,
        description: false,
        compareDates: false
      },
      status: SectionStatus.UNSET,
      isFormValid: true,
      enable: {
        jobTitle: false,
        profile: false,
        internalProfile: false
      }
    };
  }

  componentDidMount() {
    const { sectionId } = this.props.withMultipleEntriesProps.withFormProps;
    if (this.props.firstTimeValidation) {
      this.props.withMultipleEntriesProps.withFormProps.resumeId && this.validateFormFields(true);
    }
    this.subscription = validationNotifier.ValidationRequested.subscribe((notifiedSectionId) => {
      const { accordionIndex, activeAccordion } = this.props;
      if (sectionId === notifiedSectionId && accordionIndex === activeAccordion) {
        this.validateFormFields(this.state.status === SectionStatus.UNSET);
      }
    });
    this.checkNewOptionItems();
    const dropdowns = document.querySelectorAll(
      `.form-work-section-container.work${this.props.accordionIndex} input.search`
    );
    dropdowns.forEach((element) => element.setAttribute('maxLength', maxDropdownLength));
    const datepickers = document.querySelectorAll(
      `.form-work-section-container.work${this.props.accordionIndex} .custom-date-picker input`
    );
    datepickers.forEach((element) => element.setAttribute('maxLength', maxDateLength));
  }

  shouldComponentUpdate(nextProps: IWorkExperienceFormProps, nextState: IWorkExperienceFormState) {
    return (
      nextState.workExperienceFormData !== this.state.workExperienceFormData ||
      nextProps.activeAccordion !== this.props.activeAccordion ||
      nextProps.internalProfileOptions.length !== this.props.internalProfileOptions.length ||
      nextState.errors.jobTitle !== this.state.errors.jobTitle ||
      nextState.errors.client !== this.state.errors.client ||
      nextState.errors.startDate !== this.state.errors.startDate ||
      nextState.errors.description !== this.state.errors.description ||
      nextState.isFormValid !== this.state.isFormValid ||
      nextState.errors.compareDates !== this.state.errors.compareDates ||
      nextProps.jobOptions !== this.props.jobOptions ||
      nextState.workExperienceFormData.skills.length !==
        this.state.workExperienceFormData.skills.length ||
      nextState.workExperienceFormData.internalProfile !==
        this.state.workExperienceFormData.internalProfile ||
      nextState.enable.jobTitle !== this.state.enable.jobTitle ||
      nextState.enable.profile !== this.state.enable.profile ||
      nextState.enable.internalProfile !== this.state.enable.internalProfile ||
      nextState.status !== this.state.status ||
      this.verifyDifferenceOnSkill(
        nextProps.extraSectionData as ISkill[],
        this.props.extraSectionData as ISkill[]
      )
    );
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }

  componentDidUpdate(prevProps: IWorkExperienceFormProps, prevState: IWorkExperienceFormState) {
    const { extraSectionData, accordionIndex, activeAccordion } = this.props;

    const WorkExperienceData = { ...this.state.workExperienceFormData };
    const SkillsSource = extraSectionData as ISkill[];

    let updated = false;

    WorkExperienceData.skills = WorkExperienceData.skills.reduce((skills: string[], skill) => {
      SkillsSource.find((skillData) => skillData.skill === skill)
        ? skills.push(skill)
        : (updated = true);
      return skills;
    }, []);

    updated &&
      this.setState(
        ({ workExperienceFormData }) => ({
          workExperienceFormData: { ...workExperienceFormData, skills: WorkExperienceData.skills }
        }),
        () => {
          this.handleSaveData();
        }
      );

    if (prevProps.activeAccordion === accordionIndex && activeAccordion !== accordionIndex) {
      this.validateFormFields(this.state.status === SectionStatus.UNSET);
    }
  }

  checkNewOptionItems = () => {
    const { jobTitle, profile, internalProfile } = this.state.workExperienceFormData;
    const { addJob, addProfile, addInternalProfile, jobOptions, internalProfileOptions } = this.props;

    if (jobTitle) {
      let isNewJob = false;
      let jobOption = jobOptions.find((job) => job.value === jobTitle || job.key === jobTitle);
      if (!jobOption) {
        isNewJob = true;
        jobOption = this.generateJobOption(jobTitle);
        addJob(jobOption);
      }
      if (profile && !jobOption.profiles.some((profileOpt) => profileOpt.value === profile)) {
        const newProfileOption = this.generateProfileOption(profile);
        const refJob = isNewJob
          ? { ...jobOption, profiles: [newProfileOption] }
          : jobOptions.findIndex((jobOpt) => jobOpt.value === jobTitle);
        addProfile(newProfileOption, refJob);
      }
    }

    if (internalProfile) {
      const profileOption = internalProfileOptions.some(
        (profileOpt) => profileOpt.value === internalProfile || profileOpt.key === internalProfile
      );
      if (!profileOption) {
        const internalProfileOption: DropdownItemProps = {
          key: internalProfile,
          value: internalProfile,
          text: internalProfile
        };
        addInternalProfile(internalProfileOption);
      }
    }
  };

  render() {
    const {
      workExperienceFormData: {
        jobTitle,
        client,
        description,
        startDate,
        endDate,
        skills,
        internalProfile
      },
      errors,
      enable
    } = this.state;
    const { userRole } = this.props;
    const skillOptions = initSkillOptions(this.props.extraSectionData as ISkill[]);
    return (
      <Form error={!this.state.isFormValid}>
        <div className={`form-work-section-container work${this.props.accordionIndex}`}>
          <Form.Group data-testid='fields-0'>
            <Form.Dropdown
              className={`work-experience-control ${errors.jobTitle && 'input-error'}`}
              onSearchChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
                this.checkSearchFieldText(data, Fields.JobTitle)
              }
              onChange={this.handleDropdownChange}
              onClick={this.controlFocus}
              onAddItem={this.handleAddJob}
              label='Job Title'
              options={this.props.jobOptions || []}
              fluid
              search
              selection
              clearable
              allowAdditions={enable.jobTitle}
              value={jobTitle}
              id={Fields.JobTitle}
              width={6}
            />
            <Form.Field className={'work-experience-control'} width={10}>
              <label>Profile</label>
              <div className='work-experience-tooltip-icon optional-field'>
                <Popup
                  content='Add your main role profile on the project'
                  trigger={<Icon name='question circle' />}
                />
              </div>
              <Dropdown
                onSearchChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
                  this.checkSearchFieldText(data, Fields.Profile)
                }
                onChange={this.handleDropdownChange}
                onClick={this.controlFocus}
                onAddItem={this.handleAddProfile}
                options={this.getProfileOptions()}
                fluid
                search
                selection
                clearable
                allowAdditions={jobTitle !== '' && enable.profile}
                value={this.getProfile()}
                id={Fields.Profile}
              />
            </Form.Field>
          </Form.Group>
          {userRole === Roles.ADMIN && (
            <Form.Group data-testid='fields-1'>
              <Form.Field className={'work-experience-control optional-field'} width={6}>
                <Form.Dropdown
                  onChange={this.handleDropdownChange}
                  onAddItem={this.handleAddInternalProfile}
                  onSearchChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
                    this.checkSearchFieldText(data, Fields.InternalProfile)
                  }
                  onClick={this.controlFocus}
                  options={this.props.internalProfileOptions || []}
                  fluid
                  search
                  selection
                  clearable
                  label='Internal Profile'
                  allowAdditions={enable.internalProfile}
                  value={internalProfile || ''}
                  id={Fields.InternalProfile}
                />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Group data-testid='fields-2'>
            <Grid className='dates-container work-experience-dates'>
              <Grid.Column className='client' computer='six' tablet='six' mobile='sixteen'>
                <Form.Field width={16}>
                  <label>Employer</label>
                  <div className='work-experience-tooltip-icon'>
                    <Popup
                      content='To respect the NDA with our customers, only use Jalasoft as the Employer when referring to an outsourcing team.'
                      trigger={<Icon name='question circle' />}
                    />
                  </div>
                  <Form.Input
                    className={errors.client ? '' : 'input-color'}
                    error={errors.client}
                    id={Fields.Client}
                    onChange={this.handleFormInputChange}
                    onBlur={this.handleInputFocus}
                    value={client}
                    maxLength={maxInputLength}
                  />
                </Form.Field>
              </Grid.Column>
              <CustomDatePicker
                className={errors.startDate ? '' : 'input-color'}
                inputLabel='Start Date'
                id={Fields.StartDate}
                selectedDate={startDate}
                onChangeFunction={this.handleFormInputChange}
                error={errors.startDate}
                compareDatesError={errors.compareDates}
                isRequired
                mobileProp='sixteen'
                tabletProp='five'
                computerProp='five'
              />
              <CustomDatePicker
                inputLabel='End Date'
                id={Fields.EndDate}
                selectedDate={endDate}
                onChangeFunction={this.handleFormInputChange}
                mobileProp='sixteen'
                tabletProp='five'
                computerProp='five'
              />
            </Grid>
          </Form.Group>
          <Form.Dropdown
            className='work-experience-control'
            label='Skills Applied'
            value={skills}
            onClick={this.controlFocus}
            onChange={this.handleDropdownChange}
            options={skillOptions}
            fluid
            multiple
            search
            selection
            id={Fields.SkillApplied}
            data-testid='fields-3'
          />
          <Form.Field className='description-section' error={errors.description}>
            <div className='work-experience-tooltip-header'>
              <label>Description</label>
            </div>
            <RichTextEditor
              onQuillEditorChange={this.handleTextEditorChange}
              onQuillError={errors.description}
              placeHolder='Text'
              defaultValue={this.descriptionDefaultValue}
              value={description}
            />
          </Form.Field>
        </div>
      </Form>
    );
  }

  handleVerifyChange = (field: Fields) => {
    const { workExperienceFormData } = this.state;
    const dataProps = this.props.accordionFormData as IWorkExperience;

    workExperienceFormData[field] !== dataProps[field] && this.handleSaveData();
  };

  handleInputFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const controlId = event.currentTarget.id;
    const controlValue = event.target.value ? (event.target.value as string) : '';
    const newWorkExperienceFormData: IWorkExperience = { ...this.state.workExperienceFormData };
    newWorkExperienceFormData[controlId] = controlValue.replace(/\s\s+/g, ' ').trim();
    this.setState({ workExperienceFormData: newWorkExperienceFormData }, () => {
      this.handleVerifyChange(controlId as Fields);
    });
  };

  controlFocus = () => {
    if (this.props.sectionId !== this.props.currentSection.sectionId) {
      this.props.setCurrentSection && this.props.setCurrentSection();
    }
  };

  checkSearchFieldText = (
    data: DropdownProps,
    field: Fields.JobTitle | Fields.Profile | Fields.InternalProfile
  ) => {
    const newEnableAdd = data.searchQuery?.trim() !== '';
    const newData = { ...this.state.enable };
    newData[field] = newEnableAdd;
    this.setState({ enable: newData });
  };

  handleFormInputChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: InputOnChangeData) => {
    const controlId: string = data.id;
    const controlValue = data.value !== undefined ? (data.value as string) : '';
    const newWorkExperienceData: IWorkExperience = { ...this.state.workExperienceFormData };
    newWorkExperienceData[controlId] = controlValue;

    this.setState(
      {
        workExperienceFormData: newWorkExperienceData
      },
      () => {
        const { client, startDate, endDate } = this.state.workExperienceFormData;
        switch (controlId) {
          case 'client':
            this.props.setAccordionTitles(this.props.accordionIndex, undefined, client);
            this.handleSaveData();
            break;
          case 'startDate':
          case 'endDate':
            this.compareDates();
            this.handleVerifyChange(controlId as Fields);
            this.props.setAccordionTitles(
              this.props.accordionIndex,
              undefined,
              undefined,
              `(${startDate} - ${endDate || 'Present'})`
            );
            break;
          default:
            break;
        }
        this.validateField(controlValue, controlId as ValidateFields);
      }
    );
  };

  handleDropdownChange = (event: React.SyntheticEvent<HTMLElement, Event>, props: DropdownProps) => {
    this.setFormAsCurrent();
    const controlId: string = props.id;
    const controlValue =
      props.id !== 'skills'
        ? (props.value as string).replace(/\s+/g, ' ').trim()
        : (props.value as string);
    const newWorkExperienceData: IWorkExperience = { ...this.state.workExperienceFormData };
    newWorkExperienceData[controlId] = controlValue;

    if (controlId === Fields.JobTitle) {
      const existProfile = this.props.jobOptions
        .find((job) => job.value === controlValue)
        ?.profiles.some((profileOpt) => profileOpt.value === newWorkExperienceData.profile);
      if (!existProfile) newWorkExperienceData.profile = '';
    }

    this.setState(
      {
        workExperienceFormData: newWorkExperienceData
      },
      () => {
        controlId === Fields.JobTitle &&
          this.props.setAccordionTitles(this.props.accordionIndex, controlValue);
        this.handleVerifyChange(controlId as Fields);
        this.validateField(controlValue, controlId as ValidateFields);
      }
    );
  };

  handleTextEditorChange = (content: string) => {
    const newState = { ...this.state.workExperienceFormData };
    newState.description = content;
    this.setState(
      {
        workExperienceFormData: newState
      },
      () => {
        this.validateField(content, Fields.Description);
        this.handleSaveData();
      }
    );
  };

  inspectSectionValidation = () => {
    const { status } = this.state;
    const canCheckSectionStatus = status === SectionStatus.ERROR || status === SectionStatus.SUCCESS;

    canCheckSectionStatus && this.validateFormFields();
  };

  validateField = (value: string, field: ValidateFields) => {
    const { errors } = this.state;
    let validationStatus: boolean;
    if (field === Fields.Description) {
      validationStatus = !isValid(value, field, this.descriptionDefaultValue);
    } else {
      validationStatus = !isValid(typeof value === 'string' ? value.trim() : value, field);
    }
    const update = errors[field] !== validationStatus;
    update &&
      this.setState(
        ({ errors }) => ({
          errors: {
            ...errors,
            [field]: validationStatus
          }
        }),
        () => {
          this.verifyErrorFields();
        }
      );
    return validationStatus;
  };

  verifyErrorFields = () => {
    const { errors } = this.state;
    const { accordionError, accordionIndex, setAccordionError } = this.props;
    const status = !Object.values(errors).every((value) => value === false);
    accordionError !== status && setAccordionError(status, accordionIndex);
  };

  verifyDifferenceOnSkill = (newSkills: ISkill[], skill: ISkill[]): boolean => {
    let different = newSkills.length !== skill.length;
    for (let i = 0, n = newSkills.length; i < n && different === false; ++i) {
      different = skill[i].skill !== newSkills[i].skill;
    }
    return different;
  };

  setFormAsCurrent = () => {
    const {
      sectionId: mySectionId,
      currentSection: { sectionId: currentSectionId }
    } = this.props.withMultipleEntriesProps.withFormProps;
    if (mySectionId !== currentSectionId) {
      this.props.withMultipleEntriesProps.withFormProps.setCurrentSection(mySectionId);
    }
  };

  validateFormFields = (updated = false) => {
    this.compareDates();
    const { compareDates } = this.state.errors;
    const areCommonFieldsValid = this.validationFields.reduce((status, field) => {
      const successfullyValidated = this.validateField(
        this.state.workExperienceFormData[field] as string,
        field as ValidateFields
      );
      return status && successfullyValidated;
    }, true);

    const isFormValid: boolean = areCommonFieldsValid && !compareDates;
    const status = !isFormValid ? SectionStatus.SUCCESS : SectionStatus.ERROR;
    this.setState({ status });
    updated && this.props.setAccordionError(isFormValid, this.props.accordionIndex);
  };

  handleSaveData = debounce(() => {
    const { workExperienceFormData } = this.state;
    const { accordionIndex, withMultipleEntriesProps } = this.props;
    withMultipleEntriesProps.withFormProps.saveFormSection(
      workExperienceFormData,
      withMultipleEntriesProps.withFormProps.propertyKey,
      accordionIndex
    );
  }, formsDebounceTime);

  getOptionFormDropdown = (
    value: string,
    options: DropdownItemProps[]
  ): [boolean, DropdownItemProps] => {
    let existOption = true;
    let option = options.find((option) => option.value === value);

    if (!option) {
      existOption = false;
      option = {
        key: value,
        value: value,
        text: value
      };
    }

    return [existOption, option];
  };

  generateJobOption = (newJob: string): IWorkExperienceOptions => {
    return {
      key: newJob,
      value: newJob,
      text: newJob,
      profiles: []
    };
  };

  generateProfileOption = (newJob: string): DropdownItemProps => {
    return {
      key: newJob,
      value: newJob,
      text: newJob
    };
  };

  handleAddProfile = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const { jobTitle } = this.state.workExperienceFormData;
    const { addProfile, jobOptions, profile } = this.props;
    const value = (data.value as string).replace(/\s+/g, ' ').trim();

    const jobIndex = jobOptions.findIndex((job) => job.value === jobTitle);
    if (jobIndex !== -1 && jobOptions[jobIndex].profiles && value) {
      profile.email &&
        resumeOptionsService.addProfile({
          job: jobTitle,
          profile: value as string,
          type: OptionType.candidate,
          userEmail: profile.email
        });
      const newProfile = this.generateProfileOption(value);
      addProfile(newProfile, jobIndex);
    }
  };

  handleAddJob = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const { addJob, profile } = this.props;
    const newJob = (data.value as string).replace(/\s+/g, ' ').trim();
    if (newJob) {
      profile.email &&
        resumeOptionsService.addJob({
          name: newJob,
          type: OptionType.candidate,
          userEmail: profile.email
        });
    }
    const newJobOption = this.generateJobOption(newJob);
    addJob(newJobOption);
  };

  handleAddInternalProfile = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const { addInternalProfile, profile } = this.props;
    const newInternalProfile = (data.value as string).replace(/\s\s+/g, ' ').trim();

    const newInternalProfileOption = {
      key: newInternalProfile,
      value: newInternalProfile,
      text: newInternalProfile
    };

    const newInternalProfileDTO: INewInternalProfile = {
      name: newInternalProfile,
      type: OptionType.candidate,
      userEmail: profile.email
    };

    addInternalProfile(newInternalProfileOption, newInternalProfileDTO);
  };

  compareDates() {
    const {
      errors,
      workExperienceFormData: { startDate, endDate }
    } = this.state;
    const response = endDateIsBeforeStartDate(startDate, endDate);
    response !== errors.compareDates &&
      this.setState(
        ({ errors }) => ({
          errors: {
            ...errors,
            compareDates: response
          }
        }),
        () => {
          this.verifyErrorFields();
        }
      );
  }

  getInitialDateFormat = (stringDate: string) => {
    return moment(stringDate).format(formatDate);
  };

  getProfileOptions = () => {
    const { jobTitle } = this.state.workExperienceFormData;
    const { jobOptions } = this.props;
    let profiles: DropdownItemProps[] = [];
    if (jobTitle && jobOptions) {
      const selectedJob = jobOptions.find((job) => job.value === jobTitle || job.key === jobTitle);
      if (selectedJob) {
        profiles = selectedJob.profiles;
      }
    }
    return profiles;
  };

  getProfile = () => {
    const { jobTitle, profile } = this.state.workExperienceFormData;
    const { jobOptions } = this.props;

    if (jobOptions) {
      const selectedJobIndex = jobOptions.findIndex((job) => job.value === jobTitle);
      if (selectedJobIndex !== -1) {
        const selectedProfile = jobOptions[selectedJobIndex].profiles.find(
          (jobProfile) => jobProfile.value === profile
        );
        return selectedProfile ? selectedProfile.value : '';
      }
    }
    return '';
  };
}

const mapStateToProps = (state: RootStore) => {
  return {
    userRole: state.dashboard.authData.userRole,
    profile: state.dashboard.authData.profile,
    jobOptions: state.dashboard.resumeOptions.profiles,
    internalProfileOptions: state.dashboard.resumeOptions.internalProfiles
  };
};

const mapDispatchProps = () => ({
  addJob: AddJob,
  addInternalProfile: AddInternalProfileOption,
  addProfile: AddProfile
});

export default connect(mapStateToProps, mapDispatchProps())(WorkExperienceForm);
