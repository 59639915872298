import { useState, useEffect } from 'react';

const useComponentVisible = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    const currentWindowSize = window.innerWidth;
    setWindowWidth(currentWindowSize);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize, true);
    return () => {
      window.removeEventListener('resize', handleResize, true);
    };
  });
  return { windowWidth };
};

export default useComponentVisible;
