import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  sectionContainer: {
    left: 75.75,
    width: 457.5
  },
  sectionTitle: {
    fontSize: 12,
    color: '#0079A3',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    marginBottom: 9
  },
  languagesContainer: {
    position: 'relative'
  },
  language: {
    backgroundColor: '#BFBFBF33',
    borderLeft: '3.75 solid #DAE6EA',
    marginBottom: 6.75,
    paddingBottom: 3,
    paddingLeft: 12.75,
    paddingTop: 4.5
  },
  languageTitle: {
    color: '#0079A3',
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Helvetica Neue Bold'
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6.75,
    marginBottom: 8.25
  },
  languageType: {
    fontSize: 9,
    fontWeight: 'medium',
    fontFamily: 'Helvetica Neue',
    marginRight: 9
  },
  squareLevel: {
    marginRight: 9
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 5,
    marginTop: 12
  },
  contentLevel: {
    flexDirection: 'column',
    marginRight: 23.64
  },
  indicator: {
    marginBottom: 3.7275
  },
  levelName: {
    fontSize: 7.5,
    fontWeight: 'medium',
    fontFamily: 'Helvetica Neue'
  },
  headerLanguage: {
    flexDirection: 'row'
  },
  native: {
    fontSize: 9,
    fontWeight: 'medium',
    fontFamily: 'Helvetica Neue',
    marginLeft: 9
  }
});
