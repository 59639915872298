import { Rect, Svg } from '@react-pdf/renderer';
import React from 'react';

interface IRectangleLevelProps {
  width?: string;
  height?: string;
  primaryColor?: string;
  secondColor?: string;
  level: number;
  levelsNumber: number;
  highLightColor?: string;
}

export const RectangleLevel: React.FC<IRectangleLevelProps> = ({
  level,
  levelsNumber,
  highLightColor
}) => {
  const itemColor = highLightColor || '#d20000';
  const itemLevel = (index: number) => {
    const checkedItem = index < level ? itemColor : '#b9b9b9';
    return (
      <Rect
        key={`rectangle-${index}`}
        x={`${index * 7}`}
        y='0'
        width='5.25'
        height='3.5'
        fill={checkedItem}
      />
    );
  };

  return (
    <Svg width='24.9375' height='2.625' viewBox='0 0 33.25 3.5'>
      {Array(levelsNumber)
        .fill(0)
        .map((_, index) => {
          return itemLevel(index);
        })}
    </Svg>
  );
};
