export enum ActivePictureEnum {
  DISABLE_PICTURE,
  SYSTEM_PICTURE,
  CUSTOM_PICTURE
}

export interface IPictureData {
  activePicture: ActivePictureEnum;
  systemPicture: string;
  customPicture: string;
  customPictureId: string;
}

export interface IPersonalInformation {
  [key: string]: string | boolean | IPictureData;
  fullName: string;
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
  profesionalSummary: string;
  enabled: boolean;
  pictures: IPictureData;
  mainJob: string;
  mainInternalProfile: string;
}
