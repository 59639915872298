import React, { useEffect } from 'react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import GridColumn from 'semantic-ui-react/dist/commonjs/collections/Grid/GridColumn';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';

import styles from './CustomLoader.module.scss';
import logo from '../../../Assets/mon-cv-loading.gif';

export const FileLoader = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.loaderContainer}>
      <img className={styles.image} src={logo} alt='Loading...' />
    </div>
  );
};

const TopBarLoader = () => {
  return (
    <div className={`${styles.progressTopBarLoader}`} data-testid='top-bar-loader'>
      <div className={`${styles.topBarItem}  ${styles.animation}`}>
        <div className={styles.activity}></div>
      </div>
      <div className={`${styles.topBarItem}  ${styles.animation}`}>
        <div className={styles.activity}></div>
      </div>
      <div className={`${styles.topBarItem}  ${styles.animation}`}>
        <div className={styles.activity}></div>
      </div>
      <div className={`${styles.topBarItem}  ${styles.animation}`}>
        <div className={styles.activity}></div>
      </div>
      <div className={`${styles.topBarItem}  ${styles.animation}`}>
        <div className={styles.activity}></div>
      </div>
    </div>
  );
};

const BodyLoader = () => {
  return (
    <>
      <Grid className={styles.formGlobalContainer}>
        <GridRow className={styles.formRow}>
          <GridColumn only='computer' className={styles.leftSidebarGrid}>
            <TopBarLoader />
          </GridColumn>
          <GridColumn className={styles.formGrid}>
            {
              <>
                <div className={styles.customLoader}>
                  <div className={styles.formContainer} data-testid='body-loader'>
                    <div
                      className={`${styles.loaderItem} ${styles.animation} ${styles.personalInformation}`}
                    >
                      <div className={styles.activity}></div>
                    </div>
                    <div className={`${styles.loaderItem} ${styles.animation} ${styles.skill}`}>
                      <div className={styles.activity}></div>
                    </div>
                    <div className={`${styles.loaderItem} ${styles.animation} ${styles.workExperience}`}>
                      <div className={styles.activity}></div>
                    </div>
                    <div className={`${styles.loaderItem} ${styles.animation} ${styles.education}`}>
                      <div className={styles.activity}></div>
                    </div>
                    <div className={`${styles.loaderItem} ${styles.animation} ${styles.language}`}>
                      <div className={styles.activity}></div>
                    </div>
                  </div>
                  <div className={styles.progressSideBarContainer}>
                    <div
                      className={`${styles.loaderProgressSideBarTable} ${styles.animation}`}
                      id='progress-bar'
                    >
                      <div className={`${styles.activity} ${styles.progressActivity}`}></div>
                    </div>
                  </div>
                </div>
              </>
            }
          </GridColumn>
          <GridColumn only='computer' className={styles.progressSideBarGrid}></GridColumn>
        </GridRow>
      </Grid>
    </>
  );
};

const CustomLoader = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <BodyLoader />;
};
export default CustomLoader;
