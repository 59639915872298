import React from 'react';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

import './LanguagesForm.scss';
import { Levels } from '../../../../constants/dropDownItems';
import { IWrappedByMultipleEntriesHOCProps } from '../../../../HOCs/WithMultipleEntries/IWrappedByMultipleEntriesHOCProps';
import { SectionStatus } from '../../../../Models/FormResumeSection';
import { ILanguage, ILanguageOptions } from '../../../../Models/ILanguages';
import { INewLanguage, OptionType } from '../../../../Models/INewOptions';
import { AddLanguageOption } from '../../../../redux/actions/dashboardActions';
import { RootStore } from '../../../../redux/store';
import { ValidationNotifierService } from '../../../../services/validationNotifier.service';
import { isValid } from '../../../../utils/validation/formRules';
import { formsDebounceTime } from '../../../../constants/globalConstants';

interface ILanguagesFormState {
  languageFormData: ILanguage;
  errors: {
    language: boolean;
    reading: boolean;
    listening: boolean;
    writing: boolean;
    speaking: boolean;
  };
  status: SectionStatus;
  isFormValid: boolean;
  enableAddLanguage: boolean;
}

interface IStoreProps {
  userEmail: string;
  languageOptions: ILanguageOptions[];
}

interface IDispatchProps {
  addLanguageOption: (newLanguageOption: ILanguageOptions, newOption?: INewLanguage | undefined) => void;
}

type ILanguagesFormProps = IStoreProps & IDispatchProps & IWrappedByMultipleEntriesHOCProps;
const validationNotifier = ValidationNotifierService.getInstance();
const languageMaxLength = '55';

export enum Fields {
  language = 'language',
  reading = 'reading',
  listening = 'listening',
  writing = 'writing',
  speaking = 'speaking'
}

type validateFields =
  | Fields.language
  | Fields.listening
  | Fields.reading
  | Fields.speaking
  | Fields.writing;

let timer: ReturnType<typeof setTimeout>;
class LanguagesForm extends React.Component<ILanguagesFormProps, ILanguagesFormState> {
  private validationFields = [
    Fields.language,
    Fields.listening,
    Fields.reading,
    Fields.speaking,
    Fields.writing
  ];

  private subscription: Subscription | undefined;

  constructor(props: ILanguagesFormProps) {
    super(props);
    this.state = {
      languageFormData: this.props.accordionFormData as ILanguage,
      errors: {
        language: false,
        listening: false,
        reading: false,
        speaking: false,
        writing: false
      },
      status: SectionStatus.UNSET,
      isFormValid: true,
      enableAddLanguage: false
    };
  }

  componentDidMount() {
    this.checkNewOptionItems();
    const { sectionId } = this.props.withMultipleEntriesProps.withFormProps;
    if (this.props.firstTimeValidation) {
      this.props.withMultipleEntriesProps.withFormProps.resumeId && this.validateFormFields(true);
    }
    this.subscription = validationNotifier.ValidationRequested.subscribe((notifiedSectionId) => {
      const { accordionIndex, activeAccordion } = this.props;
      if (sectionId === notifiedSectionId && accordionIndex === activeAccordion) {
        this.validateFormFields(this.state.status === SectionStatus.UNSET);
      }
    });
    const dropdowns = document.querySelectorAll(
      `.form-languages-container.languages${this.props.accordionIndex} #language input.search`
    );
    dropdowns.forEach((element) => element.setAttribute('maxLength', languageMaxLength));
  }

  shouldComponentUpdate(nextProps: ILanguagesFormProps, nextState: ILanguagesFormState) {
    return (
      nextState.languageFormData !== this.state.languageFormData ||
      nextProps.languageOptions.length !== this.props.languageOptions.length ||
      nextState.errors !== this.state.errors ||
      nextState.enableAddLanguage !== this.state.enableAddLanguage ||
      nextState.isFormValid !== this.state.isFormValid ||
      nextProps.activeAccordion !== this.props.activeAccordion
    );
  }

  componentDidUpdate(prevProps: ILanguagesFormProps, prevState: ILanguagesFormState) {
    const { activeAccordion, accordionIndex } = this.props;

    if (prevProps.activeAccordion === accordionIndex && activeAccordion !== accordionIndex) {
      this.validateFormFields(this.state.status === SectionStatus.UNSET);
    }
  }

  checkNewOptionItems = () => {
    const { addLanguageOption, languageOptions } = this.props;
    const { language } = this.state.languageFormData;

    const languageOption =
      language !== '' &&
      !languageOptions.some(
        (languageOpt) => languageOpt.value === language || languageOpt.key === language
      );
    if (languageOption) {
      const newLanguageOption: ILanguageOptions = {
        key: language,
        value: language,
        text: language,
        orderBy: languageOptions.length
      };
      addLanguageOption(newLanguageOption);
    }
  };

  componentWillUnmount() {
    clearTimeout(timer);
    this.subscription?.unsubscribe();
  }

  render() {
    const {
      languageFormData: { language, listening, reading, speaking, writing },
      enableAddLanguage,
      errors
    } = this.state;
    return (
      <Form error={!this.state.isFormValid} data-testid='language-form'>
        <div className={`form-languages-container languages${this.props.accordionIndex}`}>
          <Form.Group>
            <Form.Dropdown
              className={`languages-control ${errors.language && 'input-error'}`}
              onSearchChange={this.checkSearchFieldText}
              onChange={this.handleDropdownChange}
              onClick={this.controlFocus}
              onAddItem={this.handleAddLanguage}
              label='Language'
              options={this.orderLanguageOptions(this.props.languageOptions)}
              fluid
              search
              selection
              clearable
              allowAdditions={enableAddLanguage}
              value={language}
              id={Fields.language}
              width={8}
              data-testid='languageDropdown'
            />
          </Form.Group>
          <Form.Group>
            <Form.Dropdown
              className={`languages-control ${errors.reading && 'input-error'}`}
              onChange={this.handleDropdownChange}
              onClick={this.controlFocus}
              label='Reading'
              options={Levels}
              fluid
              selection
              clearable
              value={reading}
              id={Fields.reading}
              width={4}
            />
            <Form.Dropdown
              className={`languages-control ${errors.listening && 'input-error'}`}
              onChange={this.handleDropdownChange}
              onClick={this.controlFocus}
              label='Listening'
              options={Levels}
              fluid
              selection
              clearable
              value={listening}
              id={Fields.listening}
              width={4}
            />
            <Form.Dropdown
              className={`languages-control ${errors.writing && 'input-error'}`}
              onChange={this.handleDropdownChange}
              onClick={this.controlFocus}
              label='Writing'
              options={Levels}
              fluid
              selection
              clearable
              value={writing}
              id={Fields.writing}
              width={4}
            />
            <Form.Dropdown
              className={`languages-control ${errors.speaking && 'input-error'}`}
              onChange={this.handleDropdownChange}
              onClick={this.controlFocus}
              label='Speaking'
              options={Levels}
              fluid
              selection
              clearable
              value={speaking}
              id={Fields.speaking}
              width={4}
            />
          </Form.Group>
        </div>
      </Form>
    );
  }

  controlFocus = () => {
    if (this.props.sectionId !== this.props.currentSection.sectionId) {
      this.props.setCurrentSection && this.props.setCurrentSection();
    }
  };

  handleDropdownChange = (event: React.SyntheticEvent<HTMLElement, Event>, props: DropdownProps) => {
    this.setFormAsCurrent();
    const controlId: string = props.id;
    const controlValue = (props.value as string).trim();
    const newLanguageData: ILanguage = { ...this.state.languageFormData };
    newLanguageData[controlId] = controlValue;

    this.setState(
      {
        languageFormData: newLanguageData
      },
      () => {
        if (controlId === Fields.language) {
          this.props.setAccordionTitles(this.props.accordionIndex, controlValue);
        }
        this.validateField(controlValue, controlId as validateFields);
        this.handleVerifyChange(controlId as validateFields);
      }
    );
  };

  handleVerifyChange = (field: validateFields) => {
    const { languageFormData } = this.state;
    const dataProps = this.props.accordionFormData as ILanguage;

    languageFormData[field] !== dataProps[field] && this.handleSaveData();
  };

  validateField = (value: string, field: validateFields) => {
    const { errors } = this.state;
    const validationStatus = !isValid(typeof value === 'string' ? value.trim() : value, field);

    const update = errors[field] !== validationStatus;
    update &&
      this.setState(
        ({ errors }) => ({
          errors: {
            ...errors,
            [field]: validationStatus
          }
        }),
        () => {
          this.verifyErrorFields();
        }
      );
    return validationStatus;
  };

  verifyErrorFields = () => {
    const { errors, status } = this.state;
    const { accordionIndex, setAccordionError } = this.props;
    const hasErrors = Object.values(errors).some((error) => error);
    if (status !== SectionStatus.UNSET) {
      const newStatus = hasErrors ? SectionStatus.ERROR : SectionStatus.SUCCESS;
      this.setState({ isFormValid: !hasErrors, status: newStatus });
    }
    setAccordionError(hasErrors, accordionIndex);
  };

  // this method is needed since dropdown from semantic ui is stoping event propagation so the form section never knows it has been focused again
  setFormAsCurrent = () => {
    const {
      sectionId: mySectionId,
      currentSection: { sectionId: currentSectionId }
    } = this.props.withMultipleEntriesProps.withFormProps;
    if (mySectionId !== currentSectionId) {
      this.props.withMultipleEntriesProps.withFormProps.setCurrentSection(mySectionId);
    }
  };

  validateFormFields = (updated = false) => {
    const isFormValid = this.validationFields.reduce((status, field) => {
      const successfullyValidated = this.validateField(
        this.state.languageFormData[field] as string,
        field as validateFields
      );
      return status && successfullyValidated;
    }, true);

    const status = isFormValid ? SectionStatus.SUCCESS : SectionStatus.ERROR;
    this.setState({ status });
    updated && this.props.setAccordionError(isFormValid, this.props.accordionIndex);
  };

  saveDataAccordingFocus() {
    const { languageFormData } = this.state;
    const { withMultipleEntriesProps } = this.props;
    withMultipleEntriesProps.withFormProps.saveFormSection(
      languageFormData,
      withMultipleEntriesProps.withFormProps.propertyKey,
      this.props.accordionIndex
    );
  }

  handleSaveData = () => {
    clearTimeout(timer);
    const { currentSection, sectionId } = this.props;
    if (currentSection.sectionId !== sectionId) {
      timer = setTimeout(() => {
        this.saveDataAccordingFocus();
      }, formsDebounceTime);
    } else {
      this.saveDataAccordingFocus();
    }
  };

  orderLanguageOptions = (languageOptions: ILanguageOptions[]) => {
    return languageOptions
      .sort((languageA, languageB) => languageA.orderBy - languageB.orderBy)
      .map((language) => ({
        key: language.key,
        value: language.value,
        text: language.text
      }));
  };

  checkSearchFieldText = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { enableAddLanguage } = this.state;
    const newEnableAdd = data.searchQuery?.trim() !== '';
    if (enableAddLanguage !== newEnableAdd) {
      this.setState({
        enableAddLanguage: newEnableAdd
      });
    }
  };

  handleAddLanguage = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const { languageOptions, addLanguageOption } = this.props;
    const userEmail = this.props.userEmail;
    const value = (data.value as string).trim();
    const newLanguageOptions = [...languageOptions];

    const newLanguageDTO: INewLanguage = {
      name: value,
      type: OptionType.candidate,
      userEmail: userEmail
    };

    const newLanguage: ILanguageOptions = {
      key: value,
      value: value,
      text: value,
      orderBy: newLanguageOptions.length
    };

    addLanguageOption(newLanguage, newLanguageDTO);
  };
}

const mapStateToProps = (state: RootStore): IStoreProps => {
  return {
    userEmail: state.dashboard.authData.profile.email,
    languageOptions: state.dashboard.resumeOptions.languages
  };
};

export default connect(mapStateToProps, { addLanguageOption: AddLanguageOption })(LanguagesForm);
