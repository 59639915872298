import Axios, { AxiosResponse } from 'axios';
import JwtDecode from 'jwt-decode';

import { loginApiURL } from '../../constants/settings';
import { IUserSetting } from '../../Models/IUserSetting';
import { AuthData } from '../../utils/authData';

interface IUserData {
  email: string;
  iat: number;
  id: string;
  name: string;
  role: string;
  sharedResumes: string[];
  userSetting: IUserSetting;
}

const axiosInstance = Axios.create({ headers: { 'Content-Type': 'application/json' } });

export class AuthAPIService {
  private static instance: AuthAPIService;

  public static getInstance(): AuthAPIService {
    if (!AuthAPIService.instance) {
      AuthAPIService.instance = new AuthAPIService();
    }
    return AuthAPIService.instance;
  }

  private authMonCVApi(): Promise<AxiosResponse> {
    const authData = AuthData();
    const loginDetails = {
      email: authData.profile.email,
      access_token: authData.access_token
    };
    return axiosInstance.post(loginApiURL!, loginDetails);
  }

  public async getAuthUser(): Promise<IUserData> {
    let token = '';
    const storedTokenValue = this.getToken();
    if (storedTokenValue) {
      token = storedTokenValue;
    } else {
      const loginMonCVResponse = await this.authMonCVApi();
      token = loginMonCVResponse.data.access_token;
      window.sessionStorage.setItem('access_token', JSON.stringify(token));
    }
    const session = JwtDecode<IUserData>(token);
    return session;
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem('access_token');
  }
}
