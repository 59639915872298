import { debounce } from 'lodash';
import React, { useCallback, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import ButtonProps from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';

import './ResumeMergeForm.scss';

import RemoteDropdown from './RemoteDropdown/RemoteDropdown';
import { showToastMessage, ToastEnum } from '../../Toast/Toast';
import LettersAvatar from '../../User/LettersAvatar';
import { resumesPerPage } from '../../../constants/settings';
import { IResumeFormBasic } from '../../../Models/IResumeFormBasic';
import { GetResumes } from '../../../redux/actions/dashboardActions';
import { RootStore } from '../../../redux/store';
import { ResumeService } from '../../../services/resumes/resume.service';

const DEBOUNCE_WAIT = 700;
const resumesService = ResumeService.getInstance();

interface IResumeMergeFormState {
  sourceOptions: DropdownItemProps[];
  sourceDefault?: string;
  sourceValue: string;
  targetOptions: DropdownItemProps[];
  targetValue: string;
  data: DropdownItemProps[];
  searchQuantity: number;
  currentValue?: string;
  targetQuery: string;
  sourceQuery: string;
}

interface IResumeMergeFormProps {
  click: Function;
  sourceData: { resumeId: string; fullName: string; picture: string; email: string };
  open: boolean;
  closeFunction: () => void;
}

const initState: IResumeMergeFormState = {
  sourceOptions: [],
  targetOptions: [],
  sourceValue: '',
  targetValue: '',
  targetQuery: '',
  sourceQuery: '',
  data: [],
  searchQuantity: 24
};

enum ActionKind {
  TargetSelect = 'TARGET_SELECT',
  SourceSelect = 'SOURCE_SELECT',
  SetSourceOptions = 'SET_SOURCE_OPTIONS',
  SetTargetOptions = 'SET_TARGET_OPTIONS',
  SetSourceQuery = 'SET_SORCE_QUERY',
  SetTargetQuery = 'SET_TARGET_QUERY',
  Clean = 'CLEAN'
}

type Action = {
  type: ActionKind;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  payload: any;
};

const mergeReducer = (state: IResumeMergeFormState, action: Action): IResumeMergeFormState => {
  const { type, payload } = action;
  const actionReduce = {
    [ActionKind.TargetSelect]: {
      ...state,
      targetValue: payload.targetValue
    },
    [ActionKind.SourceSelect]: {
      ...state,
      sourceValue: payload.sourceValue
    },
    [ActionKind.SetSourceOptions]: {
      ...state,
      sourceOptions: payload.sourceOptions,
      sourceDefault: payload.sourceDefault,
      sourceValue: payload.sourceValue
    },
    [ActionKind.SetTargetOptions]: {
      ...state,
      targetOptions: payload.targetOptions
    },
    [ActionKind.Clean]: {
      ...state,
      sourceOptions: []
    },
    [ActionKind.SetTargetQuery]: {
      ...state,
      targetQuery: payload.targetQuery
    },
    [ActionKind.SetSourceQuery]: {
      ...state,
      sourceQuery: payload.sourceQuery
    }
  };
  return actionReduce[type];
};
export interface IOption {
  key: string;
  text: Element;
  value: string;
}

interface IResume {
  resumeId: string;
  picture: string;
  fullName: string;
  email: string;
}

const ResumeMergeForm: React.FC<IResumeMergeFormProps> = (props: IResumeMergeFormProps) => {
  const [state, dispatchState] = useReducer(mergeReducer, initState);
  const { sourceData, open, closeFunction } = props;
  const { searchCriteria } = useSelector((state: RootStore) => state.dashboard);
  const dispatch = useDispatch();
  const disabledButton =
    state.targetValue === state.sourceValue || state.targetValue === '' || state.sourceValue === '';

  const getData = async (sourceValue: string, query: string, id: string) => {
    const { data } =
      query !== ''
        ? await resumesService.getResumesToMerge({ searchCriteria: query, quantity: resumesPerPage })
        : { data: undefined };
    if (data) {
      const resumes = data.resumes.map((resume: IResumeFormBasic) => ({
        resumeId: resume._id,
        fullName: resume.personalInformation.fullName,
        email: resume.personalInformation.email,
        picture: resume.personalInformation.pictures.systemPicture
      }));
      const newOptions = formatOptions(resumes);

      if (id === 'source') {
        dispatchState({
          type: ActionKind.SetSourceOptions,
          payload: { sourceOptions: [...newOptions], sourceQuery: query, sourceValue }
        });
      } else {
        dispatchState({
          type: ActionKind.SetTargetOptions,
          payload: { targetOptions: [...newOptions], targetQuery: query }
        });
      }
    }
  };

  const handleOnMountModal = () => {
    const srcOptions = formatOptions([sourceData]);
    dispatchState({
      type: ActionKind.SetSourceOptions,
      payload: {
        sourceOptions: [...srcOptions],
        sourceDefault: srcOptions[0].value,
        sourceValue: srcOptions[0].value
      }
    });
  };

  const saveDataButton = async () => {
    try {
      await resumesService.mergeResume(state.sourceValue, state.targetValue);
      closeFunction();
      const currentPage = sessionStorage.getItem('page');
      const page = currentPage ? parseInt(currentPage) : 1;
      const { criteria, filter, sort, visibility, refinement } = searchCriteria;
      dispatch(GetResumes(page, resumesPerPage, criteria, filter, sort, visibility, refinement));
      showToastMessage('Migrated successfully', ToastEnum.SUCCESS);
    } catch (error) {
      showToastMessage('Something wrong, please try again', ToastEnum.ERROR);
    }
  };

  const onClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    closeFunction();
  };

  const clickIcon = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
    props.click(event);
  };

  const searchData = useCallback(
    debounce((sourceValue, value: string, id) => {
      getData(sourceValue, value, id);
    }, DEBOUNCE_WAIT),
    []
  );

  const handleSearch = async (query: string, id?: string) => {
    const value = query.trim();
    await searchData(state.sourceValue, value, id as string);
  };

  const onChange = (value: string, id?: string) => {
    if (id === 'source') {
      dispatchState({ type: ActionKind.SourceSelect, payload: { sourceValue: value } });
    } else {
      dispatchState({ type: ActionKind.TargetSelect, payload: { targetValue: value } });
    }
  };

  const formatOptions = (dataOptions: IResume[]) => {
    return dataOptions.map((option) => {
      return {
        key: `${option.resumeId}`,
        text: `${option.fullName} ${option.email}`,
        content: (
          <Header as='h5' key={option.resumeId} className='option-content'>
            {option.picture ? (
              <Image src={option.picture} size='tiny' circular />
            ) : (
              <LettersAvatar className='merge-avatar' userName={option.fullName} size='tiny' />
            )}
            <Header.Content className='content-text'>
              {option.fullName}
              <Header.Subheader className='email-text'>{option.email}</Header.Subheader>
            </Header.Content>
          </Header>
        ),
        value: `${option.resumeId}`
      };
    });
  };

  const getTargetOptions = () => {
    let targetOptions: DropdownItemProps[] = [];
    if (state.targetOptions.length) {
      targetOptions = state.targetOptions.filter((option) => option.key !== state.sourceValue);
    }
    return targetOptions;
  };

  const getSourceOptions = () => {
    let sourceOptions: DropdownItemProps[] = [];
    if (state.sourceOptions.length) {
      sourceOptions = state.sourceOptions.filter((option) => option.key !== state.targetValue);
    }
    return sourceOptions;
  };

  return (
    <Modal
      className='modal-merge-resume-container'
      closeIcon
      dimmer={'inverted'}
      open={open}
      onClose={onClose}
      onClick={clickIcon}
      onMount={handleOnMountModal}
    >
      <Header className='header-title'>Migrate</Header>
      <Modal.Content>
        <Menu horizontal='true' borderless stackable>
          <Menu vertical fluid className='vertical-menu'>
            <Menu.Item header>Source Resume</Menu.Item>
            <Menu.Item>
              <RemoteDropdown
                value={state.sourceValue}
                options={getSourceOptions()}
                onChange={onChange}
                onSearch={handleSearch}
                id='source'
              />
            </Menu.Item>
          </Menu>
          <Menu vertical fluid className='vertical-menu'>
            <Menu.Item header>Target Resume</Menu.Item>
            <Menu.Item>
              <RemoteDropdown
                value={state.targetValue}
                options={getTargetOptions()}
                onChange={onChange}
                onSearch={handleSearch}
                id='target'
              />
            </Menu.Item>
          </Menu>
        </Menu>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className='merge-modal-button'
          onClick={saveDataButton}
          data-testid='merge-modal-button-id'
          disabled={disabledButton}
        >
          <Icon name='clone outline'></Icon>
          <span className='text-button'>Migrate</span>
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default React.memo(ResumeMergeForm);
