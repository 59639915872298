import React, { Dispatch, SetStateAction } from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';

import ImageEditor from './ImageEditor';
import styles from './UploadImage.module.scss';
import { CloseIcon } from '../../../../Assets/CustomIcons';
import { ActivePictureEnum } from '../../../../Models/IPersonalInformation';

interface IUploadPhoto {
  open: boolean;
  handleOpen: Dispatch<SetStateAction<boolean>>;
  setDropDownValue: Dispatch<SetStateAction<ActivePictureEnum>>;
}

const UploadPictureForm: React.FC<IUploadPhoto> = ({ children, open, handleOpen, setDropDownValue }) => {
  const closeModal = () => {
    handleOpen(false);
  };

  return (
    <Modal
      className={`${styles.modal} upload-picture-modal`}
      closeIcon={<CloseIcon className={styles.closeIcon} />}
      trigger={children}
      open={open}
      onClose={closeModal}
      dimmer='blurring'
    >
      <ImageEditor closeModal={closeModal} setDropDownValue={setDropDownValue} />
    </Modal>
  );
};

export default UploadPictureForm;
