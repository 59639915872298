import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import GridColumn from 'semantic-ui-react/dist/commonjs/collections/Grid/GridColumn';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image/Image';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import { io } from 'socket.io-client';

import LettersAvatar from './LettersAvatar';
import styles from './User.module.scss';
import avatar from '../../Assets/avatar.jpg';
import { LogOutIcon } from '../../Assets/CustomIcons';
import { baseAPIURL, logoutApiURL, Configuration } from '../../constants/settings';
import { RootStore } from '../../redux/store';

interface ILogoutToken {
  sub: string;
}

export const User: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [showPicture, setShowPicture] = useState(true);
  const [, webSocketConnection] = baseAPIURL ? baseAPIURL.split('://') : ['', ''];
  const socket = io((location.protocol === 'https:' ? 'wss' : 'ws') + `://${webSocketConnection}`);
  const {
    // eslint-disable-next-line camelcase
    authData: { profile, id_token, userName }
  } = useSelector((state: RootStore) => state.dashboard);

  const { picture } = profile;

  const handleLogOut = async () => {
    setOpen(false);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${logoutApiURL}?redirect_uri=${Configuration.post_logout_redirect_uri}`;
  };

  socket.on('closeSession', (closeObject) => {
    const { logout_token: logoutToken } = closeObject;
    const decodedLogoutToken = jwtDecode(logoutToken) as ILogoutToken;
    const storageToken =
      sessionStorage.getItem(`oidc.user:${Configuration.authority}:mon-cv`) || '{ profile: { sub: "" }}';
    const jsonStorageToken = JSON.parse(storageToken);

    if (jsonStorageToken.profile && jsonStorageToken.profile.sub) {
      if (decodedLogoutToken.sub === jsonStorageToken.profile.sub) {
        handleLogOut();
      }
    }
  });

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const onErrorPicture = (e: any) => {
    e.target.src = avatar;
    setShowPicture(false);
  };

  const pictureRender = () => {
    if (showPicture && picture) {
      return <Image src={profile.picture} className={styles.userPicture} onError={onErrorPicture} />;
    }
    return <LettersAvatar userName={userName} className={styles.letterAvatar} />;
  };

  return (
    <Grid>
      <Popup
        on='click'
        basic={true}
        trigger={
          <GridRow className={styles.profileContainer}>
            <GridColumn computer={3} tablet={3}>
              {pictureRender()}
            </GridColumn>
            <GridColumn
              textAlign='right'
              verticalAlign='middle'
              only='computer tablet'
              className={styles.userName}
              computer={13}
              tablet={13}
            >
              {userName}
            </GridColumn>
          </GridRow>
        }
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        position='bottom left'
        className={styles.userOptions}
      >
        <div className={styles.option} onClick={handleLogOut}>
          <LogOutIcon className={styles.optionIcon} />
          <span className={styles.optionText}>Log Out</span>
        </div>
      </Popup>
    </Grid>
  );
};
