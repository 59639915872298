import { View } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './Divider.styles';

const CircleSeparator: React.FC = () => {
  return (
    <View style={styles.circleSeparatorContainer}>
      <View style={styles.circle} wrap={false}></View>
      <View style={styles.line}></View>
    </View>
  );
};

export default CircleSeparator;
