import { AuthAPIService } from '../services/auth/authAPIService';

const authService = AuthAPIService.getInstance();

export function GetRequestHeader() {
  const token = authService.getToken();
  const accessToken = token ? JSON.parse(token) : '';
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
}
