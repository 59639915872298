import React from 'react';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import './SkillsForm.scss';
import { SkillLevels } from '../../../../constants/dropDownItems';
import { SkillToolTipInfo } from '../../../../constants/toolTipInfo';
import { IWrappedByMultipleEntriesHOCProps } from '../../../../HOCs/WithMultipleEntries/IWrappedByMultipleEntriesHOCProps';
import { SectionStatus } from '../../../../Models/FormResumeSection';
import { INewArea, INewSkill, OptionType } from '../../../../Models/INewOptions';
import { ISkillOption, IAreaOptions } from '../../../../Models/ISkillOptions';
import { ISkill, SkillFields } from '../../../../Models/ISkills';
import { AddArea, AddSkill } from '../../../../redux/actions/dashboardActions';
import { RootStore } from '../../../../redux/store';
import { ValidationNotifierService } from '../../../../services/validationNotifier.service';
import { isValid } from '../../../../utils/validation/formRules';
import { formsDebounceTime } from '../../../../constants/globalConstants';

interface ISkillFormState {
  skillFormData: ISkill;
  errors: {
    area: false;
    level: false;
    skill: false;
  };
  enableAddArea: boolean;
  enableAddSkill: boolean;
  status: SectionStatus;
  isFormValid: boolean;
}

interface IStoreProps {
  userEmail: string;
  skillOptions: IAreaOptions[];
}

interface IDisptachProps {
  addAreaOption: (newSkillOption: IAreaOptions, newAreaDTO?: INewArea | undefined) => void;
  addSkillOption: (
    newSkillOption: ISkillOption,
    areaOption: IAreaOptions | number,
    newSkillDTO?: INewSkill | undefined
  ) => void;
}

type ISkillFormProps = IStoreProps & IDisptachProps & IWrappedByMultipleEntriesHOCProps;

export enum Fields {
  Area = 'area',
  Skill = 'skill',
  Level = 'level'
}

type validationFields = Fields.Area | Fields.Skill | Fields.Level;

const validationNotifier = ValidationNotifierService.getInstance();
const maxInputLength = 55;

let timer: ReturnType<typeof setTimeout>;

class SkillForm extends React.Component<ISkillFormProps, ISkillFormState> {
  private validationFields = [Fields.Area, Fields.Skill, Fields.Level];
  private subscription: Subscription | undefined;

  constructor(props: ISkillFormProps) {
    super(props);
    this.state = {
      skillFormData: this.props.accordionFormData as ISkill,
      errors: {
        area: false,
        level: false,
        skill: false
      },
      enableAddArea: false,
      enableAddSkill: false,
      status: SectionStatus.UNSET,
      isFormValid: true
    };
  }

  componentDidMount() {
    const { sectionId } = this.props.withMultipleEntriesProps.withFormProps;
    if (this.props.firstTimeValidation) {
      this.props.withMultipleEntriesProps.withFormProps.resumeId && this.validateFormFields();
    }
    this.subscription = validationNotifier.ValidationRequested.subscribe((notifiedSectionId) => {
      const { accordionIndex, activeAccordion } = this.props;
      if (sectionId === notifiedSectionId && accordionIndex === activeAccordion) {
        this.validateFormFields();
      }
    });

    this.checkNewOptionItems();
    const dropDowns = document.querySelectorAll(
      `.form-skills-container.skill${this.props.accordionIndex} input.search`
    );
    dropDowns.forEach((element) => element.setAttribute('maxLength', maxInputLength.toString()));
  }

  componentWillUnmount() {
    clearTimeout(timer);
    this.subscription?.unsubscribe();
  }

  componentDidUpdate(prevProps: ISkillFormProps, prevState: ISkillFormState) {
    const { activeAccordion, accordionIndex } = this.props;

    if (prevProps.activeAccordion === accordionIndex && activeAccordion !== accordionIndex) {
      this.validateFormFields();
    }
  }

  shouldComponentUpdate(nextProps: ISkillFormProps, nextState: ISkillFormState) {
    let updated = false;
    if (
      this.props.activeAccordion === this.props.accordionIndex ||
      nextProps.activeAccordion === this.props.accordionIndex
    ) {
      updated =
        nextState.skillFormData !== this.state.skillFormData ||
        nextProps.accordionFormData !== this.props.accordionFormData ||
        nextProps.skillOptions !== this.props.skillOptions ||
        nextState.errors.area !== this.state.errors.area ||
        nextState.errors.level !== this.state.errors.level ||
        nextState.errors.skill !== this.state.errors.skill ||
        nextState.isFormValid !== this.state.isFormValid ||
        nextState.enableAddArea !== this.state.enableAddArea ||
        nextState.enableAddSkill !== this.state.enableAddSkill ||
        nextProps.activeAccordion !== this.props.activeAccordion;
    }
    return updated;
  }

  checkNewOptionItems = () => {
    const { addAreaOption, addSkillOption, skillOptions } = this.props;
    const { area, skill } = this.state.skillFormData;

    if (area !== '') {
      let isNewArea = false;
      let areaOption = skillOptions.find((areaOpt) => areaOpt.value === area || areaOpt.key === area);
      if (areaOption === undefined) {
        isNewArea = true;
        areaOption = { key: area, value: area, text: area, skills: [] };
        addAreaOption(areaOption);
      }
      if (
        skill &&
        areaOption.skills.some((skillOpt) => skillOpt.value === skill || skillOpt.key === skill) ===
          false
      ) {
        const skillOption = { key: skill, value: skill, text: skill };
        const refArea = isNewArea
          ? { ...areaOption, skills: [...areaOption.skills, skillOption] }
          : skillOptions.findIndex((areaOpt) => areaOpt.value === area || areaOpt.key === area);
        addSkillOption(skillOption, refArea);
      }
    }
  };

  render() {
    const {
      skillFormData: { area, skill, level },
      enableAddArea,
      enableAddSkill,
      errors
    } = this.state;
    const tooltip = SkillToolTipInfo.find((tooltip) => tooltip.key === level);
    return (
      <Form error={!this.state.isFormValid}>
        <div className={`form-skills-container skill${this.props.accordionIndex}`}>
          <Form.Group widths='equal'>
            <Form.Dropdown
              className={`area-section ${errors.area ? 'input-error' : ''}`}
              onSearchChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
                this.checkSearchFieldText(data, SkillFields.AREA)
              }
              onChange={this.handleDropdownChange}
              onClick={this.controlFocus}
              label='Area'
              options={this.props.skillOptions}
              fluid
              search
              selection
              clearable
              allowAdditions={enableAddArea}
              onAddItem={this.handleAddArea}
              value={area}
              width={5}
              id={Fields.Area}
            />
            <Form.Dropdown
              className={`skills-control ${errors.skill ? 'input-error' : ''}`}
              onSearchChange={(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
                this.checkSearchFieldText(data, SkillFields.SKILL)
              }
              onChange={this.handleDropdownChange}
              onClick={this.controlFocus}
              label='Skill'
              options={this.props.skillOptions.find((areaOpt) => areaOpt.value === area)?.skills || []}
              fluid
              search
              selection
              clearable
              allowAdditions={area !== '' && enableAddSkill}
              onAddItem={this.handleAddSkill}
              value={skill}
              width={5}
              id={Fields.Skill}
            />
            <Form.Field className={`level-skill-section ${errors.level ? 'input-error' : ''}`} width={5}>
              <label>Level</label>
              <div className='skills-level-icon'>
                <Popup
                  header={tooltip?.header || 'Level'}
                  content={tooltip?.content || 'Choose a level'}
                  trigger={<Icon name='question circle' />}
                />
              </div>
              <Dropdown
                onChange={this.handleDropdownChange}
                options={SkillLevels}
                onClick={this.controlFocus}
                fluid
                search
                selection
                clearable
                value={level}
                id={Fields.Level}
              />
            </Form.Field>
          </Form.Group>
        </div>
      </Form>
    );
  }

  controlFocus = () => {
    if (this.props.sectionId !== this.props.currentSection.sectionId) {
      this.props.setCurrentSection && this.props.setCurrentSection();
    }
  };

  checkSearchFieldText = (data: DropdownProps, prop: SkillFields) => {
    const field = prop === SkillFields.AREA ? 'enableAddArea' : 'enableAddSkill';
    const enableAdd = this.state[field];
    const newEnableAdd = data.searchQuery?.trim() !== '';
    if (enableAdd !== newEnableAdd) {
      const newState: ISkillFormState = { ...this.state };
      newState[field] = newEnableAdd;
      this.setState(newState);
    }
  };

  handleAddArea = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const { addAreaOption, userEmail } = this.props;
    const newArea = (data.value as string).replace(/\s\s+/g, ' ').trim();

    const newAreaOption: IAreaOptions = {
      key: newArea,
      value: newArea,
      text: newArea,
      skills: []
    };

    const newAreaDTO: INewArea = {
      name: newArea,
      type: OptionType.candidate,
      userEmail: userEmail
    };

    addAreaOption(newAreaOption, newAreaDTO);
  };

  handleAddSkill = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const { addSkillOption, skillOptions, userEmail } = this.props;
    const { area } = this.state.skillFormData;
    const newSkill = (data.value as string).replace(/\s\s+/g, ' ').trim();
    const index = skillOptions.findIndex((areaOpt) => areaOpt.value === area);

    const newSkillOption: ISkillOption = {
      key: newSkill,
      value: newSkill,
      text: newSkill
    };
    const newSkillDTO: INewSkill = {
      area: area,
      skill: newSkill,
      type: OptionType.candidate,
      userEmail: userEmail
    };
    addSkillOption(newSkillOption, index, newSkillDTO);
  };

  handleDropdownChange = (event: React.SyntheticEvent<HTMLElement, Event>, props: DropdownProps) => {
    const { skillFormData } = this.state;
    const controlId = props.id;
    const controlValue = (props.value as string).replace(/\s\s+/g, ' ').trim();
    const newSkillsData: ISkill = { ...skillFormData };
    newSkillsData[controlId] = controlValue;
    let isAreaField = false;
    let skillNeedsValidation = false;

    if (controlId === Fields.Area) {
      isAreaField = true;
      skillNeedsValidation = skillFormData[controlId] !== '';
      const existSkill = this.props.skillOptions
        .find((area) => area.value === controlValue)
        ?.skills.some((skillOpt) => skillOpt.value === skillFormData.skill);
      if (!existSkill) newSkillsData.skill = '';
    }

    this.setState(
      {
        skillFormData: newSkillsData
      },
      () => {
        switch (controlId) {
          case Fields.Area:
            {
              const subtitle = newSkillsData.skill === '' ? ' ' : newSkillsData.skill;
              this.props.setAccordionTitles(this.props.accordionIndex, controlValue, subtitle);
            }
            break;
          case Fields.Skill:
            this.props.setAccordionTitles(this.props.accordionIndex, undefined, controlValue);
            break;
          case Fields.Level:
            {
              const { text }: DropdownItemProps = props.options?.find(
                ({ value }) => value === controlValue
              ) || { text: '' };
              this.props.setAccordionTitles(
                this.props.accordionIndex,
                undefined,
                undefined,
                text as string
              );
            }
            break;
          default:
            break;
        }
        this.handleVerifyChange(controlId);
        this.validateField(controlValue, controlId);
        isAreaField && skillNeedsValidation && this.validateField(newSkillsData.skill, Fields.Skill);
      }
    );
  };

  handleDropdownSave = (event: React.SyntheticEvent<HTMLElement, Event>, props: DropdownProps) => {
    this.handleVerifyChange(props.id);
  };

  handleVerifyChange = (field: Fields) => {
    const { skillFormData } = this.state;
    const dataProps = this.props.accordionFormData;
    skillFormData[field] !== dataProps[field] && this.handleSaveData();
  };

  inspectSectionValidation = () => {
    const { status } = this.state;
    const haveErrors = Object.values(this.state.errors).some((error) => error);
    if (status !== SectionStatus.UNSET) {
      const newstatus = haveErrors ? SectionStatus.ERROR : SectionStatus.SUCCESS;
      this.setState({ isFormValid: !haveErrors, status: newstatus });
    }
    this.props.setAccordionError(haveErrors, this.props.accordionIndex);
  };

  validateField = (value: string, field: validationFields) => {
    const validationStatus = isValid(value, field);
    const updated = this.state.errors[field] !== !validationStatus;
    updated &&
      this.setState(
        ({ errors }) => ({
          errors: {
            ...errors,
            [field]: !validationStatus
          }
        }),
        () => {
          this.inspectSectionValidation();
        }
      );
    return validationStatus;
  };

  getOptionFormDropdown = (
    value: string,
    options: DropdownItemProps[]
  ): [boolean, DropdownItemProps] => {
    let existOption = true;
    let option = options.find((option) => option.value === value);

    if (!option) {
      existOption = false;
      option = {
        key: value,
        value: value,
        text: value
      };
    }

    return [existOption, option];
  };

  setFormAsCurrent = () => {
    const {
      sectionId: mySectionId,
      currentSection: { sectionId: currentSectionId }
    } = this.props.withMultipleEntriesProps.withFormProps;
    if (mySectionId !== currentSectionId) {
      this.props.withMultipleEntriesProps.withFormProps.setCurrentSection(mySectionId);
    }
  };

  validateFormFields = () => {
    const isFormValid = this.validationFields.reduce((status, field) => {
      const successfullyValidated = this.validateField(
        this.state.skillFormData[field] as string,
        field as validationFields
      );
      return status && successfullyValidated;
    }, true);

    const status = isFormValid ? SectionStatus.SUCCESS : SectionStatus.ERROR;
    this.setState({ isFormValid, status });
    this.props.setAccordionError(!isFormValid, this.props.accordionIndex);
  };

  saveDataAccordingFocus() {
    const { skillFormData } = this.state;
    const { withMultipleEntriesProps } = this.props;
    withMultipleEntriesProps.withFormProps.saveFormSection(
      skillFormData,
      withMultipleEntriesProps.withFormProps.propertyKey,
      this.props.accordionIndex
    );
  }

  handleSaveData = () => {
    clearTimeout(timer);
    const { currentSection, sectionId } = this.props;
    if (currentSection.sectionId !== sectionId) {
      timer = setTimeout(() => {
        this.saveDataAccordingFocus();
      }, formsDebounceTime);
    } else {
      this.saveDataAccordingFocus();
    }
  };
}

const mapStateToProps = (state: RootStore) => {
  return {
    userEmail: state.dashboard.authData.profile.email,
    skillOptions: state.dashboard.resumeOptions.skills
  };
};

export default connect(mapStateToProps, { addAreaOption: AddArea, addSkillOption: AddSkill })(SkillForm);
