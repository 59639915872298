import { View, Text } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './AquaUtils';
import { styles } from './ExperienceSection.styles';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { formatTextDate } from '../utils/formatTextDate';
import { verifyStringLength } from '../utils/stringUtils';
import { IWorkExperience } from '../../Models/IWorkExperience';

interface IExperiencesProps {
  data: IWorkExperience[];
  highLightColor?: string;
}

interface IExperienceProps {
  data: IWorkExperience;
  highLightColor?: string;
  indexItem: number;
}

const skillMaxLength = 55;
const ellipsisMaxLength = 75;

const Experience: React.FC<IExperienceProps> = ({ data, highLightColor, indexItem }) => {
  const { client, description, endDate, startDate, jobTitle, profile, skills } = data;
  const jobTitleText = jobTitle.replace(/\s+/g, ' ').trim();
  const profileText = profile.replace(/\s+/g, ' ').trim();
  const existsProfile = Boolean(profileText);
  const jobTitleStyle = existsProfile ? styles.ellipsis : {};

  return (
    <View style={styles.experienceContainer}>
      <View wrap={false}>
        {indexItem === 0 && (
          <Text style={{ ...styles.sectionTitle, color: getColors(highLightColor).sectionTitle }}>
            Experience
          </Text>
        )}
        <Text style={{ ...styles.date, color: getColors(highLightColor).date }}>
          {formatTextDate(startDate, endDate)}{' '}
        </Text>
        <View style={styles.jobContainer}>
          <Text style={jobTitleStyle}>{jobTitleText || ' '}</Text>
          {existsProfile && <Text style={styles.divider}> </Text>}
          <Text style={styles.ellipsis}>{profileText}</Text>
        </View>
        <Text style={styles.client}> {verifyStringLength(client, ellipsisMaxLength)}</Text>
      </View>
      <View style={styles.skillContent}>
        {skills.map((name, index) => (
          <View key={`expirienceSkill-${index}`} style={styles.skillBox} wrap={false}>
            <Text>{verifyStringLength(name, skillMaxLength)}</Text>
          </View>
        ))}
      </View>
      <View style={styles.descriptionContainer}>
        <HTMLContent data={description} />
      </View>
    </View>
  );
};

const ExperienceSection: React.FC<IExperiencesProps> = ({ data, highLightColor }) => {
  const existsData = data.length > 0 || null;
  return (
    existsData && (
      <View style={styles.sectionContainer}>
        {data.map((experience, index) => (
          <Experience
            key={`experienceItem-${index}`}
            data={experience}
            highLightColor={highLightColor}
            indexItem={index}
          />
        ))}
      </View>
    )
  );
};

export default ExperienceSection;
