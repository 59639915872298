import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';

import { getColors } from './ModernUtils';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';
import { verifyStringLength } from '../utils/stringUtils';
import { IEducation } from '../../Models/IEducation';

interface IEducationsProps {
  educations: IEducation[];
  highLightColor: string;
}

interface IEducationProps {
  education: IEducation;
  isFirst: boolean;
  highLightColor: string;
}

const styles = (textColor = templateColors.blue) => {
  const titleColor = getColors(textColor).titleColor;
  const careerColor = getColors(textColor).careerColor;
  return StyleSheet.create({
    title: {
      fontSize: 14,
      color: titleColor,
      marginBottom: 10,
      marginTop: 14
    },
    date: {
      fontSize: 10
    },
    career: {
      fontFamily: 'Roboto Bold',
      fontSize: 12,
      color: careerColor
    },
    school: {
      fontSize: 11,
      fontFamily: 'Roboto Bold'
    },
    descriptionContainer: {
      marginTop: 7,
      marginBottom: 12
    }
  });
};

Font.register({
  family: 'Roboto Bold',
  src: `/fonts/Roboto/Roboto-Bold.ttf`
});

const MAX_LENG_INPUT = 55;

const Education: React.FC<IEducationProps> = (props) => {
  const { startDate, endDate, career, school, description } = props.education;

  return (
    <React.Fragment>
      <View wrap={false}>
        {props.isFirst && (
          <Text
            style={props.highLightColor ? { ...styles(props.highLightColor).title } : styles().title}
          >
            EDUCATION
          </Text>
        )}
        <Text style={styles().date}>
          {startDate && startDate.substr(startDate.length - 4)} -{' '}
          {endDate ? endDate.substr(endDate.length - 4) : 'Present'}
        </Text>
        <Text
          style={props.highLightColor ? { ...styles(props.highLightColor).career } : styles().career}
        >
          {verifyStringLength(career, MAX_LENG_INPUT)}
        </Text>
        <Text style={styles().school}>{verifyStringLength(school, MAX_LENG_INPUT)}</Text>
      </View>
      <View style={styles().descriptionContainer}>
        <HTMLContent data={description} />
      </View>
    </React.Fragment>
  );
};

const Educations: React.FC<IEducationsProps> = ({ educations, highLightColor }) => {
  return (
    <React.Fragment>
      {educations &&
        educations.reduce((components: Array<JSX.Element>, education, index) => {
          education.enabled &&
            components.push(
              <Education
                key={index}
                education={education}
                isFirst={components.length === 0}
                highLightColor={highLightColor}
              />
            );
          return components;
        }, [])}
    </React.Fragment>
  );
};

export default Educations;
