import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import React from 'react';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu';
import MenuItem from 'semantic-ui-react/dist/commonjs/collections/Menu/MenuItem';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import styles from './DownloadPopup.module.scss';
import { IResumeForm } from '../../Models/IResumeForm';

interface IDownloadPopupProps {
  items: {
    name: string;
    component: () => Promise<JSX.Element> | JSX.Element;
  }[];
  fileName: string;
  open: boolean;
  resume: IResumeForm;
  onClose: (closeHover?: boolean) => void;
  onOpen: () => void;
  setLoading: (state: boolean) => void;
}

const DownloadPopup: React.FC<IDownloadPopupProps> = ({
  items,
  fileName,
  open,
  onClose,
  onOpen,
  children,
  setLoading
}) => {
  const downloadPDFDocument = async (
    component: () => Promise<JSX.Element> | JSX.Element,
    fileName: string
  ) => {
    open && onClose(true);
    setLoading(true);
    pdf(await component())
      .toBlob()
      .then((pdf) => {
        saveAs(pdf, fileName);
        setLoading(false);
      });
  };

  const handlePreventdefault = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    action: () => void
  ) => {
    event.preventDefault();
    action();
  };

  return (
    <Popup
      on='click'
      basic
      trigger={children}
      onClose={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        handlePreventdefault(event, onClose)
      }
      onOpen={(event: React.MouseEvent<HTMLElement, MouseEvent>) => handlePreventdefault(event, onOpen)}
      open={open}
      position='bottom right'
      className={styles.downloadOptions}
    >
      <div className={styles.popupContainer}>
        <Menu vertical pointing className={styles.verticalMenu}>
          {items.map((item, i) => (
            <MenuItem
              className={styles.menuItem}
              key={i}
              onClick={() => downloadPDFDocument(item.component, fileName)}
            >
              <div className={styles.pdfLabel}>{item.name}</div>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Popup>
  );
};

export default DownloadPopup;
