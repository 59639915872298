import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';
import { getColors } from './PlainUtils';

export const styles = (textColor = templateColors.gray) => {
  const jobColor = getColors(textColor).job;
  return StyleSheet.create({
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '30',
      paddingLeft: 40,
      paddingRight: 40
    },
    avatarContainer: {
      width: 135,
      flexDirection: 'column',
      paddingRight: 29.25,
      paddingLeft: 31.5
    },
    infoContainer: {
      width: 370.5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Georama',
      paddingLeft: 30,
      textAlign: 'center'
    },
    fullName: {
      fontSize: 24.5
    },
    fullNameItem: {
      maxWidth: 200,
      textOverflow: 'ellipsis'
    },
    job: {
      color: jobColor,
      fontSize: 13,
      marginBottom: 4
    },
    additionalInformation: {
      fontSize: 7.5
    },
    userPhoto: {
      width: 80,
      height: 80,
      objectFit: 'cover',
      borderRadius: '50%'
    }
  });
};
