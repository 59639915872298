import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  banner: {
    flexDirection: 'column'
  },
  backgroundHeader: {
    marginTop: -46.5,
    position: 'absolute'
  },
  imageHeader: {
    marginTop: -46.5,
    opacity: 0.1,
    width: 595.5,
    height: 90
  },
  avatarContainer: {
    marginTop: 14,
    marginRight: 15,
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    borderWidth: 2,
    borderRadius: 400,
    height: 75.75,
    width: 75.75
  },
  userPhoto: {
    width: '100%',
    height: '100%',
    borderRadius: 400
  },
  logoContainer: {
    marginTop: -46.5,
    position: 'absolute',
    top: 30.75,
    width: 84.9675,
    height: 28,
    left: 472.2825
  },
  styleEmptyPicture: {
    left: 75.75,
    marginLeft: -37.5,
    maxWidth: 396
  },
  inforContainer: {
    flexDirection: 'row',
    marginTop: -60.5,
    left: 37.5
  },
  textContainer: {
    flexDirection: 'column',
    maxWidth: 396
  },
  nameContainer: {
    height: 59.25,
    maxWidth: 340,
    marginRight: 30,
    marginTop: 2,
    paddingBottom: 2,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center'
  },
  name: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    flexWrap: 'wrap',
    textOverflow: 'ellipsis',
    maxLines: 2,
    marginRight: 5,
    opacity: 0.85
  },
  job: {
    paddingTop: 2,
    color: '#0079A3',
    fontSize: 12,
    fontFamily: 'Helvetica Neue',
    fontWeight: 'medium',
    textOverflow: 'ellipsis',
    maxLines: 1,
    marginBottom: 4.5
  },
  directionSection: {
    flexDirection: 'row',
    fontSize: 9,
    fontFamily: 'Helvetica Neue',
    fontWeight: 'normal'
  },
  divider: {
    marginTop: 2,
    paddingHorizontal: 3,
    marginLeft: 8,
    marginRight: 5,
    borderLeftColor: '#00000026',
    borderLeftWidth: 0.75,
    borderLeftStyle: 'solid',
    fontFamily: 'Helvetica Neue'
  },
  ellipsis: {
    maxLines: 1,
    maxWidth: 200,
    textOverflow: 'ellipsis'
  }
});
