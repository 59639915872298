import { View, Text, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';
import { verifyStringLength } from '../utils/stringUtils';
import { IWorkExperience } from '../../Models/IWorkExperience';

interface IWorkExperiencesProps {
  workExperiences: IWorkExperience[];
  highLightColor: string;
}

interface IWorkExperienceProps {
  work: IWorkExperience;
  isFirst: boolean;
  highLightColor: string;
}

const ellipsisMaxLength = 55;
const clientMaxLength = 75;

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    container: {
      marginLeft: 22
    },
    title: {
      fontSize: 15,
      fontFamily: 'Helvetica Neue Light',
      paddingBottom: 18,
      color: textColor
    },
    point: {
      fontSize: 10,
      marginLeft: -2,
      color: textColor
    },
    workContainer: {
      marginTop: -10
    },
    workContainerBottom: {
      marginLeft: 38,
      marginBottom: 8,
      marginTop: -1,
      paddingLeft: 9,
      paddingTop: 5,
      paddingBottom: 5,
      width: 320
    },
    dates: {
      width: 38,
      paddingTop: 5,
      paddingRight: 38
    },
    lineLeft: {
      borderLeft: 1,
      borderLeftColor: '#C7C7C7'
    },
    lineRight: {
      borderRight: 1,
      borderRightColor: '#C7C7C7'
    },
    startDate: {
      fontSize: 10,
      width: 37,
      color: textColor
    },
    endDate: {
      width: 26,
      fontSize: 10
    },
    subTitle: {
      fontSize: 10,
      marginLeft: 7,
      flexDirection: 'row',
      color: textColor
    },
    client: {
      fontSize: 10,
      paddingLeft: 9,
      paddingRight: 7,
      paddingTop: 5,
      paddingBottom: 5,
      maxWidth: 320
    },
    skillContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    skill: {
      height: 16,
      padding: 2,
      border: 1,
      borderRadius: 2,
      marginTop: 2,
      borderColor: '#CCCCCC',
      marginRight: 3
    },
    skillText: {
      fontSize: 8
    },
    descriptionContainer: {
      paddingTop: 5
    },
    ellipsis: {
      maxLines: 2,
      maxWidth: 144,
      textOverflow: 'ellipsis'
    }
  });

const WorkExperience: React.FC<IWorkExperienceProps> = (props) => {
  const { client, description, endDate, jobTitle, profile, skills, startDate } = props.work;
  const startYear = startDate ? startDate.substr(4) : ' ';
  const endYear = endDate ? endDate.substr(4) : 'Present';
  const jobTitleText = jobTitle.replace(/\s+/g, ' ').trim();
  const profileText = profile.replace(/\s+/g, ' ').trim();

  return (
    <React.Fragment>
      <View wrap={false}>
        {props.isFirst && <Text style={{ ...styles(props.highLightColor).title }}>Experience</Text>}
        <View style={styles().workContainer}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexDirection: 'column' }}>
              <Text style={{ ...styles(props.highLightColor).startDate }}>{startYear}</Text>
              <View style={[styles().dates, styles().lineRight]}>
                <Text style={styles().endDate}>{endYear}</Text>
              </View>
            </View>
            <Text style={{ ...styles(props.highLightColor).point }}>•</Text>
            <View style={{ flexDirection: 'column' }}>
              <View style={styles(props.highLightColor).subTitle}>
                <Text style={styles().ellipsis}>{jobTitleText || ' '}</Text>
                <Text style={{ maxWidth: '10%', paddingHorizontal: 5 }}>{profile && ' | '}</Text>
                <Text style={styles().ellipsis}>{profileText}</Text>
              </View>
              <Text
                style={[styles().ellipsis, styles().client, styles().lineLeft, { marginLeft: -2.37 }]}
              >
                {verifyStringLength(client, clientMaxLength)}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles().workContainerBottom, styles().lineLeft]}>
        <View style={styles().skillContainer}>
          {skills.map((skill, index) => (
            <View key={index} style={styles().skill} wrap={false}>
              <Text style={styles().skillText}>{verifyStringLength(skill, ellipsisMaxLength)}</Text>
            </View>
          ))}
        </View>
        <View style={styles().descriptionContainer}>
          <HTMLContent data={description} />
        </View>
      </View>
    </React.Fragment>
  );
};

const WorkExperiences: React.FC<IWorkExperiencesProps> = (props) => {
  const { workExperiences, highLightColor } = props;

  return (
    <View style={styles().container}>
      <View>
        {workExperiences.reduce((result: Array<JSX.Element>, work, index) => {
          work.enabled &&
            result.push(
              <WorkExperience
                key={index}
                work={work}
                isFirst={result.length === 0}
                highLightColor={highLightColor}
              />
            );
          return result;
        }, [])}
      </View>
    </View>
  );
};

export default WorkExperiences;
