import moment from 'moment';
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';

import styles from './CustomDatePicker.module.scss';
import { minDate, maxDate, CustomDatePickerHeader } from './CustomDatePickerHeader';
import { formatDate, isSafariBrowser } from '../../../../constants/globalConstants';
import '../../../../Styles/components/customDatePicker.scss';

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const getFormatedDate = (inputDate: any) => {
  if (inputDate) {
    return moment(inputDate).format(formatDate);
  }
};

interface ICustomDatePickerProps {
  id: string;
  className?: string;
  inputLabel: string;
  selectedDate: string | null;
  isRequired?: boolean;
  error?: boolean;
  compareDatesError?: boolean;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  onChangeFunction: (event: any, data: InputOnChangeData) => void;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  mobileProp: any;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  tabletProp: any;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  computerProp: any;
}

const CustomDatePicker: React.FC<ICustomDatePickerProps> = (props) => {
  const {
    className,
    inputLabel,
    id,
    selectedDate,
    compareDatesError,
    error,
    isRequired,
    onChangeFunction,
    mobileProp,
    tabletProp,
    computerProp
  } = props;

  const getInitialDate = () => {
    if (selectedDate) {
      const month = selectedDate.substr(0, 3);
      const year = selectedDate.substr(selectedDate.length - 4);
      if (isSafariBrowser) {
        return new Date(`${month}-01-${year}`);
      }
      return new Date(`${month}/01/${year}`);
    }
    return null;
  };

  const initialDate = getInitialDate();

  const [inputDate, setInputDate] = useState(initialDate);

  const handleOnChange = useCallback(
    (date: Date): void => {
      setInputDate(date);
      const data: InputOnChangeData = {
        id: id,
        value: getFormatedDate(date) as string
      };
      onChangeFunction(null, data);
    },
    [setInputDate]
  );

  return (
    <Grid.Column
      computer={computerProp}
      tablet={tabletProp}
      mobile={mobileProp}
      className={`custom-date-picker ${styles.datePicker}`}
    >
      <label className={`${styles.datepickerLabel} ${!isRequired && styles.optionalField}`}>
        {inputLabel}
      </label>
      <DatePicker
        className={`${className} ${styles.datePickerInput} ${
          (error || compareDatesError) && styles.datepickerError
        }`}
        selected={inputDate}
        onChange={handleOnChange}
        dateFormat={formatDate}
        minDate={minDate}
        maxDate={maxDate}
        showMonthYearPicker
        renderCustomHeader={(props) => (
          <CustomDatePickerHeader {...props} handleOnChange={handleOnChange} />
        )}
      />
    </Grid.Column>
  );
};

export default CustomDatePicker;
