import { templateColors } from '../utils/constants';

export const getColors = (highLightColor?: string) => {
  switch (highLightColor) {
    case templateColors.red:
      return {
        areaTitle: '#D20000',
        job: highLightColor,
        skillComplementLevel: '#5F0000',
        textColor: highLightColor
      };
    case templateColors.blue:
      return {
        areaTitle: '#1867AC',
        job: highLightColor,
        skillComplementLevel: '#0076A2',
        textColor: highLightColor
      };
    case templateColors.aqua:
      return {
        areaTitle: '#0EC4A9',
        job: highLightColor,
        skillComplementLevel: '#006757',
        textColor: highLightColor
      };
    case templateColors.gray:
      return {
        job: '#808080',
        areaTitle: '#808080',
        textColor: '#313131',
        skillComplementLevel: '#000000'
      };
    default:
      return {
        job: '#808080',
        areaTitle: '#808080',
        textColor: '#313131',
        skillComplementLevel: '#000000'
      };
  }
};
