export const verifyStringLength = (value: string, maxLength: number): string => {
  const newValue = value.replace(/\s\s+/g, ' ').trim();
  if (newValue.length > maxLength) {
    return newValue.substr(0, maxLength).concat('...');
  }
  return newValue;
};

export const verifyWordLength = (
  value: string,
  maxWordLength: number,
  wordBreak: string = '- '
): string => {
  if (value.length <= maxWordLength) return value;
  let words = value.split(' ');
  words = words.map((word) => {
    const length = word.length;
    if (length <= maxWordLength) return word;
    let newWord = '';
    let start = 0;
    while (start + maxWordLength < length) {
      newWord += word.substr(start, maxWordLength) + wordBreak;
      start += maxWordLength;
    }
    newWord += word.substr(start);
    return newWord;
  });
  // eslint-disable-next-line no-return-assign
  return words.reduce((newValue: string, word) => (newValue += word + ' '), '');
};

export const wordBreak = (word: string, maxLength: number = 30): string[] => {
  if (word.length > maxLength) {
    return Array.from(word)
      .map((char) => [char, ''])
      .flat();
  }
  return [word, ''];
};
