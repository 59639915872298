import { Text, View } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './EducationSection.styles';
import CircleSeparator from './Divider';
import TitleSection from './Title';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { formatTextDate } from '../utils/formatTextDate';
import { verifyStringLength } from '../utils/stringUtils';
import { IEducation } from '../../Models/IEducation';

interface IEducationProps {
  data: IEducation[];
  highLightColor: string;
}

const EDUCATION_ICON = '/images/education.png';
const MAX_LENGTH_INPUT = 55;

const Education: React.FC<IEducationProps> = ({ data, highLightColor }) => {
  const existsData = data.length > 0 || null;
  return (
    existsData && (
      <View style={styles().educationContainer}>
        <TitleSection
          urlImage={EDUCATION_ICON}
          text={'EDUCATION'}
          containerMarginLeft={'4'}
          highLightColor={highLightColor}
        />
        {data.map((item, index) => (
          <View style={styles().contentWrapper} key={`education-item-${index}`}>
            <CircleSeparator />
            <View style={styles().contentSection}>
              <View>
                <View wrap={false}>
                  <View>
                    <Text style={styles(highLightColor).career}>
                      {verifyStringLength(item.career, MAX_LENGTH_INPUT)}
                    </Text>
                    <Text style={styles().school}>
                      {verifyStringLength(item.school, MAX_LENGTH_INPUT)}
                    </Text>
                    <Text style={styles().date}>{formatTextDate(item.startDate, item.endDate)}</Text>
                  </View>
                </View>
                <View style={styles().descriptionContainer}>
                  <HTMLContent data={item.description} />
                </View>
              </View>
            </View>
          </View>
        ))}
      </View>
    )
  );
};

export default Education;
