import React from 'react';
import { Link } from 'react-router-dom';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

const PageNotFound: React.FC = () => {
  return (
    <Segment placeholder basic>
      <Header icon>
        <Icon name='dont' size='mini' />
        <h1> Page Not Found </h1>
      </Header>
      <Grid textAlign='center'>
        <Link to='/dashboard'>Go to dashboard </Link>
      </Grid>
    </Segment>
  );
};

export default PageNotFound;
