import { View, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

interface IFigureProps {
  fixed: boolean;
  customStyles?: Style;
}

const styles = StyleSheet.create({
  mainContainer: {
    position: 'absolute',
    bottom: 0,
    width: 129.75
  },
  position: {
    position: 'absolute'
  }
});

const LeftFigure: React.FC<IFigureProps> = ({ fixed = false, customStyles = {} }) => {
  return (
    <View fixed={fixed} style={[styles.mainContainer, customStyles]}>
      <Svg width='65.4765' height='88.60575' style={styles.position}>
        <Path d='M65.475,0V88.60575L0,60Z' fill='#f8f8f8' />
      </Svg>
      <Svg width='65.4765' height='440.828'>
        <Path
          d='M65.03175,88.1205,65.475,561.104H0V60Z'
          transform='translate(0, -60)'
          fill='#f1f1f1'
          opacity={0.3}
        />
      </Svg>
    </View>
  );
};

export default LeftFigure;
