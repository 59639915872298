import { Font, Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import { templateColors } from '../utils/constants';
import { skillPoints } from '../utils/skillPoints';
import { verifyStringLength } from '../utils/stringUtils';
import { ISkill } from '../../Models/ISkills';

interface ISkillsProps {
  data: ISkill[];
  highLightColor: string;
}

interface IAreaProps {
  isFirst: boolean;
  title: string;
  skills: ISkill[];
  highLightColor: string;
}

interface ISkillObj {
  area: string;
  skills: ISkill[];
}

interface ISkillProps {
  skill: string;
  level: string;
  highLightColor: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    generalTitle: {
      fontSize: 14,
      marginBottom: 10,
      marginTop: 14,
      color: textColor
    },
    skillsContainer: {
      marginBottom: 10
    },
    container: {
      marginBottom: 10
    },
    title: {
      fontSize: 10,
      fontFamily: 'Helvetica Bold',
      marginBottom: 8
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#F5F5F5',
      padding: '3 5',
      marginTop: 5,
      marginRight: 5,
      borderRadius: 4
    },
    skillName: {
      fontSize: 10,
      marginRight: 3
    },
    level: {
      fontSize: 11,
      color: textColor
    },
    complementLevel: {
      color: '#D7D7D7',
      fontSize: 11
    },
    contentLevel: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginRight: 9,
      marginBottom: 5
    },
    detailName: {
      fontSize: 8,
      paddingLeft: 3,
      paddingRight: 4
    },
    levelDetails: {
      fontSize: 7,
      paddingLeft: 3
    },
    levelStyle: {
      paddingTop: 2
    }
  });

Font.register({
  family: 'Helvetica Bold',
  src: `/fonts/Helvetica/HelveticaNeueBold.ttf`
});

const getIndexToFirstLine = (skills: ISkill[]): number => {
  const SIZE_PER_LETTER = 4;
  const SIZE_PER_SKILL = 47;
  const WIDTH_LINE = 500;

  const skillLength = skills.length - 1;
  let firstLineSkills = 0;
  let indexLine = skillLength;
  skills.some((item, index) => {
    const value = item.skill.length * SIZE_PER_LETTER + SIZE_PER_SKILL;
    if (firstLineSkills + value > WIDTH_LINE) {
      indexLine = index - 1;
    }
    firstLineSkills += value;
    return indexLine !== skillLength;
  });

  return indexLine;
};

const MAX_STRING_LENGTH = 55;

const Skill: React.FC<ISkillProps> = (props) => {
  const { level, skill } = props;

  const getLevel = (level: string) => {
    const [skillLevel, skillLevelComplement] = skillPoints(level);

    return (
      <React.Fragment>
        <Text style={styles(props.highLightColor).level}>{skillLevel}</Text>
        <Text style={styles().complementLevel}>{skillLevelComplement}</Text>
      </React.Fragment>
    );
  };

  return (
    <View style={styles().content} wrap={false}>
      <Text style={styles().skillName}>{verifyStringLength(skill, MAX_STRING_LENGTH)}</Text>
      {getLevel(level)}
    </View>
  );
};

const Area: React.FC<IAreaProps> = ({ title, skills, isFirst, highLightColor }) => {
  const skillsCopy = [...skills];
  const quantityFirstLine = getIndexToFirstLine(skills);
  const firstLine = skillsCopy
    .splice(0, quantityFirstLine + 1)
    .map((skill, index) => (
      <Skill key={index} level={skill.level} skill={skill.skill} highLightColor={highLightColor} />
    ));

  return (
    <View style={styles().container}>
      <View wrap={false}>
        {isFirst && <Text style={styles(highLightColor).generalTitle}>Skill</Text>}
        <Text style={styles().title}>{verifyStringLength(title, MAX_STRING_LENGTH)}</Text>
        {firstLine.length > 0 && <View style={{ flexDirection: 'row' }}>{firstLine}</View>}
      </View>
      <View style={styles().box}>
        {skillsCopy.map((skill, i) => (
          <Skill key={i} level={skill.level} skill={skill.skill} highLightColor={highLightColor} />
        ))}
      </View>
    </View>
  );
};

const Skills: React.FC<ISkillsProps> = ({ data, highLightColor }) => {
  const getSkills = (skills: ISkill[]) => {
    const skillsMap: Map<string, ISkill[]> = new Map<string, ISkill[]>();
    const skillsArray: ISkillObj[] = [];
    skills.forEach((skill) => {
      let skillByAreaArray = skillsMap.get(skill.area);

      if (skillByAreaArray) {
        skillByAreaArray.push(skill);
      } else {
        skillByAreaArray = [skill];
      }
      skillsMap.set(skill.area, skillByAreaArray);
    });
    skillsMap.forEach((value, key) => {
      const obj = {
        area: key,
        skills: value
      };
      skillsArray.push(obj);
    });
    return skillsArray;
  };

  const skillsDescription = [
    {
      level: '•',
      complement: '••••',
      levelName: 'Fundamental Awareness',
      levelDetails: '(basic knowledge)'
    },
    {
      level: '••',
      complement: '•••',
      levelName: 'Novice',
      levelDetails: '(limitted experience)'
    },
    {
      level: '•••',
      complement: '••',
      levelName: 'Intermediate',
      levelDetails: '(practical application)'
    },
    {
      level: '••••',
      complement: '•',
      levelName: 'Advanced',
      levelDetails: '(applied theory)'
    },
    {
      level: '•••••',
      complement: '',
      levelName: 'Expert',
      levelDetails: '(recognized authority)'
    }
  ];

  const skillsMap = getSkills(data);

  return (
    <React.Fragment>
      {skillsMap && (
        <View style={styles().skillsContainer}>
          {skillsMap.map((item, index) => (
            <Area
              key={index}
              title={item.area}
              skills={item.skills}
              isFirst={index === 0}
              highLightColor={highLightColor}
            />
          ))}
          {skillsMap.length > 0 && (
            <View style={styles().box} wrap={false}>
              {skillsDescription.map((item, i) => (
                <View key={i} style={styles().contentLevel}>
                  <Text style={[styles().level, styles().levelStyle, { color: highLightColor }]}>
                    {item.level}
                  </Text>
                  <Text style={[styles().complementLevel, styles().levelStyle]}>{item.complement}</Text>
                  <View>
                    <Text style={styles().detailName}> {item.levelName}</Text>
                    <Text style={styles().levelDetails}> {item.levelDetails}</Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      )}
    </React.Fragment>
  );
};

export default Skills;
