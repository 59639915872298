import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { templateColors } from '../utils/constants';
import { pictureToShow } from '../utils/helper';
import { verifyStringLength } from '../utils/stringUtils';
import { IPersonalInformation } from '../../Models/IPersonalInformation';

const LOGO_JALASOFT = '/images/jalasoftLogo.png';
const MAIL_ICON = '/images/mail.png';
const PHONE_ICON = '/images/phone.png';

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row'
    },
    avatar: {
      height: 90,
      width: 90,
      borderRadius: 150,
      objectFit: 'cover'
    },
    name: {
      fontSize: 25,
      width: 320,
      fontFamily: 'Helvetica Neue Light',
      maxHeight: 89
    },
    logoContainer: {
      display: 'flex'
    },
    logo: {
      marginLeft: 260,
      height: 20,
      width: 84
    },
    mainTitle: {
      fontSize: 13,
      maxHeight: 16,
      fontFamily: 'Helvetica Neue Light',
      color: textColor
    },
    email: {
      fontSize: 9
    },
    phone: {
      fontSize: 9
    },
    avatarContainer: {
      width: 135
    },
    infoContainer: {
      marginLeft: 22,
      maxWidth: 350
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: 11,
      fontFamily: 'Helvetica Neue Light'
    },
    icon: {
      width: 10,
      marginRight: 2,
      marginBottom: 2
    }
  });

interface IHeaderProps {
  personalInformation: IPersonalInformation;
  highLightColor: string;
}

const Header: React.FC<IHeaderProps> = ({ personalInformation, highLightColor }) => {
  const { fullName, email, pictures, mainJob, phoneNumber } = personalInformation;
  const validatedPictures = pictureToShow(pictures);

  return (
    <View style={styles().container}>
      <View style={styles().avatarContainer}>
        {validatedPictures.displayPicture && (
          <Image src={validatedPictures.pictureToShow} style={styles().avatar} />
        )}
      </View>
      <View style={styles().infoContainer}>
        <View style={styles().logoContainer}>
          <Image src={LOGO_JALASOFT} style={styles().logo} />
        </View>
        <Text style={styles().name}>{fullName}</Text>
        <Text style={styles(highLightColor).mainTitle}>{mainJob}</Text>
        <View style={{ marginTop: 6 }}>
          <View style={styles().fieldContainer}>
            <Image src={MAIL_ICON} style={styles().icon} />
            <Text style={styles().email}>{verifyStringLength(email, 55)}</Text>
          </View>
          {phoneNumber !== '' && (
            <View style={styles().fieldContainer}>
              <Image src={PHONE_ICON} style={styles().icon} />
              <Text style={styles().phone}>{verifyStringLength(phoneNumber, 20)}</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default Header;
