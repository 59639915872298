import { Image, View, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

import { getColors } from './AquaUtils';
import { styles } from './Header.styles';
import { CustomRectangle } from '../utils/CustomRectangle/CustomRectangle';
import { pictureToShow } from '../utils/helper';
import { IPersonalInformation } from '../../Models/IPersonalInformation';

const LOGO_JALASOFT = '/images/jalasoft-logo-white.png';
const HEADER_JALASOFT = '/images/jalasoft-header.jpg';

interface IHeaderProps {
  personalInfo: IPersonalInformation;
  highLightColor?: string;
}

const Header: React.FC<IHeaderProps> = ({ personalInfo, highLightColor }) => {
  const { pictures, fullName, mainJob, email, phoneNumber } = personalInfo;
  const validatedPicture = pictureToShow(pictures);

  const existsPhoneNumber = Boolean(phoneNumber);
  const stylesTextContainer: Style = validatedPicture.displayPicture
    ? styles.textContainer
    : { ...styles.textContainer, ...styles.styleEmptyPicture };
  const emailStyle = existsPhoneNumber ? { ...styles.ellipsis, marginRight: 5 } : {};
  const nameContainerStyle = validatedPicture.displayPicture
    ? styles.nameContainer
    : { ...styles.nameContainer, width: 396 };

  return (
    <View style={styles.banner}>
      <CustomRectangle
        width='595.5'
        height='90'
        primaryColor={getColors(highLightColor).primaryColor}
        secondColor={getColors(highLightColor).secondColor}
        styles={styles.backgroundHeader}
      />
      <Image src={HEADER_JALASOFT} style={styles.imageHeader} />
      <View style={styles.logoContainer}>
        <Image src={LOGO_JALASOFT} />
      </View>
      <View style={styles.inforContainer}>
        {validatedPicture.displayPicture && (
          <View style={styles.avatarContainer}>
            <Image style={styles.userPhoto} src={validatedPicture.pictureToShow} />
          </View>
        )}
        <View style={stylesTextContainer}>
          <View style={nameContainerStyle}>
            <Text style={styles.name}>{fullName}</Text>
          </View>
          <View>
            <Text style={{ ...styles.job, color: getColors(highLightColor).sectionTitle }}>
              {mainJob}
            </Text>
            <View style={styles.directionSection}>
              <Text style={emailStyle}>{email}</Text>
              {existsPhoneNumber && <Text style={styles.divider}> </Text>}
              <Text style={styles.ellipsis}>{phoneNumber}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Header;
