const DOCUMENT_EVENTS = [
  'mousemove',
  'mousedown',
  'click',
  'touchmove',
  'touchstart',
  'touchend',
  'keydown',
  'keypress'
];

export class IdleTimer {
  private onIdleTimeout: Function;
  private timeout: number;
  private timer: number | null;
  private active: boolean;
  private stop: boolean;

  constructor(onIdleTimeout: Function, timeout: number) {
    this.onIdleTimeout = onIdleTimeout;
    this.timeout = timeout;
    this.timer = null;
    this.active = false;
    this.stop = false;
  }

  activate = () => {
    if (!this.active) {
      this.bindEvents();
    }
    this.timer = setTimeout(this.onIdleTimeout, this.timeout);
    this.active = true;
  };

  deactivate = () => {
    if (this.active) {
      this.unBindEvents();
    }
    this.timer && clearInterval(this.timer);
    this.active = false;
  };

  togglePause = () => {
    this.stop = !this.stop;
  };

  resetTimer = () => {
    this.timer && clearInterval(this.timer);
    !this.stop && this.activate();
  };

  bindEvents = () => {
    window.addEventListener('scroll', this.resetTimer, { capture: true, passive: true });
    window.addEventListener('load', this.resetTimer);
    DOCUMENT_EVENTS.forEach((eventType) => document.addEventListener(eventType, this.resetTimer));
  };

  unBindEvents = () => {
    window.removeEventListener('scroll', this.resetTimer, { capture: true });
    window.removeEventListener('load', this.resetTimer);
    DOCUMENT_EVENTS.forEach((eventType) => document.removeEventListener(eventType, this.resetTimer));
  };
}
