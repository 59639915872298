import { IPersonalInformation } from './IPersonalInformation';
import { IWorkExperience } from './IWorkExperience';
import { IEducation } from './IEducation';
import { ILanguage } from './ILanguages';
import { ISkill } from './ISkills';
import { SectionId } from '../constants/sectionIDs';

export type ResumeSection = IPersonalInformation | IWorkExperience | IEducation | ILanguage | ISkill;

export interface ISectionData {
  personalInformation?: IPersonalInformation;
  skills?: ISkill[];
  workExperiences?: IWorkExperience[];
  educations?: IEducation[];
  languages?: ILanguage[];
}

export interface IResumeSectionToUpdate {
  updatedDate: Date;
  progressStatus: number;
  sectionData: ISectionData;
}

export type ResumeKeySection =
  | 'personalInformation'
  | 'workExperiences'
  | 'educations'
  | 'languages'
  | 'skills';

export interface ISection {
  sectionId: SectionId;
  nextSectionId: SectionId;
  previousSectionId: SectionId;
}

export enum SectionStatus {
  UNSET,
  SUCCESS,
  ERROR
}

export enum ResumeValues {
  COMPLETE = 20,
  INCOMPLETE = 10
}

export enum ResumeSectionByStatus {
  personalInformation = 'personalInformationStatus',
  skills = 'skillStatus',
  workExperiences = 'workExperienceStatus',
  educations = 'educationStatus',
  languages = 'languageStatus'
}
