import { Font, StyleSheet, View, Text } from '@react-pdf/renderer';
import React, { useState } from 'react';

import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { templateColors } from '../utils/constants';
import { verifyStringLength } from '../utils/stringUtils';
import { IEducation } from '../../Models/IEducation';

interface IEducationProps {
  data: IEducation[];
  highLightColor: string;
}

const styles = (textColor = templateColors.red) =>
  StyleSheet.create({
    title: {
      fontSize: 14,
      marginBottom: 10,
      marginTop: 14,
      color: textColor
    },
    date: {
      fontSize: 10,
      color: textColor
    },
    career: {
      fontFamily: 'Helvetica Bold',
      fontSize: 10
    },
    school: {
      fontSize: 10
    },
    descriptionContainer: {
      marginTop: 7,
      marginBottom: 12
    }
  });

Font.register({
  family: 'Helvetica Bold',
  src: `/fonts/Helvetica/HelveticaNeueBold.ttf`
});

const MAX_LENG_INPUT = 55;
const Education: React.FC<IEducationProps> = ({ data, highLightColor }) => {
  const [arrayEducations] = useState(data.filter((item) => item.enabled));

  const getEducationDate = (startDate: string, endDate: string) => {
    startDate = startDate ? startDate.replace('-', ' ') : '';
    endDate = endDate ? endDate.replace('-', ' ') : 'Present';
    return startDate.concat(' - ').concat(endDate);
  };

  return (
    <React.Fragment>
      {arrayEducations &&
        arrayEducations.map((item, index) => (
          <View key={index}>
            <View wrap={false}>
              {index === 0 && <Text style={styles(highLightColor).title}> Education </Text>}
              <View>
                <Text style={styles(highLightColor).date}>
                  {' '}
                  {getEducationDate(item.startDate, item.endDate)}
                </Text>
                <Text style={styles().career}> {verifyStringLength(item.career, MAX_LENG_INPUT)} </Text>
                <Text style={styles().school}> {verifyStringLength(item.school, MAX_LENG_INPUT)} </Text>
              </View>
            </View>
            <View style={styles().descriptionContainer}>
              <HTMLContent data={item.description} />
            </View>
          </View>
        ))}
    </React.Fragment>
  );
};

export default Education;
