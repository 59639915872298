import { StyleSheet } from '@react-pdf/renderer';

const OPACITY_20 = '26';

interface ISquareLevelStyles {
  primaryColor?: string;
  secondaryColor?: string;
  widthBase?: string | number;
  heightBase?: string | number;
  itemPercentage?: string;
}

export const styles = ({
  primaryColor = '#0079A3',
  secondaryColor = '#FFFFFF',
  widthBase = 33.36,
  heightBase = 5.1975,
  itemPercentage = '20%'
}: ISquareLevelStyles) =>
  StyleSheet.create({
    base: {
      width: widthBase,
      height: heightBase,
      borderRadius: 1.5,
      border: `0.75 solid ${secondaryColor}`
    },
    background: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      borderRadius: 1
    },
    brightness: {
      backgroundColor: `${secondaryColor}`,
      borderStyle: 'dashed',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0.75,
      borderBottomRightRadius: 0.75,
      width: '95%',
      height: '32%',
      opacity: 0.1,
      position: 'absolute',
      left: 1,
      top: 2.2
    },
    item: {
      width: itemPercentage,
      height: '100%',
      backgroundColor: `${primaryColor}${OPACITY_20}`,
      borderRightColor: `${secondaryColor}`,
      borderRightStyle: 'solid',
      borderRightWidth: 0.5625
    },
    lastItem: {
      borderRightWidth: 0,
      borderTopRightRadius: 0.75,
      borderBottomRightRadius: 0.75
    },
    firstItem: {
      borderTopLeftRadius: 0.75,
      borderBottomLeftRadius: 0.75
    },
    check: {
      opacity: 1
    }
  });
