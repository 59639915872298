import { View, Text } from '@react-pdf/renderer';
import React from 'react';

import { styles } from './ExperienceSection.styles';
import HTMLContent from '../utils/CommonPDFUtils/HTMLContent';
import { verifyStringLength } from '../utils/stringUtils';
import { IWorkExperience } from '../../Models/IWorkExperience';

interface IExperiencesProps {
  data: IWorkExperience[];
  highLightColor: string;
}

interface IExperienceProps {
  data: IWorkExperience;
  highLightColor: string;
  indexItem: number;
}

const skillMaxLength = 55;

const Experience: React.FC<IExperienceProps> = ({ data, highLightColor, indexItem }) => {
  const { client, description, endDate, startDate, jobTitle, profile, skills } = data;
  const jobTitleText = jobTitle.replace(/\s+/g, ' ').trim();
  const profileText = profile.replace(/\s+/g, ' ').trim();
  const textStartDate = startDate ? startDate.substr(4) : ' ';
  const textEndDate = endDate ? endDate.substr(4) : 'Present';
  const existsProfile = Boolean(profileText);
  const jobTitleStyle = existsProfile ? styles().ellipsis : {};

  return (
    <>
      <View wrap={false}>
        {indexItem === 0 && <Text style={styles(highLightColor).sectionTitle}>Experience</Text>}
        <View style={{ flexDirection: 'row' }}>
          <View style={styles().contentLeft}>
            <Text style={[styles().date, styles(highLightColor).startDate]}>{textStartDate}</Text>
            <Text style={[styles().date, styles().lineRight]}>{textEndDate}</Text>
          </View>
          <Text style={styles(highLightColor).point}>•</Text>
          <View style={styles().contentRight}>
            <View style={styles(highLightColor).jobContainer}>
              <Text style={jobTitleStyle}>{jobTitleText || ' '}</Text>
              {existsProfile && <Text style={styles().divider}> </Text>}
              <Text style={styles().ellipsis}>{profileText}</Text>
            </View>
            <Text style={[styles().client, styles().lineLeft]}>{client}</Text>
          </View>
        </View>
      </View>
      <View style={[styles().content, styles().lineLeft]}>
        <View style={styles().skillContent}>
          {skills.map((name, index) => (
            <View key={`expirienceSkill-${index}`} style={styles().skillBox} wrap={false}>
              <Text>{verifyStringLength(name, skillMaxLength)}</Text>
            </View>
          ))}
        </View>
        <View style={styles().descriptionContainer}>
          <HTMLContent data={description} />
        </View>
      </View>
    </>
  );
};

const ExperienceSection: React.FC<IExperiencesProps> = ({ data, highLightColor }) => {
  const existsData = data.length > 0 || null;
  return (
    existsData && (
      <View style={styles().sectionContainer}>
        {data.map((experience, index) => (
          <Experience
            indexItem={index}
            key={`experienceItem-${index}`}
            data={experience}
            highLightColor={highLightColor}
          />
        ))}
      </View>
    )
  );
};

export default ExperienceSection;
