import React, { useState, useEffect } from 'react';

import { colorOptions } from './ColorOptions';
import styles from './HighLightColor.module.scss';

interface IHighLightColor {
  defaultColor: string;
  onChange: Function;
}

const HighLightColor: React.FC<IHighLightColor> = ({ defaultColor, onChange }) => {
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  const handleOnchange = (color: string) => {
    onChange(color);
    setSelectedColor(color);
  };

  const pickedColor = (color: string) => {
    return color === selectedColor;
  };

  useEffect(() => {
    setSelectedColor(defaultColor);
  }, [defaultColor]);

  return (
    <section className={styles.HightLightColorContainer}>
      <div className={styles.iconSection}>
        <img src='/images/fill.png' />
      </div>
      <div className={styles.colorSection}>
        {colorOptions.map((option) => (
          <div
            key={option.selected}
            className={styles.picked}
            onClick={() => handleOnchange(option.selected)}
            style={pickedColor(option.selected) ? { border: `1px solid ${option.cssColor}` } : {}}
          >
            <div className={styles.chooseColor} style={{ backgroundColor: option.cssColor }}></div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HighLightColor;
