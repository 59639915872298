import { ActivePictureEnum, IPictureData } from '../../Models/IPersonalInformation';

const convertToRoman = (num: number) => {
  const decimalValue = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const romanNumeral = ['m', 'cm', 'd', 'cd', 'c', 'xc', 'l', 'xl', 'x', 'ix', 'v', 'iv', 'i'];

  let romanized = '';

  for (let index = 0; index < decimalValue.length; index++) {
    while (decimalValue[index] <= num) {
      romanized += romanNumeral[index];
      num -= decimalValue[index];
    }
  }

  return romanized;
};

const parseStringForPdf = (text: String) => {
  let textContent = '';
  for (const eachCharacter of text) {
    if (eachCharacter === '\t') {
      textContent += '  ';
    } else {
      textContent += eachCharacter;
    }
  }
  return textContent;
};

const pictureToShow = (pictures: IPictureData) => {
  const { systemPicture = '', customPicture = '', activePicture } = pictures;
  const isTherePicture = !!systemPicture || customPicture !== '';
  const isEnabled = activePicture !== ActivePictureEnum.DISABLE_PICTURE;
  const response = {
    displayPicture: isTherePicture && isEnabled,
    pictureToShow:
      activePicture === ActivePictureEnum.SYSTEM_PICTURE
        ? systemPicture
        : activePicture === ActivePictureEnum.CUSTOM_PICTURE
        ? customPicture
        : ''
  };
  return response;
};

export { convertToRoman, parseStringForPdf, pictureToShow };
