import { StyleSheet } from '@react-pdf/renderer';

import { templateColors } from '../utils/constants';

export const styles = (marginRightValue = 0, textColor = templateColors.red) =>
  StyleSheet.create({
    sectionContainer: {
      position: 'relative',
      width: 92.25
    },
    sectionTitle: {
      fontSize: 12,
      color: textColor,
      fontFamily: 'Helvetica Neue',
      marginBottom: 9
    },
    skillsContainer: {
      position: 'relative',
      width: 92.25
    },
    areaContainer: {
      marginBottom: 6.75
    },
    titleArea: {
      marginBottom: 6,
      fontSize: 9,
      fontWeight: 'medium',
      fontFamily: 'Helvetica Neue'
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    content: {
      width: 92.25,
      flexDirection: 'row',
      marginTop: 6.75,
      marginBottom: 8.25
    },
    levels: {
      marginRight: marginRightValue,
      marginTop: 5,
      color: textColor
    },
    skillName: {
      width: 63.75,
      fontSize: 7.5,
      fontFamily: 'Helvetica Neue',
      maxLines: 4,
      textOverflow: 'ellipsis'
    }
  });
