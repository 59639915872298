import { UserManager } from '../userManager.service';

export class AuthService {
  static instance: AuthService | null = null;
  userManager: UserManager;

  constructor() {
    this.userManager = new UserManager();
  }

  static getInstance() {
    if (AuthService.instance === null) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }
}
