import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  summaryContainer: {
    width: 370,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  contentSection: {
    width: '340',
    fontSize: 9,
    fontFamily: 'Georama',
    fontWeight: 'medium'
  }
});
