import { formValidationRules } from './formRules';

export const personalInformationSchema = {
  fullName: formValidationRules.fullName,
  email: formValidationRules.email,
  mainJob: formValidationRules.mainJob,
  profesionalSummary: formValidationRules.profesionalSummary
};

export const skillSchema = {
  area: formValidationRules.area,
  level: formValidationRules.level,
  skill: formValidationRules.skill
};

export const workExperienceSchema = {
  jobTitle: formValidationRules.jobTitle,
  client: formValidationRules.client,
  startDate: formValidationRules.startDate,
  endDate: formValidationRules.endDate,
  description: formValidationRules.description
};

export const educationSchema = {
  school: formValidationRules.school,
  career: formValidationRules.career,
  startDate: formValidationRules.startDate,
  endDate: formValidationRules.endDate
};

export const languageSchema = {
  language: formValidationRules.language,
  reading: formValidationRules.reading,
  listening: formValidationRules.listening,
  writing: formValidationRules.writing,
  speaking: formValidationRules.speaking
};
